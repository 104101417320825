import React, { useEffect, useState } from 'react'
import Pagination from 'react-js-pagination'
import NotFound from '../../components/not-found/NotFound'
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded'
import { Link, useSearchParams } from 'react-router-dom'
import IconButton from '../../components/Button/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import BlockIcon from '@mui/icons-material/Block'
import Switch from '../../components/Switch/Switch'
import { SecondaryButton } from '../../components/Button/Button'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { dateMonthFormat, DatePicker, Select } from '../../components/Form'
import { toast } from 'sonner'
import useFullPageLoader from '../../hooks/useFullPageLoader'
import useDebounce from '../../hooks/useDebounce'
// import { getOrderApi } from '../../services/orderManagement'
import { SiMicrosoftexcel } from 'react-icons/si'
import { IoSearch } from 'react-icons/io5'
import profileImage from '../../Assests/image/profile_not_found.png'
import { user } from '../../routes/route'
import { getLocalStorage, setLocalStorage } from '../../config/config'
import findSerialNumber from '../../utils/findSerialNumber'

import { toastMessage } from '../../utils/toastMessage'
import { fatchCategoryApi } from '../../services/categoryManagement'
import { charLimit } from '../../utils/charLimit'
import common from '../../routes/common'
import { FaFileExcel } from 'react-icons/fa'
// import UserStatus from './UserStatus'
import {
  dateFormat,
  dateFormatUtc,
  dateFormatWithTime,
  monthFormat,
} from '../../utils/dateFormat'
import { gender } from '../../utils/genderList'
import scrollToUp from '../../utils/scrollToUp'
import { CapitalizeFirstWord } from '../../utils/CapitalizeFirstWord'
import Loader from '../../comman/Loader'
import useFileDownloader from '../../hooks/useFileDownloader'
import csv from '../../Assests/image/csv.svg'
import {
  getbeforeConsultantPayoutApi,
  getConsultantPayoutApi,
  importExcelApi,
} from '../../services/consultantPayout'
import ModalBox from '../../components/modal/ModalBox'
import commonMessages from '../../utils/commonMessages'
import { Input } from '@mui/material'
import { regex } from '../../utils/regex'
import useButtonLoader from '../../hooks/UseButtonLoader'
import ReactDatePicker from 'react-datepicker'

const { USER_DETAIL } = user
const { NOT_FOUND } = common

// console.log('Gender', gender)

const ConsultantPayout = () => {
  let URL = process.env.REACT_APP_API_URL
  const [searchParam, setSearchParam] = useSearchParams()
  let activePage = +searchParam.get('page') || 1

  const [search, setSearch] = useState(
    getLocalStorage('payoutList', 'search') || ''
  )

  const debounceValue = useDebounce(search || '', 300)
  const [total, setTotal] = useState(0)
  const [payoutList, setPayoutList] = useState([])
  const [problemList, setProblemList] = useState([])
  const [statusModal, setStatusModal] = useState(false)
  const [statusValue, setStatusValue] = useState('')
  const [statusId, setStatusId] = useState('')
  const [buttonref, buttonLoader] = useButtonLoader('Import')
  const [exportbuttonref, exportbuttonLoader] = useButtonLoader('Export Payout')
  const [userType, setUserType] = useState('before')
  const genderValue = searchParam.get('gender') || ''
  const { excelFileRegex } = regex
  const { EXCEL_FORMAT } = commonMessages
  // const [statusSearch, setStatusSearch] = useState('')
  const fromDate = searchParam.get('fromDate') || ''
  const toDate = searchParam.get('toDate') || ''
  const problemValue = searchParam.get('problem') || ''
  const [importModal, setImportModal] = useState(false)
  const [file, setFile] = useState('')
  const [error, setError] = useState({})
  const [month, setMonth] = useState('')
  // const [endDateSearch, setEndDateSearch] = useState('')

  useEffect(() => {
    setLocalStorage('payoutList', 'search', search)
  }, [search])

  // const handleExcelDownload = async () => {
  //   try {
  //     setLoading(true)
  //     const sendData = {}
  //     const { data } = await excelDownloadHandler(sendData)
  //     console.log('Data', data)
  //     if (data.code === 200) {
  //       console.log('Date', data)
  //       return
  //     } else {
  //       setPayoutList([])
  //       setTotal(0)
  //       toastMessage(data.message, 'error', 'payoutList')
  //     }
  //   } catch (error) {
  //     console.log('error', error)
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  // handle all hooks
  const { loading, downloadFile } = useFileDownloader()

  const [loader, showLoader, hideLoader] = useFullPageLoader()

  const handleExcelDownload = () => {
    const url = `${URL}/admin/payout/export`

    const params = {}

    downloadFile(
      url,
      `user-excel-file-${dateFormat(new Date())}`,
      params,
      exportbuttonLoader
    )
  }

  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true)
    setStatusId(id)
    setStatusValue(status)
  }

  // gender handler

  const genderHandler = (value) => {
    if (genderValue === value) {
      searchParam.delete('gender')
    } else {
      searchParam.set('gender', value)
    }
    searchParam.delete('page')
    setSearchParam(searchParam)
  }

  // Problem handler

  const problemValueHandler = (value) => {
    if (problemValue === value) {
      searchParam.delete('problem')
    } else {
      searchParam.set('problem', value)
    }
    searchParam.delete('page')
    setSearchParam(searchParam)
  }

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set('page', event)
    setSearchParam(searchParam)
  }

  //------------------consultant payout -listing-Api----------------------------------
  const getpayoutList = async (debounceValue) => {
    try {
      showLoader()
      const sendData = {
        page: `${activePage}`,
        limit: '10',
      }
      if (debounceValue) {
        sendData.page = '1'
        sendData.search = debounceValue
        searchParam.delete('page')
        setSearchParam(searchParam)
      }
      if (fromDate) {
        sendData.fromDate = fromDate
      }
      if (toDate) {
        sendData.toDate = toDate
      }

      let data
      if (userType === 'before') {
        const response = await getbeforeConsultantPayoutApi(sendData)
        console.log(response)
        data = response.data
      } else if (userType === 'after') {
        const response = await getConsultantPayoutApi(sendData)
        data = response.data
      }

      // const { data } =
      if (data.code === 200) {
        if (userType === 'before') {
          setPayoutList(data?.result?.finalList || [])
        } else if (userType === 'after') {
          setPayoutList(data?.result?.list || [])
        }
        setTotal(data?.result?.total || 0)
        scrollToUp()
      } else {
        setPayoutList([])
        setTotal(0)
        toastMessage(data.message, 'error', 'payoutList')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      hideLoader()
    }
  }

  const validation = () => {
    let status = true
    let errors = {}
    if (!file) {
      errors['file'] = 'File is mandatory'
      status = false
    }
    if (!month) {
      errors['month'] = 'Select Month'
      status = false
    }
    setError(errors)
    return status
  }

  const fileHandler = (e) => {
    e.preventDefault()
    if (e.target.files.length > 0) {
      if (excelFileRegex.test(e.target.files[0].name)) {
        setFile(e.target.files[0])
      } else {
        e.target.value = ''
        setFile('')
        toastMessage(EXCEL_FORMAT, 'error', 'imageFormat')
      }
    } else {
      e.target.value = ''
      setFile('')
    }
  }

  console.log(file, 'File')

  const importFileHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true)
        const formData = new FormData()
        formData.append('payOut', file)
        formData.append('payoutMonth', monthFormat(month))
        const res = await importExcelApi(formData)
        if (res?.data?.code === 200) {
          toastMessage(res?.data?.message, 'success', 'importExcel')
          setImportModal(false)
          setError('')
          setMonth('')
          setFile('')
        } else {
          toastMessage(res.data.message, 'error', 'importExcel')
        }
      } catch (e) {
        console.log('Error:', e.message)
      } finally {
        buttonLoader(false)
      }
    }
  }

  // problem list handler function

  //   const fetchProblemHandler = async () => {
  //     try {
  //       showLoader();
  //       const { data } = await fatchCategoryApi();
  //       if (data.code === 200) {
  //         setProblemList(data?.result?.categoryList || []);
  //       } else {
  //         setProblemList([]);
  //         toastMessage(data.message, "error", "categoryList");
  //       }
  //     } catch (error) {
  //       console.log("error", error);
  //     } finally {
  //       hideLoader();
  //     }
  //   };

  const userTypeHandler = (type) => {
    setUserType(type)
    searchParam.set('type', type)
    searchParam.delete('fromDate')
    searchParam.delete('toDate')
    searchParam.delete('page')
    setSearchParam(searchParam)
  }

  const handleFromDate = (date) => {
    const utcDateString = dateFormatUtc(date)
    searchParam.delete('page')
    searchParam.set('fromDate', utcDateString)
    setSearchParam(searchParam)
  }
  const handleToDate = (date) => {
    const utcDateString = dateFormatUtc(date)
    searchParam.delete('page')
    searchParam.set('toDate', utcDateString)
    setSearchParam(searchParam)
  }

  useEffect(() => {
    if (Number(activePage) === 1) {
      searchParam.delete('page')
    }
    setSearchParam(searchParam)
    getpayoutList(debounceValue)
  }, [searchParam, debounceValue, fromDate && toDate])

  useEffect(() => {
    const userTypeParam = searchParam.get('type')
    if (userTypeParam) setUserType(userTypeParam)
  }, [searchParam])

  console.log(error, 'Error')

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            {/* <div className="user_management_heading"> */}
            <h4 className="Title">Consultant Payout</h4>
            <div className="block_user_button">
              <button
                className="mx-4"
                onClick={handleExcelDownload}
                ref={exportbuttonref}
              >
                Export Payout
              </button>
              <button onClick={() => setImportModal(true)}>
                Import Payout
              </button>
            </div>
            {/* </div> */}
          </div>
          <div className="FilterBox FilterArea">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className={`form-control ${search && 'active'}`}
                placeholder="Search"
                value={search || ''}
                style={{ '--color': '#FF8989' }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">
                <IoSearch />
              </span>
            </div>
            <div className="consultant_button_wrapper">
              <div className="banner_button_wrapper">
                <button
                  className={`_button ${
                    userType === 'before' && 'banner_active'
                  }`}
                  onClick={() => userTypeHandler('before')}
                >
                  Before Payout
                </button>
                <button
                  className={`_button ${
                    userType === 'after' && 'banner_active'
                  }`}
                  onClick={() => userTypeHandler('after')}
                >
                  After Payout
                </button>
              </div>
            </div>

            <div className="group_filter_value">
              <div className="form-group date_picker">
                <DatePicker
                  className={`form-control ${fromDate && 'active'}`}
                  name="startDate"
                  selected={fromDate}
                  placeholder="From Date"
                  onChange={(date) => handleFromDate(date)}
                  // selectsStart
                  // startDate={startDateSearch}
                  isClearable
                  maxDate={Date.now()}
                />
              </div>

              {/* TO Date */}
              <div className="form-group date_picker">
                <DatePicker
                  className={`form-control ${toDate && 'active'}`}
                  name="toDate"
                  selected={toDate}
                  placeholder="To Date"
                  onChange={(date) => handleToDate(date)}
                  // selectsStart
                  // startDate={startDateSearch}
                  isClearable
                  minDate={fromDate}
                />
              </div>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Date Of Joining</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Withdraw Amount (INR)</th>
                  <th>PAN</th>
                  <th>Phone Number</th>
                  <th>Bank Account Name</th>
                  {userType === 'after' && <th>Bank Account Number</th>}
                  <th>Bank IFSC code</th>
                  {userType === 'before' && <th>Bank Holder Name</th>}
                  {userType === 'after' && <th>Transaction ID</th>}
                  {userType === 'after' && <th>Payment Date</th>}
                </tr>
              </thead>
              <tbody>
                {payoutList.length > 0 ? (
                  payoutList.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                        {userType === 'after' && (
                          <tr>
                            <td>{findSerialNumber(index, activePage, 10)}</td>
                            <td>{dateFormatWithTime(user?.dateOfJoin)}</td>
                            <td>{user.name || '---'}</td>
                            <td>{user.email || '---'}</td>
                            <td>
                              {`${Number(user?.monthlyPayoutAmount).toFixed(
                                2
                              )} INR` || '---'}
                            </td>
                            <td>{user?.panNumber || '---'}</td>
                            <td>{user?.phoneNumber || '---'}</td>
                            <td>{user?.accountName || '---'}</td>
                            <td>{user?.accountNumber || '---'}</td>
                            <td>{user?.ifsc || '---'}</td>
                            <td>{user?.transactionId || '---'}</td>
                            <td>
                              {dateFormatWithTime(user?.payOutDate) || '---'}
                            </td>
                          </tr>
                        )}
                        {userType === 'before' && (
                          <tr>
                            <td>{findSerialNumber(index, activePage, 10)}</td>
                            <td>{dateMonthFormat(user?.createdAt)}</td>
                            <td>{user.name || '---'}</td>
                            <td>{user.email || '---'}</td>
                            <td>
                              {`${Number(user?.coins).toFixed(2)} INR` || '---'}
                            </td>
                            <td>{user?.panNumber || '---'}</td>
                            <td>{user?.mobile || '---'}</td>
                            <td>{user?.bankName || '---'}</td>
                            <td>{user?.bankHolderName || '---'}</td>
                            <td>{user?.ifsc || '---'}</td>
                            <td>{user?.transactionId || '---'}</td>
                            <td>
                              {dateFormatWithTime(user?.payOutDate) || '---'}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    )
                  })
                ) : (
                  <NotFound
                    cols={12}
                    msg="Payout not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total > 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}

          <ModalBox
            status={importModal}
            onClose={() => {
              setImportModal(false)
              setError('')
              setMonth('')
              setFile('')
            }}
            title={'Import File'}
            closeIcon
            size={'sm'}
            custom
            customizedFooter={
              <div className="model_custom_footer">
                {/* <p>{MESSAGE_SIZE}</p> */}
                <button
                  className="btn_size "
                  ref={buttonref}
                  onClick={importFileHandler}
                >
                  Import
                </button>
              </div>
            }
          >
            <div>
              <div className="form-group my-2">
                <label className="my-1 text-dark w-100">Select Month</label>
                <input
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  name="file"
                  required
                  onChange={(e) => fileHandler(e)}
                  placeholder="Upload Excel File"
                  className={`form-control `}
                  label={'Export Excel File'}
                  //   error={!file && error}
                  // maxLength={30}
                />
                {error.file && !file && (
                  <span className="invalid-feedback d-block">{error.file}</span>
                )}
              </div>

              {file && !error.file && (
                <div className="cstm_icon_preview">
                  <FaFileExcel />{' '}
                </div>
              )}

              <div className="form-group mb-2">
                <label className="my-1 text-dark w-100">Select Month</label>
                <DatePicker
                  selected={month}
                  onChange={(date) => setMonth(date)}
                  className={`form-control multi_search_date`}
                  isClearable
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  showFourColumnMonthYearPicker
                  maxDate={new Date()}
                />
                {error.month && (
                  <span className="invalid-feedback d-block">
                    {error.month}
                  </span>
                )}
              </div>

              {/* <div>
          {image && (
            <>
              <Avatar
                variant="square"
                sx={{
                  width: '100%',
                  borderRadius: 1,
                  height: 140,
                  border: '1px solid #FF8989',
                }}
                src={image && URL.createObjectURL(image)}
              />
            </>
          )}
        </div> */}
            </div>
          </ModalBox>
        </div>
      </div>
    </>
  )
}

export default ConsultantPayout
