import React, { useEffect, useState, Fragment } from "react";
import { toastMessage } from "../../utils/toastMessage";
import { Link, useParams, useSearchParams } from "react-router-dom";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import {
  changeMemberStatusApi,
  fetchCommunityDetailApi,
  fetchCommunityMembersListing,
} from "../../services/communityManagement";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Avatar, IconButton } from "@mui/material";
import profile from "../../Assests/image/profile_not_found.png";
import { CapitalizeFirstWord } from "../../utils/CapitalizeFirstWord";
import Switch from "../../components/Switch/Switch";
import CommunityMembers from "./CommunityMembers";
import scrollToUp from "../../utils/scrollToUp";
import { getLocalStorage } from "../../config/config";
import useDebounce from "../../hooks/useDebounce";
import Pagination from "react-js-pagination";
import NotFound from "../../components/not-found/NotFound";
import { charLimit } from "../../utils/charLimit";
import findSerialNumber from "../../utils/findSerialNumber";
import common from "../../routes/common";
import useButtonLoader from "../../hooks/UseButtonLoader";
import ModalBox from "../../components/modal/ModalBox";

const CommunityDetail = () => {
  const { id } = useParams();
  const [membersList, setMembersList] = useState([]);
  const [buttonref, buttonLoader] = useButtonLoader("Yes");
  const [membersStatusData, setMembersStatusData] = useState({});
  const [statusModal, setStatusModal] = useState(false);
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  const [search, setSearch] = useState(
    getLocalStorage("communityDetailList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);
  const [total, setTotal] = useState(null);
  const [communityDetail, setCommunityDetail] = useState({});
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const fetchCommunityDetail = async () => {
    try {
      showLoader();
      const { data } = await fetchCommunityDetailApi({ communityId: id });
      if (data.code === 200) {
        console.log(data?.result, "Response");
        toastMessage(data.message, "success", "communityDetail");
        setCommunityDetail(data?.result || {});
      } else {
        setCommunityDetail({});
        toastMessage(data.message, "error", "communityDetail");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  const fetchCommunityMembers = async () => {
    try {
      setMembersList([]);
      showLoader();
      const sendData = {
        page: activePage,
        limit: 10,
        communityId: id,
      };
      if (debounceValue) {
        sendData.page = 1;
        sendData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      const { data } = await fetchCommunityMembersListing(sendData);
      if (data.code === 200) {
        setMembersList(data?.result?.member || []);
        setTotal(data?.result?.total || 0);
      } else {
        setMembersList([]);
        setTotal(0);
        toastMessage(data.message, "error", "musicList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // Status Update Handler
  const statusUpdateHandler = (userId, status) => {
    setMembersStatusData({
      userId: userId,
      status: !status,
      communityId: id,
    });
    setStatusModal(true);
  };

  //--------------change-Member Status handler------------------------
  const changeMemberStatusHandler = async () => {
    try {
      buttonLoader(true);
      const { data } = await changeMemberStatusApi(membersStatusData);
      if (data?.code === 200) {
        setMembersList((prevListing) => {
          return prevListing.map((concern) => {
            if (concern.userId === membersStatusData?.userId) {
              return { ...concern, status: membersStatusData?.status };
            }
            return concern;
          });
        });
        toastMessage(data.message, "success", "concernStatus");
        setStatusModal(false);
        setMembersStatusData({});
      } else {
        toastMessage(data.message, "error", "concernStatus");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      buttonLoader(false);
    }
  };

  useEffect(() => {
    fetchCommunityDetail();
  }, []);

  console.log(communityDetail,"Community Detail");

  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete("page");
    }
    setSearchParam(searchParam);
    fetchCommunityMembers();
  }, [searchParam, debounceValue]);

  // console.log(membersList, "MembersList");

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <div className="user_detail_heading">
              {/* <h4 className="Title">User Management</h4> */}
              <Link className="back_icon" to={-2}>
                <IconButton>
                  <ArrowBackIosNewIcon className="back_button" />
                </IconButton>
              </Link>
              <h4 className="Title">Community Management</h4>
            </div>
            {/* <div className="d-flex">
            <div className="block_user_button me-2">
              <button>Emergency</button>
            </div>
            <div className="block_user_button me-2">
              <button>Boost Profile</button>
            </div>
            <div className="block_user_button me-2">
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  onClick={toggleSelectBox}
                  aria-expanded={isOpen ? "true" : "false"}
                >
                  Dropdown button
                </button>
                <ul
                  className={`dropdown-menu${isOpen ? " show" : ""}`}
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <Link
                      class="dropdown-item"
                      to="#"
                      onClick={showWarningHandler}
                    >
                      Warning
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="#">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="#">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
            <div className="block_user_button">
              <Link to={`/community-management/view/chat/${id}`}>
                {/* <AddIcon /> */}
                Go to chat
              </Link>
            </div>
          </div>
          {/* <div className="profile_container"> */}
          <div className="row">
            <div className="col-2">
              <div className="user_image user_icon">
                <figure>
                  <img
                    src={communityDetail.communityImage || profile}
                    alt={communityDetail.title}
                  />
                </figure>
              </div>
            </div>
            <div className="col-10 user_detail_container">
              <ul className="selected_user_detail">
                <li>
                  <p>COMMUNITY NAME :</p>
                  <p>{communityDetail.title || "---"}</p>
                </li>
                <li>
                  <p>TOTAL MEMBERS :</p>
                  <p>{total}</p>
                </li>
                <li>
                  <p>ACTIVE MEMBERS :</p>
                  <p>{communityDetail && communityDetail?.activeMember && communityDetail?.activeMember.length}</p>
                </li>
              </ul>
            </div>
            {/* <div className="col-5 user_detail_container">
            <ul className="selected_user_detail">
              <li>
                <p>Gender:</p>
                <p>
                  {communityDetail.gender
                    ? CapitalizeFirstWord(communityDetail.gender)
                    : "---"}
                </p>
              </li>
              <li>
                <p>Pan Number:</p>
                <p>{communityDetail.timeOfBirth || "---"}</p>
              </li>
              <li>
                <p>Go Live:</p>
                <Switch
                  checked={communityDetail.goLive}

                  // onChange={() =>
                  //   statusUpdateHandler(
                  //     communityDetail._id,
                  //     communityDetail.status
                  //   )
                  // }
                />
              </li>
              <li>
                <p>Address:</p>
                <p>{communityDetail.address || "---"}</p>
              </li>
            </ul>
          </div> */}
          </div>
          {/* </div> */}

          <div>
            <h4 className="my-2">Description</h4>
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: communityDetail?.description,
                }}
              />
            </div>
          </div>

          {/* <div className="row consultant-container">
          <div className="col-4 user_detail_container ">
            <ul className="selected_user_detail">
              <li>
                <p>Wallet:</p>
                <p>{communityDetail.wallet || "---"}</p>
              </li>
              <li>
                <p>Language:</p>
                {communityDetail &&
                  communityDetail.language &&
                  communityDetail.language.map((data, index) => (
                    <Fragment key={index}>
                      <p>{data}</p>
                      {index !== communityDetail.language.length - 1 && (
                        <p>,</p>
                      )}
                    </Fragment>
                  ))}
              </li>
              <li>
                <p>Education:</p>
                <p>{communityDetail?.EducationDetail?.education || "---"}</p>
              </li>
            </ul>
          </div>
          <div className="col-4 user_detail_container">
            <ul className="selected_user_detail">
              <li>
                <p>Earning:</p>
                <p>{communityDetail.earning || "---"}</p>
              </li>
              <li>
                <p>Per/Min Rate:</p>
                <p>{communityDetail.price || "---"}</p>
              </li>
              <li>
                <p>Certification:</p>
                <p>{`${communityDetail.educationCertification || "---"}`}</p>
              </li>
            </ul>
          </div>
          <div className="col-4 user_detail_container">
            <ul className="selected_user_detail">
              <li>
                <p>Last Month:</p>
                <p>{communityDetail.lastMonth || "---"}</p>
              </li>
              <li>
                <p>Total Warning:</p>
                <p>{communityDetail.totalWarning || "---"}</p>
              </li>
              <li>
                <p>Rating & Review:</p>
                <p>{communityDetail.RatingAndReview || "---"}</p>
              </li>
              <li>
                <p>Address:</p>
                <p>{communityDetail.address || "---"}</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          {communityDetail.expertise?.length > 0 && (
            <div className="col-6">
              <div className="mt-4">
                <h5>Expertise</h5>
                <CommonListing problemList={communityDetail.expertise} />
              </div>
            </div>
          )}
        </div>
        <div className="row">
          {communityDetail.primarySkills?.length > 0 && (
            <div className="col-6">
              <div className="mt-4">
                <h5>Primary Skills</h5>
                <CommonListing problemList={communityDetail.primarySkills} />
              </div>
            </div>
          )}
        </div>
        <div className="row mb-2">
          {communityDetail.otherSkills?.length > 0 && (
            <div className="col-6">
              <div className="mt-4">
                <h5>Other Skills</h5>
                <CommonListing
                  problemList={communityDetail.otherSkills.filter(
                    (skill) => !communityDetail.primarySkills.includes(skill)
                  )}
                />
              </div>
            </div>
          )}
        </div> */}
          {/* {communityDetail.skills?.length > 0 && (
            <div className="col-6">
              <div className="mt-4">
                <h5>Skill</h5>
                <CommonListing problemList={communityDetail.skills} categoryList={skillList} />
              </div>
            </div>
          )} */}

          {/* <div className="tab_table">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => {
              setKey(k)
            }}
            className="mb-3 wishlist_activ"
          >
            {tabData &&
              tabData.length > 0 &&
              tabData.map((tab) => (
                <Tab key={tab.id} eventKey={tab.eventKey} title={tab.title}>
                  <div className="target_wrapper">
                    {key === 'remedy' && <RemedyTable />}
                    {key === 'consultant' && <ConsultantHistoryTable />}
                  </div>
                </Tab>
              ))}
          </Tabs>
        </div> */}
          <div className="my-4">
            <h5>Members</h5>
            <div>
              {/* <CommunityMembers /> */}

              <div className="FilterBox FilterArea ">
                <div className="form-group search_lg_size userSearch">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search"
                    value={search || ""}
                    style={{ "--color": "#FF8989" }}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="user_search_icon">{common.SEARCH_ICON}</span>
                </div>
              </div>
              <div className="content-table">
                <table>
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Image</th>
                      <th>Gender</th>
                      <th>Mobile No.</th>
                      <th className="text-center"> Action </th>
                    </tr>
                  </thead>

                  <tbody>
                    {membersList.length > 0 ? (
                      membersList.map((community, index) => {
                        return (
                          <React.Fragment key={community._id}>
                            <tr>
                              <td>{findSerialNumber(index, activePage, 10)}</td>
                              <td>{charLimit(community?.name) || "---"}</td>
                              <td>{community?.email}</td>
                              <td>
                                <Avatar
                                  id={community._id}
                                  alt={community.name}
                                  src={community.image}
                                  sx={{
                                    width: 50,
                                    height: 50,
                                    cursor: "pointer",
                                    borderRadius: 2,
                                  }}
                                  // onClick={() => showImagePreviewHandler(community)}
                                />
                              </td>
                              <td>{community?.gender}</td>
                              <td>{community?.mobile}</td>

                              <td>
                                <div className="action_button">
                                  {/* <Link to={`${COMMUNITY_DETAIL}/${community._id}`}>
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                >
                                  <VisibilityIcon className="eye_icon" />
                                </IconButton>
                              </Link> */}
                                  {/* <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() =>
                                  updateCommunityHandler(community)
                                }
                              >
                                <EditIcon className="edit_icon" />
                              </IconButton> */}
                                  <Switch
                                    checked={community.status}
                                    onChange={() =>
                                      statusUpdateHandler(
                                        community.userId,
                                        community.status
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <NotFound
                        cols={7}
                        msg="Members not found!"
                        // icon={NOT_FOUND}
                        loader={loader}
                      />
                    )}
                  </tbody>
                </table>
              </div>
              {total >= 10 ? (
                <div className="pagination-wrapper">
                  <div className="Pagination">
                    <Pagination
                      activePage={activePage}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      itemsCountPerPage={10}
                      totalItemsCount={total}
                      pageRangeDisplayed={10}
                      onChange={handlePageChange}
                      hideDisabled={true}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* {statusModal && (
      <UserStatus
        show={statusModal}
        close={closeModel}
        setUserDetail={setUserDetail}
        setListing={''}
        status={statusValue}
        id={statusId}
      />
    )} */}
      <ModalBox
        status={statusModal}
        onClose={() => {
          setStatusModal(false);
          setMembersStatusData({});
        }}
        title="Change  Status"
        description="Do you want to change the status of this member?"
        action={changeMemberStatusHandler}
        ref={buttonref}
      />
    </>
  );
};

export default CommunityDetail;
