import React from 'react'
import ModalBox from '../../components/modal/ModalBox'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { toastMessage } from '../../utils/toastMessage'
import { approveConsultantStatus } from '../../services/consultantManagement'

const ApprovedConsultant = ({
  show,
  close,
  setListing,
  id,
  setConsultantCount,
  approveStatus,
}) => {
  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader('Yes')

  // handle all function

  //--------------change-status-category------------------------
  const approveConsultantHandler = async () => {
    try {
      buttonLoader(true)
      let sendData = { consultantId: id, approve: approveStatus }
      const { data } = await approveConsultantStatus(sendData)
      if (data.code === 200) {
        setListing((prevListing) => {
          return prevListing.filter((consultant) => consultant._id !== id)
        })
        setConsultantCount((prevConsultantCount) => {
          if (prevConsultantCount > 0) {
            return prevConsultantCount - 1
          }
          return prevConsultantCount
        })
        close()
        toastMessage(data.message, 'success', 'approveConsultant')
      } else {
        toastMessage(data.message, 'error', 'approveConsultant')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      buttonLoader(false)
    }
  }
  return (
    <ModalBox
      status={show}
      onClose={close}
      title={`${approveStatus ? 'Approve' : 'Disapprove'} consultant`}
      description={`Do you want to ${
        approveStatus ? 'approve' : 'disapprove'
      } this consultant?`}
      action={approveConsultantHandler}
      ref={buttonref}
    />
  )
}

export default ApprovedConsultant
