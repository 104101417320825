import React, { useState } from "react";
import ModalBox from "../../../../components/modal/ModalBox";
import { Input, TextArea } from "../../../../components/Form";
import { optimizeFunction } from "../../../../utils/regex";
import useButtonLoader from "../../../../hooks/UseButtonLoader";
import { toastMessage } from "../../../../utils/toastMessage";
import { addContentApi, editContentApi } from "../../../../services/cms";
import { Avatar } from "@mui/material";
import { checkImageDimensions } from "../../../../utils/checkImageDimension";
import commonMessages from "../../../../utils/commonMessages";
import { regex } from "../../../../utils/regex";

const EditSkill = ({
  setEditModal,
  editModal,
  getCmsDetails,
  selectedSkill,
  setSelectedSkill,
  userType,
  type,
}) => {
  const [buttonref, buttonLoader] = useButtonLoader("Update");
  //   const [skillInput, setSkillInput] = useState("");
  const [skillImage, setSkillImage] = useState("");
  const [error, setError] = useState({});
  const { IMAGE_FORMAT, MESSAGE_SIZE } = commonMessages;
  const { imageFileRegex } = regex;

  // console.log(selectedSkill,"type");

  const modalClose = () => {
    setError({});
    setEditModal(false);
  };

  const validation = () => {
    let status = true;
    const errors = {};
    if (!selectedSkill.title) {
      errors["title"] = "Skill is mandatory";
      status = false;
    }
    setError(errors);
    return status;
  };

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        // const validSize = await checkImageDimensions(e.target.files[0]);
        // if (validSize) {
        //   setSkillImage(e.target.files[0]);
        // } else {
        //   e.target.value = "";
        //   setSkillImage("");
        setSkillImage(e.target.files[0]);
        // }
      } else {
        e.target.value = "";
        setSkillImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setSkillImage("");
    }
  };

  const editSkillHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("title", selectedSkill.title);
        formData.append("skillsId", selectedSkill._id);
        formData.append("contentType", type);
        formData.append("userType", selectedSkill?.userType);
        if (skillImage && skillImage?.name) {
          formData.append("skillImage", skillImage);
        }
        const { data } = await editContentApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "editSkill");
          getCmsDetails();
          modalClose();
        } else {
          toastMessage(data.message, "error", "editSkill");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  // console.log(skillImage,"SkillImage");
  return (
    <ModalBox
      status={editModal}
      onClose={modalClose}
      title={"Edit Skill"}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        <div className="model_custom_footer">
          {/* <p>{MESSAGE_SIZE}</p> */}
          <button
            className="btn_size"
            ref={buttonref}
            onClick={editSkillHandler}
          >
            Update
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={selectedSkill?.title || ""}
            required
            onChange={(e) => {
              const sanitizedText = optimizeFunction(e.target.value);
              setSelectedSkill({ ...selectedSkill, ["title"]: sanitizedText });
            }}
            placeholder="Skill"
            className={`form-control `}
            label={"Edit Skill"}
            error={!selectedSkill.title && error.title}
            maxLength={30}
          />
        </div>

        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="categoryImage"
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Skill Image"
            className={`form-control `}
            label={"Skill Image"}
            // error={!skillImage && error.image}
            // maxLength={30}
          />
        </div>

        <div>
          {
            <>
              <Avatar
                variant="square"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  height: 140,
                  border: "1px solid #FF8989",
                }}
                src={
                  skillImage && skillImage.name
                    ? URL.createObjectURL(skillImage)
                    : selectedSkill.skillImage
                }
              />
            </>
          }
        </div>
      </div>
    </ModalBox>
  );
};

export default EditSkill;
