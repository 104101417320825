import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import useDebounce from '../../hooks/useDebounce'
import useFullPageLoader from '../../hooks/useFullPageLoader'
import { Avatar, IconButton } from '@mui/material'
import Switch from '../../components/Switch/Switch'
import NotFound from '../../components/not-found/NotFound'
import Pagination from 'react-js-pagination'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { meditation } from '../../routes/route'
import common from '../../routes/common'
import { toastMessage } from '../../utils/toastMessage'
import findSerialNumber from '../../utils/findSerialNumber'
import { charLimit } from '../../utils/charLimit'
import EditIcon from '@mui/icons-material/Edit'
// import showFullSizeImage from '../../utils/showFullSizeImage'
import scrollToUp from '../../utils/scrollToUp'
import AddMeditationCategory from './AddMeditationCategory'
import { fetchMeditationApi } from '../../services/meditationManagement'
import MeditationCategoryStatus from './MeditationCategoryStatus'
import EditMeditationCategory from './EditMeditationCategory'
import { getLocalStorage, setLocalStorage } from '../../config/config'
import ImagePreviewModel from '../../components/imagePreview/ImagePreviewModel'
import AddIcon from '@mui/icons-material/Add';
const { NOT_FOUND } = common
const { MEDITATION_DETAIL } = meditation

const MeditationCategoryList = () => {
  // handle all state
  const [searchParam, setSearchParam] = useSearchParams()
  let activePage = +searchParam.get('page') || 1
  // const [search, setSearch] = useState(searchParam.get('query') || '')
  // let limit = +searchParam.get('limit') || 10
  const [search, setSearch] = useState(
    getLocalStorage('meditationCategory', 'search') || ''
  )
  const debounceValue = useDebounce(search || '', 300)

  const [addMeditationModal, setAddMeditationModal] = useState(false)
  const [editMeditationModel, setEditMeditationModel] = useState(false)
  const [meditationCategoryList, setMeditationCategoryList] = useState([])
  const [total, setTotal] = useState(0)
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [statusModal, setStatusModal] = useState(false)
  const [statusValue, setStatusValue] = useState('')
  const [statusId, setStatusId] = useState('')
  const [showImagePreviewModel, setShowImagePreviewModel] = useState(false)
  const [imagePreview, setImagePreview] = useState('')
  const [imageTitle, setImageTitle] = useState('')
  const [
    selectedMeditationCategoryDetail,
    setSelectedMeditationCategoryDetail,
  ] = useState(null)

  useEffect(() => {
    setLocalStorage('meditationCategory', 'search', search)
  }, [search])

  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true)
    setStatusId(id)
    setStatusValue(status)
  }

  // update music category handler
  const updateMeditationCategoryHandler = (data) => {
    setSelectedMeditationCategoryDetail(data || null)
    setEditMeditationModel(true)
  }

  //*********Handel Block********* */
  // const blockHandler = (id, status) => {
  //     setBlockModal(true);
  //     setBlockId(id);
  //     // setStatusValue(status);
  // };
  //*********Handle update********* */
  // const updateHandler = (id, enName, arName, imageUrl, categoryId) => {
  //   setUpdateModal(true)
  //   setUpdateSubCategoryData({
  //     id,
  //     enName,
  //     arName,
  //     imageUrl,
  //     categoryId,
  //   })
  // }
  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set('page', event)
    setSearchParam(searchParam)
  }

  // close model

  const closeModel = () => {
    setSelectedMeditationCategoryDetail(null)
    setStatusValue('')
    setStatusId('')
    setShowImagePreviewModel(false)
    setImagePreview('')
    setImageTitle('')
    setAddMeditationModal(false)
    setStatusModal(false)
    setEditMeditationModel(false)
  }

  // //------------------listing-music-Api----------------------------------
  const fetchMeditationHandler = async () => {
    try {
      setMeditationCategoryList([])
      showLoader()
      const sendData = {
        page: activePage,
        limit: 5,
      }
      if (debounceValue) {
        sendData.page = 1
        sendData.search = search
        searchParam.delete('page')
        setSearchParam(searchParam)
      }
      const { data } = await fetchMeditationApi(sendData)
      if (data.code === 200) {
        setMeditationCategoryList(data?.result?.list || [])
        setTotal(data?.result?.total || 0)
        scrollToUp()
      } else {
        setMeditationCategoryList([])
        setTotal(0)
        toastMessage(data.message, 'error', 'musicList')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      hideLoader()
    }
  }

  const showImagePreviewHandler = (meditationCategory) => {
    setShowImagePreviewModel(true)
    setImagePreview(meditationCategory.meditationImage)
    setImageTitle(meditationCategory.title)
  }

  // useEffect(() => {
  //   if (debounceValue) {
  //     searchParam.set('query', debounceValue)
  //   } else {
  //     searchParam.delete('query')
  //   }
  //   if (activePage === 1) {
  //     searchParam.delete('page')
  //   }
  //   setSearchParam(searchParam)
  //   fetchCategoryHandler(activePage, debounceValue)
  // }, [searchParam, debounceValue])
  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete('page')
    }
    setSearchParam(searchParam)
    fetchMeditationHandler()
  }, [searchParam, debounceValue])
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Meditation Management</h4>
            <div className="block_user_button">
              <button onClick={() => setAddMeditationModal(true)}>
               <AddIcon/> Add Category
              </button>
            </div>
          </div>
          <div className="FilterBox FilterArea ">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search || ''}
                style={{ '--color': '#FF8989' }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">{common.SEARCH_ICON}</span>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  {/* <th>Music Id</th> */}
                  <th>Meditation Title</th>
                  <th>Meditation Image</th>
                  <th>Number of Meditation</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>

              <tbody>
                {meditationCategoryList.length > 0 ? (
                  meditationCategoryList.map((meditation, index) => {
                    return (
                      <React.Fragment key={meditation._id}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 5)}</td>
                          {/* <td>{meditation._id || '---'}</td> */}
                          <td>{charLimit(meditation.title, 30) || '---'}</td>
                          <td>
                            <Avatar
                              id={meditation._id}
                              alt={meditation.title}
                              src={meditation.meditationImage}
                              sx={{
                                width: 50,
                                height: 50,
                                cursor: 'pointer',
                                borderRadius: 2,
                              }}
                              onClick={() =>
                                showImagePreviewHandler(meditation)
                              }
                            />
                          </td>

                          <td>{Number(meditation.meditationMedia) || 0}</td>
                          <td>
                            <div className="action_button">
                              <Link
                                to={`${MEDITATION_DETAIL}/${meditation.title}/${meditation._id}`}
                              >
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                >
                                  <VisibilityIcon className="eye_icon" />
                                </IconButton>
                              </Link>
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() =>
                                  updateMeditationCategoryHandler(meditation)
                                }
                              >
                                <EditIcon className="edit_icon" />
                              </IconButton>
                              <Switch
                                checked={meditation.status}
                                onChange={() =>
                                  statusUpdateHandler(
                                    meditation._id,
                                    meditation.status
                                  )
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })
                ) : (
                  <NotFound
                    cols={7}
                    msg="Meditation Categories not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total >= 5 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  itemsCountPerPage={5}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}

          {/* <FloatingButton
            onClick={() => setAddModalShow(true)}
            icon={<AddIcon />}
          /> */}
        </div>
        {addMeditationModal && (
          <AddMeditationCategory
            show={addMeditationModal}
            close={closeModel}
            listing={fetchMeditationHandler}
          />
        )}
        {statusModal && (
          <MeditationCategoryStatus
            show={statusModal}
            close={closeModel}
            setListing={setMeditationCategoryList}
            status={statusValue}
            id={statusId}
          />
        )}
        {editMeditationModel && (
          <EditMeditationCategory
            show={editMeditationModel}
            close={closeModel}
            meditationCategoryDetail={selectedMeditationCategoryDetail}
            setMeditationCategoryDetail={setSelectedMeditationCategoryDetail}
            meditationListing={fetchMeditationHandler}
          />
        )}
        {showImagePreviewModel && (
          <ImagePreviewModel
            show={showImagePreviewModel}
            close={closeModel}
            imageUrl={imagePreview}
            title={imageTitle}
          />
        )}
      </div>
    </>
  )
}

export default MeditationCategoryList
