import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Input, Select } from '../../components/Form'
import ModalBox from '../../components/modal/ModalBox'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { addBannerApi } from '../../services/bannerManagement'
import { fatchCategoryApi } from '../../services/categoryManagement'
import { charLimit } from '../../utils/charLimit'
import { checkImageAspectRatio } from '../../utils/checkImageDimension'
import commonMessages from '../../utils/commonMessages'
import { allowAlphaNumericWithSpace, regex } from '../../utils/regex'
import { toastMessage } from '../../utils/toastMessage'
const { imageFileRegex } = regex

const {
  ADD_BANNER_TITLE,
  CHOOSE_CATEGORY,
  BANNER_IMAGE,
  IMAGE_FORMAT,
  MESSAGE_SIZE,
} = commonMessages

const AddBanner = ({ show, close, userType, bannerList }) => {
  const [bannerValue, setBannerValue] = useState({
    title: '',
    categoryId: '',
    userType: userType,
  })
  const [categoryList, setCategoryList] = useState([])
  const [image, setImage] = useState('')
  const [error, setError] = useState({})
  const [buttonref, buttonLoader] = useButtonLoader('Save')

  // console.log("AddSubCategory",input)
  //----------vaidation------------
  const validation = () => {
    let errorMessage = {}
    let status = true

    // if (!bannerValue.title) {
    //   errorMessage['banner'] = ADD_BANNER_TITLE
    //   status = false
    // }

    if (!bannerValue.categoryId) {
      errorMessage['category'] = CHOOSE_CATEGORY
      status = false
    }

    if (!image.name) {
      errorMessage['image'] = BANNER_IMAGE
      status = false
    }

    setError(errorMessage)
    return status
  }

  // Add Banner image handler
  const imageHandler = async (e) => {
    e.preventDefault()
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        const validSize = await checkImageAspectRatio(e.target.files[0],'16/9')
        if (validSize) {
          setImage(e.target.files[0])
        } else {
          e.target.value = ''
          setImage('')
        }
      } else {
        e.target.value = ''
        setImage('')
        toastMessage(IMAGE_FORMAT, 'error', 'imageFormat')
      }
    } else {
      e.target.value = ''
      setImage('')
    }
  }

  //------------------onChange-input-value---------------------------
  const handleChange = (e) => {
    const { name, value } = e.target
    let updatedValue = value
    if (name === 'title') {
      updatedValue = allowAlphaNumericWithSpace(value)
    }
    setBannerValue({ ...bannerValue, [name]: updatedValue })
  }

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setImage('')
    setBannerValue({ title: '', categoryId: '' })
    setError({})
    close()
  }
  //--------------Add-sub-category------------------------
  const addBannerHandler = async () => {
    const status = validation()
    if (status) {
      try {
        buttonLoader(true)
        const formData = new FormData()
        if(bannerValue?.title){

          formData.append('title', bannerValue.title)
        }
        formData.append('categoryId', bannerValue.categoryId)
        formData.append('userType', userType)
        formData.append('bannerImage', image)
        const { data } = await addBannerApi(formData)
        if (data.code === 200) {
          toastMessage(data.message, 'success', 'addBanner')
          bannerList()
          modalClose()
        } else {
          toastMessage(data.message, 'error', 'addBanner')
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        buttonLoader(false)
      }
    }
  }
  //------------------listing-Api-category-dropdown--------------------------------
  const fatchCategory = async () => {
    try {
      setCategoryList([])
      const { data } = await fatchCategoryApi()
      if (data.code === 200) {
        setCategoryList(data?.result?.categoryList || [])
      } else {
        setCategoryList([])
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    fatchCategory()
  }, [])
  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={'Add Banner'}
      closeIcon
      size={'sm'}
      custom
      customizedFooter={
        <div className="model_custom_footer">
          {/* <p>{MESSAGE_SIZE}</p> */}
          <button
            className="btn_size "
            ref={buttonref}
            onClick={addBannerHandler}
          >
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={bannerValue?.title || ''}
            // required
            onChange={(e) => handleChange(e)}
            placeholder="Banner Title"
            className={`form-control `}
            label={'Banner Title'}
            // error={!bannerValue.title && error.banner}
            // maxLength={30}
          />
        </div>
        <div className="form-group mb-2">
          <Select
            name="categoryId"
            value={bannerValue?.categoryId || ''}
            onChange={(e) => handleChange(e)}
            required
            className="form-control"
            label="Category"
            error={!bannerValue.categoryId && error.category}
          >
            <option value="">Choose Category</option>
            {categoryList &&
              categoryList.length > 0 &&
              categoryList.map(({ title, _id }, index) => {
                return (
                  <React.Fragment key={_id}>
                    <option value={_id}>{charLimit(title, 50)}</option>
                  </React.Fragment>
                )
              })}
          </Select>
        </div>
        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="categoryImage"
            // value={image.name || ''}
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Banner Image"
            className={`form-control `}
            label={'Banner Image'}
            error={!image && error.image}
            // maxLength={30}
          />
        </div>

        <div>
          {image && (
            <>
              <Avatar
                variant="square"
                sx={{
                  width: '100%',
                  borderRadius: 1,
                  height: 140,
                  border: '1px solid #FF8989',
                }}
                src={image && URL.createObjectURL(image)}
              />
            </>
          )}
        </div>
      </div>
    </ModalBox>
  )
}

export default AddBanner
