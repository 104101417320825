import React from 'react'
import ModalBox from '../../components/modal/ModalBox'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { toastMessage } from '../../utils/toastMessage'

import { changeUserStatus } from '../../services/userManagement'

const UserStatus = ({ show, close, setListing, status, id, setUserDetail }) => {
  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader('Yes')

  // handle all function

  //--------------change-status-category------------------------
  const changeStatus = async () => {
    try {
      buttonLoader(true)
      let sendData = { status: !status, userId: id }
      const { data } = await changeUserStatus(sendData)
      if (data.code === 200) {
        if (setListing) {
          setListing((prevListing) => {
            return prevListing.map((user) => {
              if (user._id === id) {
                return { ...user, status: !status }
              }
              return user
            })
          })
        }
        if (setUserDetail) {
          setUserDetail((prevUserdetail) => {
            return { ...prevUserdetail, status: !status }
          })
        }
        toastMessage(data.message, 'success', 'userStatus')
        close()
      } else {
        toastMessage(data.message, 'error', 'userStatus')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      buttonLoader(false)
    }
  }
  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Change User Status"
      description="Do you want to change the status of this user?"
      action={changeStatus}
      ref={buttonref}
    />
  )
}

export default UserStatus
