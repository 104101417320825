import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from '../../config/axiosInstance'

// ---------------------fatch-music-listing--------------------------
export const getUserListApi = async (data) => {
  const endPoint = `/admin/user/list`
  return await axios.post(endPoint, data, setJwtToken())
}

// ---------------------fatch-Deleted-User-listing--------------------------
export const getDeletedUserListApi = async (data) => {
  const endPoint =
    `/admin/user/delete/list` + '?' + new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// ---------------------change-status-banner---------------------------
export const changeUserStatus = async (data) => {
  const endPoint = `/admin/user/block_unblock`
  return await axios.put(endPoint, data, setJwtToken())
}

// ---------------------get-user-detail---------------------------
export const getSelectedUserDetail = async (data) => {
  const endPoint =
    `/admin/user/details` + '?' + new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

export const excelDownloadHandler = async (data) => {
  const endPoint = `/admin/user/download`
  //  + '?' + new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// User History
export const getUserHistoryApi = async (data) => {
  const endPoint =
    `/admin/user/history` + '?' + new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// Logout from all device

export const logoutUserFromAllDevice = async (data) => {
  const endPoint =
    `/admin/user/log_out` + '?' + new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}
