import React, { useEffect, useMemo, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import useButtonLoader from "../../../hooks/UseButtonLoader";
import { getCmsDetailApi, updateCmsApi } from "../../../services/cms/index";
import { toastMessage } from "../../../utils/toastMessage";
import { regex } from "../../../utils/regex";
import commonMessages from "../../../utils/commonMessages";
import csv from "../../../Assests/image/csv.svg";
const Editor = ({
  zoneType,
  title,
  language,
  zoneData,
  setZoneData,
  error,
  setError,
  pdfFile,
  setPdfFile,
}) => {
  const [buttonref, buttonLoader] = useButtonLoader("Update");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { documentFileRegex } = regex;

  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      toolbar: true,
      placeholer:"",
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
      controls: {
        font: {
          command: "fontname",
          list: {
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
            "Consolas,monaco,monospace": "Consolas",
          },
        },
      },
    }),
    []
  );

  //   ******************** Upload File Handler*********************** //
  const uploadFileHandler = (e) => {
    const { name, value, files } = e.target;
    if (files.length > 0) {
      let file = files[0];
      if (file && documentFileRegex.test(file.name)) {
        setPdfFile(file);
      } else {
        e.target.value = "";
        setPdfFile("");
        toastMessage("Please upload pdf format", "error", "docFormat");
      }
    }
  };
  const uploadFileRef = useRef();

  return (
    <>
    {/* <input
                type="file"
                defaultValue={''}
                ref={uploadFileRef}
                onChange={uploadFileHandler}
                aria-hidden={true}
                hidden={true}
              

              /> */}
      <div className="d-flex justify-content-between">
        <div>
          <h4 className="text-start text-dark mb-4 mt-4">{language}</h4>
        </div>
        <div className="d-flex align-items-center">
          {/* {pdfFile && pdfFile.name || zoneData.vastuCategory?.vastuCompassCategoryImage ? (
            <div className="csv-image-wrapper">
              <figure>
                <img src={csv} alt="csv file download" />
              </figure>
            </div>
          ):''} */}
              {/* <div className="block_user_button mt-2">
                <button onClick={() => uploadFileRef.current.click()}>
                  Upload PDF
                </button>
              </div>
              <span className="text-danger">{error.pdfFile}</span> */}
              
        </div>
      </div>
      {loader}
      <JoditEditor
        ref={editor}
        value={zoneData.descriptionEngish}
        tabIndex={1}
        onBlur={(value)=>{
            setZoneData({
                ...zoneData,
                ["descriptionEngish"]: value,
              });
        }}
        config={config}
      />
      {(!zoneData.descriptionEngish ||
        zoneData.descriptionEngish === "<p><br></p>" ||
        /<p>(&nbsp;\s*)*<br><\/p>/.test(zoneData.descriptionEngish)) &&
        error.descriptionEngish && (
          <p
            className={`invalid-feedback ${
              (!zoneData.descriptionEngish ||
                zoneData.descriptionEngish === "<p><br></p>" ||
                /<p>(&nbsp;\s*)*<br><\/p>/.test(zoneData.descriptionEngish)) &&
              "d-block"
            }`}
          >
            {error.descriptionEngish}
          </p>
        )}
    </>
  );
};

export default Editor;
