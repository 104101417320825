import React, { useEffect, useState } from 'react'
import Pagination from 'react-js-pagination'
import NotFound from '../../../components/not-found/NotFound'
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded'
import { Link, useSearchParams } from 'react-router-dom'
import IconButton from '../../../components/Button/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import BlockIcon from '@mui/icons-material/Block'
import Switch from '../../../components/Switch/Switch'
import profileImage from '../../../Assests/image/profile_not_found.png'
import { user } from '../../../routes/route'
const { USER_DETAIL } = user

const userData = [
  {
    problem: 'Rahul Chauhan',
    consultant: 'rahul',
    date: '26/03/1998 23:34',
    type: 'This is type',
    amount: 'This is amount',
  },
  {
    problem: 'Rahul Chauhan',
    consultant: 'rahul',
    date: '26/03/1998 23:34',
    type: 'This is type',
    amount: 'This is amount',
  },
  {
    problem: 'Rahul Chauhan',
    consultant: 'rahul',
    date: '26/03/1998 23:34',
    type: 'This is type',
    amount: 'This is amount',
  },
]

const ConsultantHistoryTable = () => {
  // handle all state

  const [activePage, setActivePage] = useState(0)
  return (
    <div className="content-table">
      <table>
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Problem</th>
            <th>Consultant</th>
            <th>Date and Time</th>
            <th>Type</th>
            <th>Amount</th>
          </tr>
        </thead>
        {/* profile: profileImage, name: 'Rahul Chauhan', userId: '123456789',
              PhoneNumber: '1234567890', Email: 'rahul@gmail.com', gender:
              'male', RegistrationDate: '12 Jan 1234', */}
        <tbody>
          {userData.length > 0 ? (
            userData.map((user, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>{++index}</td>
                    <td>{user.problem || '---'}</td>
                    <td>{user.consultant || '---'}</td>
                    <td>{user.date || '---'}</td>
                    <td>{user.type || '---'}</td>
                    <td>{user.amount || '---'}</td>
                  </tr>
                </React.Fragment>
              )
            })
          ) : (
            <NotFound
              cols={9}
              msg="No Order Found!"
              icon={<ViewListRoundedIcon sx={{ fontSize: 48 }} />}
              // loader={loader}
            />
          )}
        </tbody>
      </table>
    </div>
  )
}
export default ConsultantHistoryTable
