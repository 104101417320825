import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { Avatar, IconButton } from "@mui/material";
import Switch from "../../components/Switch/Switch";
import NotFound from "../../components/not-found/NotFound";
import Pagination from "react-js-pagination";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { notification } from "../../routes/route";
import common from "../../routes/common";
// import AddCategory from './AddCategory'
import { fatchCategoryApi } from "../../services/categoryManagement";
import { toastMessage } from "../../utils/toastMessage";
import findSerialNumber from "../../utils/findSerialNumber";
import { charLimit } from "../../utils/charLimit";
import { fetchGiftApi } from "../../services/giftManagement";
import EditIcon from "@mui/icons-material/Edit";
// import GiftStatus from "./GiftStatus";
// import AddGift from "./AddGift";
// import EditGift from "./EditGift";
import AddIcon from "@mui/icons-material/Add";
import DescriptionModel from "../../components/imagePreview/DescriptionModel";
import AddNotification from "./AddNotification";
import { deleteNotificationApi, notificationListingApi } from "../../services/notificationManagement";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import { dateFormatWithTime } from "../../utils/dateFormat";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalBox from "../../components/modal/ModalBox";
import useButtonLoader from "../../hooks/UseButtonLoader";
// import CategoryStatus from './CategoryStatus'
const { ADD_NOTIFICATION } = notification;
const { NOT_FOUND } = common;

const Notification = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  const [search, setSearch] = useState(
    getLocalStorage("notificationList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);
  const [buttonRef, buttonLoader] = useButtonLoader("Yes");
  const [addNotificationModal, setAddNotificationModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [statusModal, setStatusModal] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [statusId, setStatusId] = useState("");
  const [selectedNotification, setSelectedNotification] = useState({});
  const [description, setDescription] = useState({});
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    setLocalStorage("notificationList", "search", search);
  }, [search]);

  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true);
    setStatusId(id);
    setStatusValue(status);
  };

  //   ********Edit data*************//
  const editHandler = (data) => {
    setSelectedNotification(data);
    setEditModal(true);
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  // close model
  const closeModel = () => {
    setStatusValue("");
    setStatusId("");
    setStatusModal(false);
  };

  // //------------------listing-Category-Api----------------------------------
  const fetchNotificationHandler = async (debounceValue) => {
    try {
      const sendData = {};
      setNotificationList([]);
      showLoader();
      sendData.limit = "10";
      sendData.page = activePage;
      if (debounceValue) {
        sendData.page = "1";
        sendData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      setSearchParam(searchParam);
      const res = await notificationListingApi(sendData);
      if (res.data.code === 200) {
        setNotificationList(res?.data?.result?.list || []);
        setTotal(res?.data?.result?.total || 0);
      } else {
        setNotificationList([]);
        setTotal(0);
        toastMessage(res.data.message, "error", "notificationList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // Delete Notification Handler
  const deleteNotificationHandler = async () => {
    try {
      buttonLoader(true);
      const res = await deleteNotificationApi({
        notificationId: statusId,

      });
      if (res?.data?.code === 200) {
        toastMessage(res?.data?.message, "success", "deleteNotification");
        setDeleteModal(false);
        fetchNotificationHandler();
      } else {
        toastMessage(res?.data?.message, "error", "deleteNotification");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      buttonLoader(false);
    }
  };

  const onClose = () => {
    setDescription(null);
    setDescriptionModal(false);
  };

  useEffect(() => {
    if (Number(activePage) === 1) {
      searchParam.delete("page");
    }
    setSearchParam(searchParam);
    fetchNotificationHandler(debounceValue);
  }, [searchParam, debounceValue]);

  useEffect(() => {
    fetchNotificationHandler();
  }, []);
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Notification Management</h4>
            <div className="block_user_button">
              <button onClick={() => setAddNotificationModal(true)}>
                <AddIcon />
                Add Notification
              </button>
              {/* <Link to={`${ADD_NOTIFICATION}`}>
                <div className="block_user_button">
                  <button>
                    <AddIcon />
                    Add Notification
                  </button>
                </div>
              </Link> */}
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Created At</th>
                  {/* <th>Title</th> */}
                  <th>Message</th>
                  <th>User Type</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>

              <tbody>
                {notificationList.length > 0 ? (
                  notificationList.map((notification, index) => {
                    return (
                      <React.Fragment key={notification._id}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          <td>{dateFormatWithTime(notification.createdAt)}</td>
                          {/* <td>{charLimit(notification.title, 40) || "---"}</td> */}

                          <td
                            onClick={() => {
                              if (
                                notification.message &&
                                notification.message.length > 50
                              ) {
                                setDescriptionModal(true);
                                setDescription({
                                  ...description,
                                  message: notification.message,
                                  title: notification?.title,
                                });
                              }
                            }}
                          >
                            {notification?.message &&
                              (notification.message.length > 50
                                ? charLimit(notification.message, 50)
                                : notification.message || "---")}
                          </td>
                          <td>
                            {notification?.userType === "cons"
                              ? "Consultant"
                              : "User"}
                          </td>
                          <td>
                            <div className="action_button">
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() => {
                                  setDeleteModal(true);
                                  setStatusId(notification._id);
                                }}
                              >
                                <DeleteIcon className="edit_icon" />
                              </IconButton>
                              {/* <Switch
                                checked={notification.status}
                                onChange={() =>
                                  statusUpdateHandler(notification._id, notification.status)
                                }
                              /> */}
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={7}
                    msg="Notification not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total >= 10 ? (
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                previousLabel={"previous"}
                nextLabel={"next"}
                itemsCountPerPage={10}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            </div>
          ) : null}
        </div>
        {addNotificationModal && (
          <AddNotification
            show={addNotificationModal}
            close={() => setAddNotificationModal(false)}
            listing={fetchNotificationHandler}
          />
        )}
        {/* {statusModal && (
          <notificationStatus
            show={statusModal}
            close={closeModel}
            listing={notificationList}
            setListing={setNotificationList}
            status={statusValue}
            id={statusId}
          />
        )}
        {editModal && (
          <EditGift
            setEditModal={setEditModal}
            editModal={editModal}
            selectedNotification={selectedNotification}
            setSelectedNotification={setSelectedNotification}
            fetchNotificationHandler={fetchNotificationHandler}
          />
        )} */}

        <DescriptionModel
          show={descriptionModal}
          close={onClose}
          description={description?.message}
          title={description?.title}
        />

        <ModalBox
          status={deleteModal}
          onClose={() => setDeleteModal(false)}
          title={"Delete Notification"}
          description={"Are you sure want to delete this notification?"}
          action={deleteNotificationHandler}
          ref={buttonRef}
        />
      </div>
    </>
  );
};

export default Notification;
