import { Avatar } from '@mui/material'

const ImagePreview = ({ imageUrl }) => {
  return (
    <Avatar
      variant="square"
      sx={{
        width: '100%',
        borderRadius: 1,
        height: 140,
        border: '1px solid #FF8989',
      }}
      src={imageUrl}
    />
  )
}
export default ImagePreview
