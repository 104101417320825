import React, { useEffect, useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import { Input, Select } from "../../components/Form";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { fatchCategoryApi } from "../../services/categoryManagement";
import {
  allowAlphaNumericWithSpace,
  optimizeFunction,
  regex,
} from "../../utils/regex";
import { Avatar } from "@mui/material";
import { charLimit } from "../../utils/charLimit";
import { toastMessage } from "../../utils/toastMessage";
import { addBannerApi, updateBannerApi } from "../../services/bannerManagement";
import commonMessages from "../../utils/commonMessages";
import {
  checkImageAspectRatio,
  checkImageDimensions,
} from "../../utils/checkImageDimension";
const {
  ADD_BANNER_TITLE,
  CHOOSE_CATEGORY,
  IMAGE_FORMAT,
  MESSAGE_SIZE,
} = commonMessages;
const { imageFileRegex } = regex;

const EditBanner = ({
  show,
  close,
  bannerList,
  bannerDetail,
  setBannerDetail,
}) => {
  const [categoryList, setCategoryList] = useState([]);
  const [image, setImage] = useState("");
  const [error, setError] = useState({});
  const [buttonref, buttonLoader] = useButtonLoader("Update");
  const [updateBanner, setUpdateBanner] = useState({
    id: bannerDetail._id,
    userType: bannerDetail.userType,
    title: bannerDetail.title,
    categoryId: bannerDetail.categoryId?._id,
  });

  // console.log("AddSubCategory",input)
  //----------vaidation------------
  const validation = () => {
    let errorMessage = {};
    let status = true;

    // if (!updateBanner.title) {
    //   errorMessage['banner'] = ADD_BANNER_TITLE
    //   status = false
    // }

    if (!updateBanner.categoryId) {
      errorMessage["category"] = CHOOSE_CATEGORY;
      status = false;
    }

    // if (!image.name) {
    //   errorMessage['image'] = 'Please provider banner image'
    //   status = false
    // }

    setError(errorMessage);
    return status;
  };

  // Add Banner image handler
  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        // setImage(e.target.files[0])
        const validSize = await checkImageAspectRatio(
          e.target.files[0],
          "16/9"
        );
        // console.log('isValid', validSize)
        if (validSize) {
          setImage(e.target.files[0]);
        } else {
          e.target.value = "";
          setImage("");
        }
      } else {
        setImage("");
        e.target.value = "";
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setImage("");
    }
  };
  //------------------onChange-input-value---------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "title") {
      updatedValue = allowAlphaNumericWithSpace(value);
    }
    setUpdateBanner({ ...updateBanner, [name]: updatedValue });
  };

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setImage("");
    setBannerDetail({});
    setError({});
    close();
  };
  //--------------Add-sub-category------------------------
  const updateBannerHandler = async () => {
    const status = validation();
    if (status) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("bannerId", updateBanner.id);
        // if (updateBanner?.title) {
          formData.append("title", updateBanner.title || "");
        // }
        formData.append("categoryId", updateBanner.categoryId);
        if (image && image.name) {
          formData.append("bannerImage", image);
        }
        const { data } = await updateBannerApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addBanner");
          bannerList();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addBanner");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };
  //------------------listing-Api-category-dropdown--------------------------------
  const fatchCategory = async () => {
    try {
      setCategoryList([]);
      const { data } = await fatchCategoryApi();
      if (data.code === 200) {
        setCategoryList(data?.result?.categoryList || []);
      } else {
        setCategoryList([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fatchCategory();
  }, []);
  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={"Edit Banner"}
      closeIcon
      custom
      size={"sm"}
      customizedFooter={
        <div className="model_custom_footer">
          {/* <p>{MESSAGE_SIZE}</p> */}
          <button
            className="btn_size "
            ref={buttonref}
            onClick={updateBannerHandler}
          >
            Update
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={updateBanner?.title || ""}
            // required
            onChange={(e) => handleChange(e)}
            placeholder="Banner Title"
            className={`form-control `}
            label={"Banner Title"}
            // error={!updateBanner.title && error.banner}
            // maxLength={30}
          />
        </div>
        <div className="form-group mb-2">
          <Select
            name="categoryId"
            value={updateBanner?.categoryId || ""}
            onChange={(e) => handleChange(e)}
            required
            className="form-control"
            label="Category"
            error={!updateBanner.categoryId && error.category}
          >
            <option value="">Choose Category</option>
            {categoryList &&
              categoryList.length > 0 &&
              categoryList.map(({ title, _id }, index) => {
                return (
                  <React.Fragment key={_id}>
                    <option value={_id}>{charLimit(title, 50)}</option>
                  </React.Fragment>
                );
              })}
          </Select>
        </div>
        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="categoryImage"
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Banner Image"
            className={`form-control `}
            label={"Banner Image"}
            // error={!image && error.image}
            // maxLength={30}
          />
        </div>

        <div>
          {
            <>
              <Avatar
                variant="square"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  height: 140,
                  border: "1px solid #FF8989",
                }}
                src={
                  image && image.name
                    ? URL.createObjectURL(image)
                    : bannerDetail.bannerImage
                }
              />
            </>
          }
        </div>
      </div>
    </ModalBox>
  );
};

export default EditBanner;
