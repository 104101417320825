import React, { useState } from "react";
import ModalBox from "../../../components/modal/ModalBox";
import { TextArea } from "../../../components/Form";
import { allowAlphaNumericWithSpace} from "../../../utils/regex";
import { toastMessage } from "../../../utils/toastMessage";
import useButtonLoader from "../../../hooks/UseButtonLoader";
import {updateSupportApi } from "../../../services/supportManagement";

const EditSupportSection = ({
  selectedData,
  setSelectedData,
  editModal,
  setEditModal,
  getsupportList,headingTitle,
  // userType,supportType
}) => {
  const [buttonref, buttonLoader] = useButtonLoader("Save");
  const [error, setError] = useState({});


  console.log(selectedData,"SelectedData");


  const modalClose = () => {
    setError({});
    setEditModal(false);
    setSelectedData({});
  };

  const validation = () => {
    let status = true;
    const errors = {};
    if (!selectedData.question) {
      errors["question"] = "Question is mandatory";
      status = false;
    }
    if (!selectedData.description) {
      errors["description"] = "Description is mandatory";
      status = false;
    }
    setError(errors);
    return status;
  };

  const updateSupportHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("supportId",selectedData?._id);
        formData.append("question", selectedData?.question);
        formData.append("description", selectedData.description);
        formData.append("supportType", selectedData?.supportType);
        formData.append("userType", selectedData?.userType);
        const { data } = await updateSupportApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "editSupport");
          getsupportList();
          modalClose();
        } else {
          toastMessage(data.message, "error", "editSupport");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };
  return (
    <ModalBox
      status={editModal}
      onClose={modalClose}
      title={headingTitle}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        <button className="btn_size" ref={buttonref} onClick={updateSupportHandler}>
          Save
        </button>
      }
    >
      <div>
        <div className="form-group mb-2">
          <TextArea
            type="text"
            name="question"
            value={selectedData.question || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowAlphaNumericWithSpace(e.target.value);
              setSelectedData({ ...selectedData, ["question"]: sanitizedText });
            }}
            placeholder="Enter Question..."
            className={`form-control`}
            label={"Question"}
            error={!selectedData.question && error.question}
            // maxLength={40}
          />
        </div>
        <div className="form-group mb-2">
          <TextArea
            type="text"
            name="description"
            value={selectedData.description || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowAlphaNumericWithSpace(e.target.value);
              setSelectedData({ ...selectedData, ["description"]: sanitizedText });
            }}
            placeholder="Enter description..."
            className={`form-control`}
            label={"Description"}
            error={!selectedData?.description && error.description}
            // maxLength={40}
          />
        </div>
      </div>
    </ModalBox>
  );
};

export default EditSupportSection;
