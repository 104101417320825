import { Input, Select } from "../../components/Form";
import {
  consultantType,
  dailyHoursOptions,
  expertist,
  gender,
  languages,
} from "../../utils/genderList";
import { CommonSelect } from "../../components/Form";
import { useEffect, useState } from "react";
import { fatchCategoryApi } from "../../services/categoryManagement";
import { toastMessage } from "../../utils/toastMessage";
import PhoneInput from "react-phone-input-2";
import { allowNumber, optimizeFunction } from "../../utils/regex";
import { regex } from "../../utils/regex";
const { email } = regex;

const genderOptions = gender.map(({ value, name }) => ({ value, label: name }));
const languageOptions = languages.map(({ value, name }) => ({
  value,
  label: name,
}));

const dailyHourOptions = dailyHoursOptions.map(({ value, name }) => ({
  value,
  label: name,
}));
const expertiseOption = expertist.map(({ value, name }) => ({
  value,
  label: name,
}));
const consultantTypeOption = consultantType.map(({ value, name }) => ({
  value,
  label: name,
}));

const AddConsultantForm = ({
  setLanguageValue,
  setGenderValue,
  setExpertiseValue,
  setConsultantTypeValue,
  setConsultantSkill,
  setAddConsultantValue,
  addConsultantValue,
  countryCode,
  setCountryCode,
  countryName,
  setCountryName,
  languageValue,
  genderValue,
  expertiseValue,
  consultantTypeValue,
  consultantSkill,
  error,
}) => {
  // handle all state

  const [consultantSkillOption, setConsultantSkillOption] = useState([]);
  const [consultantSkillLoader, setConsultantSkillLoader] = useState(false);

  // handle all fucntion

  // handle change

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "name") {
      updatedValue = optimizeFunction(value);
    }

    if (name === "avgLiveBrier") {
      updatedValue = allowNumber(value);
    }
    setAddConsultantValue({ ...addConsultantValue, [name]: updatedValue });
  };

  const handlePhoneChange = (phone, country) => {
    // Extract the phone number without country code
    const phoneWithoutCountryCode = phone.replace(country.dialCode, "");
    // Update states
    setAddConsultantValue({
      ...addConsultantValue,
      mobile: phoneWithoutCountryCode,
    });
    setCountryCode(country.dialCode);
    setCountryName(country.name);
  };

  // fetch consultant skill
  const fetchConsultantSkillHandler = async () => {
    try {
      setConsultantSkillLoader(true);
      const { data } = await fatchCategoryApi();
      if (data.code === 200) {
        const updatedConsultantSkill =
          data?.result?.categoryList &&
          data?.result?.categoryList.length > 0 &&
          data?.result?.categoryList.map(({ _id, title }) => ({
            value: _id,
            label: title,
          }));
        setConsultantSkillOption(updatedConsultantSkill);
      } else {
        setConsultantSkillOption([]);
        toastMessage(data.message, "error", "categoryList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setConsultantSkillLoader(false);
    }
  };

  // validation

  useEffect(() => {
    fetchConsultantSkillHandler();
  }, []);

  // console.log('setConsultantSkillOption', consultantSkillOption)

  return (
    <div className="row">
      <div className="col-6">
        {/* name */}
        <div className="form-group mb-2">
          <Input
            type="text"
            name="name"
            value={addConsultantValue?.name || ""}
            required
            onChange={handleChange}
            placeholder="Enter Consultant Name"
            className={`form-control `}
            label={"Name"}
            error={!addConsultantValue?.name && error.name}
            // maxLength={30}
          />
        </div>

        {/* gender */}
        <div className="form-group select_sm_width">
          <CommonSelect
            className="add-consultant"
            label={"Gender"}
            options={genderOptions}
            onChange={setGenderValue}
            required
            error={!genderValue && error.gender}
            // value={genderValue || ''}
            // onChange={(e) => genderHandler(e.target.value)}
          />
        </div>
        {/* Language */}
        <div className="form-group select_sm_width">
          <CommonSelect
            className="add-consultant"
            label={"Language"}
            options={languageOptions}
            onChange={setLanguageValue}
            isMulti={true}
            isSearchable={true}
            required
            error={languageValue.length === 0 && error.language}
            // value={genderValue || ''}
            // onChange={(e) => genderHandler(e.target.value)}
          />
        </div>
        {/* Expertise */}
        <div className="form-group select_sm_width">
          <CommonSelect
            className="add-consultant"
            label={"Expertise"}
            options={expertiseOption}
            onChange={setExpertiseValue}
            isMulti={true}
            isSearchable={true}
            required
            error={expertiseValue.length === 0 && error.expertise}
            // value={genderValue || ''}
            // onChange={(e) => genderHandler(e.target.value)}
          />
        </div>
        {/* Average live brier */}
        <div className="form-group mb-2">
          <Input
            type="text"
            name="avgLiveBrier"
            value={addConsultantValue?.avgLiveBrier || ""}
            required
            onChange={handleChange}
            placeholder="Average Live Brier (in Hours)"
            className={`form-control `}
            label={"Avg. Live Brier (in Hours)"}
            error={!addConsultantValue.avgLiveBrier && error.avgLiveBrier}
            // maxLength={30}
          />
        </div>
      </div>
      <div className="col-6">
        <div className="form-group cstM-tele_input mb-2 text-wrapper">
          <label className="my-1 text-dark">Mobile Number</label>
          <PhoneInput
            className={`${!addConsultantValue.mobile && "is-invalid"}`}
            country={"in"}
            value={countryCode}
            enableSearch={true} // Ensure enableSearch is set to true
            placeholder={"Enter Mobile Number"}
            countryCodeEditable={false}
            onChange={handlePhoneChange}
          />
          {!addConsultantValue.mobile && (
            <span className="invalid-feedback">{error.mobile}</span>
          )}
        </div>

        {/* Type */}
        <div className="form-group select_sm_width">
          <CommonSelect
            className="add-consultant"
            label={"Consultant Type"}
            options={consultantTypeOption}
            onChange={setConsultantTypeValue}
            required
            error={!consultantTypeValue && error.type}
            // value={genderValue || ''}
            // onChange={(e) => genderHandler(e.target.value)}
          />
        </div>
        {/* email */}
        <div className="form-group mb-2">
          <Input
            type="text"
            name="email"
            value={addConsultantValue?.email || ""}
            required
            onChange={handleChange}
            placeholder="Enter email"
            className={`form-control `}
            label={"Email"}
            // error={!addConsultantValue.email && error.email}
            error={
              !addConsultantValue.email
                ? error.email
                : !addConsultantValue.email.match(email) && error.matchEmail
            }
            // maxLength={30}
          />
        </div>
        {/* Skill */}
        <div className="form-group select_sm_width">
          <CommonSelect
            className="add-consultant"
            label={"Skill"}
            options={consultantSkillOption}
            onChange={setConsultantSkill}
            isMulti={true}
            isSearchable={true}
            loading={consultantSkillLoader}
            required
            error={consultantSkill.length === 0 && error.skill}
            // value={genderValue || ''}
            // onChange={(e) => genderHandler(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};
export default AddConsultantForm;
