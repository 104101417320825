import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// ---------------------fetch-subAdmin-listing--------------------------
export const fetchSubadminListApi = async (data) => {
  const endPoint =
    `/admin/list` + "?" + new URLSearchParams({ ...data }).toString();

  return await axios.get(endPoint, setJwtToken());
};

// SubAdmin Details API
export const subAdminDetailApi = async (data) => {
  const endPoint =
    `/admin/details` + "?" + new URLSearchParams({ ...data }).toString();

  return await axios.get(endPoint, setJwtToken());
};

//--------------------------Add SubAdmin---------------------
export const addSubadminApi = async (reqData) => {
  const endPoint = `/admin/sign_up`;
  return await axios.post(endPoint, reqData, setJwtToken());
};

//--------------------------Update SubAdmin---------------------
export const updateSubadminApi = async (reqData) => {
  const endPoint = `/admin/update`;
  return await axios.put(endPoint, reqData, setJwtToken());
};
//--------------------------update SubAdmin status---------------------
export const updateSubadminStatus = async (reqData) => {
  const endPoint = `/admin/status_update`;
  return await axios.put(endPoint, reqData, setJwtToken());
};
