import React, { useEffect, useMemo, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import useButtonLoader from "../../../hooks/UseButtonLoader";
import { getCmsDetailApi, updateCmsApi } from "../../../services/cms/index";
import { toastMessage } from "../../../utils/toastMessage";
import { regex } from "../../../utils/regex";
import commonMessages from "../../../utils/commonMessages";
import csv from "../../../Assests/image/csv.svg";
const HindiEditor = ({
  zoneType,
  title,
  language,
  zoneData,
  setZoneData,
  error,
  setError,
  pdfFile,
  setPdfFile,
}) => {
  const [buttonref, buttonLoader] = useButtonLoader("Update");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { documentFileRegex } = regex;

  const editor = useRef(null);


  const config = useMemo(
    () => ({
      readonly: false,
      toolbar: true,
      spellcheck: true,
      placeholer:"",
      language: "en",
      toolbarButtonSize: "medium",
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
      controls: {
        font: {
          command: "fontname",
          list: {
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
            "Consolas,monaco,monospace": "Consolas",
          },
        },
      },
    }),
    []
  );

  const uploadFileRef = useRef();

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <h4 className="text-start text-dark mb-4 mt-4">{language}</h4>
        </div>
      </div>
      {loader}
      <JoditEditor
        ref={editor}
        value={zoneData.descriptionHindi}
        tabIndex={1}
        onBlur={(value)=>{
            setZoneData({
                ...zoneData,
                ["descriptionHindi"]: value,
              });
        }}
        config={config}
      />
    {(!zoneData.descriptionHindi ||
        zoneData.descriptionHindi === "<p><br></p>" ||
        /<p>(&nbsp;\s*)*<br><\/p>/.test(zoneData.descriptionHindi)) &&
        error.descriptionHindi && (
          <p
            className={`invalid-feedback ${
              (!zoneData.descriptionHindi ||
                zoneData.descriptionHindi === "<p><br></p>" ||
                /<p>(&nbsp;\s*)*<br><\/p>/.test(zoneData.descriptionHindi)) &&
              "d-block"
            }`}
          >
            {error.descriptionHindi}
          </p>
        )}
    </>
  );
};

export default HindiEditor;
