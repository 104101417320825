// import { useState } from 'react'
// import axios from 'axios'
// import { setJwtToken } from '../config/axiosInstance'

// const useFileDownloader = () => {
//   const [loading, setLoading] = useState(false)

//   const downloadFile = async (url, params = {}) => {
//     try {
//       setLoading(true)

//       const response = await axios.get(url, {
//         params,
//         responseType: 'blob',
//         headers: setJwtToken().headers,
//       })

//       if (response.status === 200) {
//         const currentDate = new Date()
//         const formattedDate = currentDate.toISOString().slice(0, 10)
//         const rnFile = `${formattedDate}.xlsx`

//         const contentDisposition = response.headers['content-disposition']
//         const matches = /filename="([^"]+)"/.exec(contentDisposition)
//         const filename = matches ? matches[1] : `excel-file-${rnFile}` // Use rnFile here instead of 'excel-file.xlsx'

//         const fileURL = window.URL.createObjectURL(new Blob([response.data]))
//         const link = document.createElement('a')
//         link.href = fileURL
//         link.setAttribute('download', filename)
//         document.body.appendChild(link)
//         link.click()

//         window.URL.revokeObjectURL(fileURL)
//         document.body.removeChild(link)
//         setLoading(false)
//       } else {
//         setLoading(false)
//         throw new Error('File download failed')
//       }
//     } catch (error) {
//       setLoading(false)
//       console.error('Error downloading file:', error)
//     }
//   }

//   return { loading, downloadFile }
// }

// export default useFileDownloader

import { useState } from 'react'
import axios from 'axios'
import { setJwtToken } from '../config/axiosInstance'

const useFileDownloader = () => {
  const [loading, setLoading] = useState(false)
  // let loading = false

  const downloadFile = async (url, fileName, params = {}) => {
    try {
      setLoading(true)

      const { headers } = setJwtToken()
      const response = await axios.get(url, {
        params,
        responseType: 'blob',
        headers,
      })

      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        })

        const blobUrl = window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = blobUrl
        link.download = fileName

        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
        window.URL.revokeObjectURL(blobUrl)

        setLoading(false)
      } else {
        throw new Error('File download failed')
      }
    } catch (error) {
      console.error('Error downloading file:', error)
      // Handle error gracefully, e.g., display an error message to the user
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request cancelled:', error.message)
      } else {
        console.error('Error:', error.message)
      }
    }
  }

  return { loading, downloadFile }
}

export default useFileDownloader
