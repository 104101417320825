import React, { Fragment, useEffect, useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { toastMessage } from "../../utils/toastMessage";
import {
  addConsultant,
  changeConsultantStatus,
  editConsultant,
  getSelectedConsultantDetail,
} from "../../services/consultantManagement";
import AddConsultantForm from "./AddConsultantForm";
import { fatchCategoryApi } from "../../services/categoryManagement";
import { allowNumber, optimizeFunction, regex } from "../../utils/regex";
import commonMessages from "../../utils/commonMessages";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import {
  CommonSelect,
  EditCommonSelect,
  Input,
  TextArea,
} from "../../components/Form";
import PhoneInput from "react-phone-input-2";
import ReactDatePicker from "react-datepicker";
import {
  astrologyPlatforms,
  consultantType,
  dailyHoursOptions,
  experience,
  expertist,
  gender,
  hearAboutOptions,
  languages,
  mainSourceOfIncome,
  qualification,
} from "../../utils/genderList";
import { dateFormat, dateFormatYear } from "../../utils/dateFormat";
import createMediaData from "../../utils/mediaDataSender";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import scrollToUp from "../../utils/scrollToUp";
import useFullPageLoader from "../../hooks/useFullPageLoader";

const {
  CONSULTANT_NAME,
  VALID_CONSULTANT_EMAIL,
  CONSULTANT_EMAIL,
  CONSULTANT_NUMBER,
  AVERAGE_LIVE_BRIER,
  CONSULTANT_GENDER,
  CONSULTANT_LANGUAGE,
  CONSULTANT_ENTERPRISE,
  CONSULTANT_TYPE,
  CONSULTANT_SKILL,
  IMAGE_FORMAT,
} = commonMessages;

const { email, imageFileRegex } = regex;
const EditConsultant = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  // handle all state
  const [consultantDetail, setConsultantDetail] = useState({});
  const [languageValue, setLanguageValue] = useState([]);
  const [genderValue, setGenderValue] = useState({});
  const [qualificationValue, setQualificationValue] = useState([]);
  const [expertiseValue, setExpertiseValue] = useState([]);
  const [workOtherPlatformValue, setWorkOtherPlatformValue] = useState("");
  const [platformValue, setPlatformValue] = useState("");
  const [dailyHourValue, setDailyHourValue] = useState("");
  const [sourceOfIncomeValue, setSourceOfIncomeValue] = useState("");
  const [hearAboutUsValue, sethearAboutUsValue] = useState({});
  const [experienceValue, setExperienceValue] = useState("");
  // const [consultantTypeValue, setConsultantTypeValue] = useState("");
  const [consultantSkill, setConsultantSkill] = useState([]);
  const [otherSkill, setOtherSkill] = useState([]);
  const [profileImage, setProfileImage] = useState("");

  // const [consultantDetail, setConsultantDetail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [error, setError] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader("Update");

  const genderOptions = gender.map(({ value, name }) => ({
    label: name,
    value: value,
  }));

  // console.log("gender option",genderOptions)

  const experienceOptions = experience.map(({ value, name }) => ({
    value,
    label: name,
  }));

  const workAnyOtherPlatformOptions = [
    {
      value: "Yes",
      label: "Yes",
    },
    {
      value: "No",
      label: "No",
    },
  ];

  const platformOptions = astrologyPlatforms.map(({ value, name }) => ({
    value,
    label: name,
  }));
  const hearOptions = hearAboutOptions.map(({ name, value }) => ({
    value,
    label: name,
  }));
  const sourceOfIncomeOptions = mainSourceOfIncome.map(({ value, name }) => ({
    value,
    label: name,
  }));

  const qualificationOptions = qualification.map(({ value, name }) => ({
    value,
    label: name,
  }));
  const dailyHourOptions = dailyHoursOptions.map(({ value, name }) => ({
    value,
    label: name,
  }));
  // const consultantTypeOption = consultantType.map(({ value, name }) => ({
  //   value,
  //   label: name,
  // }));

  const [consultantSkillOption, setConsultantSkillOption] = useState([]);
  const [expertiseOption, setExpertiseOption] = useState([]);
  const [languageOptions, setLanguageOption] = useState([]);
  const [otherSkillOption, setOtherSkillOption] = useState([]);
  const [consultantSkillLoader, setConsultantSkillLoader] = useState(false);
  const [otherSkillLoader, setOtherSkillOptionLoader] = useState(false);

  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!consultantDetail.name) {
      errorMessage["name"] = CONSULTANT_NAME;
      status = false;
    }
    if (!consultantDetail.userName) {
      errorMessage["userName"] = "User Name is Mandatory";
      status = false;
    }

    if (!consultantDetail.email) {
      errorMessage["email"] = CONSULTANT_EMAIL;
      status = false;
    }
    if (!consultantDetail.email?.match(email)) {
      errorMessage["matchEmail"] = VALID_CONSULTANT_EMAIL;
      status = false;
    }

    if (!consultantDetail.mobile) {
      errorMessage["mobile"] = CONSULTANT_NUMBER;
      status = false;
    }
    if (!consultantDetail.profileImage && !profileImage) {
      errorMessage["profileImage"] = "Please add consultant profile";
      status = false;
    }

    if (!consultantDetail.avgLiveBrier) {
      errorMessage["avgLiveBrier"] = AVERAGE_LIVE_BRIER;
      status = false;
    }
    if (!consultantDetail.bio) {
      errorMessage["bio"] = "Please add consultant bio";
      status = false;
    }
    if (!consultantDetail.greaterChallengeAndConquer) {
      errorMessage["greaterChallengeAndConquer"] = "Required";
      status = false;
    }
    if (!consultantDetail.workingFullTimeJob) {
      errorMessage["workingFullTimeJob"] = "Required";
      status = false;
    }
    if (!consultantDetail.foreignCountryNo) {
      errorMessage["foreignCountryNo"] = "Required";
      status = false;
    }
    if (!consultantDetail.learnAstrologyFrom) {
      errorMessage["learnAstrologyFrom"] = "Required";
      status = false;
    }
    if (!consultantDetail.reasonOfOnboard) {
      errorMessage["reasonOfOnboard"] = "Required";
      status = false;
    }
    if (!consultantDetail.minimumEarningExpectation) {
      errorMessage["minimumEarningExpectation"] = "Required";
      status = false;
    }
    if (!sourceOfIncomeValue) {
      errorMessage["mainSourceIncome"] =
        "Please add consultant source of income";
      status = false;
    }
    if (!consultantDetail.pincode) {
      errorMessage["pincode"] = "Please add consultant pincode";
      status = false;
    }
    if (!consultantDetail.state) {
      errorMessage["state"] = "Please add consultant state";
      status = false;
    }
    if (!consultantDetail.city) {
      errorMessage["city"] = "Please add consultant city";
      status = false;
    }
    if (!consultantDetail.address) {
      errorMessage["address"] = "Please add consultant address";
      status = false;
    }
    if (!dateOfBirth) {
      errorMessage["dateOfBirth"] = "Please add DOB";
      status = false;
    }
    if (!dailyHourValue) {
      errorMessage["dailyWorkHour"] = "Please add your daily work hour ";
      status = false;
    }
    if (!consultantDetail.price) {
      errorMessage["price"] = "Please add price (per min)";
      status = false;
    }
    if (!consultantDetail.whenHearAboutUs) {
      errorMessage["whenHearAboutUs"] = "Required";
      status = false;
    }

    // if (!consultantDetail.score) {
    //   errorMessage["score"] = "Please add consultant score";
    //   status = false;
    // }
    if (!workOtherPlatformValue.value) {
      errorMessage["otherOnlinePlatformWork"] = "Required";
      status = false;
    }
    if (workOtherPlatformValue.value === "Yes" && !platformValue.value) {
      errorMessage["platformValue"] = "Select consultant Platform";
      status = false;
    }
    if (
      workOtherPlatformValue.value === "Yes" &&
      platformValue.value === "Other" &&
      !consultantDetail.onlinePlatformName
    ) {
      errorMessage["platformName"] = "Enter consultant platform name";
      status = false;
    }
    if (!consultantDetail.whenHearAboutUs) {
      errorMessage["whenHearAboutUs"] = "Required";
      status = false;
    }
    if (!hearAboutUsValue) {
      errorMessage["hearAboutUs"] = "Required";
      status = false;
    }

    if (genderValue.length <= 0) {
      errorMessage["gender"] = CONSULTANT_GENDER;
      status = false;
    }

    if (languageValue.length === 0) {
      errorMessage["language"] = CONSULTANT_LANGUAGE;
      status = false;
    }
    if (qualificationValue.length === 0) {
      errorMessage["qualification"] = "Qualification is required";
      status = false;
    }
    if (
      qualificationValue.value === "Others" &&
      !consultantDetail.highestQualification
    ) {
      errorMessage["highestQualification"] =
        "Please enter consultant  qualification";
      status = false;
    }

    if (expertiseValue.length === 0) {
      errorMessage["expertise"] = CONSULTANT_ENTERPRISE;
      status = false;
    }
    // if (!consultantTypeValue) {
    //   errorMessage["type"] = CONSULTANT_TYPE;
    //   status = false;
    // }

    if (consultantSkill.length === 0) {
      errorMessage["skill"] = CONSULTANT_SKILL;
      status = false;
    }
    // if (otherSkill.length === 0) {
    //   errorMessage["otherSkill"] = "Please select consultant other skill";
    //   status = false;
    // }

    setError(errorMessage);
    return status;
  };

  // console.log(hearAboutUsValue, "hearAboutUsValue");

  const getSelectedConsultantDetailHandler = async () => {
    try {
      showLoader();
      const sendData = {
        consultantId: id,
      };
      const { data } = await getSelectedConsultantDetail(sendData);
      console.log(data, "data");
      if (data.code === 200) {
        setConsultantDetail(data?.result);
        setLanguageValue(
          data?.result.language.map((item) => ({
            label: item,
            value: item,
          }))
        );
        setConsultantSkill(
          data?.result?.primarySkills.map((item) => ({
            label: item,
            value: item,
          }))
        );
        setGenderValue({
          label:
            data?.result.gender.charAt(0).toUpperCase() +
            data?.result.gender.slice(1),
          value: data?.result.gender,
        });
        setOtherSkill(
          data?.result?.otherSkills.map((item) => ({
            label: item,
            value: item,
          }))
        );
        setExpertiseValue(
          data?.result?.expertise.map((item) => ({
            label: item,
            value: item,
          }))
        );
        setExperienceValue({
          label: data?.result?.experienceYear,
          value: data?.result?.experienceYear,
        });
        setDailyHourValue({
          label: data?.result?.dailyWorkHour,
          value: data?.result?.dailyWorkHour,
        });
        sethearAboutUsValue({
          label: data?.result?.hearAboutUs,
          value: data?.result?.hearAboutUs,
        });
        setWorkOtherPlatformValue({
          label: data?.result?.otherOnlinePlatformWork,
          value: data?.result?.otherOnlinePlatformWork,
        });

        setPlatformValue({
          label: data?.result?.onlinePlatformWork,
          value: data?.result?.onlinePlatformWork,
        });

        setSourceOfIncomeValue({
          label: data?.result?.mainSourceIncome,
          value: data?.result?.mainSourceIncome,
        });

        setQualificationValue({
          label: data?.result?.qualification,
          value: data?.result?.qualification,
        });
        if (data?.result?.dateOfBirth) {
          setDateOfBirth(new Date(data?.result?.dateOfBirth));
        }

        scrollToUp();
      } else {
        setConsultantDetail("");
        toastMessage(data.message, "error", "consultantDetail");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // console.log(error,"Error");

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "name") {
      updatedValue = optimizeFunction(value);
    }

    if (
      name === "avgLiveBrier" ||
      name === "price" ||
      name === "foreignCountryNo" ||
      name === "pincode" ||
      name==="score"
    ) {
      updatedValue = allowNumber(value);
    }
    setConsultantDetail({ ...consultantDetail, [name]: updatedValue });
  };

  const handlePhoneChange = (phone, country) => {
    // Extract the phone number without country code
    const phoneWithoutCountryCode = phone.replace(country.dialCode, "");
    // Update states
    setConsultantDetail({
      ...consultantDetail,
      mobile: phoneWithoutCountryCode,
    });
    // setCountryCode("+"+country.dialCode);
    setCountryCode("+" + country?.dialCode);
    setCountryName(country.name);
  };

  //  Profile Image Handler
  const profileImageHandler = (e) => {
    let { name, value, files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      if (file && imageFileRegex.test(file.name)) {
        setProfileImage(file);
      } else {
        e.target.value = "";
        setProfileImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setProfileImage("");
    }
  };

  // fetch consultant skill
  const fetchConsultantSkillHandler = async () => {
    try {
      setConsultantSkillLoader(true);
      const { data } = await fatchCategoryApi();
      if (data.code === 200) {
        const updatedConsultantSkill =
          data?.result?.skillList &&
          data?.result?.skillList.length > 0 &&
          data?.result?.skillList.map(({ _id, title }) => ({
            value: title,
            label: title,
          }));
        setConsultantSkillOption(updatedConsultantSkill);
        setOtherSkillOption(updatedConsultantSkill);

        const expertise =
          data?.result?.categoryList &&
          data?.result?.categoryList.length > 0 &&
          data.result.categoryList.map(({ _id, title }) => ({
            value: title,
            label: title,
          }));
        setExpertiseOption(expertise);

        const language =
          data?.result?.languageList &&
          data?.result?.languageList.length > 0 &&
          data.result.languageList.map(({ _id, title }) => ({
            value: title,
            label: title,
          }));
        setLanguageOption(language);
      } else {
        setConsultantSkillOption([]);
        setOtherSkillOption([]);
        setExpertiseOption([]);
        setLanguageOption([]);

        toastMessage(data.message, "error", "categoryList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setConsultantSkillLoader(false);
    }
  };

  useEffect(() => {
    fetchConsultantSkillHandler();
  }, []);

  //--------------change-Consultant------------------------
  const updateConsultantHandler = async () => {
    let isValid = validation();
    if (isValid) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        if (profileImage && profileImage.name) {
          formData.append("profileImage", profileImage);
        }
        if (languageValue && languageValue.length != 0) {
          for (let idx = 0; idx < languageValue.length; idx++) {
            formData.append("language", languageValue[idx].value);
          }
        }

        if (consultantSkill && consultantSkill.length != 0) {
          for (let idx = 0; idx < consultantSkill.length; idx++) {
            formData.append("primarySkills", consultantSkill[idx].value);
          }
        }
        if (otherSkill && otherSkill.length != 0) {
          for (let idx = 0; idx < otherSkill.length; idx++) {
            formData.append("otherSkills", otherSkill[idx].value);
          }
        } else {
          formData.append("otherSkillsDeleted", true);
        }
        if (expertiseValue && expertiseValue.length != 0) {
          for (let idx = 0; idx < expertiseValue.length; idx++) {
            formData.append("expertise", expertiseValue[idx].value);
          }
        }
        if (qualificationValue.value === "Others") {
          formData.append(
            "highestQualification",
            consultantDetail?.highestQualification || ""
          );
        }
        formData.append("qualification", qualificationValue.value || "");
        formData.append("userName", consultantDetail?.userName || "");

        formData.append("dailyWorkHour", dailyHourValue.value || "");
        formData.append("hearAboutUs", hearAboutUsValue.value || "");
        formData.append(
          "otherOnlinePlatformWork",
          workOtherPlatformValue.value
        );
        if (workOtherPlatformValue.value === "Yes") {
          formData.append("onlinePlatformWork", platformValue.value);
        }
        if (
          workOtherPlatformValue.value === "Yes" &&
          platformValue.value === "Other"
        ) {
          formData.append(
            "onlinePlatformName",
            consultantDetail.onlinePlatformName
          );
        }
        formData.append("mainSourceIncome", sourceOfIncomeValue.value);
        formData.append("experienceYear", experienceValue.value);

        const sendData = {
          consultantId: id,
          name: consultantDetail.name || "",
          email: consultantDetail.email || "",
          price: consultantDetail.price || "",
          countryCode: countryCode || "+91",
          mobile: consultantDetail.mobile || "",
          gender: genderValue.value || "",
          score:consultantDetail?.score || "",
          // consType: consultantTypeValue || "",
          address: consultantDetail.address || "",
          avgLiveBrier: consultantDetail.avgLiveBrier || "",
          dateOfBirth: dateFormatYear(dateOfBirth) || "",
          learnAstrologyFrom: consultantDetail?.learnAstrologyFrom,
          instaLink: consultantDetail?.instaLink || "",
          faceBookLink: consultantDetail?.faceBookLink || "",
          linkedinLink: consultantDetail?.linkedinLink || "",
          youTubeLink: consultantDetail.youTubeLink || "",
          foreignCountryNo: consultantDetail.foreignCountryNo || "",
          workingFullTimeJob: consultantDetail?.workingFullTimeJob,
          greaterChallengeAndConquer:
            consultantDetail.greaterChallengeAndConquer,
          bio: consultantDetail.bio,
          isRefer: consultantDetail?.isRefer,
          whenHearAboutUs: consultantDetail.whenHearAboutUs || "",
          minimumEarningExpectation:
            consultantDetail.minimumEarningExpectation || "",
          pincode: consultantDetail?.pincode || "",
          state: consultantDetail?.state || "",
        };
        createMediaData(formData, sendData);
        const data = await editConsultant(formData);
        if (data?.data.code === 200) {
          toastMessage(data.data?.message, "success", "addConsultant");
          setLanguageValue([]);
          setGenderValue("");
          setExpertiseValue([]);
          setConsultantSkill([]);
          setConsultantDetail("");
          setCountryCode("");
          setCountryName("");
          setConsultantDetail("");
          navigate(-1);
        } else {
          toastMessage(data.data?.message, "error", "addConsultant");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  useEffect(() => {
    getSelectedConsultantDetailHandler();
  }, []);

  useEffect(() => {
    setConsultantDetail({ ...consultantDetail, platformName: "" });
  }, [workOtherPlatformValue, platformValue]);

  return (
    <Fragment>
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <div className="user_detail_heading">
              <Link className="back_icon" to={-1}>
                <IconButton>
                  <ArrowBackIosNewIcon className="back_button" />
                </IconButton>
              </Link>
              <h4 className="Title">Edit Consultant Management</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div>
                <label className="my-1 text-dark">
                  Upload Profile
                  <span className="text-danger"> *</span>
                </label>
              </div>
              <input
                type="file"
                onChange={profileImageHandler}
                accept="image/*"
                className={`form-control ${error.profileImage && "is-invalid"}`}
              />
              {error.profileImage && (
                <div className="invalid-feedback d-block">
                  {error.profileImage}
                </div>
              )}
            </div>
            <div className="col-4">
              {/* name */}
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="name"
                  value={consultantDetail?.name || ""}
                  required
                  onChange={handleChange}
                  placeholder="Enter Consultant Name"
                  className={`form-control `}
                  label={"Name"}
                  error={!consultantDetail?.name && error.name}
                  maxLength={30}
                />
              </div>
            </div>

            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="userName"
                  value={consultantDetail?.userName || ""}
                  required
                  onChange={handleChange}
                  placeholder="Enter Consultant User Name"
                  className={`form-control `}
                  label={"User Name"}
                  error={!consultantDetail?.userName && error.userName}
                  maxLength={30}
                />
              </div>
            </div>
            {/* Email */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="email"
                  value={consultantDetail?.email || ""}
                  required
                  onChange={handleChange}
                  placeholder="Enter email"
                  className={`form-control `}
                  label={"Email"}
                  // error={!consultantDetail.email && error.email}
                  error={
                    !consultantDetail.email
                      ? error.email
                      : !consultantDetail.email.match(email) && error.matchEmail
                  }
                />
              </div>
            </div>
            <div className="col-4 cstm_consultant_profile_image">
              <Stack direction="row" className="w" spacing={2}>
                <Avatar
                  alt=""
                  sx={{ width: 150, height: 150 }}
                  src={
                    profileImage && profileImage.name
                      ? URL.createObjectURL(profileImage)
                      : consultantDetail.profileImage
                  }
                />
              </Stack>
            </div>

            {/* phone Input */}
            <div className="col-4">
              <div className="form-group cstM-tele_input mb-2 text-wrapper">
                <label className="my-1 text-dark">Mobile Number</label>
                <PhoneInput
                  className={`${!consultantDetail.mobile && "is-invalid"}`}
                  country={"in"}
                  // value={countryCode}
                  value={`${consultantDetail.countryCode}${consultantDetail.mobile}`}
                  enableSearch={true}
                  placeholder={"Enter Mobile Number"}
                  countryCodeEditable={false}
                  onChange={handlePhoneChange}
                />
                {!consultantDetail.mobile && (
                  <span className="invalid-feedback">{error.mobile}</span>
                )}
              </div>
            </div>

            <div className="col-4">
              <div className="form-group select_sm_width">
                <EditCommonSelect
                  className="add-consultant"
                  label={"Gender"}
                  options={genderOptions}
                  onChange={setGenderValue}
                  isMulti={false}
                  initialSelectedValues={genderValue}
                  required
                  error={error.gender}
                />
              </div>
            </div>
            {/* date picker */}
            <div className="col-4">
              <div className="form-group mb-2">
                <label className="my-1 text-dark w-100">DOB</label>
                <ReactDatePicker
                  selected={dateOfBirth}
                  onChange={(date) => setDateOfBirth(date)}
                  className={`form-control multi_search_date`}
                  isClearable
                  dateFormat="dd-MMM-yyyy"
                  placeholderText="dd-MMM-yyyy"
                  showMonthDropdown
                  useShortMonthInDropdown
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  maxDate={new Date()}
                />
                {error.dateOfBirth && (
                  <span className="invalid-feedback d-block">
                    {error.dateOfBirth}
                  </span>
                )}
              </div>
            </div>

            {/* Language */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <EditCommonSelect
                  className="add-consultant"
                  label={"Language"}
                  options={languageOptions}
                  onChange={setLanguageValue}
                  initialSelectedValues={languageValue}
                  isMulti={true}
                  isSearchable={true}
                  required
                  error={languageValue.length === 0 && error.language}
                />
              </div>
            </div>
            {/* Primary skills */}
            <div className="col-4">
              <div
                className={`form-group select_sm_width ${
                  consultantSkill.length === 1 && "hide_multiSelect_arrow"
                }`}
              >
                <EditCommonSelect
                  className="add-consultant"
                  label={"Primary skill"}
                  options={consultantSkillOption}
                  onChange={setConsultantSkill}
                  initialSelectedValues={consultantSkill}
                  loading={consultantSkillLoader}
                  isMulti={true}
                  isSearchable={true}
                  maxOptions={3}
                  menuDisable={consultantSkill.length === 1 ? true : false}
                  hideSelectedOptions={consultantSkill.length === 1}
                  required
                  error={consultantSkill.length === 0 && error.skill}
                />
              </div>
            </div>
            {/* Other skills */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <EditCommonSelect
                  className="add-consultant"
                  label={"Others skill"}
                  options={otherSkillOption}
                  onChange={setOtherSkill}
                  initialSelectedValues={otherSkill}
                  isMulti={true}
                  isSearchable={true}
                  loading={otherSkillLoader}
                  required
                  error={otherSkill.length === 0 && error.otherSkill}
                />
              </div>
            </div>
            {/* Expertise */}
            <div className="col-4">
              <div
                className={`form-group select_sm_width ${
                  expertiseValue.length === 3 && "hide_multiSelect_arrow"
                }`}
              >
                <EditCommonSelect
                  className="add-consultant"
                  label={"Expertise"}
                  options={expertiseOption}
                  onChange={setExpertiseValue}
                  initialSelectedValues={expertiseValue}
                  isMulti={true}
                  isSearchable={true}
                  required
                  maxOptions={3}
                  menuDisable={expertiseValue.length === 3}
                  error={expertiseValue.length === 0 && error.expertise}
                />
              </div>
            </div>

            {/* Experiences in Years */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <EditCommonSelect
                  className="add-consultant"
                  label={"Experiences in Years"}
                  options={experienceOptions}
                  onChange={setExperienceValue}
                  initialSelectedValues={experienceValue}
                  required
                  error={experienceValue.length === 0 && error.experienceYear}
                />
              </div>
            </div>
   {/* Consultant Score */}
   <div className="col-4">
   <div className="form-group mb-2">
              <Input
                type="text"
                name="score"
                value={consultantDetail?.score || ""}
                required
                onChange={handleChange}
                placeholder="Enter Consultant Score"
                className={`form-control `}
                label={"Consultant Score"}
                // error={!consultantDetail?.score && error.score}
                maxLength={4}
              />
            </div>
            </div>
         

            {/* How many hours you work daily? */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <EditCommonSelect
                  className="add-consultant"
                  label={"How many hours you work daily?"}
                  options={dailyHourOptions}
                  onChange={setDailyHourValue}
                  initialSelectedValues={dailyHourValue}
                  required
                  error={dailyHourValue.length === 0 && error.dailyWorkHour}
                />
              </div>
            </div>

            {/*  Price Per Min  */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <Input
                  type="text"
                  name="price"
                  value={consultantDetail?.price || ""}
                  required
                  onChange={handleChange}
                  placeholder="Price"
                  className={`form-control `}
                  label={"Price (Per Min)"}
                  error={!consultantDetail.price && error.price}
                  maxLength={3}
                />
              </div>
            </div>
            {/* Where did you hear about Vedic Meet? (Give Option) */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <EditCommonSelect
                  className="add-consultant"
                  label={"Where did you hear about Vedic Meet?"}
                  options={hearOptions}
                  onChange={sethearAboutUsValue}
                  initialSelectedValues={hearAboutUsValue}
                  required
                  error={!hearAboutUsValue && error.hearAboutUs}
                />
              </div>
            </div>

            {/* When did you hear about vedic meet? */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <Input
                  type="text"
                  name="whenHearAboutUs"
                  value={consultantDetail?.whenHearAboutUs || ""}
                  required
                  onChange={handleChange}
                  placeholder="When you hear about us.."
                  className={`form-control `}
                  label={"When did you hear about vedic meet?"}
                  error={
                    !consultantDetail.whenHearAboutUs && error.whenHearAboutUs
                  }
                  // maxLength={30}
                />
              </div>
            </div>

            {/* Are you work on any other online astrologer platform? */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <EditCommonSelect
                  className="add-consultant"
                  label={
                    "Are you work on any other online astrologer platform?"
                  }
                  options={workAnyOtherPlatformOptions}
                  onChange={setWorkOtherPlatformValue}
                  initialSelectedValues={workOtherPlatformValue}
                  required
                  error={error.otherOnlinePlatformWork}
                />
              </div>
            </div>

            {workOtherPlatformValue.value === "Yes" && (
              <div className="col-4">
                <div className="form-group select_sm_width">
                  <EditCommonSelect
                    className="add-consultant"
                    label={"Please select platform"}
                    options={platformOptions}
                    onChange={setPlatformValue}
                    initialSelectedValues={platformValue}
                    required
                    error={error.platformValue}
                  />
                </div>
              </div>
            )}

            {workOtherPlatformValue.value == "Yes" &&
              platformValue.value == "Other" && (
                <div className="col-4">
                  <div className="form-group select_sm_width">
                    <Input
                      type="text"
                      name="onlinePlatformName"
                      value={consultantDetail?.onlinePlatformName || ""}
                      required
                      onChange={(e) => {
                        setConsultantDetail({
                          ...consultantDetail,
                          onlinePlatformName: e.target.value,
                        });
                      }}
                      placeholder="Enter platform name"
                      className={`form-control `}
                      label={"Platform name"}
                      error={
                        !consultantDetail.platformName && error.platformName
                      }
                    />
                  </div>
                </div>
              )}

            {/* Avg Live Brier */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="avgLiveBrier"
                  value={consultantDetail?.avgLiveBrier || ""}
                  required
                  onChange={handleChange}
                  placeholder="Average Live Barrier (in Mins)"
                  className={`form-control `}
                  label={"Avg. Live Barrier (in Mins)"}
                  error={!consultantDetail.avgLiveBrier && error.avgLiveBrier}
                  // maxLength={30}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="address"
                  value={consultantDetail?.address || ""}
                  required
                  onChange={handleChange}
                  placeholder="Address"
                  className={`form-control `}
                  label={"Address"}
                  error={!consultantDetail.address && error.address}
                  // maxLength={30}
                />
              </div>
            </div>
            {/* City */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="city"
                  value={consultantDetail?.city || ""}
                  required
                  onChange={handleChange}
                  placeholder="City"
                  className={`form-control `}
                  label={"City"}
                  error={!consultantDetail.city && error.city}
                  // maxLength={30}
                />
              </div>
            </div>

            {/* State */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="state"
                  value={consultantDetail?.state || ""}
                  required
                  onChange={handleChange}
                  placeholder="State"
                  className={`form-control `}
                  label={"State"}
                  error={!consultantDetail.state && error.state}
                  // maxLength={30}
                />
              </div>
            </div>
            {/* Pin Code */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="pincode"
                  value={consultantDetail?.pincode || ""}
                  required
                  onChange={handleChange}
                  placeholder="Pincode"
                  className={`form-control `}
                  label={"Pincode"}
                  error={!consultantDetail.pincode && error.pincode}
                  maxLength={6}
                />
              </div>
            </div>

            {/* Main source of income */}
            <div className="col-4">
              <div className="form-group mb-2">
                <EditCommonSelect
                  className="add-consultant"
                  label={
                    "What is your main source of income(Other than astrology)?"
                  }
                  options={sourceOfIncomeOptions}
                  onChange={setSourceOfIncomeValue}
                  initialSelectedValues={sourceOfIncomeValue}
                  required
                  error={error.mainSourceIncome}
                />
              </div>
            </div>

            {/* Highest Qualification */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <EditCommonSelect
                  className="add-consultant"
                  label={"Please select your highest qualification"}
                  options={qualificationOptions}
                  onChange={setQualificationValue}
                  initialSelectedValues={qualificationValue}
                  isMulti={false}
                  isSearchable={false}
                  required
                  error={!qualificationValue && error.qualification}
                />
              </div>
            </div>
            {qualificationValue.value == "Others" && (
              <div className="col-4">
                <div className="form-group mb-2">
                  <Input
                    type="text"
                    name="highestQualification"
                    value={consultantDetail?.highestQualification || ""}
                    required
                    onChange={handleChange}
                    placeholder="Enter Qualification"
                    className={`form-control `}
                    label={"Qualification"}
                    error={
                      !consultantDetail.highestQualification &&
                      error.highestQualification
                    }
                  />
                </div>
              </div>
            )}

            {/* Minimum earning expecatations form vedic */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="minimumEarningExpectation"
                  value={consultantDetail?.minimumEarningExpectation || ""}
                  required
                  onChange={handleChange}
                  placeholder="Earning Expectations"
                  className={`form-control `}
                  label={"Minimum earning expecatations from vedic?"}
                  error={
                    !consultantDetail.minimumEarningExpectation &&
                    error.minimumEarningExpectation
                  }
                  // maxLength={30}
                />
              </div>
            </div>

            {/* Instagram Profile Link */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="instaLink"
                  value={consultantDetail?.instaLink || ""}
                  onChange={handleChange}
                  placeholder="Insta profile link"
                  className={`form-control `}
                  label={"Instagram Profile Link"}
                  error={!consultantDetail.instaLink && error.instaLink}
                  // maxLength={30}
                />
              </div>
            </div>

            {/* FB profile Link */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="faceBookLink"
                  value={consultantDetail?.faceBookLink || ""}
                  onChange={handleChange}
                  placeholder="Facebook profile link"
                  className={`form-control `}
                  label={"Facebook Profile Link"}
                  error={!consultantDetail.faceBookLink && error.faceBookLink}
                  // maxLength={30}
                />
              </div>
            </div>

            {/* Linked In Profile Link */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="linkedinLink"
                  value={consultantDetail?.linkedinLink || ""}
                  onChange={handleChange}
                  placeholder="LinkedIn profile link"
                  className={`form-control `}
                  label={"LinkedIn Profile Link"}
                  error={!consultantDetail.linkedinLink && error.linkedinLink}
                  // maxLength={30}
                />
              </div>
            </div>

            {/* Youtube Profile Link */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="youTubeLink"
                  value={consultantDetail?.youTubeLink || ""}
                  onChange={handleChange}
                  placeholder="youtube profile link"
                  className={`form-control `}
                  label={"Youtube Profile Link"}
                  error={!consultantDetail.youTubeLink && error.youTubeLink}
                  // maxLength={30}
                />
              </div>
            </div>

            {/* Reason For OnBoard */}
            <div className="col-4">
              <div className="form-group mb-2">
                <TextArea
                  type="text"
                  name="reasonOfOnboard"
                  value={consultantDetail?.reasonOfOnboard || ""}
                  required
                  onChange={handleChange}
                  placeholder="Enter Reason..."
                  className={`form-control `}
                  label={"Why do you belive we should onborad you?"}
                  error={
                    !consultantDetail.reasonOfOnboard && error.reasonOfOnboard
                  }
                  // maxLength={30}
                />
              </div>
            </div>

            {/* WHere do u learn from  */}
            <div className="col-4">
              <div className="form-group mb-2">
                <TextArea
                  type="text"
                  name="learnAstrologyFrom"
                  value={consultantDetail?.learnAstrologyFrom || ""}
                  required
                  onChange={handleChange}
                  placeholder="Learn Astrology From..."
                  className={`form-control `}
                  label={"Where did you learn astrology from?"}
                  error={
                    !consultantDetail.learnAstrologyFrom &&
                    error.learnAstrologyFrom
                  }
                  // maxLength={30}
                />
              </div>
            </div>
            {/* Has anyone refered you to vedic meet */}
            <div className="col-4">
              <div className="form-group mb-2">
                <div>
                  <label className="my-1">
                    Has anyone refered you to vedic meet
                  </label>
                  <span className="text-danger"> *</span>
                </div>
                <div className="checkbox_Wrap">
                  <label>
                    <span>Yes </span>{" "}
                    <input
                      type="radio"
                      name="isRefer"
                      value={true}
                      checked={
                        consultantDetail?.isRefer == true ||
                        consultantDetail?.isRefer == "true"
                      }
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    <span>No</span>
                    <input
                      type="radio"
                      name="isRefer"
                      value={false}
                      checked={
                        consultantDetail?.isRefer == false ||
                        consultantDetail?.isRefer == "false"
                      }
                      onChange={handleChange}
                    />
                  </label>
                </div>
                {error.isRefer && (
                  <div className="invalid-feedback d-block">
                    {error.isRefer}
                  </div>
                )}
              </div>
            </div>

            {/* Number of countries you lived or travelled to? */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="foreignCountryNo"
                  value={consultantDetail?.foreignCountryNo || ""}
                  required
                  onChange={handleChange}
                  placeholder="Enter No. of countries you travelled or lived.."
                  className={`form-control `}
                  label={"Number of countries you lived or travelled to?"}
                  error={
                    !consultantDetail.foreignCountryNo && error.foreignCountryNo
                  }
                  maxLength={3}
                />
              </div>
            </div>

            {/* Are you currently working full time job? */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="workingFullTimeJob"
                  value={consultantDetail?.workingFullTimeJob || ""}
                  required
                  onChange={handleChange}
                  placeholder="Are you currently working full time job?"
                  className={`form-control `}
                  label={"Are you currently working full time job?"}
                  error={
                    !consultantDetail.workingFullTimeJob &&
                    error.workingFullTimeJob
                  }
                  // maxLength={30}
                />
              </div>
            </div>

            {/* Bio */}
            <div className="col-4">
              <div className="form-group mb-2">
                <TextArea
                  type="text"
                  name="bio"
                  value={consultantDetail?.bio || ""}
                  required
                  onChange={handleChange}
                  placeholder="Bio.."
                  className={`form-control `}
                  label={"Bio"}
                  error={!consultantDetail.bio && error.bio}
                  // maxLength={30}
                />
              </div>
            </div>

            {/* Grater Challenge & Conqueor */}
            <div className="col-4">
              <div className="form-group mb-2">
                <TextArea
                  type="text"
                  name="greaterChallengeAndConquer"
                  value={consultantDetail?.greaterChallengeAndConquer || ""}
                  required
                  onChange={handleChange}
                  placeholder="What has been your greatest challenge and how did you conquer it..."
                  className={`form-control `}
                  label={
                    "What has been your greatest challenge and how did you conquer it?"
                  }
                  error={
                    !consultantDetail.greaterChallengeAndConquer &&
                    error.greaterChallengeAndConquer
                  }
                />
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              className="btn_size text-center"
              onClick={updateConsultantHandler}
              ref={buttonref}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditConsultant;
