import React, { useState } from 'react'
import ModalBox from '../../components/modal/ModalBox'
import { Input } from '../../components/Form'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { allowAlphaNumeric, optimizeFunction, regex } from '../../utils/regex'
import { Avatar } from '@mui/material'
import { toastMessage } from '../../utils/toastMessage'
import { updateMusicCategoryApi } from '../../services/musicManagement'
import { checkImageDimensions } from '../../utils/checkImageDimension'
import commonMessages from '../../utils/commonMessages'

const {
  MUSIC_CATEGORY_TITLE,
  MUSIC_CATEGORY_IMAGE,
  IMAGE_FORMAT,
  MESSAGE_SIZE,
} = commonMessages
const { imageFileRegex } = regex

const EditMusicCategory = ({
  show,
  close,
  musicCategoryDetail,
  setMusicCategoryDetail,
  musicListing,
}) => {
  // handle all state

  const [image, setImage] = useState('')
  const [error, setError] = useState({})
  const [updateMusicCategory, setUpdateMusicCategory] = useState({
    id: musicCategoryDetail._id,
    title: musicCategoryDetail.title,
    image: musicCategoryDetail.musicImage,
  })

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader('Update')

  // handle all function

  // console.log("AddSubCategory",input)
  //----------vaidation------------
  const validation = () => {
    let errorMessage = {}
    let status = true

    if (!updateMusicCategory.title) {
      errorMessage['musicTitle'] = MUSIC_CATEGORY_TITLE
      status = false
    }

    setError(errorMessage)
    return status
  }

  // image handler function
  const imageHandler = async (e) => {
    e.preventDefault()
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        // setImage(e.target.files[0])
        const validSize = await checkImageDimensions(e.target.files[0])
        if (validSize) {
          setImage(e.target.files[0])
        } else {
          e.target.value = ''
          setImage('')
        }
      } else {
        setImage('')
        e.target.value = ''
        toastMessage(IMAGE_FORMAT, 'error', 'imageFormat')
      }
    } else {
      e.target.value = ''
      setImage('')
    }
  }
  //------------------onChange-input-value---------------------------
  const handleChange = (e) => {
    const { name, value } = e.target
    let updatedValue = value
    if (name === 'title') {
      updatedValue = allowAlphaNumeric(value)
    }
    setUpdateMusicCategory({ ...updateMusicCategory, [name]: updatedValue })
  }

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setImage('')
    setMusicCategoryDetail({})
    setError({})
    close()
  }
  //--------------Update-music-category------------------------
  const updateMusicCategoryHandler = async () => {
    const status = validation()
    if (status) {
      try {
        buttonLoader(true)
        const formData = new FormData()
        formData.append('musicId', updateMusicCategory.id)
        formData.append('title', updateMusicCategory.title)
        if (image && image.name) {
          formData.append('musicImage', image)
        }
        const { data } = await updateMusicCategoryApi(formData)
        if (data.code === 200) {
          toastMessage(data.message, 'success', 'updateMusicCategory')
          musicListing()
          modalClose()
        } else {
          toastMessage(data.message, 'error', 'updateMusicCategory')
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        buttonLoader(false)
      }
    }
  }

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={'Edit Music Category'}
      closeIcon
      size={'sm'}
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <p>{MESSAGE_SIZE}</p>
          <button
            className="btn_size"
            ref={buttonref}
            onClick={updateMusicCategoryHandler}
          >
            Update
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={updateMusicCategory?.title || ''}
            required
            onChange={(e) => handleChange(e)}
            placeholder="Category Title"
            className={`form-control `}
            label={'Add Category'}
            error={!updateMusicCategory.title && error.musicTitle}
            // maxLength={30}
          />
        </div>

        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="categoryImage"
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Banner Image"
            className={`form-control `}
            label={'Category Image'}
            // error={!image && error.image}
            // maxLength={30}
          />
        </div>

        <div>
          {
            <>
              <Avatar
                variant="square"
                sx={{
                  width: '100%',
                  borderRadius: 1,
                  height: 140,
                  border: '1px solid #FF8989',
                }}
                src={
                  image && image.name
                    ? URL.createObjectURL(image)
                    : updateMusicCategory.image
                }
              />
            </>
          }
        </div>
      </div>
    </ModalBox>
  )
}

export default EditMusicCategory
