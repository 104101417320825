import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import NotFound from "../../components/not-found/NotFound";
import { Link, useParams, useSearchParams } from "react-router-dom";
import IconButton from "../../components/Button/IconButton";
import { Select } from "../../components/Form";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
import { IoSearch } from "react-icons/io5";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import findSerialNumber from "../../utils/findSerialNumber";
import { toastMessage } from "../../utils/toastMessage";
import common from "../../routes/common";
import { dateFormat } from "../../utils/dateFormat";
import scrollToUp from "../../utils/scrollToUp";
import { CapitalizeFirstWord } from "../../utils/CapitalizeFirstWord";
import Loader from "../../comman/Loader";
import ModalBox from "../../components/modal/ModalBox";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { block_unblock_Api, fetchFormApi } from "../../services/form16A";
import Switch from "../../components/Switch/Switch";
import consultantPerformance from "../../Assests/image/consultantPerformance.svg";
import AddIcon from "@mui/icons-material/Add";
// import AddForm from "./AddForm";
// import EditForm from "./EditForm";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EditDiscountCoupon from "./EditDiscountCoupon";
import AddDiscountCoupon from "./AddDiscountCoupon";
import {
  deleteDiscountCouponApi,
  discount_block_unblock_Api,
  fetchShopifyDiscountCouponApi,
} from "../../services/shopify";
import DeleteIcon from "@mui/icons-material/Delete";

const { NOT_FOUND } = common;

const Shopify = () => {
  const [buttonRef, buttonLoader] = useButtonLoader("Yes");
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  const { consultantId } = useParams();

  const [search, setSearch] = useState(
    getLocalStorage("shopifyDiscountList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);
  const [total, setTotal] = useState(0);
  const [statusModal, setStatusModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState({});
  const [couponId, setCouponId] = useState({});
  const [shopifyDiscountList, setShopifyDiscountList] = useState([]);
  const [eventId, setEventId] = useState({});
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const status = searchParam.get("status") || "";

  useEffect(() => {
    setLocalStorage("shopifyDiscountList", "search", search);
  }, [search]);

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  const editHandler = (data) => {
    setSelectedCoupon(data);
    setEditModal(true);
  };
  //------------------request-listing-Api----------------------------------
  const getShopifyDiscountList = async (debounceValue) => {
    try {
      showLoader();
      const sendData = {
        page: `${activePage}`,
        limit: "10",
        consultantId: consultantId,
      };
      if (debounceValue) {
        sendData.page = "1";
        sendData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      setShopifyDiscountList([]);
      const { data } = await fetchShopifyDiscountCouponApi(sendData);
      if (data.code === 200) {
        setShopifyDiscountList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setShopifyDiscountList([]);
        setTotal(0);
        toastMessage(data.message, "error", "shopifyDiscountList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // Change Form Status Handler
  const changeFormStatusHandler = async () => {
    try {
      buttonLoader(true);
      const { discountCouponId, status } = couponId;
      const newStatus = Boolean(status);

      const res = await discount_block_unblock_Api({
        discountCouponId: discountCouponId,
        status: newStatus,
      });
      if (res?.data?.code === 200) {
        toastMessage(
          res?.data?.message,
          "success",
          "blockUnblockShopifyDiscount"
        );
        setStatusModal(false);
        setCouponId({});
        getShopifyDiscountList();
      } else {
        toastMessage(
          res?.data?.message,
          "error",
          "blockUnblockShopifyDiscount"
        );
        setCouponId({});
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      buttonLoader(false);
    }
  };

  // Delete Discount Coupon
  const deleteCouponHandler = async () => {
    try {
      buttonLoader(true);
      const res = await deleteDiscountCouponApi({
        discountCouponId: couponId.discountCouponId,
      });
      if (res?.data?.code === 200) {
        toastMessage(res?.data?.message, "success", "discountCouponId");
        setCouponId("");
        setDeleteModal(false);
        getShopifyDiscountList();
      } else {
        toastMessage(res?.data?.message, "error", "discountCouponId");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      buttonLoader(false);
    }
  };

  useEffect(() => {
    if (Number(activePage) === 1) {
      searchParam.delete("page");
    }
    if (!status) {
      searchParam.delete("status");
    }
    setSearchParam(searchParam);
    getShopifyDiscountList(debounceValue);
  }, [searchParam, debounceValue]);

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <div className="user_detail_heading">
              <Link
                className="back_icon"
                to={`/consultant-management/consultant-detail/${consultantId}`}
              >
                <IconButton>
                  <ArrowBackIosNewIcon className="back_button" />
                </IconButton>
              </Link>
              <h4 className="Title">Shopify</h4>
            </div>
            {/* <h4 className="Title"></h4> */}
            <div className="block_user_button">
              <button onClick={() => setAddModal(true)}>
                <AddIcon />
                Add Shopify Discount Coupon
              </button>
            </div>
          </div>
          <div className="FilterBox FilterArea">
            {/* <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className={`form-control ${search && "active"}`}
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">
                <IoSearch />
              </span>
            </div> */}
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Coupon</th>
                  <th>Commission</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>
              <tbody>
                {shopifyDiscountList.length > 0 ? (
                  shopifyDiscountList.map((data, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          <td className="user_profile_image">
                            {data?.coupon || "---"}
                          </td>
                          <td>{data?.commission}</td>
                          <td>
                            {/* {form?.status === 1 && ( */}
                            <div className="action_button">
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() => editHandler(data)}
                              >
                                <EditIcon className="edit_icon" />
                              </IconButton>
                              <Switch
                                checked={data.status}
                                onChange={() => {
                                  setStatusModal(true);
                                  setCouponId({
                                    discountCouponId: data._id,
                                    status: !data.status,
                                  });
                                }}
                              />
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() => {
                                  setDeleteModal(true);
                                  setCouponId({
                                    discountCouponId: data._id,
                                  });
                                }}
                              >
                                <DeleteIcon className="edit_icon" />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={8}
                    msg="Request not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total > 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/* Status Modal */}
      <ModalBox
        status={statusModal}
        onClose={() => {
          setStatusModal(false);
          setCouponId({});
        }}
        title={"Change Status"}
        description={
          "Are you sure want to change status of this discount coupon?"
        }
        action={changeFormStatusHandler}
        ref={buttonRef}
      />

      {addModal && (
        <AddDiscountCoupon
          addModal={addModal}
          setAddModal={setAddModal}
          listing={getShopifyDiscountList}
          consultantId={consultantId}
        />
      )}

      {editModal && (
        <EditDiscountCoupon
          editModal={editModal}
          setEditModal={setEditModal}
          setSelectedCoupon={setSelectedCoupon}
          selectedCoupon={selectedCoupon}
          listing={getShopifyDiscountList}
        />
      )}

      <ModalBox
        status={deleteModal}
        onClose={() => setDeleteModal(false)}
        title={"Delete Coupon Discount"}
        description={"Are you sure want to delete this coupon discount?"}
        action={deleteCouponHandler}
        ref={buttonRef}
      />
    </>
  );
};

export default Shopify;
