import React, { useState } from 'react'
import ModalBox from '../../../components/modal/ModalBox'
import { Input } from '../../../components/Form'
import useButtonLoader from '../../../hooks/UseButtonLoader'
import { allowAlphaNumericWithSpace, optimizeFunction, regex } from '../../../utils/regex'
import { toastMessage } from '../../../utils/toastMessage'
import { addMeditationApiHandler } from '../../../services/meditationManagement'
import commonMessages from '../../../utils/commonMessages'
import YoutubeVideoPlayer from '../../../components/Video/YoutubeVideoPlayer'
import {
  addTrainingMediaApiHandler,
  editTrainingMediaApiHandler,
} from '../../../services/trainingManagement'
const { TRAINING_TITLE, TRAINING_LINK, VALID_TRAINING_LINK } = commonMessages
const { youtubeLinkRegex,youtubeUrlRegex } = regex

const EditTraining = ({
  show,
  close,
  trainingCategoryDetail,
  setTrainingCategoryDetail,
  trainingListing,
}) => {
  // handle all state
  const [editTrainingValue, setEditTrainingValue] = useState({
    id: trainingCategoryDetail._id,
    title: trainingCategoryDetail.title,
    link: trainingCategoryDetail.videoLink,
  })
  const [error, setError] = useState({})

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader('Update')

  // handle all function

  // console.log("AddSubCategory",input)
  //----------vaidation------------
  const handleValidation = () => {
    const error = {}
    let status = true

    if (!editTrainingValue.title) {
      error['title'] = TRAINING_TITLE
      status = false
    }

    if (!editTrainingValue.link) {
      error['videoLink'] = TRAINING_LINK
      status = false
    }
    // if (!editTrainingValue.link.match(youtubeLinkRegex)) {
    //   error['matchVideoLink'] = VALID_TRAINING_LINK
    //   status = false
    // }
    if (!editTrainingValue.link.match(youtubeUrlRegex)) {
      error['matchVideoLink'] = VALID_TRAINING_LINK
      status = false
    }

    setError(error)
    return status
  }

  // Add training value

  const editTrainingVideoHandler = (event) => {
    const { name, value } = event.target
    let updatedValue = value
    if (name === 'title') {
      updatedValue = allowAlphaNumericWithSpace(value)
    }
    setEditTrainingValue({ ...editTrainingValue, [name]: updatedValue })
  }

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setEditTrainingValue({
      id: '',
      title: '',
      link: '',
    })
    setError({})
    close()
  }
  //--------------Add-sub-category------------------------
  const editTrainingHandler = async () => {
    const status = handleValidation()
    if (status) {
      try {
        buttonLoader(true)
        const sendData = {
          growthTrainingMediaId: editTrainingValue.id,
          title: editTrainingValue.title,
          videoLink: editTrainingValue.link,
        }
        const { data } = await editTrainingMediaApiHandler(sendData)
        if (data.code === 200) {
          toastMessage(data.message, 'success', 'editTrainingHandler')
          trainingListing()
          modalClose()
        } else {
          toastMessage(data.message, 'error', 'editTrainingHandler')
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        buttonLoader(false)
      }
    }
  }

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={'Edit Training Video'}
      closeIcon
      custom
      customizedFooter={
        <button
          className="btn_size"
          ref={buttonref}
          onClick={editTrainingHandler}
        >
          Update
        </button>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={editTrainingValue.title || ''}
            required
            onChange={(e) => editTrainingVideoHandler(e)}
            placeholder="Training Title"
            className={`form-control`}
            label={'Training Title'}
            error={!editTrainingValue.title && error.title}
            maxhandleValidationLength={40}
          />
        </div>

        <div className="form-group mb-2">
          <Input
            type="text"
            name="link"
            value={editTrainingValue.link || ''}
            required
            onChange={(e) => editTrainingVideoHandler(e)}
            placeholder="Training Video Link"
            className={`form-control`}
            label={'Training Video Link'}
            error={
              !editTrainingValue.link
                ? error.videoLink
                // : !editTrainingValue.link.match(youtubeLinkRegex) &&
                : !editTrainingValue.link.match(youtubeUrlRegex) &&

                  error.matchVideoLink
            }
          />
        </div>

        {editTrainingValue.link &&
          // editTrainingValue.link.match(youtubeLinkRegex) && (
          editTrainingValue.link.match(youtubeUrlRegex) && (

            <YoutubeVideoPlayer
              src={editTrainingValue.link || ''}
              height={'200'}
              width={'100%'}
            />
          )}
      </div>
    </ModalBox>
  )
}

export default EditTraining
