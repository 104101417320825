import {
    instance as axios,
    setJwtToken,
    setMultiPartHeader,
  } from '../../config/axiosInstance'
  
  // ---------------------fatch-explore--------------------------
  export const fetchExploreApi = async (data) => {
    const endPoint =
      `/admin/explore` + '?' + new URLSearchParams({ ...data }).toString()
    return await axios.get(endPoint, setJwtToken())
  }

 // ---------------------add-explore---------------------------
export const AddExploreApi = async (data) => {
    const endPoint = `/admin/explore/add`
    return await axios.post(endPoint, data, setMultiPartHeader())
  }

 // ---------------------change-status-explore---------------------------
export const changeExploreStatusCategory = async (data) => {
    const endPoint = `/admin/explore/block_unblock`
    return await axios.put(endPoint, data, setJwtToken())
  }

// update explore cateogry
export const updateStoryApi = async (data) => {
    const endPoint = `/admin/explore/update`
    return await axios.put(endPoint, data, setMultiPartHeader())
  }


  //   //------------------ Explore Detail API-------------------------------
  export const fetchExploreDetailApi = async (data) => {
    const endPoint =
      `/admin/explore/details` + '?' + new URLSearchParams({ ...data }).toString()
  
    return await axios.get(endPoint, setJwtToken())
  }

    //   //------------------ Explore Like and COmment API-------------------------------
    export const fetchExploreLikeCommentApi = async (data) => {
      const endPoint =
        `/admin/explore/likes_comments` + '?' + new URLSearchParams({ ...data }).toString()
    
      return await axios.get(endPoint, setJwtToken())
    }