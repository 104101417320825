import {
    instance as axios,
    setJwtToken,
  } from '../../config/axiosInstance'
  
  // ---------------------Notification-listing--------------------------
  export const notificationListingApi = async (data) => {
    const endPoint =
      `/admin/notification` + '?' + new URLSearchParams({ ...data }).toString()
    return await axios.get(endPoint, setJwtToken())
  }

//--------------------------Send Notification---------------------
export const sendNotificationApi = async (reqData) => {
    const endPoint = `/admin/notification/send`
    return await axios.post(endPoint, reqData, setJwtToken())
  }

    // ---------------------Delete Notification--------------------------
    export const deleteNotificationApi = async (data) => {
      const endPoint =
        `/admin/notification/delete` + '?' + new URLSearchParams({ ...data }).toString()
      return await axios.delete(endPoint, setJwtToken())
    }

  