import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const usePasswordToggle = (size = "medium") => {
  const [visible, setVisible] = useState(false);

  const Icon = visible ? (
    <VisibilityIcon
      onClick={() => setVisible((visible) => !visible)}
      fontSize={size}
      sx={{color:"black"}}
    />
  ) : (
    <VisibilityOffIcon
      onClick={() => setVisible((visible) => !visible)}
      fontSize={size}
      sx={{color:"black"}}
    />
  );

  const inputType = visible ? "text" : "password";

  return [inputType, Icon];
};

export default usePasswordToggle;
