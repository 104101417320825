import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import NotFound from "../../components/not-found/NotFound";
import { Link, useSearchParams } from "react-router-dom";
import IconButton from "../../components/Button/IconButton";
import { Select } from "../../components/Form";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
import { IoSearch } from "react-icons/io5";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import findSerialNumber from "../../utils/findSerialNumber";
import { toastMessage } from "../../utils/toastMessage";
import common from "../../routes/common";
import { dateFormat } from "../../utils/dateFormat";
import scrollToUp from "../../utils/scrollToUp";
import { CapitalizeFirstWord } from "../../utils/CapitalizeFirstWord";
import Loader from "../../comman/Loader";
import { fetchLiveApi } from "../../services/liveManagement/Index";
import ModalBox from "../../components/modal/ModalBox";
import { emitData, socketConnection } from "../../utils/webSocketServices";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { fetchOffersApi } from "../../services/offersManagement";
import Switch from "../../components/Switch/Switch";
import OfferStatus from "./OfferStatus";
import AddIcon from "@mui/icons-material/Add";
import AddOffer from "./AddOffer";
import EditIcon from "@mui/icons-material/Edit";
import EditOffer from "./EditOffer";

const { NOT_FOUND } = common;

const OffersManagement = () => {
  const [buttonRef, buttonLoader] = useButtonLoader("Yes");
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;

  const [search, setSearch] = useState(
    getLocalStorage("offersList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);
  const [selectedOfferDetail, setSelectedOfferDetail] = useState(null);
  const [editOfferModal, setEditOfferModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [offersList, setOffersList] = useState([]);
  const [eventId, setEventId] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [addOfferModal, setAddOfferModal] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const status = searchParam.get("status") || "";
  const [statusModal, setStatusModal] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [statusId, setStatusId] = useState(null);

  useEffect(() => {
    setLocalStorage("offersList", "search", search);
  }, [search]);

  const statusHandler = (value) => {
    if (status === value) {
      searchParam.delete("status");
    } else {
      searchParam.set("status", value);
    }
    searchParam.delete("page");
    setSearchParam(searchParam);
  };

  // update music category handler
  const updateOfferHandler = (data) => {
    setSelectedOfferDetail(data || null);
    setEditOfferModal(true);
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true);
    setStatusId(id);
    setStatusValue(status);
  };

  //------------------request-listing-Api----------------------------------
  const getOffersList = async (debounceValue) => {
    try {
      showLoader();
      const sendData = {
        page: `${activePage}`,
        limit: "10",
        type: "OFFERS",
      };
      if (debounceValue) {
        sendData.page = "1";
        sendData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      if (status) {
        sendData.status = status;
      }
      setOffersList([]);
      const { data } = await fetchOffersApi(sendData);
      if (data.code === 200) {
        setOffersList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setOffersList([]);
        setTotal(0);
        toastMessage(data.message, "error", "offersList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (Number(activePage) === 1) {
      searchParam.delete("page");
    }
    if (!status) {
      searchParam.delete("status");
    }
    setSearchParam(searchParam);
    getOffersList(debounceValue);
  }, [searchParam, debounceValue]);

  const closeModel = () => {
    setAddOfferModal(false);
    setEditOfferModal(false);
    setStatusValue("");
    setStatusId("");
    setStatusModal(false);
  };

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Offers Management</h4>
            <div className="block_user_button">
              <button onClick={() => setAddOfferModal(true)}>
                <AddIcon />
                Add Offer
              </button>
            </div>
          </div>
          <div className="FilterBox FilterArea">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className={`form-control ${search && "active"}`}
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">
                <IoSearch />
              </span>
            </div>

            <div className="group_filter_value">
              {/* Gender-Filter */}
              {/* <div className="form-group select_sm_width">
                <Select
                  className={`form-control ${status && "active"}`}
                  value={status || ""}
                  onChange={(e) => statusHandler(e.target.value)}
                >
                  <option value="">Select Users</option>
                  <option value="LOYALUSERS">Loyal Users</option>
                  <option value="NEWUSERS">New Users</option>
                </Select>
              </div> */}
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Coupon Type</th>
                  <th>Title</th>
                  <th>Percentage</th>
                  <th>User Type</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>
              <tbody>
                {offersList.length > 0 ? (
                  offersList.map((offers, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          <td className="user_profile_image">
                            {offers?.couponType || "---"}
                          </td>
                          <td>{offers.title || "---"}</td>
                          {/* <td>
                            {dateFormat(offers.eventDate) || "---"},{" "}
                            {offers?.eventTime}
                          </td> */}
                          <td>{offers?.couponDiscount || "0"}</td>
                          <td>{offers?.userType || "---"}</td>
                          <td>
                            {/* {offers?.status === 1 && ( */}

                            <div className="action_button">
                              <Switch
                                checked={offers.status}
                                onChange={() =>
                                  statusUpdateHandler(offers._id, offers.status)
                                }
                              />
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() => updateOfferHandler(offers)}
                              >
                                <EditIcon className="edit_icon" />
                              </IconButton>
                            </div>
                            {/* )} */}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={8}
                    msg="Request not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total > 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {statusModal && (
        <OfferStatus
          show={statusModal}
          close={closeModel}
          setListing={setOffersList}
          status={statusValue}
          id={statusId}
        />
      )}

      {addOfferModal && (
        <AddOffer
          show={addOfferModal}
          close={closeModel}
          listing={getOffersList}
        />
      )}

      {editOfferModal && (
        <EditOffer
          show={editOfferModal}
          close={closeModel}
          offerDetail={selectedOfferDetail}
          setOfferDetail={setSelectedOfferDetail}
          listing={getOffersList}
        />
      )}
      {/* 
      <ModalBox
        status={viewModal}
        onClose={closeModel}
        title="End Live Broadcast"
        description="Are you sure you want to end this live broadcast?"
        ref={buttonRef}statusValue
      /> */}
    </>
  );
};

export default OffersManagement;
