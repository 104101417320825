import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Switch from "../../components/Switch/Switch";
import {
  changeFreeTrialStatus,
  dashboardGraphApi,
  fetchDashboardDetailsApi,
} from "../../services/dashboard";
import scrollToUp from "../../utils/scrollToUp";
import { toastMessage } from "../../utils/toastMessage";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import ModalBox from "../../components/modal/ModalBox";
import useButtonLoader from "../../hooks/UseButtonLoader";
import ReactDatePicker from "react-datepicker";
import { dateFormatUtc } from "../../utils/dateFormat";

const Dashboard = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const currentDate = new Date();
  const oneMonthBefore = new Date(
    currentDate.setMonth(currentDate.getMonth() - 1)
  );
  const [startDate, setStartDate] = useState(oneMonthBefore);
  const [endDate, setEndDate] = useState(new Date());
  const [dashboardDetail, setDashboardDetail] = useState({});
  const [status, setStatus] = useState(null);
  const [statusModal, setStatusModal] = useState(false);
  const [buttonref, buttonLoader] = useButtonLoader("Yes");
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "revenue",
        data: [],
      },
    ],
    options: {
      xaxis: {
        categories: [],
      },
      chart: {
        type: "area",
        stacked: false,
        height: 350,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
          autoSelected: "zoom",
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 1,
      },
      title: {
        text: "Revenue Details",
        align: "left",
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      stroke: {
        curve: "smooth",
        width: 1,
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  });

  //------------------request-listing-Api----------------------------------
  const getDashboardList = async () => {
    try {
      showLoader();
      setDashboardDetail({});
      const { data } = await fetchDashboardDetailsApi();
      if (data.code === 200) {
        setDashboardDetail(data?.result || {});
        scrollToUp();
      } else {
        setDashboardDetail({});
        toastMessage(data.message, "error", "offersList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  //--------------change-status-sub-category------------------------
  const changeFreeTrialStatusHandler = async () => {
    try {
      buttonLoader(true);
      let sendData = { status: !status };
      const { data } = await changeFreeTrialStatus(sendData);
      if (data.code === 200) {
        getDashboardList();
        toastMessage(data.message, "success", "offerStatus");
        setStatusModal(false);
      } else {
        toastMessage(data.message, "error", "offerStatus");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      buttonLoader(false);
    }
  };

  // Fetching Dashboard Graph
  const fetchingDashboardGraphHandlder = async () => {
    try {
      showLoader();
      let sendData = {
        startDate: dateFormatUtc(startDate),
        endDate: dateFormatUtc(endDate),
      };
      const { data } = await dashboardGraphApi(sendData);
      if (data.code === 200) {
        const res = data?.result;
        const dataValue = res?.map((data) => data?.totalAmountPayToPlateform);
        // console.log(dataValue,"dataValue");
        const week = res?.map(
          (data) => `${data?._id.split("-").slice(0, 3).join("-")}`
        );
        const category = week.reverse();
        setChartData((prevChartData) => ({
          ...prevChartData,
          series: [{ name: "Revenue", data: dataValue }],
          options: {
            ...prevChartData.options,
            xaxis: { categories: category || [] },
          },
        }));
      } else {
        toastMessage(data.message, "error", "dashboardGraph");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // console.log(chartData,"ChartData");

  useEffect(() => {
    getDashboardList();
  }, []);

  useEffect(() => {
    fetchingDashboardGraphHandlder();
  }, [startDate, endDate]);

  // console.log(dashboardDetail, "Dashboard Detail");

  return (
    <div className="WrapperArea">
      <div className="WrapperBox">
        <div className="TitleBox">
          <h4 className="Title">Dashboard</h4>
          <div className="block_user_button">
            {loader}
            {/* <button
            onClick={() => setAddConcernModal(true)}
            >
              <AddIcon/>Add Concern
            </button> */}
          </div>
        </div>
        <div className="dashboard">
          <div className="top-section">
            <div className="stats">
              <div className="stat-item">
                Total Users <span>{dashboardDetail?.totalUser}</span>
              </div>
              <div className="stat-item">
                Total Consultants{" "}
                <span>{dashboardDetail?.totalConsultant}</span>
              </div>
              <div className="stat-item">
                Total Earning{" "}
                <span>
                  {Number(
                    dashboardDetail?.totalEarning?.platformEarning
                  ).toFixed(2) || "N/A"}
                </span>
              </div>
              <div className="stat-item">
                Earned by consultants{" "}
                <span>
                  {Number(
                    dashboardDetail?.totalEarning?.consultantTotalEarning
                  ).toFixed(2) || "N/A"}
                </span>
              </div>
            </div>
            <div className="controls">
              {/* <button className="settlement-button">All Settlement</button> */}
              <div className="toggle">
                <span>Free Trial</span>
                <Switch
                  checked={dashboardDetail?.freeTrailOffer}
                  onChange={() => {
                    setStatusModal(true);
                    setStatus(dashboardDetail?.freeTrailOffer);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="revenue-details">
            <div className="d-flex">
              <div className="col-4">
                <div className="form-group mb-2">
                  <label className="my-1 text-dark w-100">Start Date</label>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className={`form-control multi_search_date`}
                    isClearable
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="dd-MMM-yyyy"
                    showMonthDropdown
                    useShortMonthInDropdown
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    maxDate={new Date()}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group mb-2">
                  <label className="my-1 text-dark w-100">End Date</label>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className={`form-control multi_search_date`}
                    isClearable
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="dd-MMM-yyyy"
                    showMonthDropdown
                    useShortMonthInDropdown
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    minDate={startDate}
                  />
                </div>
              </div>
            </div>
            <h3>Revenue Details</h3>
            <ReactApexChart
              options={chartData?.options}
              series={chartData.series}
              type="area"
              height={350}
            />
          </div>
          <div className="membership-details">
            <div className="membership-item">
              Total Silver Membership{" "}
              <span>{dashboardDetail?.goldMemeber?.silver?.total || 0}</span>
            </div>
            <div className="membership-item">
              Total Gold Membership{" "}
              <span>{dashboardDetail?.goldMemeber?.gold?.total || 0}</span>
            </div>
            <div className="membership-item">
              Total no. of chat <span>{dashboardDetail?.totalChats || 0}</span>
            </div>
            <div className="membership-item">
              Total no. of Calls <span>{dashboardDetail?.totalCalls || 0}</span>
            </div>
            <div className="membership-item">
              Total no. of Video Calls{" "}
              <span>{dashboardDetail?.totalVideo || 0}</span>
            </div>
            <div className="membership-item">
              Total consultants live{" "}
              <span>{dashboardDetail?.totalLiveConsultant || 0}</span>
            </div>
          </div>
        </div>

        <ModalBox
          status={statusModal}
          onClose={() => setStatusModal(false)}
          title="Change Status"
          description="Do you want to change status ?"
          action={changeFreeTrialStatusHandler}
          ref={buttonref}
        />
      </div>
    </div>
  );
};

export default Dashboard;
