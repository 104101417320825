import React, { useEffect } from "react";
import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Form from "react-bootstrap/Form";
import Avatar from "../css/Avatar1.png";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import { matchPath, useLocation } from "react-router-dom";

const ROUTE_ARR = {
  "/consultant-payout": ["payoutList"],
  "/music-category": ["musicCategory", "musicList"],
  "/user": ["userList", "useDetailList", "deletedUserList"],
  "/consultant-management": ["consultantList", "deletedConsultantList"],
  "/category": ["category", "categoryMedia"],
  "/banner": [],
  "/dashboard": [],
  "/meditation-category": ["meditationCategory"],
  "/training-management": ["trainingCategory", "trainingList"],
  "/image-management": ["imageList"],
  "/explore-management": ["exploreList"],
  "/request-management": ["requestList"],
  "/discount-management": ["discountList"],
  "/notification-management": ["notificationList"],
  "/horoscope-management": ["horoscopeList"],
  "/live-management": ["liveList"],
  "/support-management": ["queryList", "supportListType"],
  "/form-16A": ["formList"],
  "/concern-management": ["concern", "remedyList"],
  "/transaction-management": ["transaction"],
};
export default function Header() {
  // const name = localStorage.getItem('auction_userName')

  const location = useLocation();

  useEffect(() => {
    Object.entries(ROUTE_ARR).forEach(([key, val]) => {
      const match = matchPath(
        {
          path: `${key}/*`,
          exact: false,
          strict: false,
        },
        location.pathname
      );

      // if match then remove all previous keys
      // console.log(match)
      if (match) {
        try {
          let tempVar = {};
          let localData = JSON.parse(localStorage.getItem("admin"));
          val.forEach((item) => {
            tempVar = { ...tempVar, [item]: localData[item] };
          });
          localStorage.setItem("admin", JSON.stringify(tempVar));
        } catch (err) {
          // localStorage.removeItem("admin")
        }
      }
    });
  }, [location]);

  return (
    <div className="mainHead">
      <div className="header">
        <ViewHeadlineIcon
          style={{
            color: "white",
            fontSize: 35,
            cursor: "pointer",
          }}
        />

        <div className="mx-2">
          <img
            style={{ height: "40px", width: "40px" }}
            src={Avatar}
            alt="profile"
          />
          {/* <span className='mx-2 fs-6'>{name}</span> comes from Api Name */}
          <span className="mx-2 fs-6">Admin</span>
        </div>
        {/* <Link>
          <figure>
            <img alt="" src="./images/user.png" />
          </figure>
          {empName}
        </Link> */}
        {/* <div className="logo"><FaBars /></div> */}
        {/* <div className="header_notification"> <NotificationsIcon />
        <span className='bell'> 2</span> 

    <Form.Select aria-label="Default select example">
      <option> English</option>
      <option value="1">One</option>
      <option value="2">Two</option>
      <option value="3">Three</option>
    </Form.Select>


</div> */}
      </div>
    </div>
  );
}
