// addBannerApi
import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from '../../config/axiosInstance'

// ---------------------fatch-category-listing--------------------------
export const fetchConsultantListApi = async (data) => {
  const endPoint = `/admin/consultant`
  return await axios.post(endPoint, data, setJwtToken())
}

// Fetching Deleted Consultant List API
export const getDeletedConsultantListApi = async (data) => {
  const endPoint =
    `/admin/consultant/delete/list` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// Approve consultant status

export const approveConsultantStatus = async (data) => {
  const endPoint = `/admin/consultant/approve`
  return await axios.put(endPoint, data, setJwtToken())
}

// Change consultant status

export const changeConsultantStatus = async (data) => {
  const endPoint = `/admin/consultant/block_unblock`
  return await axios.put(endPoint, data, setJwtToken())
}

// add consultant

export const addConsultant = async (data) => {
  // console.log(data,"data-->controller")
  const endPoint = `/admin/consultant/add`
  return await axios.post(endPoint, data, setMultiPartHeader())
}

// Edit Consultant
export const editConsultant = async (data) => {
  const endPoint = `/admin/consultant/update`
  return await axios.put(endPoint, data, setMultiPartHeader())
}

// Get selected consultant detail

export const getSelectedConsultantDetail = async (data) => {
  const endPoint =
    `/admin/consultant/details` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// // ---------------------add-Banner---------------------------
// export const addBannerApi = async (data) => {
//   const endPoint = '/admin/banner/add'
//   return await axios.post(endPoint, data, setMultiPartHeader())
// }

// // ---------------------update-banner---------------------------
// export const updateBannerApi = async (data) => {
//   const endPoint = `/admin/banner/edit`
//   return await axios.put(endPoint, data, setMultiPartHeader())
// }
// // ---------------------change-status-banner---------------------------
export const fetchConsultantRequestCountApi = async () => {
  const endPoint = `/admin/consultant/counts`
  return await axios.get(endPoint, setJwtToken())
}

export const sendWarningApi = async (data) => {
  const endPoint = `/admin/consultant/send_warning`
  return await axios.post(endPoint, data, setJwtToken())
}

// Consultant Management Order History
export const getConsultantOrderHistoryApi = async (data) => {
  const endPoint =
    `/admin/consultant/order_history` +
    // `https://49a5-122-161-53-242.ngrok-free.app`+
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// // ---------------------change-status-banner---------------------------
export const fetchWalletDeductionApi = async (data) => {
  const endPoint = `/admin/consultant/wallet/deduction`
  return await axios.post(endPoint, data, setJwtToken())
}

// Consultant Management Availability Rate
export const consultantCheckPerformanceAvailability = async (data) => {
  const endPoint =
    `/admin/consultant/availibility/rate` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// Consultant Management Loyal Customer
export const consultantCheckPerformanceLoyalCustomer = async (data) => {
  const endPoint =
    `/admin/consultant/loyal/customer` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// Consultant Management Avg Talk Time
export const consultantCheckPerformanceAvgTalkTime = async (data) => {
  const endPoint =
    `/admin/consultant/avg/talk/time` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// Consultant Management Avg  Time
export const consultantCheckPerformanceAvgTime = async (data) => {
  const endPoint = `/admin/consultant/avg/rating`
  return await axios.post(endPoint, data, setJwtToken())
}

// Mark Green Tick In Consultant API
export const markGreenTickApi = async (data) => {
  const endPoint = `/admin/consultant/mark_green_tick`
  return await axios.put(endPoint, data, setMultiPartHeader())
}
// Go Live In Consultant API
export const goLiveApi = async (data) => {
  const endPoint = `/admin/consultant/go_live`
  return await axios.put(endPoint, data, setJwtToken())
}

// New customer conversion
export const newCustomerConversionApi = async (data) => {
  const endPoint =
    `/admin/consultant/new/customer/conversion` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// Customer satisfaction
export const customerSatisfactionApi = async (data) => {
  const endPoint =
    `/admin/consultant/customer/satisfaction` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// New customer rating percentage
export const newCustomerRatingPercentageApi = async (data) => {
  const endPoint =
    `/admin/consultant/new/customer/rating` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

//New user serve properly percentage.
export const newUserServeProperlyPercentageApi = async (data) => {
  const endPoint =
    `/admin/consultant/new/user/serve/properly` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// Customer retention.
export const customerRetentionApi = async (data) => {
  const endPoint =
    `/admin/consultant/customer/retention` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// New User Retention.
export const newUserRetentionApi = async (data) => {
  const endPoint =
    `/admin/consultant/user/retention` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// Logout consultant from all device

export const logoutConsultantFromAllDevice = async (data) => {
  const endPoint =
    `/admin/consultant/log_out` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}
