import React from 'react'
import ModalBox from '../../../components/modal/ModalBox'
import useButtonLoader from '../../../hooks/UseButtonLoader'
import { toastMessage } from '../../../utils/toastMessage'
import { changeMusicStatus } from '../../../services/musicManagement'

const MusicStatus = ({ show, close, status, id, setListing }) => {
  const [buttonref, buttonLoader] = useButtonLoader('Update')

  //--------------change-status-sub-category------------------------
  const changeMusicStatusHandler = async () => {
    try {
      buttonLoader(true)
      let sendData = { status: !status, musicMediaId: id }
      const { data } = await changeMusicStatus(sendData)
      if (data.code === 200) {
        setListing((prevListing) => {
          return prevListing.map((music) => {
            if (music._id === id) {
              return { ...music, status: !status }
            }
            return music
          })
        })
        toastMessage(data.message, 'success', 'musicCategoryStatus')
        close()
      } else {
        toastMessage(data.message, 'error', 'musicCategoryStatus')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      buttonLoader(false)
    }
  }
  console.log('button loader', buttonLoader)
  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Change Music Status"
      description="Do you want to change the status of this music?"
      action={changeMusicStatusHandler}
      ref={buttonref}
    />
  )
}

export default MusicStatus
