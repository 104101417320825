import React, { useEffect, useState, Fragment } from "react";
import Pagination from "react-js-pagination";
import NotFound from "../../../../components/not-found/NotFound";
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";
import { Link, useSearchParams } from "react-router-dom";
import IconButton from "../../../../components/Button/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import { getOrderApi } from '../../services/orderManagement'
import { SiMicrosoftexcel } from "react-icons/si";
import { IoSearch } from "react-icons/io5";
import profileImage from "../../../../Assests/image/profile_not_found.png";
// import { user } from "../../routes/route";
// import { getLocalStorage, setLocalStorage } from "../../config/config";
import findSerialNumber from "../../../../utils/findSerialNumber";

import { toastMessage } from "../../../../utils/toastMessage";
// import { fatchCategoryApi } from "../../services/categoryManagement";
import { charLimit } from "../../../../utils/charLimit";
// import common from "../../routes/common";
// import UserStatus from './UserStatus'
import {
  dateFormat,
  dateFormatUtc,
  timeFormat,
} from "../../../../utils/dateFormat";
import EditIcon from "@mui/icons-material/Edit";
// import { gender } from "../../utils/genderList";
// import scrollToUp from "../../utils/scrollToUp";
// import { CapitalizeFirstWord } from "../../utils/CapitalizeFirstWord";
// import Loader from "../../comman/Loader";
// import useFileDownloader from "../../hooks/useFileDownloader";
// import csv from "../../Assests/image/csv.svg";
// import {
//   fetchRequestListApi,
//   getRequestTypeDetail,
//   requestAcceptRejectApi,
// } from "../../services/requestManagement";
// import { Avatar } from "@mui/material";
// import RequestDetail from "./RequestDetail";

// const { USER_DETAIL } = user;
// const { NOT_FOUND } = common;

const Meditation = ({
  supportList,
  activePage,
  setEditModal,
  setSelectedData,
  setDescriptionModal,
  setTitleModal,
  setTitle,
  setDescription,
}) => {
  // console.log(supportList,"Support List");

  return (
    <Fragment>
      <div className="content-table">
        <table>
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Question</th>
              <th>Description</th>
              {/* <th>Support Type</th> */}
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {supportList && supportList.length > 0 ? (
              supportList.map((supportData, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{findSerialNumber(index, activePage, 10)}</td>
                      <td
                        onClick={() => {
                          if (
                            supportData.question &&
                            supportData.question.length > 50
                          ) {
                            setTitleModal(true);
                            setTitle(supportData.question);
                          }
                        }}
                      >
                        {supportData?.question &&
                          (supportData.question.length > 50
                            ? charLimit(supportData.question, 50)
                            : supportData.question || "---")}
                      </td>

                      <td
                        onClick={() => {
                          if (
                            supportData.description &&
                            supportData.description.length > 50
                          ) {
                            setDescriptionModal(true);
                            setDescription(supportData.description);
                          }
                        }}
                      >
                        {supportData?.description &&
                          (supportData.description.length > 50
                            ? charLimit(supportData.description, 50)
                            : supportData.description || "---")}
                      </td>

                      {/* <td>{supportData?.supportType || "---"}</td> */}
                      <td>
                        <div className="action_button">
                          <IconButton
                            aria-label="update"
                            className="px-2 py-0"
                            onClick={() => {
                              setEditModal(true);
                              setSelectedData(supportData);
                            }}
                          >
                            <EditIcon className="edit_icon" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            ) : (
              <NotFound
                cols={6}
                msg="Request not found!"
                // icon={NOT_FOUND}
                // loader={loader}
              />
            )}
          </tbody>
        </table>
      </div>
      {/* {total > 10 ? (
        <div className="pagination-wrapper">
          <div className="Pagination">
            <Pagination
              activePage={activePage}
              previousLabel={"previous"}
              nextLabel={"next"}
              itemsCountPerPage={10}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              hideDisabled={true}
            />
          </div>
        </div>
      ) : null} */}
    </Fragment>
  );
};

export default Meditation;
