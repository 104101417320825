import React, { useEffect, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "./subAdminStyle.css";
import { Input } from "../../components/Form";
import { Button } from "../../components/Button/Button";
import { rights } from "./roles";
import { regex } from "../../utils/regex";
import { addSubadminApi } from "../../services/subAdmin";
import { toastMessage } from "../../utils/toastMessage";
import useButtonLoader from "../../hooks/UseButtonLoader";

const AddSubadmin = () => {
  const [inputField, setInputField] = useState({});
  const [buttonref, buttonLoader] = useButtonLoader("Create Sub-Admin");
  const [rightsField, setRightsField] = useState({});
  const [error, setError] = useState({});
  const { email, password } = regex;
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputField({ ...inputField, [name]: value });
  };
  const rightsHandler = (e, index) => {
    const { name, value, checked } = e.target;
    setRightsField({ ...rightsField, [value]: checked });
  };

  const validation = () => {
    let status = true;
    let errors = {};
    if (!inputField?.name) {
      errors["name"] = "Please enter name";
      status = false;
    }
    if (!inputField?.email) {
      errors["email"] = "Please enter email";
      status = false;
    } else if (!inputField?.email.match(email)) {
      errors["email"] = "Please enter valid email";
      status = false;
    }
    // if (!inputField?.password) {
    //   errors["password"] = "Please enter password";
    //   status = false;
    // } else if (!inputField?.password.match(password)) {
    //   errors["password"] = "Please enter valid password";
    //   status = false;
    // }
    // if (!inputField?.role) {
    //   errors["role"] = "Please enter role";
    //   status = false;
    // }
    setError(errors);
    return status;
  };

    // console.log(rights);

  const addSubadminHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true);
        const { data } = await addSubadminApi({
          ...inputField,
          rights: rightsField,
          role:"sub-admin",
        });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "addSubadmin");
          navigate("/subadmin");
        } else {
          toastMessage(data?.message, "error", "addSubadmin");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        buttonLoader(false);
      }
    }
  };

  useEffect(() => {
    const initialRights = rights.reduce((acc, right) => {
      acc[right.value] = false;
      return acc;
    }, {});
    setRightsField(initialRights);
  }, [rights]);

  return (
    <div className="WrapperArea">
      <div className="WrapperBox">
        <div className="TitleBox">
          <div className="user_detail_heading">
            <Link className="back_icon" to={-1}>
              <IconButton>
                <ArrowBackIosNewIcon className="back_button" />
              </IconButton>
            </Link>
            <h4 className="Title">Add Sub-Admin</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            {/* name */}
            <div className="form-group mb-2">
              <Input
                type="text"
                name="name"
                value={inputField?.name || ""}
                required
                onChange={handleChange}
                placeholder="Enter  Name"
                className={`form-control `}
                label={"Name"}
                error={error.name}
                maxLength={30}
              />
            </div>
          </div>
          <div className="col-6">
            {/* name */}
            <div className="form-group mb-2">
              <Input
                type="text"
                name="email"
                value={inputField?.email || ""}
                required
                onChange={handleChange}
                placeholder="Enter Email"
                className={`form-control `}
                label={"Email"}
                error={error.email}
                maxLength={30}
              />
            </div>
          </div>
          {/* User Name */}
          {/* <div className="col-6">
            <div className="form-group mb-2">
              <Input
                type="text"
                name="role"
                value={inputField?.role || ""}
                required
                onChange={handleChange}
                placeholder="Enter Role"
                className={`form-control `}
                label={"Role"}
                error={error.role}
                maxLength={30}
              />
            </div>
          </div> */}
          {/* Email */}
          {/* <div className="col-6">
            <div className="form-group mb-2">
              <Input
                type="text"
                name="password"
                value={inputField?.password || ""}
                required
                onChange={handleChange}
                placeholder="Enter Password"
                className={`form-control `}
                label={"Password"}
                error={error.password}
              />
            </div>
          </div> */}
        </div>

        <div className="row my-4 ">
          {/* <div className="col-md-12"> */}
          <h5 className="form-label">Select Rights</h5>
          <div className="row">
            {rights.map((right, index) => (
              <div key={index} className="form-check col-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={right?.value}
                  name={right?.name}
                  id={`right${index}`}
                  onChange={rightsHandler}
                />
                <label className="form-check-label" htmlFor={`right${index}`}>
                  {right?.name}
                </label>
              </div>
            ))}
          </div>
          {/* </div> */}
        </div>
        <div className="text-center mt-4">
          <Button ref={buttonref} className="btn" onClick={addSubadminHandler}>
            Create Sub-Admin
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddSubadmin;
