// addBannerApi
import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from '../../config/axiosInstance'

// ---------------------fatch-category-listing--------------------------
export const fetchBannerApi = async (data) => {
  const endPoint =
    `/admin/banner/list` + '?' + new URLSearchParams({ ...data }).toString()

  return await axios.get(endPoint, setJwtToken())
}

// ---------------------add-Banner---------------------------
export const addBannerApi = async (data) => {
  const endPoint = '/admin/banner/add'
  return await axios.post(endPoint, data, setMultiPartHeader())
}

// ---------------------update-banner---------------------------
export const updateBannerApi = async (data) => {
  const endPoint = `/admin/banner/edit`
  return await axios.put(endPoint, data, setMultiPartHeader())
}
// ---------------------change-status-banner---------------------------
export const changeBannerStatus = async (data) => {
  const endPoint = `/admin/banner/block_unblock`
  return await axios.put(endPoint, data, setJwtToken())
}
