import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  consultantCheckPerformanceAvailability,
  consultantCheckPerformanceAvgTalkTime,
  consultantCheckPerformanceAvgTime,
  consultantCheckPerformanceLoyalCustomer,
  customerRetentionApi,
  customerSatisfactionApi,
  newCustomerConversionApi,
  newCustomerRatingPercentageApi,
  newUserRetentionApi,
  newUserServeProperlyPercentageApi,
} from "../../services/consultantManagement";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { toastMessage } from "../../utils/toastMessage";
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const CheckPerformance = () => {
  const { id: consultantId, name } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [loyalCustomer, setLoyalCustomer] = useState("");
  const [avgTime, setAvgTime] = useState("");
  const [availabilityRate, setAvailabilityRate] = useState({});
  const [customerRetention, setCustomerRetention] = useState();
  const [
    userServeProperlyPercentage,
    setUserServeProperlyPercentage,
  ] = useState();
  const [
    newCustomerRatingPercentage,
    setNewUserCustomerRatingPercentage,
  ] = useState();
  const [customerSatisfaction, setCustomerSatisfaction] = useState();
  const [newCustomerConversion, setNewCustomerConversion] = useState();
  const [rating, setRating] = useState({});
  const [newUserRetention, setNewUserRetention] = useState();

  // Loyal Customer Handler
  const fetchLoyalCustomerHandler = async () => {
    try {
      setLoyalCustomer("");
      showLoader();
      const { data } = await consultantCheckPerformanceLoyalCustomer({
        consultantId: consultantId,
      });
      if (data.code === 200) {
        setLoyalCustomer(data?.result?.loyalCus || []);
      } else {
        setLoyalCustomer("");
        toastMessage(data.message, "error", "loyalCustomer");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // Loyal Avg Time Handler
  const fetchAvgTimeHandler = async () => {
    try {
      setAvgTime("");
      showLoader();
      const { data } = await consultantCheckPerformanceAvgTalkTime({
        consultantId: consultantId,
      });
      if (data.code === 200) {
        setAvgTime(data?.result?.avgCallTime);
      } else {
        setAvgTime("");
        toastMessage(data.message, "error", "loyalCustomer");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // New Customer Conversion
  const fetchNewCustomerConversionHandler = async () => {
    try {
      setNewCustomerConversion();
      showLoader();
      const { data } = await newCustomerConversionApi({
        consultantId: consultantId,
      });
      if (data.code === 200) {
        setNewCustomerConversion(data?.result?.custConversion);
      } else {
        setNewCustomerConversion("");
        toastMessage(data.message, "error", "setNewCustomerConversion");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // New User Retention
  const fetchNewUserRetentionHandler = async () => {
    try {
      setNewUserRetention();
      showLoader();
      const { data } = await newUserRetentionApi({
        consultantId: consultantId,
      });
      if (data.code === 200) {
        setNewUserRetention(data?.result?.userRetention);
      } else {
        setNewUserRetention("");
        toastMessage(data.message, "error", "setNewUserRetention");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // Customer Satisfaction
  const fetchCustomerSatisfactionionHandler = async () => {
    try {
      setCustomerSatisfaction("");
      showLoader();
      const { data } = await customerSatisfactionApi({
        consultantId: consultantId,
      });
      if (data?.code === 200) {
        // setCustomerSatisfaction(data?.result?.avgRating);
        const avgRating = data?.result?.avgRating || 0; // Default to 0 if avgRating is undefined
        const percentage = (avgRating / 5) * 100;
        setCustomerSatisfaction(percentage);
      } else {
        setCustomerSatisfaction("");
        toastMessage(data.message, "error", "setCustomerSatisfaction");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // New Customer Rating Percentage
  const fetchNewCustomerRatingPercentageHandler = async () => {
    try {
      setNewUserCustomerRatingPercentage();
      showLoader();
      const { data } = await newCustomerRatingPercentageApi({
        consultantId: consultantId,
      });
      if (data?.code === 200) {
        setNewUserCustomerRatingPercentage(data?.result?.newCusAvgRating);
      } else {
        setNewUserCustomerRatingPercentage("");
        toastMessage(
          data.message,
          "error",
          "setNewUserCustomerRatingPercentage"
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // User Serve Properly Percentage
  const fetchUserServeProperlyPercentage = async () => {
    try {
      setUserServeProperlyPercentage();
      showLoader();
      const { data } = await newUserServeProperlyPercentageApi({
        consultantId: consultantId,
      });
      if (data?.code === 200) {
        setUserServeProperlyPercentage(data?.result?.serveProperlyPercentage);
      } else {
        setUserServeProperlyPercentage("");
        toastMessage(data.message, "error", "setUserServeProperlyPercentage");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // Customer retention
  const fetchCustomerRetention = async () => {
    try {
      setCustomerRetention();
      showLoader();
      const { data } = await customerRetentionApi({
        consultantId: consultantId,
      });
      if (data?.code === 200) {
        setCustomerRetention(data?.result?.customerRetention);
      } else {
        setCustomerRetention("");
        toastMessage(data.message, "error", "fetchCustomerRetention");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // Check Availability Rate Handler
  const fetchCheckPerformanceAvailabilityRate = async () => {
    try {
      setAvailabilityRate({});
      showLoader();
      const { data } = await consultantCheckPerformanceAvailability({
        consultantId: consultantId,
      });
      if (data.code === 200) {
        setAvailabilityRate(data?.result || []);
      } else {
        setAvailabilityRate({});
        toastMessage(data.message, "error", "loyalCustomer");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // Check Rating
  const fetchCheckPerformanceRating = async () => {
    try {
      setRating({});
      showLoader();
      const { data } = await consultantCheckPerformanceAvgTime({
        consultantId: consultantId,
      });
      if (data.code === 200) {
        setRating(data?.result || []);
      } else {
        setRating({});
        toastMessage(data.message, "error", "loyalCustomer");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    fetchLoyalCustomerHandler();
    fetchCheckPerformanceRating();
    fetchAvgTimeHandler();
    fetchCheckPerformanceAvailabilityRate();
    fetchNewCustomerConversionHandler();
    fetchCustomerSatisfactionionHandler();
    fetchNewCustomerRatingPercentageHandler();
    fetchUserServeProperlyPercentage();
    fetchCustomerRetention();
    fetchNewUserRetentionHandler();
  }, []);

  return (
    <div className="WrapperArea">
      <div className="WrapperBox">
        <div className="TitleBox">
          <div className="user_detail_heading">
            {/* <h4 className="Title">User Management</h4> */}
            <Link
              className="back_icon"
              to={`/consultant-management/consultant-detail/${consultantId}`}
            >
              <IconButton>
                <ArrowBackIosNewIcon className="back_button" />
              </IconButton>
            </Link>
            <h4 className="Title">{name}</h4>
          </div>
        </div>
        <div class="container">
          <div class="card">
            <div class="title">New Customer Conversion % </div>
            <div class="value">
              {(newCustomerConversion &&
                Number(newCustomerConversion).toFixed(2)) ||
                0}
            </div>
          </div>
          <div class="card">
            <div class="title">Customer Satifaction %</div>
            <div class="value">
              {(customerSatisfaction &&
                Number(customerSatisfaction).toFixed(2)) ||
                0}
            </div>
          </div>
          <div class="card">
            <div class="title">New Customer Rating % </div>
            <div class="value">
              {(newCustomerRatingPercentage &&
                Number(newCustomerRatingPercentage).toFixed(2)) ||
                0}
            </div>
          </div>
          <div class="card">
            <div class="title">New User Serve Properly % </div>
            <div class="value">
              {(userServeProperlyPercentage &&
                Number(userServeProperlyPercentage).toFixed(2)) ||
                0}
            </div>
          </div>
          <div class="card">
            <div class="title">Customer Retention</div>
            <div class="value">
              {(customerRetention && Number(customerRetention).toFixed(2)) || 0}
            </div>
          </div>
          <div class="card">
            <div class="title">New User Retention % </div>
            <div class="value">
              {(newUserRetention && Number(newUserRetention).toFixed(2)) || 0}
            </div>
          </div>
          <div class="card">
            <div class="title">Customer Average Talk Time (hrs)</div>
            <div class="value">{avgTime && Number(avgTime).toFixed(2)}</div>
          </div>
          <div class="card">
            <div class="title">Loyal Customers % </div>
            <div class="value">
              {(loyalCustomer && Number(loyalCustomer).toFixed(2)) || 0}
            </div>
          </div>
          <div class="card">
            <div class="title">Chat Availability Rate (hrs) </div>
            <div class="value">
              {availabilityRate?.chatAvailibilityRate &&
                Number(availabilityRate?.chatAvailibilityRate).toFixed(2)}
            </div>
          </div>
          <div class="card">
            <div class="title">Average Chat Rating</div>
            <div class="value">
              {rating?.chatAvgRating &&
                Number(rating?.chatAvgRating).toFixed(2)}
            </div>
          </div>
          <div class="card">
            <div class="title">Call Availability Rate (hrs)</div>
            <div class="value">
              {availabilityRate?.callAvailibilityRate &&
                Number(availabilityRate?.callAvailibilityRate).toFixed(2)}
            </div>
          </div>
          <div class="card">
            <div class="title">Average Call Rating</div>
            <div class="value">
              {rating?.callAvgRating &&
                Number(rating?.callAvgRating).toFixed(2)}
            </div>
          </div>
          {/* <div class="card">
            <div class="title">New user performance</div>
            <div class="value">10K</div>
          </div> */}
          {/* <div class="card">
            <div class="title">Customer Satisfaction</div>
            <div class="value">1000</div>
          </div>
          <div class="card">
            <div class="title">New user rating</div>
            <div class="value">1000</div>
          </div>
          <div class="card">
            <div class="title">New user Served Properly</div>
            <div class="value">10%</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CheckPerformance;
