import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { sidebar } from "../routes/Sidebar";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
// import Logo from '../css/laptopA.png';
import LogoutIcon from "@mui/icons-material/Logout";
import ModalBox from "../components/modal/ModalBox";
import SubMenu from "./SubMenu";
import { fetchConsultantRequestCountApi } from "../services/consultantManagement";
import { toastMessage } from "../utils/toastMessage";
import { useDispatch, useSelector } from "react-redux";
import { setRightsInfo, setRole } from "../utils/store/slices/rightsSlice";

const Sidebar = () => {
  const [logoutModal, setLogoutModal] = useState(false);
  const { rightsInfo, role } = useSelector((state) => state?.vedicRights);
  const navigate = useNavigate();
  const location = useLocation();

  function logoutHandler() {
    navigate("/");
    localStorage.removeItem("vedic_token");
    localStorage.removeItem("vedic_email");
    localStorage.removeItem("vedic_role");
    localStorage.removeItem("vedic_rights");
  }

  return (
    <div>
      <Accordion defaultActiveKey="0">
        <div className="SidenavArea">
          <div className="SidenavHead">
            {/* <div className="Logo">
                            <img src={Logo || ""} alt={"Logo"} />
                        </div> */}
            <h2 style={{ fontWeight: "900" }}>Vedic Meet</h2>
          </div>
          <div className="SidenavBody">
            <ul>
              {sidebar(rightsInfo, role).map(
                ({ path, icon, name, isChild, id }, index) => {
                  return isChild ? (
                    <SubMenu
                      isChild={isChild}
                      icon={icon}
                      path={path}
                      name={name}
                      key={path}
                      // style={style}
                      // toggle={() => ddToggler(path)}
                    />
                  ) : (
                    <li
                      className={location.pathname === path ? "active" : ""}
                      key={path}
                    >
                      <NavLink to={path}>
                        <span>{icon}</span>
                        {name}
                      </NavLink>
                    </li>
                  );
                }
              )}
            </ul>
          </div>
          <div
            className="SidenavFooter"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Link
              onClick={() => setLogoutModal(true)}
              // style={{ display: 'flex', alignItems: 'center' }}
            >
              <span className="mx-1">
                <LogoutIcon fontSize="small" sx={{ color: "#fff" }} />
              </span>
              Logout
            </Link>
          </div>
        </div>
      </Accordion>
      <ModalBox
        status={logoutModal}
        onClose={() => setLogoutModal(false)}
        title={"Logout"}
        description={"Are you sure want to logout?"}
        action={logoutHandler}
      />
    </div>
  );
};

export default Sidebar;
