import {
    instance as axios,
    setJwtToken,
    setMultiPartHeader,
  } from "../../config/axiosInstance";
  
  // ---------------------fatch-transaction-listing--------------------------
  export const fetchTransactionApi = async (data) => {
    const endPoint =
      `/admin/transaction` + "?" + new URLSearchParams({ ...data }).toString();
    return await axios.get(endPoint, setJwtToken());
  };