import {
    instance as axios,
    setJwtToken,
    setMultiPartHeader,
  } from "../../config/axiosInstance";
  
  // ---------------------fetch-request-listing--------------------------
  export const fetchRequestListApi = async (data) => {
    const endPoint =
      `/admin/request` + "?" + new URLSearchParams({ ...data }).toString();
  
    return await axios.get(endPoint, setJwtToken());
  };

//   Get Request Type Details
  export const getRequestTypeDetail = async (data) => {
    const endPoint =
      `/admin/request/details` +
      '?' +
      new URLSearchParams({ ...data }).toString()
    return await axios.get(endPoint, setJwtToken())
  }

//   //------------------ Request Approve Handler-------------------------------
export const requestAcceptRejectApi = async (data) => {
    const endPoint = `admin/request/accept_reject`;
    return await axios.put(endPoint, data, setJwtToken());
  };

