import React, { useEffect, useState } from "react";
import Logo from "../../css/logo1.png";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../../components/Form/index";
import usePasswordToggle from "../../hooks copy/usePasswordToggle";
import logo from "../../Assests/image/logo.png";
import { loginApi } from "../../services/auth/Auth";
import { toastMessage } from "../../utils/toastMessage";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { regex } from "../../utils/regex";
import commonMessages from "../../utils/commonMessages";
import { auth } from "../../routes/route";
import { getDataFromLocal } from "../../config/config";
import { useDispatch } from "react-redux";
import { setRights, setRightsInfo, setRole } from "../../utils/store/slices/rightsSlice";
const {
  EMAIL_NOT_FOUND,
  PASSWORD_NOT_FOUND,
  VALID_EMAIL,
  VALID_PASSWORD,
} = commonMessages;
const { FORGET_PASSWORD_ROUTE } = auth;

const Login = () => {
  const token = getDataFromLocal("vedic_token");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { email, password } = regex;

  const [inputType, Icon] = usePasswordToggle("small");
  const [buttonref, buttonLoader] = useButtonLoader("Login");
  const [error, setError] = useState({});

  const [loginValue, setLoginValue] = useState({
    email: "",
    password: "",
  });

  const handleValidation = () => {
    const error = {};
    let status = true;

    if (!loginValue.email) {
      error["email"] = EMAIL_NOT_FOUND;
      status = false;
    }
    if (!loginValue.email.match(email)) {
      error["matchEmail"] = VALID_EMAIL;
      status = false;
    }

    if (!loginValue.password) {
      error["password"] = PASSWORD_NOT_FOUND;
      status = false;
    }

    // if (!loginValue.password.match(password)) {
    //   error['matchPassword'] = VALID_PASSWORD
    //   status = false
    // }

    setError(error);
    return status;
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setLoginValue({ ...loginValue, [name]: value });
  };

  const admiLogin = async (e) => {
    e.preventDefault();
    const isValid = handleValidation();
    if (isValid) {
      try {
        buttonLoader(true);
        const { data } = await loginApi(loginValue);
        if (data.code === 200) {
          localStorage.setItem("vedic_token", data?.result?.token);
          localStorage.setItem("vedic_email", data?.result?.email);
          localStorage.setItem("vedic_role", data?.result?.role || "admin");
          localStorage.setItem(
            "vedic_rights",
            JSON.stringify(data?.result?.rights || {})
          );
          dispatch(setRightsInfo({ rightsInfo: data?.result?.rights }));
          dispatch(setRole({ role: data?.result?.role }));

          navigate("/dashboard");
        } else {
          toastMessage(data.message, "error", "adminLogin");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  useEffect(() => {
    if (token) {
      // setAuthToken(token)
      navigate("/dashboard");
    }
  }, [token]);

  return (
    <>
      <div className="LoginArea">
        <h1 className="login_head">Vedic Meet</h1>
        <div className="LoginBox">
          <figure className="company_logo">
            <img src={logo} alt={"This is company logo"} />
          </figure>
          <h5 className="mt-2">Login here</h5>
          <form>
            <div className="form-group">
              <Input
                label={"Email ID"}
                type="text"
                name="email"
                value={loginValue.email}
                onChange={onChangeHandler}
                placeholder="Enter Email"
                className={`form-control `}
                error={
                  !loginValue.email
                    ? error.email
                    : !loginValue.email.match(email) && error.matchEmail
                }
                maxLength={40}
                leftIcon={
                  <span className="Icon">
                    <i className="fa fa-envelope" />
                  </span>
                }
              />
            </div>

            <div className="form-group">
              <Input
                type={inputType}
                name="password"
                value={loginValue.password}
                onChange={onChangeHandler}
                placeholder="Enter Password"
                className={`form-control `}
                label={"Password"}
                // error={
                //   !loginValue.password
                //     ? error.password
                //     : !loginValue.password.match(password) &&
                //       error.matchPassword
                // }
                error={!loginValue.password && error.password}
                maxLength={15}
                leftIcon={
                  <span className="Icon">
                    <i className="fa fa-unlock-alt" />
                  </span>
                }
              />
              <span className="icon">{Icon}</span>
            </div>

            <div className="Checkboxs">
              <Link to={FORGET_PASSWORD_ROUTE}>forgot password?</Link>
            </div>

            <div className="form_btn">
              <button className="login_btn" ref={buttonref} onClick={admiLogin}>
                Log In <i className="fa fa-sign-in"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
