import React from "react";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";

const NotFound = ({
  cols = 9,
  msg = "No Entries Found",
  icon = <ChecklistRtlIcon sx={{ fontSize: 48 }} />,
  loader = false,
}) => {
  return (
    !loader && (
      <tr>
        <td colSpan={cols}>
          <div className="text-center mt-2">
            <div className="my-1">{icon}</div>
            <h5>{msg}</h5>
          </div>
        </td>
      </tr>
    )
  );
};

export default NotFound;
