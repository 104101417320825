import React from 'react'
import Success from '../../css/Success.png'
import { Button } from '../../components/Button/Button'
import { Link } from 'react-router-dom'

const LoginSuccess = () => {
  // return (
  //   <div>
  //     <div className="SuccessArea">
  //       <div className="SuccessBox">
  //         <span>
  //           <img src={Success} alt="Success" />
  //         </span>
  //         <h3>Congratulations!</h3>
  //         <p>Your password has been updated successfully!</p>
  //         <Button to="/">Continue To Login</Button>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <>
      <div className="LoginArea">
        <h1 className="login_head">Vedic Meet</h1>
        <div className="LoginBox">
          <h5 className="mt-2">Congratulations!</h5>
          <img className="success_logo" src={Success} alt="Success" />
          <p className="text-center fs-5">
            Your password has been updated successfully!
          </p>
          <div className="form_btn">
            {/* <button className="login_btn"> */}
            <Link className="link_color" to={'/'}>
              Go to login
            </Link>
            {/* </button> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginSuccess
