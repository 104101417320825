import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { Avatar, IconButton } from "@mui/material";
import Switch from "../../components/Switch/Switch";
import NotFound from "../../components/not-found/NotFound";
import Pagination from "react-js-pagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { category, music, vastuCompass } from "../../routes/route";
import common from "../../routes/common";
import { toastMessage } from "../../utils/toastMessage";
import findSerialNumber from "../../utils/findSerialNumber";
import { charLimit } from "../../utils/charLimit";
import EditIcon from "@mui/icons-material/Edit";
// import AddMusic from './AddMusicCategory'
import showFullSizeImage from "../../utils/showFullSizeImage";
// import MusicCategoryStatus from './MusicCategoryStatus'
// import EditMusicCategory from './EditMusicCategory'
import scrollToUp from "../../utils/scrollToUp";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import ImagePreviewModel from "../../components/imagePreview/ImagePreviewModel";
import { fetchVastuCategoryApi } from "../../services/vastuCompassCategory";
import VastuCompassCategoryStatus from "./VastuCompassCategoryStatus";
import AddVastuCompass from "./AddVastuCompass";
import EditVastuCompass from "./EditVastuCompass";
import { fatchCategoryApi } from "../../services/categoryManagement";
import AddIcon from "@mui/icons-material/Add";
const { NOT_FOUND } = common;
const { VASTU_COMPASS_CATEGORY_DETAIL } = vastuCompass;

const VastuCompassCategory = () => {
  // handle all state
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  // const [search, setSearch] = useState(searchParam.get('query') || '')
  const [search, setSearch] = useState(
    getLocalStorage("musicCategory", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [vastuCategoryList, setVastuCategoryList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [statusModal, setStatusModal] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [statusId, setStatusId] = useState("");
  const [selectedVastuDetail, setSelectedVastuDetail] = useState(null);
  const [showImagePreviewModel, setShowImagePreviewModel] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [imageTitle, setImageTitle] = useState("");
  const [vastuCategory, setVastuCategory] = useState([]);
  const [vastuTitle, setVastuTitle] = useState([]);

  // Function to set localStorage values whenever the state variables change
  useEffect(() => {
    setLocalStorage("musicCategory", "search", search);
  }, [search]);
  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true);
    setStatusId(id);
    setStatusValue(status);
  };

  // update vastu category handler
  const updateVastuCategoryHandler = (data) => {
    setSelectedVastuDetail(data || null);
    setEditModal(true);
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  const showImagePreviewHandler = (image) => {
    setShowImagePreviewModel(true);
    setImagePreview(image.vastuCompassCategoryImage);
    setImageTitle(image.title);
  };

  // close model

  const closeModel = () => {
    setSelectedVastuDetail(null);
    setStatusValue("");
    setStatusId("");
    setAddModal(false);
    setStatusModal(false);
    setEditModal(false);
    setImagePreview("");
    setImageTitle("");
    setShowImagePreviewModel(false);
  };

  // //------------------listing-music-Api----------------------------------
  const fetchVastuCompassHandler = async () => {
    try {
      setVastuCategoryList([]);
      showLoader();
      const sendData = {
        page: activePage,
        limit: 10,
      };
      if (debounceValue) {
        sendData.page = 1;
        sendData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      const { data } = await fetchVastuCategoryApi(sendData);
      if (data.code === 200) {
        setVastuCategoryList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setVastuCategoryList([]);
        setTotal(0);
        toastMessage(data.message, "error", "musicList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  const fetchMasterData = async () => {
    // console.log("fetchMasterData","ok");
    try {
      const res = await fatchCategoryApi();
      if (res?.data?.code === 200) {
        // console.log("res", res);
        setVastuCategory(res.data.result.masterData.vastuCategory);
        setVastuTitle(res.data.result.masterData.vastuTitle);
      } else {
        toastMessage(res.data.message, "error", "masterData");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // useEffect(() => {
  //   if (debounceValue) {
  //     searchParam.set('query', debounceValue)
  //   } else {
  //     searchParam.delete('query')
  //   }
  //   if (activePage === 1) {
  //     searchParam.delete('page')
  //   }
  //   setSearchParam(searchParam)
  //   fetchCategoryHandler(activePage, debounceValue)
  // }, [searchParam, debounceValue])
  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete("page");
    }
    setSearchParam(searchParam);
    fetchVastuCompassHandler();
  }, [searchParam, debounceValue]);

  useEffect(() => {
    if (addModal == true || editModal == true) {
      fetchMasterData();
    }
  }, [addModal, editModal]);

  console.log(addModal,"addModal"); 
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Vastu Compass Category</h4>
            <div className="block_user_button">
              <button onClick={() => setAddModal(true)}>
                <AddIcon />
                Add Category
              </button>
            </div>
          </div>
          <div className="FilterBox FilterArea ">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">{common.SEARCH_ICON}</span>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Category Title</th>
                  <th>Category Image</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>

              <tbody>
                {vastuCategoryList.length > 0 ? (
                  vastuCategoryList.map((vastuCategory, index) => {
                    return (
                      <React.Fragment key={vastuCategory._id}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          <td>{charLimit(vastuCategory.title, 30) || "---"}</td>
                          <td>
                            <Avatar
                              id={vastuCategory._id}
                              alt={vastuCategory.title}
                              src={vastuCategory.vastuCompassCategoryImage}
                              sx={{
                                width: 50,
                                height: 50,
                                cursor: "pointer",
                                borderRadius: 2,
                              }}
                              onClick={() =>
                                showImagePreviewHandler(vastuCategory)
                              }
                            />
                          </td>

                          <td>
                            <div className="action_button">
                              <Link
                                to={`${VASTU_COMPASS_CATEGORY_DETAIL}/${vastuCategory._id}`}
                              >
                                <IconButton
                                  aria-label="update"
                                  // className="px-2 py-0"
                                >
                                  <VisibilityIcon className="eye_icon" />
                                </IconButton>
                              </Link>
                              <IconButton
                                aria-label="update"
                                className="mx-2 py-0"
                                onClick={() =>
                                  updateVastuCategoryHandler(vastuCategory)
                                }
                              >
                                <EditIcon className="edit_icon" />
                              </IconButton>
                              <Switch
                                className="mx-2"
                                checked={vastuCategory.status}
                                onChange={() =>
                                  statusUpdateHandler(
                                    vastuCategory._id,
                                    vastuCategory.status
                                  )
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={7}
                    msg="Vastu compass category not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total >= 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}

          {/* <FloatingButton
            onClick={() => setAddModalShow(true)}
            icon={<AddIcon />}
          /> */}
        </div>
        {addModal && (
          <AddVastuCompass
            show={addModal}
            close={closeModel}
            listing={fetchVastuCompassHandler}
            vastuCategory={vastuCategory}
            setVastuCategory={setVastuCategory}
            vastuTitle={vastuTitle}
            setVastuTitle={setVastuTitle}
          />
        )}
        {statusModal && (
          <VastuCompassCategoryStatus
            show={statusModal}
            close={closeModel}
            setListing={setVastuCategoryList}
            status={statusValue}
            id={statusId}
          />
        )}
        {editModal && (
          <EditVastuCompass
            show={editModal}
            close={closeModel}
            selectedVastuDetail={selectedVastuDetail}
            setSelectedVastuDetail={setSelectedVastuDetail}
            vastuListing={fetchVastuCompassHandler}
            vastuCategory={vastuCategory}
            setVastuCategory={setVastuCategory}
            vastuTitle={vastuTitle}
            setVastuTitle={setVastuTitle}
          />
        )}
        {showImagePreviewModel && (
          <ImagePreviewModel
            show={showImagePreviewModel}
            close={closeModel}
            imageUrl={imagePreview}
            title={imageTitle}
          />
        )}
      </div>
    </>
  );
};

export default VastuCompassCategory;
