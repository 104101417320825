import React, { useEffect, useState } from 'react'
// import FloatingButton from '../../components/Button/FloatingButton'
import AddIcon from '@mui/icons-material/Add'
import { Link, useSearchParams } from 'react-router-dom'
import useDebounce from '../../../hooks/useDebounce'
import useFullPageLoader from '../../../hooks/useFullPageLoader'
import { Avatar, IconButton } from '@mui/material'
import { dateFormat } from '../../../components/Form'
import Switch from '../../../components/Switch/Switch'
import NotFound from '../../../components/not-found/NotFound'
import Pagination from 'react-js-pagination'
import image from '../../../Assests/image/profile_not_found.png'
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation'
// import { fatchSubCategoryApi } from '../..'
import { toast } from 'sonner'
// import SubCategoryStatus from '../SubCategoryStatus'
import BlockIcon from '@mui/icons-material/Block'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import EditSubCategory from '../EditSubCategory'
import BlockSubCategory from '../BlockCategory'
import categoryImage from '../../../Assests/image/haircut.jpg'
import { category } from '../../../routes/route'
import common from '../../../routes/common'
import AddCategory from '../AddCategory'
import AddMantra from './AddMantra'
const { ADD_MUSIC } = category

const categoryList = [
  {
    title: 'Category',
    image: image,
    id: '12345678',
  },
  {
    title: 'Category',
    image: image,
    id: '12345678',
  },
  {
    title: 'Category',
    image: image,
    id: '12345678',
  },
]

const MantraList = () => {
  const [searchParam, setSearchParam] = useSearchParams()
  let activePage = +searchParam.get('page') || 1
  const [search, setSearch] = useState(searchParam.get('query') || '')
  const debounceValue = useDebounce(search || '', 300)

  const [addMantraModel, setAddMantraModal] = useState(false)
  const [subCategoryList, setSubCategoryList] = useState([])
  const [total, setTotal] = useState(0)
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [statusModal, setStatusModal] = useState(false)
  const [statusValue, setStatusValue] = useState('')
  const [statusId, setStatusId] = useState('')
  // const [blockModal, setBlockModal] = useState(false);
  // const [blockId, setBlockId] = useState('');
  const [updateModal, setUpdateModal] = useState(false)
  const [updateSubCategoryData, setUpdateSubCategoryData] = useState({
    id: '',
    enName: '',
    arName: '',
    imageUrl: '',
    categoryId: '',
  })

  //*********Handel status********* */
  // const statusUpdateHandler = (id, status) => {
  //   setStatusModal(true)
  //   setStatusId(id)
  //   setStatusValue(status)
  // }
  //*********Handel Block********* */
  // const blockHandler = (id, status) => {
  //     setBlockModal(true);
  //     setBlockId(id);
  //     // setStatusValue(status);
  // };
  //*********Handle update********* */
  // const updateHandler = (id, enName, arName, imageUrl, categoryId) => {
  //   setUpdateModal(true)
  //   setUpdateSubCategoryData({
  //     id,
  //     enName,
  //     arName,
  //     imageUrl,
  //     categoryId,
  //   })
  // }
  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set('page', event)
    setSearchParam(searchParam)
  }

  // //------------------listing-Api----------------------------------
  // const fatchSubCategory = async (
  //   page = activePage,
  //   search = debounceValue
  // ) => {
  //   try {
  //     setSubCategoryList([])
  //     showLoader()
  //     const res = await fatchSubCategoryApi({ page, search })
  //     if (res.data.code === 200) {
  //       // console.log("subCategory",res.data.data.subCategorylist)
  //       setSubCategoryList(res?.data?.data?.subCategorylist || [])
  //       setTotal(res?.data?.data?.pagination?.totalItems || 0)
  //       hideLoader()
  //     } else {
  //       setSubCategoryList([])
  //       setTotal(0)
  //       hideLoader()
  //     }
  //   } catch (e) {
  //     hideLoader()
  //     toast.error(e.message)
  //   }
  // }

  // useEffect(() => {
  //   if (debounceValue) {
  //     searchParam.set('query', debounceValue)
  //   } else {
  //     searchParam.delete('query')
  //   }
  //   if (activePage === 1) {
  //     searchParam.delete('page')
  //   }
  //   setSearchParam(searchParam)
  //   fatchSubCategory(activePage, debounceValue)
  // }, [searchParam, debounceValue])
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Category Management</h4>
            <div className="block_user_button">
              <button onClick={() => setAddMantraModal(true)}>
                Add Music / Mantra
              </button>
            </div>
          </div>
          <div className="FilterBox FilterArea ">
            <figure className="category_detail-image">
              <img src={categoryImage} alt={'category_image'} />
            </figure>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Category Title</th>
                  <th>Category Image</th>
                  {/* <th>Category Id</th> */}
                  <th className="text-center"> Action </th>
                </tr>
              </thead>

              <tbody>
                {categoryList.length > 0 ? (
                  categoryList.map((category, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{++index + (activePage - 1) * 10}</td>
                          <td>{category.title || '---'}</td>
                          <td>
                            <Avatar
                              alt={'subcategory'}
                              src={category.image}
                              sx={{
                                width: 50,
                                height: 50,
                              }}
                            />
                          </td>
                          {/* <td>{category.id || '---'}</td> */}
                          <td>
                            <div className="action_button">
                              <Link to={`${ADD_MUSIC}/${123}`}>
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                >
                                  <VisibilityIcon className="eye_icon" />
                                </IconButton>
                              </Link>
                              <Switch
                                checked={true}
                                // onChange={() => statusUpdateHandler(_id, status)}
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })
                ) : (
                  <NotFound
                    cols={7}
                    msg="No sub Categories Found!"
                    icon={<BabyChangingStationIcon sx={{ fontSize: 48 }} />}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total >= 10 ? (
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                previousLabel={'previous'}
                nextLabel={'next'}
                itemsCountPerPage={10}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            </div>
          ) : null}

          {/* <FloatingButton
            onClick={() => setAddModalShow(true)}
            icon={<AddIcon />}
          /> */}
        </div>
        {addMantraModel && (
          <AddMantra
            show={addMantraModel}
            close={() => setAddMantraModal(false)}
            // listing={fatchSubCategory}
          />
        )}
        {/* <SubCategoryStatus
          show={statusModal}
          close={() => setStatusModal(false)}
          listing={fatchSubCategory}
          apiStatus={statusValue}
          id={statusId}
        /> */}
        {/* <EditSubCategory
          show={updateModal}
          close={() => setUpdateModal(false)}
          data={updateSubCategoryData}
          listing={fatchSubCategory}
        /> */}
      </div>
    </>
  )
}

export default MantraList
