import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import NotFound from "../../components/not-found/NotFound";
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import IconButton from "../../components/Button/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BlockIcon from "@mui/icons-material/Block";
import Switch from "../../components/Switch/Switch";
import { SecondaryButton } from "../../components/Button/Button";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { DatePicker, Select } from "../../components/Form";
import { toast } from "sonner";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
// import { getOrderApi } from '../../services/orderManagement'
import { SiMicrosoftexcel } from "react-icons/si";
import { IoSearch } from "react-icons/io5";
import profileImage from "../../Assests/image/profile_not_found.png";
import { user } from "../../routes/route";
import scrollToUp from "../../utils/scrollToUp";
import { toastMessage } from "../../utils/toastMessage";
import {
  fetchConsultantListApi,
  getDeletedConsultantListApi,
  logoutConsultantFromAllDevice,
} from "../../services/consultantManagement";
import findSerialNumber from "../../utils/findSerialNumber";
import { dateFormat, dateFormatUtc } from "../../utils/dateFormat";
import { GrDocumentPerformance } from "react-icons/gr";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ApprovedConsultant from "./ApprovedConsultant";
import ConsultantStatus from "./ConsultantStatus";
import { gender } from "../../utils/genderList";
import common from "../../routes/common";
import AddConsultant from "./AddConsultant";
import { fatchCategoryApi } from "../../services/categoryManagement";
import { charLimit } from "../../utils/charLimit";
import { CapitalizeFirstWord } from "../../utils/CapitalizeFirstWord";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import useFileDownloader from "../../hooks/useFileDownloader";
import Loader from "../../comman/Loader";
import csv from "../../Assests/image/csv.svg";
import consultantPerformance from "../../Assests/image/consultantPerformance.svg";
import { consultant } from "../../routes/route";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import useButtonLoader from "../../hooks/UseButtonLoader";
import ModalBox from "../../components/modal/ModalBox";

const { USER_DETAIL } = user;
const { NOT_FOUND } = common;
const { CONSULTANT_ADD, CONSULTANT_EDIT, CONSULTANT_DELETE } = consultant;

const DeleteConsultant = () => {
  let URL = process.env.REACT_APP_API_URL;

  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  let registrationDate = searchParam.get("registrationDate") || "";
  let problemValue = searchParam.get("problem") || "";
  let skillsValue = searchParam.get("skills") || "";

  let genderValue = searchParam.get("gender") || "";
  const [search, setSearch] = useState(
    getLocalStorage("consultantList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);
  const isNew = searchParam.get("isNew") === "true";
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [consultantList, setConsultantList] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [buttonref, buttonLoader] = useButtonLoader("Logout");
  const [statusModal, setStatusModal] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [statusId, setStatusId] = useState("");
  const [logoutCosultantModel, setLogoutCosultantModel] = useState(false);
  const [statusSearch, setStatusSearch] = useState("");
  const [startDateSearch, setStartDateSearch] = useState("");
  const [endDateSearch, setEndDateSearch] = useState("");
  const [approveConsultant, setApproveConsultant] = useState(false);
  const [addConsultantModel, setAddConsultantModel] = useState(false);
  const [consultantSkillOption, setConsultantSkillOption] = useState([]);
  const [consultantRequstCount, setConsultantRequestCount] = useState("");
  const [approveStatus, setApproveStatus] = useState("");
  const navigate = useNavigate();

  // handle all hooks

  const { loading, downloadFile } = useFileDownloader();

  useEffect(() => {
    setLocalStorage("consultantList", "search", search);
  }, [search]);

  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true);
    setStatusId(id);
    setStatusValue(status);
  };

  const logoutCloseModel = () => {
    setLogoutCosultantModel(false);
    setStatusId("");
  };

  const handleExcelDownload = () => {
    const url = `${URL}/admin/consultant/download`;
    const params = {};
    downloadFile(url, `consultant-excel-file${dateFormat(new Date())}`, params);
  };

  const handleLogoutHandler = async () => {
    try {
      buttonLoader(true);
      const sendData = { consultantId: statusId };
      const { data } = await logoutConsultantFromAllDevice(sendData);
      if (data.code === 200) {
        logoutCloseModel();
      } else {
        toastMessage(data.message, "error", "consultantList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      buttonLoader(false);
    }
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };
  //*********Handel refresh icon********* */
  const refreshHandler = (event) => {
    setSearch("");
    searchParam.delete("page");
    // searchParam.delete("problem");
    // searchParam.delete("gender");
    // searchParam.delete("registrationDate");
    // searchParam.delete("isNew");
    setSearchParam(searchParam);
  };

  // handle date picker date

  // const handleRegistrationDate = (date) => {
  //   const utcDateString = dateFormatUtc(date);
  //   searchParam.delete("page");
  //   searchParam.set("registrationDate", utcDateString);
  //   setSearchParam(searchParam);
  // };

  // approve consultant handler

  const approveConsultantHandler = (id, status) => {
    setApproveStatus(status);
    setStatusId(id);
    setApproveConsultant(true);
  };

  // gender handler

  // const genderHandler = (value) => {
  //   if (genderValue === value) {
  //     searchParam.delete("gender");
  //   } else {
  //     searchParam.set("gender", value);
  //   }
  //   searchParam.delete("page");
  //   setSearchParam(searchParam);
  // };

  // const problemHandler = (value) => {
  //   if (problemValue === value) {
  //     searchParam.delete("problem");
  //   } else {
  //     searchParam.set("problem", value);
  //   }
  //   searchParam.delete("page");
  //   setSearchParam(searchParam);
  // };

  // const skillHandler = (value) => {
  //   if (skillsValue === value) {
  //     searchParam.delete("skills");
  //   } else {
  //     searchParam.set("skills", value);
  //   }
  //   searchParam.delete("page");
  //   setSearchParam(searchParam);
  // };

  // close model

  const closeModel = () => {
    setApproveConsultant(false);
    setStatusModal(false);
    setAddConsultantModel(false);
    setStatusValue("");
    setStatusId("");
    setApproveStatus("");
  };

  const isNewHandler = (isNew) => {
    const updatedNew = !isNew;
    searchParam.set("isNew", updatedNew);
    searchParam.delete("page");
    searchParam.delete("gender");
    searchParam.delete("registrationDate");
    setSearchParam(searchParam);
  };

  //------------------listing-Api----------------------------------
  const fetchConsultantListHandler = async (isNew) => {
    try {
      setConsultantList([]);
      showLoader();
      const sendData = {
        page: `${activePage}`,
        limit: "10",
        // filter: {},
      };

      // if (isNew) {
      //   sendData.isNew = "true";
      // }
      // if (registrationDate) {
      //   sendData.filter.registerDate = registrationDate;
      // }
      // if (genderValue) {
      //   sendData.filter.gender = genderValue;
      // }
      // if (problemValue) {
      //   sendData.filter.problems = problemValue;
      // }
      // if (skillsValue) {
      //   sendData.filter.skills = skillsValue;
      // }
      if (debounceValue) {
        sendData.page = "1";
        sendData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      const { data } = await getDeletedConsultantListApi(sendData);
      if (data.code === 200) {
        setConsultantList(data?.result?.list || []);
        setConsultantRequestCount(data?.result?.newConsultantcount);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setConsultantList([]);
        setTotal(0);
        toastMessage(data.message, "error", "consultantList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // useEffect(() => {
  //   if (debounceValue) {
  //     searchParam.set('query', debounceValue)
  //   } else {
  //     searchParam.delete('query')
  //   }
  //   if (activePage === 1) {
  //     searchParam.delete('page')
  //   }
  //   setSearchParam(searchParam)
  //   getOrder(activePage, debounceValue)
  // }, [searchParam, debounceValue])

  // useEffect(() => {
  //   if (
  //     (startDateSearch && endDateSearch) ||
  //     (startDateSearch === '' && endDateSearch === '')
  //   ) {
  //     getOrder()
  //   }
  // }, [startDateSearch, endDateSearch])

  const fetchConsultantSkillHandler = async () => {
    try {
      const { data } = await fatchCategoryApi();
      if (data.code === 200) {
        const updatedConsultantSkill =
          data?.result?.categoryList &&
          data?.result?.categoryList.length > 0 &&
          data?.result?.categoryList.map(({ id, title }) => ({
            value: id,
            label: title,
          }));

        const updatedSKillList =
          data?.result?.skillList &&
          data?.result?.skillList.length &&
          data?.result?.skillList.map(({ id, title }) => ({
            value: id,
            label: title,
          }));
        setConsultantSkillOption(updatedConsultantSkill);
        setSkillList(updatedSKillList);
      } else {
        setConsultantSkillOption([]);
        setSkillList([]);
        toastMessage(data.message, "error", "categoryList");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  //*********Logout handler function********* */
  const ConsultantLogoutHandler = (id) => {
    setStatusId(id);
    setLogoutCosultantModel(true);
  };

  // validation

  useEffect(() => {
    if (!isNew) {
      fetchConsultantSkillHandler();
    }
  }, [isNew]);

  useEffect(() => {
    if (Number(activePage) === 1) {
      searchParam.delete("page");
    }

    setSearchParam(searchParam);
    fetchConsultantListHandler(isNew);
  }, [searchParam, debounceValue]);

  const notFoundMessage = !isNew
    ? "Consultant not found!"
    : "Consultant requests not found!";

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <div className="user_management_heading">
              <div>
                <h4 className="Title">Deleted Consultant</h4>
              </div>
              {/* <div className="consultant-wrapper">
                <span
                  className="user_management_excel"
                  onClick={handleExcelDownload}
                >
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="csv-image-wrapper">
                      <figure>
                        <img src={csv} alt="csv file download" />
                      </figure>
                    </div>
                  )}
                </span>
                <div className="mx-2">
                  <Link to={`${CONSULTANT_DELETE}`}>
                    <div className="block_user_button">
                      <button
                      onClick={() => setAddConsultantModel(true)}
                      >
                        <AddIcon />
                        Delete Consultant
                      </button>
                    </div>
                  </Link>
                </div>
                <Link to={`${CONSULTANT_ADD}`}>
                  <div className="block_user_button">
                    <button
                    // onClick={() => setAddConsultantModel(true)}
                    >
                      <AddIcon />
                      Add Consultant
                    </button>
                  </div>
                </Link>
              </div> */}
            </div>
          </div>
          {/* <div className="consultant_button_wrapper">
            <div className="banner_button_wrapper">
              <button
                className={`_button ${!isNew && "banner_active"}`}
                onClick={() => isNewHandler(isNew)}
              >
                All Consultant
              </button>
              <button
                className={`_button ${isNew && "banner_active"}`}
                onClick={() => isNewHandler(isNew)}
              >
                {`Consultant Requests (${consultantRequstCount || 0})`}
              </button>
            </div>
          </div> */}
          <div className="FilterBox FilterArea consultant-header">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className={`form-control ${search && "active"}`}
                placeholder="Search"
                value={search || ""}
                onChange={(e) => setSearch(e.target.value)}
                style={{ "--color": "#FF8989" }}
                // disabled={consultantList.length === 0}
              />
              <span className="user_search_icon">
                <IoSearch />
              </span>
            </div>
            {/* --------search-status----------- */}

            {isNew || (
              <div className="group_filter_value">
                {/* <div className="form-group date_picker">
                  <DatePicker
                    className={`form-control ${registrationDate && "active"}`}
                    name="startDate"
                    selected={registrationDate}
                    placeholder="Registration Date"
                    onChange={(date) => handleRegistrationDate(date)}
                    maxDate={Date.now()}
                    isClearable
                  />
                </div> */}

                <div className="form-group select_sm_width">
                  {/* <Select
                    className={`form-control ${genderValue && "active"}`}
                    value={genderValue || ""}
                    onChange={(e) => genderHandler(e.target.value)}
                  >
                    <option value="">Gender</option>
                    {gender &&
                      gender.length > 0 &&
                      gender.map((gender) => {
                        return (
                          <option value={gender.value} key={gender.id}>
                            {gender.name}
                          </option>
                        );
                      })}
                  </Select> */}
                </div>
                <div className="form-group refresh_button">
                  <SecondaryButton onClick={refreshHandler}>
                    <RotateLeftIcon />
                  </SecondaryButton>
                </div>
              </div>
            )}
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>User Name</th>
                  <th>Phone Number</th>
                  <th>Type</th>
                  <th>Gender</th>
                  {isNew || <th>Registration Date</th>}
                  {isNew || <th>Check Performance</th>}
                  {isNew && <th>Request Date</th>}
                  {/* <th className="text-center"> Action </th>
                  <th>Logout from all Device</th> */}
                </tr>
              </thead>
              <tbody>
                {consultantList.length > 0 ? (
                  consultantList.map((consultant, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          <td className="user_profile_image">
                            <img
                              src={consultant.profileImage || profileImage}
                              alt={`${index + 1}-${
                                consultant.name
                              } profile image`}
                            />
                          </td>
                          <td>
                            {consultant.name
                              ? CapitalizeFirstWord(consultant.name)
                              : "---"}
                          </td>
                          <td>
                            {consultant.userName ? consultant.userName : "---"}
                          </td>
                          {/* <td>{consultant.consultantId || '---'}</td> */}
                          <td>
                            {`${consultant.countryCode || ""}-${
                              consultant.mobile || "---"
                            }`}
                          </td>
                          <td>
                            {consultant.consType
                              ? CapitalizeFirstWord(consultant.consType)
                              : "---"}
                          </td>
                          <td>
                            {consultant.gender
                              ? CapitalizeFirstWord(consultant.gender)
                              : "---"}
                          </td>
                          {isNew || (
                            <td>{dateFormat(consultant.createdAt) || "---"}</td>
                          )}
                          {isNew || (
                            <td>
                              {/* <span className="consultant_performance">
                                <GrDocumentPerformance />
                              </span> */}
                              <div
                                onClick={() => {
                                  navigate(
                                    `/consultant-management/check-performance/${consultant._id}/${consultant?.name}`
                                  );
                                }}
                              >
                                <figure className="consultant-performance-icon">
                                  <img
                                    src={consultantPerformance}
                                    alt="Consultant performance"
                                  />
                                </figure>
                              </div>
                            </td>
                          )}
                          {isNew && (
                            <td>
                              {consultant.createdAt
                                ? dateFormat(consultant.createdAt)
                                : "---"}
                            </td>
                          )}
                          {/* <td>
                            <div className="action_button">
                              <Link
                                to={`/consultant-management/consultant-detail/${consultant._id}`}
                              >
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                >
                                  <VisibilityIcon className="eye_icon" />
                                </IconButton>
                              </Link>
                              <Link to={`${CONSULTANT_EDIT}/${consultant._id}`}>
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                  onClick={() => updateBannerHandler(banner)}
                                >
                                  <EditIcon className="edit_icon" />
                                </IconButton>
                              </Link>
                              {isNew && (
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                  onClick={() =>
                                    approveConsultantHandler(
                                      consultant._id,
                                      true
                                    )
                                  }
                                >
                                  <CheckCircleOutlineIcon className="tick_icon" />
                                </IconButton>
                              )}
                              </Link>

                              {isNew || (
                                <Switch
                                  checked={consultant.status}
                                  onChange={() =>
                                    statusUpdateHandler(
                                      consultant._id,
                                      consultant.status
                                    )
                                  }
                                />
                              )}
                              {isNew && (
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                  onClick={() =>
                                    approveConsultantHandler(
                                      consultant._id,
                                      false
                                    )
                                  }
                                >
                                  <BlockIcon className="tick_icon" />
                                </IconButton>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="block_user_button">
                              <button
                                className="mx-4"
                                onClick={() =>
                                  ConsultantLogoutHandler(consultant._id)
                                }
                                ref={buttonref}
                              >
                                Logout
                              </button>
                            </div>
                          </td> */}
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={9}
                    msg={notFoundMessage}
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total > 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}
        </div>
        {approveConsultant && (
          <ApprovedConsultant
            show={approveConsultant}
            close={closeModel}
            setListing={setConsultantList}
            id={statusId}
            setConsultantCount={setConsultantRequestCount}
            approveStatus={approveStatus}
          />
        )}
        {statusModal && (
          <ConsultantStatus
            show={statusModal}
            close={closeModel}
            setListing={setConsultantList}
            status={statusValue}
            id={statusId}
          />
        )}
        {addConsultantModel && (
          <AddConsultant
            show={addConsultantModel}
            close={closeModel}
            consultantListApi={() => fetchConsultantListHandler(isNew)}
          />
        )}
        {logoutCosultantModel && (
          <ModalBox
            status={logoutCosultantModel}
            onClose={logoutCloseModel}
            title="Logout Consultant from all Device"
            description="Do you want to logout the consultant from all the devices?"
            action={handleLogoutHandler}
            ref={buttonref}
          />
        )}
      </div>
    </>
  );
};

export default DeleteConsultant;
