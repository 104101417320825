import React from 'react'
import ModalBox from '../../../components/modal/ModalBox'
import useButtonLoader from '../../../hooks/UseButtonLoader'
import { toastMessage } from '../../../utils/toastMessage'
import { changeMeditationStatus } from '../../../services/meditationManagement'

const MeditationStatus = ({ show, close, status, id, setListing }) => {
  const [buttonref, buttonLoader] = useButtonLoader('Update')

  //--------------change-status-sub-category------------------------
  const changeMeditationStatusHandler = async () => {
    try {
      buttonLoader(true)
      let sendData = { status: !status, meditationMediaId: id }
      const { data } = await changeMeditationStatus(sendData)
      if (data.code === 200) {
        setListing((prevListing) => {
          return prevListing.map((meditation) => {
            if (meditation._id === id) {
              return { ...meditation, status: !status }
            }
            return meditation
          })
        })
        toastMessage(data.message, 'success', 'meditationStatus')
        close()
      } else {
        toastMessage(data.message, 'error', 'meditationStatus')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      buttonLoader(false)
    }
  }

  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Change Meditation Status"
      description="Do you want to change the status of this meditation?"
      action={changeMeditationStatusHandler}
      ref={buttonref}
    />
  )
}

export default MeditationStatus
