import React, { useEffect, useRef, useState } from 'react'
import ModalBox from '../../../components/modal/ModalBox'
import { Input, Select } from '../../../components/Form'
import useButtonLoader from '../../../hooks/UseButtonLoader'
// import { AddSubCategoryApi } from '../../../services/subCategory'
import { toast } from 'sonner'
import { fatchCategoryApi } from '../../../services/categoryManagement'
import { regex } from '../../../utils/regex'
import ImagePicker from '../../../components/imagePicker/ImagePicker'
import { Avatar } from '@mui/material'
const { imageFileRegex } = regex

const mantraType = [
  {
    value: 'a',
    type: 'a',
  },
  {
    value: 'b',
    type: 'b',
  },
  {
    value: 'c',
    type: 'c',
  },
  {
    value: 'd',
    type: 'd',
  },
]

const AddMantra = ({ show, close, listing }) => {
  const musicRef = useRef(null)
  const [mantraValue, setMantraValue] = useState({
    description: '',
    type: '',
  })
  const [audio, setAudio] = useState('')
  const [error, setError] = useState({})
  const [buttonref, buttonLoader] = useButtonLoader('Add')
  const [categoryList, setCategoryList] = useState([])

  // console.log("AddSubCategory",input)
  //----------vaidation------------
  const validation = () => {
    let errorMessage = {}
    let status = true

    // if (!categoryTitle) {
    //   errorMessage['category'] = 'Please add category Title'
    //   status = false
    // }
    // if (!image.name) {
    //   errorMessage['image'] = 'Please choose image'
    //   status = false
    // } else if (!imageFileRegex.test(image.name)) {
    //   errorMessage['image'] = 'Invalid image format'
    //   status = false
    // }
    setError(errorMessage)
    return status
  }
  //------------------onChange-input-value---------------------------
  // const handleChange = (e) => {
  //   const { name, value, files } = e.target
  //   if (name === 'category') {
  //     // Allow only letters and spaces for the name, and remove leading spaces
  //     const cleanedName = value
  //       .replace(/^[ ]+/g, '')
  //       .replace(/[^A-Za-z ]/gi, '')
  //       .replace(/\s+/g, ' ')
  //     setInput({
  //       ...input,
  //       [name]: cleanedName,
  //     })
  //   } else if (name === 'nameArabic') {
  //     let cleanedValue = value
  //       .replace(/^[ ]+/g, '') // Remove leading spaces
  //       .replace(/[^\u0621-\u064A\s]/g, '') // Match Arabic characters (including space)
  //       .replace(/\s+/g, ' ')
  //     setInput({
  //       ...input,
  //       [name]: cleanedValue,
  //     })
  //   } else if (name === 'image' && files && files[0]) {
  //     // Handle image change
  //     setImage(files[0])
  //   } else {
  //     setInput({ ...input, [name]: value })
  //   }
  // }

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    // setImage("");
    // setInput({ name: '', categoryId: '' })
    setError({})
    close()
  }
  //--------------Add-sub-category------------------------
  // const addSubCategory = async () => {
  //   const status = validation()
  //   if (status) {
  //     try {
  //       buttonLoader(true)
  //       const formData = new FormData()
  //       formData.append('categoryId', input.categoryId)
  //       formData.append('enName', input.name)
  //       formData.append('arName', input.nameArabic)
  //       // formData.append("image", image)
  //       const res = await AddSubCategoryApi(formData)
  //       if (res.data.code === 200) {
  //         toast.success(res.data.message)
  //         modalClose()
  //         listing()
  //         buttonLoader(false)
  //       } else {
  //         toast.error(res.data.message)
  //         buttonLoader(false)
  //       }
  //     } catch (e) {
  //       toast.error(e.message)
  //       buttonLoader(false)
  //     }
  //   }
  // }
  //------------------listing-Api-category-dropdown--------------------------------
  // const fatchCategory = async () => {
  //   try {
  //     setCategoryList([])
  //     const res = await fatchCategoryApi()
  //     if (res.data.code === 200) {
  //       setCategoryList(res?.data?.data?.categorylist || [])
  //     } else {
  //       setCategoryList([])
  //     }
  //   } catch (e) {
  //     toast.error(e.message)
  //   }
  // }

  // useEffect(() => {
  //   fatchCategory()
  // }, [])
  // const handleAudioChange = (e) => {
  //   const file = e.target.files[0]
  //   const maxSizeInBytes = 10 * 1024 * 1024 // 10 MB (adjust as needed)
  //   const maxDurationInSeconds = 180 // 3 minutes

  //   if (file && file.size <= maxSizeInBytes) {
  //     const audioElement = document.createElement('audio')
  //     audioElement.src = URL.createObjectURL(file)

  //     audioElement.onloadedmetadata = () => {
  //       console.log('duration', audioElement.duration)
  //       if (audioElement.duration <= maxDurationInSeconds) {
  //         setAudio(file)
  //         // setIsPlaying(true) // Automatically play the audio when selected
  //       } else {
  //         // Provide feedback to the user about the duration limit exceeded
  //         alert(
  //           'Audio duration exceeds the limit. Please select a shorter file.'
  //         )
  //       }
  //     }

  //     audioElement.onerror = () => {
  //       // Handle error loading audio file
  //       alert('Error loading audio file. Please try again.')
  //     }
  //   } else {
  //     // Provide feedback to the user about the file size limit exceeded
  //     alert('File size exceeds the limit. Please select a smaller file.')
  //   }
  // }

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={'Add Music/Mantra'}
      closeIcon
      custom
      customizedFooter={
        <button
          className="btn_size"
          ref={buttonref}
          // onClick={addSubCategory}
        >
          Save
        </button>
      }
    >
      <div>
        {/* <div className="form-group mb-2">
          <Input
            type="text"
            name="name"
            value={input?.name || ''}
            onChange={handleChange}
            placeholder="Enter Name in english"
            className="form-control"
            label="Name (in english)"
            error={error.name}
            maxLength={30}
          />
        </div> */}
        <div className="form-group mb-2">
          <label htmlFor="descripton">Description</label>
          <textarea
            cols={2}
            style={{ resize: 'none' }}
            type="text"
            name="description"
            id="description"
            // value={mantraValue?.description || ''}
            required
            // onChange={handleChange}
            placeholder="Description"
            className={`form-control `}
            label={'Add Category'}
            // error={error.nameArabic}
            // maxLength={30}
          />
        </div>
        <div className="form-group mb-2">
          <Select
            name="categoryId"
            value={mantraValue?.type || ''}
            // onChange={handleChange}
            className="form-control"
            label="Choose Category"
            required
            // error={error.categoryId}
          >
            <option value="">Type</option>
            {mantraType.length > 0 &&
              mantraType.map((mantra, index) => {
                // if (status) {
                return (
                  <React.Fragment key={index}>
                    <option value={index}>{mantra.type}</option>
                  </React.Fragment>
                )
                // }
              })}
          </Select>
        </div>

        <div className="music-wrapper">
          <p>Music Mantra</p>
          <label htmlFor="musicInput" className="button-label">
            Choose Mp3
            <input
              type="file"
              id="musicInput"
              name="music"
              accept="audio/mp3"
              // onChange={(e) => handleAudioChange(e)}
              className="form-control"
              hidden
              ref={musicRef}
            />
          </label>
        </div>
        {audio && (
          <div className="music-player">
            <audio
              src={URL.createObjectURL(audio)}
              autoPlay={true}
              controls
              controlsList="nodownload"
            />
            {/* <button className="play-btn" onClick={togglePlay}>
              {isPlaying ? 'Pause' : 'Play'}
            </button> */}
          </div>
        )}
      </div>
    </ModalBox>
  )
}

export default AddMantra
