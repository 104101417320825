import React, { useState } from 'react'
import ModalBox from '../../../components/modal/ModalBox'
import { Input } from '../../../components/Form'
import useButtonLoader from '../../../hooks/UseButtonLoader'
import { regex, optimizeFunction, allowAlphaNumericWithSpace } from '../../../utils/regex'
import { Avatar } from '@mui/material'
import { toastMessage } from '../../../utils/toastMessage'
import { AddCategoryApi } from '../../../services/categoryManagement'
import configData from '../../../config/configData'
import { addMusicApiHandler } from '../../../services/musicManagement'
import { checkImageDimensions } from '../../../utils/checkImageDimension'
import commonMessages from '../../../utils/commonMessages'
import { convertLength } from '@mui/material/styles/cssUtils'
import { convertSecToMin } from '../../../utils/convertSecToMin'
import AddIcon from '@mui/icons-material/Add';
const { imageFileRegex, audioFileRegex } = regex
const {
  MUSIC_TITLE,
  MUSIC_IMAGE,
  MUSIC,
  IMAGE_FORMAT,
  VALID_AUDIO,
  AUDIO_EXCEED_SIZE,
  EXCEED_SIZE,
  ERROR_AUDIO_LOADING,
} = commonMessages

const AddMusic = ({ show, close, listing, musicId }) => {
  // handle all state
  const [musicTitle, setMusicTitle] = useState('')
  const [image, setImage] = useState('')
  const [music, setMusic] = useState('')
  const [error, setError] = useState({})
  const [categoryList, setCategoryList] = useState([])
  const [musicDuration,setMusicDuration]=useState("");

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader('Save')

  // handle all function

  // console.log("AddSubCategory",input)
  //----------vaidation------------
  const validation = () => {
    let errorMessage = {}
    let status = true

    if (!musicTitle) {
      errorMessage['title'] = MUSIC_TITLE
      status = false
    }
    if (!image.name) {
      errorMessage['image'] = MUSIC_IMAGE
      status = false
    }
    if (!music.name) {
      errorMessage['music'] = MUSIC
      status = false
    }

    setError(errorMessage)
    return status
  }

  // Add Category image handler function

  const imageHandler = async (e) => {
    e.preventDefault()
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        // setImage(e.target.files[0])
        const validSize = await checkImageDimensions(
          e.target.files[0],
          1920,
          1080
        )
        if (validSize) {
          setImage(e.target.files[0])
        } else {
          e.target.value = ''
          setImage('')
        }
      } else {
        e.target.value = ''
        setImage('')
        toastMessage(IMAGE_FORMAT, 'error', 'imageFormat')
      }
    } else {
      e.target.value = ''
      setImage('')
    }
  }

  const resetInputAndMusic = (e) => {
    e.target.value = ''
    setMusic('')
  }

  const audioHandler = (e) => {
    e.preventDefault()

    const maxSizeInBytes = configData.audio_legth
    const maxDurationInSeconds = configData.audio_duration
    const file = e.target?.files[0]

    if (!file) {
      resetInputAndMusic(e)
      return
    }

    if (!audioFileRegex.test(file.name)) {
      resetInputAndMusic(e)
      toastMessage(VALID_AUDIO, 'error', 'addAudio')
      return
    }

    if (file.size > maxSizeInBytes) {
      resetInputAndMusic(e)
      toastMessage(EXCEED_SIZE, 'error', 'addAudio')
      return
    }

    const audioElement = document.createElement('audio')
    audioElement.src = URL.createObjectURL(file)
    audioElement.onloadedmetadata = () => {
      if (audioElement.duration > maxDurationInSeconds) {
        resetInputAndMusic(e)
        toastMessage(AUDIO_EXCEED_SIZE, 'error', 'addAudio')
      } else {
        setMusicDuration(convertSecToMin(audioElement.duration));
        setMusic(file)
      }
    }
    audioElement.onerror = () => {
      resetInputAndMusic(e)
      toastMessage(ERROR_AUDIO_LOADING, 'error', 'addAudio')
    }
  }

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setImage('')
    setMusic('')
    setMusicTitle('')
    setError({})
    close()
  }
  //--------------Add-sub-category------------------------
  const addMusicHandler = async () => {
    const status = validation()
    if (status) {
      try {
        buttonLoader(true)
        const formData = new FormData()
        formData.append('musicId', musicId)
        formData.append('title', musicTitle)
        formData.append('musicImage', image)
        formData.append('musicMedia', music)
        formData.append("musicDuration",musicDuration)

        const { data } = await addMusicApiHandler(formData)
        if (data.code === 200) {
          toastMessage(data.message, 'success', 'addMusicHandler')
          listing()
          modalClose()
        } else {
          toastMessage(data.message, 'error', 'addMusicHandler')
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        buttonLoader(false)
      }
    }
  }

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={'Add Music'}
      size={'sm'}
      closeIcon
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <p>
            Uploaded image will be resized to fit within: Width of 1920px and
            height of 1080px image must be in either the .jpg .jpeg or .png
            format.
          </p>
          <button
            className="btn_size"
            ref={buttonref}
            onClick={addMusicHandler}
          >
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={musicTitle || ''}
            required
            onChange={(e) => {
              const sanitizedText = allowAlphaNumericWithSpace(e.target.value)
              setMusicTitle(sanitizedText)
            }}
            placeholder="Music Title"
            className={`form-control`}
            label={'Music Title'}
            error={!musicTitle && error.title}
            maxLength={40}
          />
        </div>
        <div className="form-group my-2">
          <Input
            type="file"
            accept="audio/mp3"
            name="musicMedia"
            required
            onChange={(e) => audioHandler(e)}
            placeholder="Upload Music"
            className={`form-control `}
            label={'Upload Music'}
            error={!music && error.music}
          />
        </div>

        {music && (
          <div className="music-player">
            <audio
              src={music && URL.createObjectURL(music)}
              // autoPlay={true}
              controls
              controlsList="nodownload"
            />
          </div>
        )}
        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="musicImage"
            // value={image.name || ''}
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Music Image"
            className={`form-control `}
            label={'Music Image'}
            error={!image && error.image}
            // maxLength={30}
          />
        </div>
        <div>
          {image && (
            <>
              <Avatar
                variant="square"
                sx={{
                  width: '100%',
                  borderRadius: 1,
                  height: 140,
                  border: '1px solid #FF8989',
                }}
                src={image && URL.createObjectURL(image)}
              />
            </>
          )}
        </div>
      </div>
    </ModalBox>
  )
}

export default AddMusic
