import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import NotFound from "../../components/not-found/NotFound";
import { Link, useParams, useSearchParams } from "react-router-dom";
import IconButton from "../../components/Button/IconButton";
import { Select } from "../../components/Form";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
import { IoSearch } from "react-icons/io5";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import findSerialNumber from "../../utils/findSerialNumber";
import { toastMessage } from "../../utils/toastMessage";
import common from "../../routes/common";
import { dateFormat } from "../../utils/dateFormat";
import scrollToUp from "../../utils/scrollToUp";
import { CapitalizeFirstWord } from "../../utils/CapitalizeFirstWord";
import Loader from "../../comman/Loader";
import ModalBox from "../../components/modal/ModalBox";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { block_unblock_Api, fetchFormApi } from "../../services/form16A";
import Switch from "../../components/Switch/Switch";
import consultantPerformance from "../../Assests/image/consultantPerformance.svg";
import AddIcon from "@mui/icons-material/Add";
import AddForm from "./AddForm";
import EditIcon from "@mui/icons-material/Edit";
import EditForm from "./EditForm";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const { NOT_FOUND } = common;

const FORM = () => {
  const [buttonRef, buttonLoader] = useButtonLoader("Yes");
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  const {consultantId}=useParams();

  const [search, setSearch] = useState(
    getLocalStorage("formList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);
  const [total, setTotal] = useState(0);
  const [statusModal, setStatusModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState({});
  const [formId, setFormId] = useState({});
  const [formList, setFormList] = useState([]);
  const [eventId, setEventId] = useState({});
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const status = searchParam.get("status") || "";

  useEffect(() => {
    setLocalStorage("formList", "search", search);
  }, [search]);

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  const editHandler = (data) => {
    console.log(data,"Data");
    setSelectedForm(data);
    setEditModal(true);
  };
  //------------------request-listing-Api----------------------------------
  const getFormList = async (debounceValue) => {
    try {
      showLoader();
      const sendData = {
        page: `${activePage}`,
        limit: "10",
        consultantId:consultantId
      };
      if (debounceValue) {
        sendData.page = "1";
        sendData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      setFormList([]);
      const { data } = await fetchFormApi(sendData);
      if (data.code === 200) {
        setFormList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setFormList([]);
        setTotal(0);
        toastMessage(data.message, "error", "formList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // Change Form Status Handler
  const changeFormStatusHandler = async () => {
    console.log(formId,"FormId")
    try {
      buttonLoader(true);
      const { form16Id, status } = formId;
      const newStatus = Boolean(status);
      
      const res = await block_unblock_Api({
        form16Id: form16Id,
        status: newStatus,
      });
    //   console.log(res, "Response");
      if (res?.data?.code === 200) {
        toastMessage(res?.data?.message, "success", "form16Id");
        setStatusModal(false);
        setFormId({});
        getFormList();
      } else {
        toastMessage(res?.data?.message, "error", "form16Id");
        setFormId({});
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      buttonLoader(false);
    }
  };

  useEffect(() => {
    if (Number(activePage) === 1) {
      searchParam.delete("page");
    }
    if (!status) {
      searchParam.delete("status");
    }
    setSearchParam(searchParam);
    getFormList(debounceValue);
  }, [searchParam, debounceValue]);

  const formDownloadHandler = (form) => {
    window.open(form);
  };

  // console.log(form16Id,"form16Id");

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
          <div className="user_detail_heading">
            <Link className="back_icon" to={`/consultant-management/consultant-detail/${consultantId}`}>
              <IconButton>
                <ArrowBackIosNewIcon className="back_button" />
              </IconButton>
            </Link>
            <h4 className="Title">Form 16A</h4>
          </div>
            {/* <h4 className="Title"></h4> */}
            <div className="block_user_button">
              <button onClick={() => setAddModal(true)}>
                <AddIcon />
                Add Form 16A
              </button>
            </div>
          </div>
          <div className="FilterBox FilterArea">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className={`form-control ${search && "active"}`}
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">
                <IoSearch />
              </span>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Title</th>
                  <th>Uploaded File</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>
              <tbody>
                {formList.length > 0 ? (
                  formList.map((form, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          <td className="user_profile_image">
                            {form?.title || "---"}
                          </td>
                          <td
                            onClick={() => formDownloadHandler(form?.form16Pdf)}
                          >
                            <div>
                              <figure className="consultant-performance-icon">
                                <img
                                  src={consultantPerformance}
                                  alt="Consultant performance"
                                />
                              </figure>
                            </div>
                          </td>
                          <td>
                            {/* {form?.status === 1 && ( */}
                            <div className="action_button">
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() => editHandler(form)}
                              >
                                <EditIcon className="edit_icon" />
                              </IconButton>
                              <Switch
                                checked={form.status}
                                onChange={() => {
                                  setStatusModal(true);
                                  setFormId({
                                    form16Id: form._id,
                                    status:
                                      !form.status,
                                  });
                                }}
                              />
                            </div>
                        
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={8}
                    msg="Request not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total > 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/* Status Modal */}
      <ModalBox
        status={statusModal}
        onClose={() => {
          setStatusModal(false);
          setFormId({});
        }}
        title={"Change Status"}
        description={"Are you sure want to change status of this form?"}
        action={changeFormStatusHandler}
        ref={buttonRef}
      />

      {addModal && (
        <AddForm
          addModal={addModal}
          setAddModal={setAddModal}
          listing={getFormList}
          consultantId={consultantId}
        />
      )}

      {editModal && (
        <EditForm
          editModal={editModal}
          setEditModal={setEditModal}
          setSelectedForm={setSelectedForm}
          selectedForm={selectedForm}
          listing={getFormList}
        />
      )}
    </>
  );
};

export default FORM;
