import React, { useState } from "react";
import ModalBox from "../../../components/modal/ModalBox";
import { Input } from "../../../components/Form";
import useButtonLoader from "../../../hooks/UseButtonLoader";
import { regex, optimizeFunction, allowAlphaNumeric, allowAlphaNumericWithSpace } from "../../../utils/regex";
import { Avatar } from "@mui/material";
import { toastMessage } from "../../../utils/toastMessage";
import { AddCategoryApi } from "../../../services/categoryManagement";
import configData from "../../../config/configData";
import {
  addMusicApiHandler,
  updateMusicMediaApi,
} from "../../../services/musicManagement";
import commonMessages from "../../../utils/commonMessages";
import { checkImageDimensions } from "../../../utils/checkImageDimension";
import { convertSecToMin } from "../../../utils/convertSecToMin";
const { imageFileRegex, audioFileRegex } = regex;
const {
  MUSIC_TITLE,
  MUSIC_IMAGE,
  MUSIC,
  IMAGE_FORMAT,
  VALID_AUDIO,
  AUDIO_EXCEED_SIZE,
  EXCEED_SIZE,
  ERROR_AUDIO_LOADING,
} = commonMessages;

const EditMusic = ({
  show,
  close,
  musicDetail,
  setMusicDetail,
  musicListing,
}) => {
  // handle all state

  const [image, setImage] = useState("");
  const [music, setMusic] = useState("");
  const [error, setError] = useState({});
  const [updateMusic, setUpdateMusic] = useState({
    musicMediaId: musicDetail._id,
    title: musicDetail.title,
    music: musicDetail.musicMedia,
    image: musicDetail.musicImage,
    musicDuration: musicDetail.musicDuration,
  });

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader("Update");

  // handle all function

  // console.log("AddSubCategory",input)
  //----------vaidation------------
  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!updateMusic.title) {
      errorMessage["title"] = MUSIC_TITLE;
      status = false;
    }

    setError(errorMessage);
    return status;
  };

  // change handler
  //------------------onChange-input-value---------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "title") {
      updatedValue = allowAlphaNumericWithSpace(value);
    }
    setUpdateMusic({ ...updateMusic, [name]: updatedValue });
  };

  // Add Category image handler function

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        // setImage(e.target.files[0])
        const validSize = await checkImageDimensions(
          e.target.files[0],
          1920,
          1080
        );
        if (validSize) {
          setImage(e.target.files[0]);
        } else {
          e.target.value = "";
          setImage("");
        }
      } else {
        e.target.value = "";
        setImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setImage("");
    }
  };

  const resetInputAndMusic = (e) => {
    e.target.value = "";
    setMusic("");
  };

  const audioHandler = (e) => {
    e.preventDefault();

    const maxSizeInBytes = configData.audio_legth;
    const maxDurationInSeconds = configData.audio_duration;
    const file = e.target?.files[0];

    if (!file) {
      resetInputAndMusic(e);
      return;
    }

    if (!audioFileRegex.test(file.name)) {
      resetInputAndMusic(e);
      toastMessage(VALID_AUDIO, "error", "addAudio");
      return;
    }

    if (file.size > maxSizeInBytes) {
      resetInputAndMusic(e);
      toastMessage(EXCEED_SIZE, "error", "addAudio");
      return;
    }

    const audioElement = document.createElement("audio");
    audioElement.src = URL.createObjectURL(file);
    audioElement.onloadedmetadata = () => {
      if (audioElement.duration > maxDurationInSeconds) {
        resetInputAndMusic(e);
        toastMessage(AUDIO_EXCEED_SIZE, "error", "addAudio");
      } else {
        setMusic(file);
        setUpdateMusic({
          ...updateMusic,
          musicDuration: convertSecToMin(audioElement?.duration),
        });
      }
    };
    audioElement.onerror = () => {
      resetInputAndMusic(e);
      toastMessage(ERROR_AUDIO_LOADING, "error", "addAudio");
    };
  };

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setImage("");
    setMusic("");
    setMusicDetail(null);
    setUpdateMusic(null);
    setError({});
    close();
  };

  //--------------Add-sub-category------------------------
  const updateMusicHandler = async () => {
    const status = validation();
    if (status) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("musicMediaId", updateMusic.musicMediaId);
        formData.append("title", updateMusic.title);
        formData.append("musicDuration",updateMusic.musicDuration);
        if (music && music.name) {
          formData.append("musicMedia", music);
        }
        if (image && image?.name) {
          formData.append("musicImage", image);
        }
        const { data } = await updateMusicMediaApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addMusic");
          musicListing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addMusic");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={"Edit Music"}
      size={"sm"}
      closeIcon
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <p>
            Uploaded image will be resized to fit within: Width of 1920px and
            height of 1080px image must be in either the .jpg .jpeg or .png
            format.
          </p>
          <button
            className="btn_size"
            ref={buttonref}
            onClick={updateMusicHandler}
          >
            Update
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={updateMusic.title || ""}
            required
            onChange={(e) => handleChange(e)}
            placeholder="Music Title"
            className={`form-control`}
            label={"Music Title"}
            error={!updateMusic.title && error.title}
            maxLength={40}
          />
        </div>
        <div className="form-group my-2">
          <Input
            type="file"
            accept="audio/mp3"
            name="musicMedia"
            required
            onChange={(e) => audioHandler(e)}
            placeholder="Upload Music"
            className={`form-control `}
            label={"Upload Music"}
            // error={!music && error.music}
          />
        </div>

        {/* {music && ( */}
        <div className="music-player">
          <audio
            src={
              music && music.name
                ? URL.createObjectURL(music)
                : updateMusic.music
            }
            // autoPlay={true}
            controls
            controlsList="nodownload"
          />
        </div>
        {/* )} */}
        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="musicImage"
            // value={image.name || ''}
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Music Image"
            className={`form-control `}
            label={"Music Image"}
            // error={!image && error.image}
            // maxLength={30}
          />
        </div>
        <div>
          {/* {image && ( */}
          <>
            <Avatar
              variant="square"
              sx={{
                width: "100%",
                borderRadius: 1,
                height: 140,
                border: "1px solid #FF8989",
              }}
              src={
                image && image.name
                  ? URL.createObjectURL(image)
                  : updateMusic.image
              }
            />
          </>
          {/* )} */}
        </div>
      </div>
    </ModalBox>
  );
};

export default EditMusic;
