import React, { useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { Input } from "../../components/Form";
import { requestAcceptRejectApi } from "../../services/requestManagement";
import { toastMessage } from "../../utils/toastMessage";
import PhoneInput from "react-phone-input-2";
import { allowAlphaNumericWithSpace } from "../../utils/regex";

const RequestDetail = ({
  viewModal,
  setViewModal,
  requestId,
  getRequestList,
  requestDetail,
  newData,
  currentData,
  selectedRequestDetail,
  requestType,
}) => {
  // console.log(newData, "newData");
  // console.log(newData?.requestType, "REquest Type");
  // console.log(currentData, "CurrentData");
  const [buttonref, buttonLoader] = useButtonLoader("Save");
  const accept_reject_handler = async (data) => {
    try {
      buttonLoader(true);
      const res = await requestAcceptRejectApi({
        approveStatus: data,
        requestId: requestId.requestId,
      });
      if (res?.data?.code === 200) {
        toastMessage(res?.data?.message, "success", "acceptReject");
        setViewModal(false);
        getRequestList();
      } else {
        toastMessage(res?.data?.message, "error", "acceptReject");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      buttonLoader(false);
    }
  };

  return (
    <ModalBox
      status={viewModal}
      onClose={() => {
        setViewModal(false);
      }}
      title={`Update ${
        newData?.requestType === "PANCARD"
          ? "PAN"
          : newData?.requestType === "BANK"
          ? "Bank"
          : newData?.requestType === "PHONE"
          ? "Phone Number"
          : newData?.requestType === "FLAG"?"Flag":""
      }  Details`}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        // requestId?.approveStatus == 2 || requestId?.approveStatus==3 ? (
        requestId?.approveStatus == 1 ? (
          <div className=" model_custom_footer ">
            <button
              className="btn_size mx-4"
              // ref={buttonref}
              onClick={() => {
                accept_reject_handler(2);
              }}
            >
              Accept
            </button>
            <button
              className="btn_size mx-4"
              // ref={buttonref}
              onClick={() => {
                accept_reject_handler(3);
              }}
            >
              Reject
            </button>
          </div>
        ) : (
          <div></div>
        )
      }
    >
      {newData?.requestType === "BANK" && (
        <div className="row">
          <div className="col-6">
            <h6>Current Bank Details</h6>
          </div>
          <div className="col-6">
            {" "}
            <h6>New Bank Details</h6>
          </div>
          <div className="col-6 mt-4">
            {/* Current Account Number */}
            <div className="form-group mb-2">
              <Input
                type="text"
                name="currentAccountNumber"
                value={currentData?.accountNumber || ""}
                required
                placeholder="Enter Account Number"
                className={`form-control `}
                label={"Account Number"}
                disabled
                maxLength={20}
              />
            </div>
          </div>
          {/* New ACcount Number */}
          <div className="col-6 mt-4">
            <div className="form-group mb-2">
              <Input
                type="text"
                name="newAccountNumber"
                value={newData?.accountNumber || ""}
                required
                placeholder="Enter Account Number"
                className={`form-control `}
                label={"Account Number"}
                maxLength={20}
                disabled
              />
            </div>
          </div>

          {/* Current Bank Name */}
          <div className="col-6">
            <div className="form-group mb-2">
              <Input
                type="text"
                name="currentBankName"
                value={currentData?.bankName || ""}
                required
                placeholder="Enter Bank Name"
                className={`form-control `}
                label={"Bank Name"}
                maxLength={20}
                disabled
              />
            </div>
          </div>
          {/* New Bank Name */}
          <div className="col-6">
            <div className="form-group mb-2">
              <Input
                type="text"
                name="newBankName"
                value={newData?.bankName || ""}
                required
                placeholder="Enter Bank Name"
                className={`form-control `}
                label={"Bank Name"}
                maxLength={20}
                disabled
              />
            </div>
          </div>
          {/* IFSC */}
          <div className="col-6">
            <div className="form-group mb-2">
              <Input
                type="text"
                name="currentIfsc"
                value={currentData?.ifsc || ""}
                required
                placeholder="Enter IFSC"
                className={`form-control `}
                label={"IFSC"}
                maxLength={20}
                disabled
              />
            </div>
          </div>
          {/* IFSC */}
          <div className="col-6">
            <div className="form-group mb-2">
              <Input
                type="text"
                name="newIfsc"
                value={newData?.ifsc || ""}
                required
                placeholder="Enter IFSC"
                className={`form-control `}
                label={"IFSC"}
                maxLength={20}
                disabled
              />
            </div>
          </div>
          {/* Current Bank Holder Name */}
          <div className="col-6">
            <div className="form-group mb-2">
              <Input
                type="text"
                name="currentBankHolderName"
                value={currentData?.bankHolderName || ""}
                required
                placeholder="Enter Bank Holder Name"
                className={`form-control `}
                label={"Bank Holder Name"}
                maxLength={20}
                disabled
              />
            </div>
          </div>
          {/* New Bank Holder Name */}
          <div className="col-6">
            <div className="form-group mb-2">
              <Input
                type="text"
                name="newBankHolderName"
                value={newData?.bankHolderName || ""}
                required
                placeholder="Enter Bank Holder Name"
                className={`form-control `}
                label={"Bank Holder Name"}
                maxLength={20}
                disabled
              />
            </div>
          </div>
        </div>
      )}
      {newData?.requestType === "PHONE" && (
        <div className="row">
          <div className="col-6">
            <h6>Current Secondary Phone Details</h6>
            {/* <h6>Primary Phone Number</h6> */}
          </div>
          <div className="col-6">
            {" "}
            <h6>New Secondary Phone Details</h6>
            {/* <h6>Secondary Phone Number</h6> */}
          </div>
          <div className="col-6 mt-4">
            {/* Current Phone Number */}
            <div className="form-group cstM-tele_input mb-2 text-wrapper">
              <label className="my-1 text-dark">Mobile Number</label>
              <PhoneInput
                country={"in"}
                value={`${currentData?.countryCode}${currentData?.mobile}`}
                enableSearch={false}
                placeholder={"Enter Mobile Number"}
                countryCodeEditable={false}
                disabled={true}
              />
            </div>
          </div>
          {/* New Phone Number */}
          <div className="col-6 mt-4">
            <div className="form-group cstM-tele_input mb-2 text-wrapper">
              <label className="my-1 text-dark">Mobile Number</label>
              <PhoneInput
                country={"in"}
                value={`${newData?.countryCode}${newData?.mobile}`}
                enableSearch={false}
                placeholder={"Enter Mobile Number"}
                countryCodeEditable={false}
                disabled={true}
              />
            </div>
          </div>
        </div>
      )}

      {newData?.requestType === "PANCARD" && (
        <div className="row">
          <div className="col-6">
            <h6>Current PAN Details</h6>
          </div>
          <div className="col-6">
            {" "}
            <h6>New PAN Details</h6>
          </div>
          <div className="col-6 mt-4">
            {/* Current PAN Number */}
            <div className="form-group mb-2">
              <Input
                type="text"
                name="currentPanNumber"
                value={currentData?.panNumber || ""}
                className={`form-control `}
                label={"PAN Number"}
                disabled
              />
            </div>
          </div>
          <div className="col-6 mt-4">
            {/* New PAN Number */}
            <div className="form-group mb-2">
              <Input
                type="text"
                name="newPanNumber"
                value={newData?.panNumber || ""}
                className={`form-control `}
                label={"PAN Number"}
                disabled
              />
            </div>
          </div>

          <div className="col-6 mt-4">
            {/* Current PAN Image */}
            <div className="form-group mb-2">
              {currentData?.panImage && <img src={currentData.panImage} />}
            </div>
          </div>

          <div className="col-6 mt-4">
            {/* New PAN image */}
            <div className="form-group mb-2">
              {newData?.panImage && <img src={newData.panImage} />}
            </div>
          </div>
        </div>
      )}

      {newData?.requestType === "PRICE" && (
        <div className="row">
          <div className="col-6">
            <h6>Current Price </h6>
          </div>
          <div className="col-6">
            {" "}
            <h6>New Price</h6>
          </div>
          <div className="col-6 mt-4">
            {/* Current PAN Number */}
            <div className="form-group mb-2">
              <Input
                type="text"
                name="price"
                value={currentData?.price || ""}
                className={`form-control `}
                label={"Price"}
                disabled
              />
            </div>
          </div>
          <div className="col-6 mt-4">
            {/* New PAN Number */}
            <div className="form-group mb-2">
              <Input
                type="text"
                name="newPrice"
                value={newData?.price || ""}
                className={`form-control `}
                label={"Price"}
                disabled
              />
            </div>
          </div>
        </div>
      )}

      {/* Flag */}
      {newData?.requestType==="FLAG" && (
        <>
          <div className="form-group mb-2">
            <Input
              type="text"
              name="consultantName"
              value={currentData?.consName || "---"}
              className={`form-control`}
              label={"Consultant Name"}
              disabled
            />
          </div>
          <div className="form-group mb-2">
            <Input
              type="text"
              name="userName"
              value={currentData?.userName || "---"}
              className={`form-control`}
              label={"User Name"}
              disabled
            />
          </div>
          <div className="form-group mb-2">
            <Input
              type="text"
              name="review"
              value={currentData?.review}
              className={`form-control`}
              label={"Review"}
              disabled
            />
          </div>
          <div className="form-group my-2">
            <Input
              type="text"
              name="reason"
              value={currentData?.flagReason}
              disabled
              className={`form-control`}
              label={"Reason"}
            />
          </div>
        </>
      )}
    </ModalBox>
  );
};

export default RequestDetail;
