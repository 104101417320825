const AudioPreview = ({ musicUrl }) => {
  return (
    <div className="music-player">
      <audio
        src={musicUrl}
        // autoPlay={true}
        controls
        controlsList="nodownload"
      />
    </div>
  )
}
export default AudioPreview
