import React, { useMemo, useRef, useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import {
  allowAlphaNumericWithSpace,
  allowNumber,
  regex,
} from "../../utils/regex";
import commonMessages from "../../utils/commonMessages";
import { Avatar } from "@mui/material";
import { toastMessage } from "../../utils/toastMessage";
import { Input, Select, TextArea } from "../../components/Form";
import ReactDatePicker from "react-datepicker";
import { dateFormatUtc } from "../../utils/dateFormat";
import { addDiscountApi } from "../../services/discountManagement";
import useButtonLoader from "../../hooks/UseButtonLoader";
import JoditEditor from "jodit-react";

const AddMembership = ({
  addMembershipModal,
  setAddMembershipModal,
  fetchDiscountHandler,
  buttonType,
}) => {
  const [error, setError] = useState({});
  const [membershipField, setMembershipField] = useState({});
  const [image, setImage] = useState("");
  const { imageFileRegex } = regex;
  const { IMAGE_FORMAT } = commonMessages;
  const [buttonref, buttonLoader] = useButtonLoader("Save");

  const modalClose = () => {
    setError({});
    setImage("");
    setMembershipField({});
    setAddMembershipModal(false);
  };

  const validation = () => {
    let status = true;
    let error = {};

    if (!membershipField?.title) {
      error["title"] = "Memebership Name is required *";
      status = false;
    }
    // if (!membershipField?.details) {
    //   error["details"] = "Detail is required *";
    //   status = false;
    // }
    if (
      !membershipField?.details ||
      membershipField?.details === "<p><br></p>"
    ) {
      error["details"] = "Detail is required *";
      status = false;
    }
    if (!membershipField?.membershipPrice) {
      error["membershipPrice"] = "Price is required *";
      status = false;
    }
    if (!membershipField?.discountPercentage) {
      error["discountPercentage"] = "Discount Price is required *";
      status = false;
    }
    if (!membershipField?.planType) {
      error["planType"] = "Plan type is required *";
      status = false;
    }
    if (!membershipField?.membershipDiscountPrice) {
      error["membershipDiscountPrice"] = "Discount price is required *";
      status = false;
    }

    if (!image && !image?.name) {
      error["membershipDiscountImage"] = "Image is required *";
      status = false;
    }
    setError(error);
    return status;
  };

  // const imageHandler = async (e) => {
  //   e.preventDefault();
  //   if (e.target.files.length > 0) {
  //     if (imageFileRegex.test(e.target.files[0].name)) {
  //       setImage(e.target.files[0]);
  //     } else {
  //       e.target.value = "";
  //       setImage("");
  //       toastMessage(IMAGE_FORMAT, "error", "imageFormat");
  //     }
  //   } else {
  //     e.target.value = "";
  //     setImage("");
  //   }
  // };





  const imageHandler = async (e) => {
    e.preventDefault();
    const { files } = e.target;

    if (files.length === 0) {
      clearInputAndImageState(e);
      return;
    }

    const file = files[0];
    const fileName = file.name.toLowerCase();
    const isGif = fileName.endsWith(".gif");
    const isImage = imageFileRegex.test(fileName);


    if (isGif) {
      handleGif(file, e);
    } else if (isImage) {
      // setType("image");
      handleImage(file, e);
    }else {
      handleInvalidFileFormat(e);
    }
  };

  const clearInputAndImageState = (e) => {
    e.target.value = "";
    setImage("");
  };

  const handleGif = async (file, e) => {
      setImage(file);
  };
  

  const handleImage = async (file, e) => {
      setImage(file);
    
  };

  const handleInvalidFileFormat = (e) => {
    toastMessage(
      "Invalid format: Only Audio and images and videos supported",
      "error",
      "InvalidFormat"
    );
    clearInputAndImageState(e);
  };

  const addMembershipHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("title", membershipField.title || "");
        formData.append("type", buttonType);
        formData.append("details", membershipField.details || "");
        formData.append("membershipPrice", membershipField.membershipPrice);
        formData.append("membershipDiscountPrice", membershipField.membershipDiscountPrice);
        formData.append("planType", membershipField.planType);
        formData.append(
          "membershipDuration",
          1
        );
        
        formData.append("discountPercentage", membershipField.discountPercentage);

        if (image && image?.name) {
          formData.append("membershipDiscountImage", image);
        }
        const { data } = await addDiscountApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addCoupon");
          fetchDiscountHandler();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addCoupon");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  const editor = useRef(null);

  //   console.log("error", error);

  const config = useMemo(
    () => ({
      readonly: false,
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
      controls: {
        font: {
          command: "fontname",
          list: {
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
            "Consolas,monaco,monospace": "Consolas",
          },
        },
      },
    }),
    []
  );

  return (
    <ModalBox
      status={addMembershipModal}
      onClose={modalClose}
      title={"Add Membership"}
      size={"sm"}
      closeIcon
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <button
            className="btn_size"
            ref={buttonref}
            onClick={addMembershipHandler}
          >
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={membershipField.title || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowAlphaNumericWithSpace(e.target.value);
              setMembershipField({
                ...membershipField,
                ["title"]: sanitizedText,
              });
            }}
            placeholder="Membership Name"
            className={`form-control`}
            label={"Membership Name"}
            error={!membershipField.title && error.title}
            maxLength={30}
          />
        </div>
        <div className="form-group mb-2" id="myjoditEditor">
          <label className="my-1 text-dark">Detail</label>
          {/* <TextArea
            type="text"
            name="details"
            value={membershipField.details || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowAlphaNumericWithSpace(e.target.value);
              setMembershipField({
                ...membershipField,
                ["details"]: sanitizedText,
              });
            }}
            placeholder="Detail"
            className={`form-control`}
            label={"Detail"}
            error={!membershipField.details && error.details}
            maxLength={500}
          /> */}
          <JoditEditor
            ref={editor}
            value={membershipField.details}
            tabIndex={1}
            onBlur={(e) => {
              setMembershipField({
                ...membershipField,
                details: e,
              });
            }}
            config={config}
          />
          {error?.details && (
            <p
              className={`invalid-feedback ${
                !membershipField.details ||
                membershipField.details === "<p><br></p>"
              } && d-block`}
            >
              {error?.details}
            </p>
          )}
        </div>
        <div className="form-group mb-2">
        <Select
            name="planType"
            value={membershipField.planType}
            required
            onChange={(e) => {
              setMembershipField({
                ...membershipField,
                planType: e.target.value,
              });
            }}
            placeholder="Vastu Category Title"
            className={`form-control`}
            label={"Plan Type"}
            error={!membershipField.planType && error.planType}
            maxLength={40}
          >
            <option value="">Select Plan</option>
            <option value="GOLD">Gold</option>
            <option value="SILVER">Silver</option>
            <option value="OTHERS">Others</option>
            </Select>
            </div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="membershipPrice"
            value={membershipField.membershipPrice || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setMembershipField({
                ...membershipField,
                ["membershipPrice"]: sanitizedText,
              });
            }}
            placeholder="Price"
            className={`form-control`}
            label={"Membership Price"}
            error={!membershipField.membershipPrice && error.membershipPrice}
            maxLength={4}
          />
        </div>

        <div className="form-group mb-2">
          <Input
            type="text"
            name="membershipDiscountPrice"
            value={membershipField.membershipDiscountPrice || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setMembershipField({
                ...membershipField,
                ["membershipDiscountPrice"]: sanitizedText,
              });
            }}
            placeholder="Discount"
            className={`form-control`}
            label={" Membership Discount Price"}
            error={
              !membershipField.membershipDiscountPrice &&
              error.membershipDiscountPrice
            }
            maxLength={500}
          />
        </div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="discountPercentage"
            value={membershipField.discountPercentage || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setMembershipField({
                ...membershipField,
                ["discountPercentage"]: sanitizedText,
              });
            }}
            placeholder="Discount"
            className={`form-control`}
            label={" Membership Discount Percentage"}
            error={
              !membershipField.discountPercentage &&
              error.discountPercentage
            }
            maxLength={4}
          />
        </div>
        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="membershipDiscountImage"
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Image"
            className={`form-control `}
            label={"Image"}
            error={!image && error.membershipDiscountImage}
          />
        </div>

        <div>
          {image && (
            <>
              <Avatar
                variant="square"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  height: 140,
                  border: "1px solid #FF8989",
                }}
                src={image && URL.createObjectURL(image)}
              />
            </>
          )}
        </div>
      </div>
    </ModalBox>
  );
};

export default AddMembership;
