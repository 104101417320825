import { toastMessage } from './toastMessage'

import { io } from 'socket.io-client'

let socket

export function emitData(eventName, payload, liveList, setLiveList) {
  return new Promise((resolve, reject) => {
    socket.emit(eventName, payload)

    socket.on(eventName, (args) => {
      resolve(args)
      toastMessage('End live broadcast', 'success', 'endBroadcast')
      let tempArr = []

      liveList.forEach((it) => {
        if (it._id === payload.broadcastId) {
          tempArr.push({ ...it, status: 2 })
        } else {
          tempArr.push(it)
        }
      })
      setLiveList(tempArr)
    })

    socket.on('error', (error) => {
      reject(error)
      toastMessage(error, 'error', 'endBroadcast')
    })
  })
}

export function socketConnection() {
  socket = io('https://devnode.foodonreels.com:4990', {
    auth: {
      token: localStorage.getItem('vedic_token'),
      type: 'admin',
    },
  })

  socket.on('connect', () => {
    console.log('Connected to the socket server')
  })

  socket.on('connect_error', (error) => {
    console.error('Connection failed:', error)
  })
  return socket
}
