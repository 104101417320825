import React from 'react'
import ModalBox from '../../components/modal/ModalBox'

import useButtonLoader from '../../hooks/UseButtonLoader'
import { changeStatusGift } from '../../services/giftManagement'
import { toastMessage } from '../../utils/toastMessage'

const GiftStatus = ({ show, close, listing, status, id, setListing }) => {
  const [buttonref, buttonLoader] = useButtonLoader('Yes')

  //--------------change-gift-status------------------------
  const changeStatus = async () => {
    try {
      buttonLoader(true)
      let data = { status: !status, giftId: id }
      const res = await changeStatusGift(data)
      if (res.data.code === 200) {
      setListing((prevListing) => {
        return prevListing.map((gift) => {
          if (gift._id === id) {
            return { ...gift, status: !status }
          }
          return gift
        })
      })
      close()
      toastMessage(res.data.message, 'success', 'giftStatus')

      } else {
         toastMessage(res.data.message,'error','giftStatus')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      buttonLoader(false)
    }
  }
  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Change Gift Status"
      description="Do you want to change the status of this gift?"
      action={changeStatus}
      ref={buttonref}
    />
  )
}

export default GiftStatus
