import React, { useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import { Input, Select } from "../../components/Form";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { allowAlphaNumeric, allowAlphaNumericWithSpace, allowNumber } from "../../utils/regex";
import { toastMessage } from "../../utils/toastMessage";
import { AddConcernApi } from "../../services/concern/Index";
import { AddOfferApi } from "../../services/offersManagement";

const AddOffer = ({ show, close, listing }) => {
  // handle all state
  const [offerData, setOfferData] = useState({
    type:"OFFERS",
  });
  const [error, setError] = useState({});

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader("Save");

  //----------vaidation------------
  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!offerData?.title) {
      errorMessage["title"] = "Offer Data is mandatory";
      status = false;
    }
    if (!offerData?.couponDiscount) {
      errorMessage["couponDiscount"] = "Percentage is mandatory";
      status = false;
    }
    if (!offerData?.userType) {
      errorMessage["userType"] = "User Type is mandatory";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  //------------------modal-close--------------------------
  const modalClose = () => {
    setOfferData({});
    setError({});
    close();
  };

//   console.log(offerData,"Offer Data");
  //--------------Add-Music-category------------------------
  const addOfferHandler = async () => {
    const status = validation();
    if (status) {
      try {
        buttonLoader(true);
        const { data } = await AddOfferApi(offerData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addOffer");
          listing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addOffer");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      offerData={"Add Offer"}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <button
            className="btn_size"
            ref={buttonref}
            onClick={addOfferHandler}
          >
            Save
          </button>
        </div>
      }
      category
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={offerData?.title || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowAlphaNumericWithSpace(e.target.value);
              setOfferData({...offerData,title:sanitizedText});
            }}
            placeholder="Enter Title"
            className={`form-control`}
            label={"Title"}
            error={!offerData.title && error.title}
            maxLength={40}
          />
        </div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="couponDiscount"
            value={offerData?.couponDiscount || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setOfferData({ ...offerData, couponDiscount: sanitizedText });
            }}
            placeholder="Percentage"
            className={`form-control`}
            label={"Percentage"}
            error={!offerData?.couponDiscount && error.couponDiscount}
            maxLength={3}
          />
        </div>
        <div className="form-group mb-2">
          <Select
            name="userType"
            value={offerData.userType}
            required
            onChange={(e) => {
              setOfferData({
                ...offerData,
                userType: e.target.value,
              });
            }}
            // placeholder
            className={`form-control`}
            label={"User Type"}
            error={!offerData.userType && error.userType}
            maxLength={40}
          >
            <option value="">Select User Type</option>
            <option value="NEWUSERS">New User</option>
            <option value="LOYALUSERS">Loyal User</option>
          </Select>
        </div>
      </div>
    </ModalBox>
  );
};

export default AddOffer;
