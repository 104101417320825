import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = ({ status, type }) => {
  return (
    <Backdrop
      sx={{
        color: "#000",
        background: "#3a3a3a00",
        left: type ? "0" : "17rem",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={status}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;
