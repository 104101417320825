import React, { useState, useEffect } from "react";
import { CustomTab, CustomTabs } from "../../components/tabs/Tabs";
import { Link, useSearchParams } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import AddIcon from "@mui/icons-material/Add";
import Coins from "./section/coins&Transactions/Coins";
import common from "../../routes/common";
import Pagination from "react-js-pagination";
import { toastMessage } from "../../utils/toastMessage";
import scrollToUp from "../../utils/scrollToUp";
import { fetchSupportListApi } from "../../services/supportManagement";
import useDebounce from "../../hooks/useDebounce";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import Live from "./section/liveSession/Live";
import Meditation from "./section/vedicMeditation/Meditation";
import Consultant from "./section/consultant/Consultant";
import Remedy from "./section/remedy/Remedy";
import Vastu from "./section/vastu/Vastu";
import Technical from "./section/technical/Technical";
import AddSupportSection from "./section/AddSupportSection";
import EditSupportSection from "./section/EditSupportSection";
import DescriptionModel from "../../components/imagePreview/DescriptionModel";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IconButton } from "@mui/material";
import Community from "./section/Community/Community";
import Others from "./section/Others/Others";

const AddSupport = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  const [search, setSearch] = useState(
    getLocalStorage("supportListType", "search") || ""
  );
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const debounceValue = useDebounce(search || "", 300);
  const [supportList, setSupportList] = useState([]);
  const [total, setTotal] = useState(null);
  const userType = searchParam.get("userType") || "user";
  const supportType = searchParam.get("supportType") || "COINS";
  const [addSupport, setAddSupport] = useState({});
  const [addModal, setAddModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [titleModal, setTitleModal] = useState(false);

  const headingTitle = {
    COINS: "Add Coins",
    LIVE: "Add Live Session",
    MEDITATION: "Add Vedic Meditation",
    CONSULTANT: "Add Consultant",
    REMEDY: "Add Remedy",
    VASTU: "Add Vastu Compass",
    TECHNICAL: "Add Technical Support",
    COMMUNITY: "Add Community",
    OTHERS: "Others",
  }[supportType];

  useEffect(() => {
    setLocalStorage("supportListType", "search", search);
  }, [search]);

  const userTypeHandler = (value) => {
    if (userType === value) {
      searchParam.delete("userType");
    } else if (value === "cons") {
      searchParam.set("userType", value);
      searchParam.set("supportType", "TECHNICAL");
    } else {
      searchParam.set("userType", value);
    }
    searchParam.delete("page");
    setSearchParam(searchParam);
  };

  const supportTypeHandler = (type) => {
    if (type !== "TECHNICAL") {
      searchParam.set("supportType", type);
      searchParam.set("userType", "user");
    } else {
      searchParam.set("supportType", type);
    }
    setSearchParam(searchParam);
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  const getsupportList = async () => {
    try {
      showLoader();
      const sendData = {
        page: `${activePage}`,
        limit: "10",
        supportType: supportType,
        userType: userType,
      };
      if (debounceValue) {
        sendData.page = "1";
        sendData.search = search;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      setSupportList([]);
      console.log(sendData, "SendData");
      const { data } = await fetchSupportListApi(sendData);
      if (data.code === 200) {
        setSupportList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setSupportList([]);
        setTotal(0);
        toastMessage(data.message, "error", "supportList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getsupportList();
  }, [searchParam, debounceValue]);

  return (
    <div className="WrapperArea">
      <div className="WrapperBox">
        <div className="TitleBox">
          <div className="user_detail_heading">
            <Link className="back_icon" to={"/support-management"}>
              <IconButton>
                <ArrowBackIosNewIcon className="back_button" />
              </IconButton>
            </Link>
            <h4 className="Title">Add Support Category</h4>
          </div>
          <div className="block_user_button">
            <button onClick={() => setAddModal(true)}>
              <AddIcon /> {headingTitle}
            </button>
          </div>
        </div>
        <div className="banner_wrapper">
          <div className="FilterBox FilterArea ">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">{common.SEARCH_ICON}</span>
            </div>
          </div>
          <div className="banner_button_wrapper">
            <button
              className={`_button ${userType === "user" && "banner_active"}`}
              onClick={() => userTypeHandler("user")}
            >
              User
            </button>
            {/* {supportType === "TECHNICAL" && ( */}
            <button
              className={`_button ${userType === "cons" && "banner_active"}`}
              onClick={() => userTypeHandler("cons")}
            >
              Consultant
            </button>
            {/* )} */}
          </div>
        </div>

        <div
          className={`col-sm-${
            userType === "cons" ? "12" : "12"
          } col-form-label mx-auto mt-3`}
        >
          <CustomTabs
            value={supportType}
            onChange={(e, section) => {
              supportTypeHandler(section);
            }}
            aria-label="Content"
            variant="fullWidth"
          >
            <CustomTab value="COINS" label="Coins & Transaction" />
            <CustomTab value="LIVE" label="Live Session" />
            <CustomTab value="MEDITATION" label="Vedic Meditation" />
            <CustomTab value="CONSULTANT" label="Consultant" />
            <CustomTab value="REMEDY" label="Remedy" />
            <CustomTab value="VASTU" label="Vastu Compass" />
            <CustomTab value="TECHNICAL" label="Technical Support" />
            <CustomTab value="COMMUNITY" label="Community Support" />
            <CustomTab value="OTHERS" label="Others" />
          </CustomTabs>
        </div>

        <div className="row">
          {loader}
          {supportType === "COINS" && (
            <Coins
              // userType={userType}
              // supportType={supportType}
              supportList={supportList}
              setSupportList={setSupportList}
              activePage={activePage}
              editModal={editModal}
              setEditModal={setEditModal}
              setSelectedData={setSelectedData}
              setDescriptionModal={setDescriptionModal}
              setTitleModal={setTitleModal}
              setTitle={setTitle}
              setDescription={setDescription}
            />
          )}
          {supportType === "LIVE" && (
            <Live
              // userType={userType}
              // supportType={supportType}
              supportList={supportList}
              setSupportList={setSupportList}
              activePage={activePage}
              editModal={editModal}
              setEditModal={setEditModal}
              setSelectedData={setSelectedData}
              setDescriptionModal={setDescriptionModal}
              setTitleModal={setTitleModal}
              setTitle={setTitle}
              setDescription={setDescription}
            />
          )}
          {supportType === "MEDITATION" && (
            <Meditation
              // userType={userType}
              // supportType={supportType}
              supportList={supportList}
              setSupportList={setSupportList}
              activePage={activePage}
              editModal={editModal}
              setEditModal={setEditModal}
              setSelectedData={setSelectedData}
              setDescriptionModal={setDescriptionModal}
              setTitleModal={setTitleModal}
              setTitle={setTitle}
              setDescription={setDescription}
            />
          )}
          {supportType === "CONSULTANT" && (
            <Consultant
              // userType={userType}
              // supportType={supportType}
              supportList={supportList}
              setSupportList={setSupportList}
              activePage={activePage}
              editModal={editModal}
              setEditModal={setEditModal}
              setSelectedData={setSelectedData}
              setDescriptionModal={setDescriptionModal}
              setTitleModal={setTitleModal}
              setTitle={setTitle}
              setDescription={setDescription}
            />
          )}
          {supportType === "REMEDY" && (
            <Remedy
              // userType={userType}
              // supportType={supportType}
              supportList={supportList}
              setSupportList={setSupportList}
              activePage={activePage}
              editModal={editModal}
              setEditModal={setEditModal}
              setSelectedData={setSelectedData}
              setDescriptionModal={setDescriptionModal}
              setTitleModal={setTitleModal}
              setTitle={setTitle}
              setDescription={setDescription}
            />
          )}
          {supportType === "VASTU" && (
            <Vastu
              // userType={userType}
              // supportType={supportType}
              supportList={supportList}
              setSupportList={setSupportList}
              activePage={activePage}
              editModal={editModal}
              setEditModal={setEditModal}
              setSelectedData={setSelectedData}
              setDescriptionModal={setDescriptionModal}
              setTitleModal={setTitleModal}
              setTitle={setTitle}
              setDescription={setDescription}
            />
          )}
          {supportType === "TECHNICAL" && (
            <Technical
              // userType={userType}
              // supportType={supportType}
              supportList={supportList}
              setSupportList={setSupportList}
              activePage={activePage}
              editModal={editModal}
              setEditModal={setEditModal}
              setSelectedData={setSelectedData}
              setDescriptionModal={setDescriptionModal}
              setTitleModal={setTitleModal}
              setTitle={setTitle}
              setDescription={setDescription}
            />
          )}
          {supportType === "COMMUNITY" && (
            <Community
              // userType={userType}
              // supportType={supportType}
              supportList={supportList}
              setSupportList={setSupportList}
              activePage={activePage}
              editModal={editModal}
              setEditModal={setEditModal}
              setSelectedData={setSelectedData}
              setDescriptionModal={setDescriptionModal}
              setTitleModal={setTitleModal}
              setTitle={setTitle}
              setDescription={setDescription}
            />
          )}
          {supportType === "OTHERS" && (
            <Others
              // userType={userType}
              // supportType={supportType}
              supportList={supportList}
              setSupportList={setSupportList}
              activePage={activePage}
              editModal={editModal}
              setEditModal={setEditModal}
              setSelectedData={setSelectedData}
              setDescriptionModal={setDescriptionModal}
              setTitleModal={setTitleModal}
              setTitle={setTitle}
              setDescription={setDescription}
            />
          )}
        </div>

        {total > 10 ? (
          <div className="pagination-wrapper">
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                previousLabel={"previous"}
                nextLabel={"next"}
                itemsCountPerPage={10}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                hideDisabled={true}
              />
            </div>
          </div>
        ) : null}

        <AddSupportSection
          addSupport={addSupport}
          setAddSupport={setAddSupport}
          addModal={addModal}
          setAddModal={setAddModal}
          getsupportList={getsupportList}
          headingTitle={headingTitle}
          userType={userType}
          supportType={supportType}
        />
        <EditSupportSection
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          editModal={editModal}
          setEditModal={setEditModal}
          getsupportList={getsupportList}
          headingTitle={headingTitle}
          // userType={userType}
          // supportType={supportType}
        />

        {descriptionModal && (
          <DescriptionModel
            show={descriptionModal}
            close={() => {
              setDescriptionModal(false);
              setDescription("");
            }}
            description={description}
            title={"Description"}
          />
        )}

        {titleModal && (
          <DescriptionModel
            show={titleModal}
            close={() => {
              setTitleModal(false);
              setTitle("");
            }}
            description={title}
            title={"Question"}
          />
        )}
      </div>
    </div>
  );
};

export default AddSupport;
