import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// ---------------------Shopify Discount Coupon listing--------------------------
export const fetchShopifyDiscountCouponApi = async (data) => {
  const endPoint =
    `/admin/consultant/shopify/discount_coupon` +
    "?" +
    new URLSearchParams({ ...data }).toString();

  return await axios.get(endPoint, setJwtToken());
};

//--------------------------Add Discount Coupon---------------------
export const addShopifyDiscountCouponApi = async (reqData) => {
  const endPoint = `/admin/consultant/shopify/discount_coupon/add`;
  return await axios.post(endPoint, reqData, setJwtToken());
};

//--------------------------update Discount Coupon ---------------------
export const updateDiscountCouponApi = async (reqData) => {
  const endPoint = `/admin/consultant/shopify/discount_coupon/update`;
  return await axios.put(endPoint, reqData, setJwtToken());
};

//--------------------------Delete Discount Coupon ---------------------
export const deleteDiscountCouponApi = async (data) => {
  const endPoint =
    `/admin/consultant/shopify/discount_coupon/delete` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.delete(endPoint, setJwtToken());
};

//  Change Discount Coupon Status
export const discount_block_unblock_Api = async (data) => {
  const endPoint = `/admin/consultant/shopify/discount_coupon/status`;
  return await axios.put(endPoint, data, setJwtToken());
};

// Shopify Order List
export const fetchShopifyOrderListApi = async (data) => {
  const endPoint =
    `/admin/consultant/shopify/order` +
    "?" +
    new URLSearchParams({ ...data }).toString();

  return await axios.get(endPoint, setJwtToken());
};

// Shopify Commission
export const fetchShopifyCommissionListApi = async () => {
  const endPoint = `/admin/consultant/shopify/order/commission`;
  return await axios.get(endPoint, setJwtToken());
};
