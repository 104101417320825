import EditIcon from '@mui/icons-material/Edit'
import {
  Avatar,
  IconButton
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Switch from '../../components/Switch/Switch'
import NotFound from '../../components/not-found/NotFound'
import useFullPageLoader from '../../hooks/useFullPageLoader'
import common from '../../routes/common'
import { fetchBannerApi } from '../../services/bannerManagement'
import { charLimit } from '../../utils/charLimit'
import findSerialNumber from '../../utils/findSerialNumber'
import { toastMessage } from '../../utils/toastMessage'
import AddBanner from './AddBanner'
import BannerStatus from './BannerStatus'
import EditBanner from './EditBanner'

import AddIcon from '@mui/icons-material/Add'
import Pagination from 'react-js-pagination'
import ImagePreviewModel from '../../components/imagePreview/ImagePreviewModel'
import scrollToUp from '../../utils/scrollToUp'
// import common from '../../routes/common'
const { NOT_FOUND } = common

const BannerList = () => {
  // handle all state
  const [searchParam, setSearchParam] = useSearchParams()
  let activePage = +searchParam.get('page') || 1
  let limit = +searchParam.get('limit') || 5
  let buttonKey = searchParam.get('type') || 'user'
  const [addBannerModel, setAddBannerModel] = useState(false)
  const [total, setTotal] = useState(0)
  const [statusModal, setStatusModal] = useState(false)
  const [statusValue, setStatusValue] = useState('')
  const [statusId, setStatusId] = useState('')
  const [updateModal, setUpdateModal] = useState(false)
  // const [buttonKey, setButtonKey] = useState('user')
  const [bannerList, setBannerList] = useState([])
  const [selectedBannerDetail, setSelectedBannerDetail] = useState(null)
  const [showImagePreviewModel, setShowImagePreviewModel] = useState(false)
  const [imagePreview, setImagePreview] = useState('')
  const [imageTitle, setImageTitle] = useState('')


  // handle all hooks

  const [loader, showLoader, hideLoader] = useFullPageLoader()

  // handle all function

  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true)
    setStatusId(id)
    setStatusValue(status)
  }
  // update banner handler
  const updateBannerHandler = (data) => {
    setSelectedBannerDetail(data || null)
    setUpdateModal(true)
  }

  // banner type handler

  const bannerTypeHandler = (type) => {
    searchParam.set('type', type)
    searchParam.set('page', 1)
    setSearchParam(searchParam)
  }

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set('page', event)
    setSearchParam(searchParam)
  }

  // limit handler

  const limitHandler = (value) => {
    searchParam.set('limit', value)
    searchParam.set('page', 1)
    setSearchParam(searchParam)
  }

  const showImagePreviewHandler = (banner) => {
    setShowImagePreviewModel(true)
    setImagePreview(banner.bannerImage)
    setImageTitle(banner.title)
  }

  // close model

  const closeModel = () => {
    setStatusModal(false)
    setUpdateModal(false)
    setAddBannerModel(false)
    setShowImagePreviewModel(false)
    setImagePreview('')
    setImageTitle('')
    setStatusValue('')
    setStatusId('')
  }

  // //------------------Banner - listing-Api----------------------------------
  const fetchBannerist = async (userType) => {
    try {
      setBannerList([])
      showLoader()
      const sendData = {
        page: activePage,
        limit: limit,
        userType: userType,
      }
      const { data } = await fetchBannerApi(sendData)
      if (data.code === 200) {
        setBannerList(data?.result?.list || [])
        setTotal(data?.result?.total || 0)
        scrollToUp()
      } else {
        setBannerList([])
        setTotal(0)
        toastMessage(data.message, 'error', 'bannerList')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      hideLoader()
    }
  }

  useEffect(() => {
    // if (debounceValue) {
    //   searchParam.set('query', debounceValue)
    // } else {
    //   searchParam.delete('query')
    // }
    if (activePage === 1) {
      searchParam.delete('page')
    }
    setSearchParam(searchParam)
    fetchBannerist(buttonKey)
  }, [searchParam])

  // useEffect(() => {
  //   fetchBannerist(buttonKey)
  // }, [buttonKey])
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Banner's Management</h4>
            <div className="block_user_button">
              <button onClick={() => setAddBannerModel(true)}>
                <AddIcon/>Add Banner
              </button>
            </div>
          </div>
          <div className="banner_wrapper">
            <h5>{buttonKey === 'user' ? 'User' : 'Consultant'}'s Banners</h5>
            <div className="banner_button_wrapper">
              <button
                className={`_button ${buttonKey === 'user' && 'banner_active'}`}
                onClick={() => bannerTypeHandler('user')}
              >
                User
              </button>
              <button
                className={`_button ${buttonKey === 'cons' && 'banner_active'}`}
                onClick={() => bannerTypeHandler('cons')}
              >
                Consultant
              </button>
            </div>
          </div>

          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Baner Name</th>
                  <th>Category</th>
                  <th>Banner Image</th>
                  {/* <th>Banner Id</th> */}
                  <th className="text-center"> Action </th>
                </tr>
              </thead>

              <tbody>
                {bannerList && bannerList.length > 0 ? (
                  bannerList.map((banner, index) => {
                    return (
                      <React.Fragment key={banner._id}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, limit)}</td>
                          <td>{charLimit(banner.title, 25) || '---'}</td>
                          {/* <td>{charLimit(banner.category, 30) || 'N/A'}</td> */}
                          <td>
                            {charLimit(banner?.categoryId.title, 25) || '---'}
                          </td>
                          <td>
                            <Avatar
                              variant="square"
                              id={banner._id}
                              alt={banner.title}
                              src={banner.bannerImage}
                              sx={{
                                width: 50,
                                height: 50,
                                borderRadius: 3,
                                cursor: 'pointer',
                              }}
                              onClick={() => showImagePreviewHandler(banner)}
                            />
                          </td>
                          {/* <td>{banner.id || '---'}</td> */}
                          <td>
                            <div className="action_button">
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() => updateBannerHandler(banner)}
                              >
                                <EditIcon className="edit_icon" />
                              </IconButton>
                              <Switch
                                checked={banner.status}
                                onChange={() =>
                                  statusUpdateHandler(banner._id, banner.status)
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })
                ) : (
                  <NotFound
                    cols={7}
                    msg="Banner not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>

          {total > limit && (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  itemsCountPerPage={limit}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          )}

          {/* <FloatingButton
            onClick={() => setAddModalShow(true)}
            icon={<AddIcon />}
          /> */}
        </div>
        {addBannerModel && (
          <AddBanner
            show={addBannerModel}
            close={closeModel}
            userType={buttonKey}
            bannerList={() => fetchBannerist(buttonKey)}
            // listing={fatchSubCategory}
          />
        )}
        {statusModal && (
          <BannerStatus
            show={statusModal}
            close={closeModel}
            setListing={setBannerList}
            status={statusValue}
            id={statusId}
          />
        )}
        {updateModal && (
          <EditBanner
            show={updateModal}
            close={closeModel}
            bannerDetail={selectedBannerDetail}
            bannerList={() => fetchBannerist(buttonKey)}
            setBannerDetail={setSelectedBannerDetail}
          />
        )}
        {showImagePreviewModel && (
          <ImagePreviewModel
            show={showImagePreviewModel}
            close={closeModel}
            imageUrl={imagePreview}
            title={imageTitle}
          />
        )}
      </div>
    </>
  )
}

export default BannerList
