import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { Avatar, IconButton } from "@mui/material";
import Switch from "../../components/Switch/Switch";
import NotFound from "../../components/not-found/NotFound";
import Pagination from "react-js-pagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { category, explore, music } from "../../routes/route";
import common from "../../routes/common";
import { toastMessage } from "../../utils/toastMessage";
import findSerialNumber from "../../utils/findSerialNumber";
import { charLimit } from "../../utils/charLimit";
import { fetchMusicApi } from "../../services/musicManagement";
import EditIcon from "@mui/icons-material/Edit";
// import AddMusic from "./AddMusicCategory";
// import showFullSizeImage from "../../utils/showFullSizeImage";
// import MusicCategoryStatus from "./MusicCategoryStatus";
// import EditMusicCategory from "./EditMusicCategory";
import scrollToUp from "../../utils/scrollToUp";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import ImagePreviewModel from "../../components/imagePreview/ImagePreviewModel";
import { fetchExploreApi } from "../../services/exploreManagement";
import ExploreStatus from "./ExploreStatus";
import AddStories from "./AddStories";
import EditStories from "./EditStories";
import AddIcon from "@mui/icons-material/Add";
import DescriptionModel from "../../components/imagePreview/DescriptionModel";
// import VideoThumbnail from 'react-video-thumbnail'
import { regex } from "../../utils/regex";
import VideoThumbnail from "../../comman/VideoThumbnails";
import videoLogo from "../../Assests/image/videoLogo.png";
const { NOT_FOUND } = common;
const { EXPLORE_DETAIL } = explore;

const ExploreManagement = () => {
  // handle all state
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  // const [search, setSearch] = useState(searchParam.get('query') || '')
  const [search, setSearch] = useState(
    getLocalStorage("exploreList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [descriptionModel, setDescriptionModel] = useState(false);
  const [description, setDescription] = useState("");
  const [exploreList, setExploreList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [statusModal, setStatusModal] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [statusId, setStatusId] = useState("");
  const [selectedExploreDetail, setSelectedExploreDetail] = useState(null);
  const [showImagePreviewModel, setShowImagePreviewModel] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [imageTitle, setImageTitle] = useState("");
  const { imageFileRegex, videoFileRegex } = regex;
  const [meditationVideo, setMeditationVideo] = useState({});

  // Function to set localStorage values whenever the state variables change
  useEffect(() => {
    setLocalStorage("exploreList", "search", search);
  }, [search]);
  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true);
    setStatusId(id);
    setStatusValue(status);
  };

  // update music category handler
  const updateExploreHandler = (data) => {
    setSelectedExploreDetail(data || null);
    setEditModal(true);
  };

  //*********Handel Block********* */
  // const blockHandler = (id, status) => {
  //     setBlockModal(true);
  //     setBlockId(id);
  //     // setStatusValue(status);
  // };
  //*********Handle update********* */
  // const updateHandler = (id, enName, arName, imageUrl, categoryId) => {
  //   setUpdateModal(true)
  //   setUpdateSubCategoryData({
  //     id,
  //     enName,
  //     arName,
  //     imageUrl,
  //     categoryId,
  //   })
  // }
  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  const showImagePreviewHandler = (explore) => {
    setShowImagePreviewModel(true);
    setImageTitle(explore.title);
    if (explore.media.type === "video") {
      setImagePreview(videoLogo);
    } else {
      setImagePreview(explore.media.url);
    }
  };

  // close model

  const closeModel = () => {
    setSelectedExploreDetail(null);
    setStatusValue("");
    setStatusId("");
    setAddModal(false);
    setStatusModal(false);
    setEditModal(false);
    setImagePreview("");
    setImageTitle("");
    setShowImagePreviewModel(false);
  };

  // //------------------listing-music-Api----------------------------------
  const fetchExploreHandler = async () => {
    try {
      setExploreList([]);
      showLoader();
      const sendData = {
        page: activePage,
        limit: 10,
      };
      if (debounceValue) {
        sendData.page = 1;
        sendData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      const { data } = await fetchExploreApi(sendData);
      if (data.code === 200) {
        setExploreList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setExploreList([]);
        setTotal(0);
        toastMessage(data.message, "error", "exploreList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // useEffect(() => {
  //   if (debounceValue) {
  //     searchParam.set('query', debounceValue)
  //   } else {
  //     searchParam.delete('query')
  //   }
  //   if (activePage === 1) {
  //     searchParam.delete('page')
  //   }
  //   setSearchParam(searchParam)
  //   fetchCategoryHandler(activePage, debounceValue)
  // }, [searchParam, debounceValue])
  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete("page");
    }
    setSearchParam(searchParam);
    fetchExploreHandler();
  }, [searchParam, debounceValue]);
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Explore Management</h4>
            <div className="block_user_button">
              <button onClick={() => setAddModal(true)}>
                <AddIcon />
                Add Stories
              </button>
            </div>
          </div>
          <div className="FilterBox FilterArea ">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">{common.SEARCH_ICON}</span>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Title</th>
                  <th>Media</th>
                  <th>Description</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>

              <tbody>
                {exploreList.length > 0 ? (
                  exploreList.map((explore, index) => {
                    return (
                      <React.Fragment key={explore._id}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          {/* <td>{explore._id || '---'}</td> */}
                          <td>{charLimit(explore.title, 30) || "---"}</td>
                          <td>
                            <Avatar
                              id={explore._id}
                              alt={explore.title}
                              // src={
                              //   explore?.media?.type === "video"
                              //     ? (explore?.videoThumbnail || videoLogo)
                              //     : explore?.media?.url || ""
                              // }
                              src={
                                explore?.media?.type === "video"
                                  ? (videoLogo)
                                  : explore?.media?.url || ""
                              }
                              sx={{
                                width: 50,
                                height: 50,
                                cursor: "pointer",
                                borderRadius: 2,
                              }}
                              onClick={() => showImagePreviewHandler(explore)}
                            />
                          </td>

                          <td
                            onClick={() => {
                              if (
                                explore.description &&
                                explore.description.length > 50
                              ) {
                                setDescriptionModel(true);
                                setDescription(explore.description);
                              }
                            }}
                          >
                            {explore?.description &&
                              (explore.description.length > 50
                                ? charLimit(explore.description, 50)
                                : explore.description || "---")}
                          </td>

                          <td>
                            <div className="action_button">
                              <Link to={`${EXPLORE_DETAIL}/${explore._id}`}>
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                >
                                  <VisibilityIcon className="eye_icon" />
                                </IconButton>
                              </Link>
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() => updateExploreHandler(explore)}
                              >
                                <EditIcon className="edit_icon" />
                              </IconButton>
                              <Switch
                                checked={explore.status}
                                onChange={() =>
                                  statusUpdateHandler(
                                    explore._id,
                                    explore.status
                                  )
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={7}
                    msg="Explore not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total >= 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}
        </div>
        {addModal && (
          <AddStories
            show={addModal}
            close={closeModel}
            listing={fetchExploreHandler}
          />
        )}
        {statusModal && (
          <ExploreStatus
            show={statusModal}
            close={closeModel}
            setListing={setExploreList}
            status={statusValue}
            id={statusId}
          />
        )}
        {editModal && (
          <EditStories
            show={editModal}
            close={closeModel}
            selectedExploreDetail={selectedExploreDetail}
            setSelectedExploreDetail={setSelectedExploreDetail}
            fetchExploreHandler={fetchExploreHandler}
          />
        )}
        {showImagePreviewModel && (
          <ImagePreviewModel
            show={showImagePreviewModel}
            close={closeModel}
            imageUrl={imagePreview}
            title={imageTitle}
          />
        )}
        {descriptionModel && (
          <DescriptionModel
            show={descriptionModel}
            close={() => {
              setDescriptionModel(false);
            }}
            description={description}
            title={"Description"}
          />
        )}
      </div>
    </>
  );
};

export default ExploreManagement;
