import React, { useState } from "react";
import ModalBox from "../../../../components/modal/ModalBox";
import { Input, TextArea } from "../../../../components/Form";
import { optimizeFunction } from "../../../../utils/regex";
import useButtonLoader from "../../../../hooks/UseButtonLoader";
import { toastMessage } from "../../../../utils/toastMessage";
import { addContentApi } from "../../../../services/cms";

const AddLanguage = ({
  setAddModal,
  addModal,
  getCmsDetails,
  userType,
  type,
}) => {
  const [buttonref, buttonLoader] = useButtonLoader("Save");
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");

  //   console.log(userType,type);

  const modalClose = () => {
    setError("");
    setAddModal(false);
  };

  const validation = () => {
    let status = true;
    if (!title) {
      setError("Language is mandatory");
      status = false;
    }
    return status;
  };

  const addLanguageHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true);
        const formData=new FormData();
        formData.append("title",title);
        formData.append("contentType",type);
        formData.append("userType",userType);
        const { data } = await addContentApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addLanguage");
          setTitle("");
          setError("");
          getCmsDetails();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addLanguage");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };
  return (
    <ModalBox
      status={addModal}
      onClose={modalClose}
      title={"Add Language"}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        <button className="btn_size" ref={buttonref} onClick={addLanguageHandler}>
          Save
        </button>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={title || ""}
            required
            onChange={(e) => {
              const sanitizedText = optimizeFunction(e.target.value);
              setTitle(sanitizedText);
            }}
            placeholder="Language"
            className={`form-control`}
            label={"Language"}
            error={!title && error}
            maxLength={40}
          />
        </div>
      </div>
    </ModalBox>
  );
};

export default AddLanguage;
