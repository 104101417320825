import moment from "moment-timezone";

const getTimezone = () => {
  try {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return timeZone;
  } catch (error) {
    console.error("Error retrieving timezone:", error);
  }
};

export const dateFormat = (date) => {
  const timezone = getTimezone();
  return date ? moment(date).tz(timezone).format("DD MMM YYYY") : "";
};

// Adjust the path to where your getTimezone function is located
export const monthFormat = (date) => {
  const timezone = getTimezone();
  return date ? moment(date).tz(timezone).format("MM/YYYY") : "";
};

// In year month Date format
export const dateFormatYear = (date) => {
  const timezone = getTimezone();
  return date ? moment(date).tz(timezone).format("YYYY MM DD") : "";
};

// in utc

export const dateFormatUtc = (date) => {
  const timezone = getTimezone();
  return date
    ? moment(date).tz(timezone).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    : "";
};

export const dateFormatWithTime = (date) => {
  const timezone = getTimezone();
  return date ? moment(date).tz(timezone).format("DD MMM YYYY, hh:mm A") : "";
};

export const timeFormat = (time) => {
  const timezone = getTimezone();
  return time ? moment(time).tz(timezone).format("hh:mm A") : "";
};


export const formatToTwoDecimalPoints = (num=0) => {
  console.log("number",num)
  console.log(parsedNum,"ParsedNum");
  const parsedNum = parseFloat(num);
  // console.log(typeof parsedNum,"Parsed Num");
  if (isNaN(parsedNum)) {
    throw new Error('Input must be a number');
  }
  return +parsedNum.toFixed(2);
};
