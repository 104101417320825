import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { Avatar, IconButton } from "@mui/material";
import Switch from "../../components/Switch/Switch";
import NotFound from "../../components/not-found/NotFound";
import Pagination from "react-js-pagination";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { category } from "../../routes/route";
import common from "../../routes/common";
// import AddCategory from './AddCategory'
import { fatchCategoryApi } from "../../services/categoryManagement";
import { toastMessage } from "../../utils/toastMessage";
import findSerialNumber from "../../utils/findSerialNumber";
import { charLimit } from "../../utils/charLimit";
import { fetchGiftApi } from "../../services/giftManagement";
import EditIcon from "@mui/icons-material/Edit";
import { fetchReferApi, referApproveApi } from "../../services/refer";
import { dateFormat, dateFormatUtc } from "../../utils/dateFormat";
import useButtonLoader from "../../hooks/UseButtonLoader";
import ModalBox from "../../components/modal/ModalBox";
import { DatePicker } from "../../components/Form";
// import GiftStatus from "./GiftStatus";
// import AddGift from "./AddGift";
// import EditGift from "./EditGift";
// import CategoryStatus from './CategoryStatus'
const { ADD_MUSIC } = category;
const { NOT_FOUND } = common;

const ReferManagement = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  let buttonKey = searchParam.get("type") || "cons";
  const [search, setSearch] = useState(searchParam.get("query") || "");
  const debounceValue = useDebounce(search || "", 300);
  const [addGiftModal, setAddGiftModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [referList, setReferList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [approveModal, setApproveModal] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [referId, setReferId] = useState("");
  const [selectedGift, setSelectedGift] = useState({});
  const [buttonLoader, setButtonLoader] = useButtonLoader("Yes");
  const referalDate = searchParam.get("referalDate") || "";

  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setApproveModal(true);
    setReferId(id);
    setStatusValue(status);
  };

  //   ********Edit data*************//
  const editHandler = (data) => {
    setSelectedGift(data);
    setEditModal(true);
  };

  const bannerTypeHandler = (type) => {
    searchParam.set("type", type);
    searchParam.set("page", 1);
    setSearchParam(searchParam);
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  // close model
  const closeModel = () => {
    setStatusValue("");
    setReferId("");
    setApproveModal(false);
  };

  const handleReferralDate = (date) => {
    const utcDateString = dateFormatUtc(date);
    searchParam.delete("page");
    searchParam.set("referalDate", utcDateString);
    setSearchParam(searchParam);
  };

  // //------------------Refer-Listing-Api----------------------------------
  const fetchReferHandler = async () => {
    try {
      const sendData = {};
      setReferList([]);
      showLoader();
      //   sendData.limit = "1";
      sendData.page = activePage;
      sendData.userType = buttonKey;
      if (referalDate) {
        sendData.referalDate = referalDate;
      }
      setSearchParam(searchParam);
      const res = await fetchReferApi(sendData);
      if (res.data.code === 200) {
        setReferList(res?.data?.result?.list || []);
        setTotal(res?.data?.result?.total || 0);
      } else {
        setReferList([]);
        setTotal(0);
        toastMessage(res.data.message, "error", "referList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // -----------------refer approve handler------------------------
  const referApproveHandler = async () => {
    if (referId) {
      try {
        setButtonLoader(true);
        const res = await referApproveApi({ referId: referId });
        if (res?.data?.code === 200) {
          toastMessage(res?.data?.message, "success", "aproveRetailer");
          fetchReferHandler();
          setApproveModal(false);
        } else {
          toastMessage(res?.data?.message, "error", "aproveRetailer");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setButtonLoader(false);
      }
    }
  };

  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete("page");
    }
    setSearchParam(searchParam);

    fetchReferHandler(activePage);
  }, [searchParam]);

  useEffect(() => {
    fetchReferHandler();
  }, []);

  // console.log(referList, "ReferList");
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Refer Management</h4>
            <div className="block_user_button">
              {/* <button onClick={() => setAddGiftModal(true)}>Add Gift</button> */}
            </div>
          </div>
          <div className="group_filter_value"></div>
          <div className="banner_wrapper">
            <h5>{buttonKey === "user" ? "User" : "Consultant"}'s Refer</h5>

            <div className="banner_button_wrapper">
              <div className="date_picker_wrap _button">
                <DatePicker
                  className={`form-control ${referalDate && "active"}`}
                  name="startDate"
                  selected={referalDate}
                  placeholder="Registration Date"
                  onChange={(date) => handleReferralDate(date)}
                  isClearable
                  maxDate={Date.now()}
                  

                />
              </div>
              {/* <button
                className={`_button ${buttonKey === "user" && "banner_active"}`}
                onClick={() => bannerTypeHandler("user")}
              >
                User
              </button> */}
              {/* <button
                className={`_button ${buttonKey === "cons" && "banner_active"}`}
                onClick={() => bannerTypeHandler("cons")}
              >
                Consultant
              </button> */}
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Referral Date</th>
                  <th>Referred from</th>
                  <th>Referred To</th>
                  <th>Referral Code</th>
                  <th>Points</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>

              <tbody>
                {referList.length > 0 ? (
                  referList.map((refer, index) => {
                    return (
                      <React.Fragment key={refer._id}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          <td>{dateFormat(refer.createdAt) || "---"}</td>
                          <td>{refer.referBy?.email || "---"}</td>
                          <td>{refer.referTo?.email || "---"}</td>
                          <td>{refer?.referCode || "---"}</td>
                          <td>{refer.points}</td>
                          <td>
                            <div className="action_button">
                              <button
                                className={`${
                                  refer?.referStatus === 1
                                    ? "btn_size_opaque"
                                    : "btn_size_transparent"
                                }`}
                                disabled={refer?.referStatus === 2}
                                onClick={() => {
                                  setReferId(refer?._id);
                                  setApproveModal(true);
                                }}
                              >
                                {refer.referStatus === 1
                                  ? "Approve"
                                  : "Approved"}
                              </button>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={7}
                    msg="Refer not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total >= 10 ? (
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                previousLabel={"previous"}
                nextLabel={"next"}
                itemsCountPerPage={10}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            </div>
          ) : null}
        </div>
        <ModalBox
          status={approveModal}
          onClose={() => {
            setApproveModal(false);
          }}
          title="Approve Referral"
          description="Do you want to approve this referral?"
          action={referApproveHandler}
          ref={buttonLoader}
        />
      </div>
    </>
  );
};

export default ReferManagement;
