import { Avatar } from '@mui/material'
import ModalBox from '../modal/ModalBox'
import { charLimit } from '../../utils/charLimit'

const DescriptionModel = ({ show, close, description,title }) => {
  return (
    <ModalBox
      status={show}
      onClose={close}
      title={charLimit(title, 32)}
      closeIcon
      size={'sm'}
      custom
      customizedFooter={
        <button className="btn_size" onClick={close}>
          Close
        </button>
      }
    >
      <div className="mt-3">
      <p>{description}</p>
      </div>
    </ModalBox>
  )
}
export default DescriptionModel
