
// const isRightHandler=()=>{
 const role= localStorage.getItem("vedic_role")
 const rights=localStorage.getItem("vedic_rights");
// }

// console.log(role,"Is Rights Handler");
// console.log(rights,"Is Rights Handler");


// Auth

export const auth = {
  FORGET_PASSWORD_ROUTE: "/forgot-password",
  RESET_PASSWORD_ROUTE: "/reset-password",
};

// User

export const user = {
  USER_MANAGEMENT: "/user",
  USER_DETAIL: "/user/user-detail",
  USER_DELETE:"/user/delete",
  RIGHTS:true
};

// Consulant

export const consultant = {
  CONSULTANT_MANAGEMENT: "/consultant-management",
  CONSULTANT_DETAIL: "/consultant-management/consultant-detail",
  CONSULTANT_ADD: "/consultant-management/add",
  CONSULTANT_DELETE: "/consultant-management/delete",
  CONSULTANT_EDIT: "/consultant-management/edit",
  CONSULTANT_CHECK_PERFORMANCE: "/consultant-management/check-performance",
  RIGHTS:true
};

// Dashboard

export const dashboard = {
  DASHBOARD: "/dashboard",
  RIGHTS:true
};

// Category

export const category = {
  CATEGORY: "/category",
  ADD_MEDIA: "/category/add-media",
  RIGHTS:true
};

// Banner

export const banner = {
  BANNER: "/banner",
  RIGHTS:true
};

// Music

export const music = {
  MUSIC_LIST: "/music-category",
  MUSIC_DETAIL: "/music-category/add-music",
  RIGHTS:true
};

// Medication

export const meditation = {
  MEDITATION_LIST: "/meditation-category",
  MEDITATION_DETAIL: "/meditation-category/add-meditation",
  RIGHTS:true
};

// Trainig management

export const training = {
  TRAINING_LIST: "/training-category",
  TRAINING_DETAIL: `/training-category/add-training`,
  RIGHTS:true
};

export const image = {
  IMAGE_LIST: "/image-management",
  IMAGE_DETAIL: `/image-management/approve-image`,
  RIGHTS:true
};

export const cms = {
  CONTENT_MANAGEMENT: "/content-management",
};

//  Vastu Compass Category
export const vastuCompass = {
  VASTU_COMPASS_CATEGORY: "/vastu-compass-category",
  VASTU_COMPASS_CATEGORY_DETAIL: "/vastu-compass-category/view",
  RIGHTS:true
};

//  Gift Management
export const gift = {
  GIFT_MANAGEMENT: "/gift-management",
  RIGHTS:true
};

//  Community Management
export const community = {
  COMMUNITY_MANAGEMENT: "/community-management",
  COMMUNITY_DETAIL: "/community-management/view",
  COMMUNITY_CHAT: "/community-management/view/chat",
  RIGHTS:true
};

// Explore Management
export const explore = {
  EXPLORE_MANAGEMENT: "/explore-management",
  EXPLORE_DETAIL: "/explore-management/view",
  RIGHTS:true
};

// Refer Earn
export const refer = {
  REFER_MANAGEMENT: "/refer-management",
  RIGHTS:true
};

// Refer Earn
export const request = {
  REQUEST_MANAGEMENT: "/request-management",
  RIGHTS:true
};

// Discount Management
export const discount = {
  DISCOUNT_MANAGEMENT: "/discount-management",
  RIGHTS:true
};
// Horoscope Management
export const horoscope = {
  HOROSCOPE_MANAGEMENT: "/horoscope-management",
  VIEW: "/horoscope-management/view",
  RIGHTS:true
};

// Live Management
export const live = {
  LIVE_MANAGEMENT: "/live-management",
  RIGHTS:true
};

// Notification Management
export const notification = {
  NOTIFICATION_MANAGEMENT: "/notification-management",
  ADD_NOTIFICATION: "/notification-management/add",
  RIGHTS:true
};

// Support Management
export const support = {
  SUPPORT_MANAGEMENT: "/support-management",
  ADD_SUPPORT: "/support-management/add",
  VIEW_SUPPORT: "/support-management/view",
  RIGHTS:true
};
// Feedback Management
export const feedback = {
  FEEDBACK_MANAGEMENT: "/feedback-management",
  RIGHTS:true
};
// Form
export const form = {
  FORM: "/form-16A/:consultantId",
  RIGHTS:true
};
// Shopify
export const shopify = {
  SHOPIFY: "/shopify/:consultantId",
  RIGHTS:true
};

// Shopify Consultant COmmission
export const commission = {
  COMMISSION: "/commission",
  RIGHTS:true
};

// Concern
export const concern = {
  CONCERN: "/concern-management",
  CONCERN_DETAIL: "/concern-management/add-remedy",
  RIGHTS:true
};

// Transaction
export const transaction = {
  TRANSACTION: "/transaction-management",
  RIGHTS:true
};

// Offers Management
export const offers = {
  OFFERS: "/offers-management",
  RIGHTS:true
};

// Consultant Payout
export const consultantPayout = {
  PAYOUT: "consultant-payout",
  RIGHTS:true
};

// Subadmin
export const subadmin = {
  SUBADMIN: "/subadmin",
  ADDSUBADMIN: "/subadmin/add",
  EDITSUBADMIN: "/subadmin/edit/:subAdminId",
  VIEWSUBADMIN: "/subadmin/view/:subAdminId",
  RIGHTS:true
};
