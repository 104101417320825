import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// ---------------------Dashboard Details API---------------------------
export const fetchDashboardDetailsApi = async () => {
  const endPoint = `/admin/dashboard/details`;
  return await axios.get(endPoint, setJwtToken());
};

// Dashboard Free Trial status
export const changeFreeTrialStatus = async (data) => {
  const endPoint =
    `/admin/dashboard/free_trail` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Dashboard Graph status
export const dashboardGraphApi = async (data) => {
    const endPoint =
      `/admin/dashboard/graph` +
      "?" +
      new URLSearchParams({ ...data }).toString();
    return await axios.get(endPoint, setJwtToken());
  };
