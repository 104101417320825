import React, { useState } from "react";
import ModalBox from "../../../components/modal/ModalBox";
import { Input } from "../../../components/Form";
import useButtonLoader from "../../../hooks/UseButtonLoader";
import { allowAlphaNumericWithSpace, optimizeFunction, regex } from "../../../utils/regex";
import { toastMessage } from "../../../utils/toastMessage";
import { addMeditationApiHandler } from "../../../services/meditationManagement";
import commonMessages from "../../../utils/commonMessages";
import YoutubeVideoPlayer from "../../../components/Video/YoutubeVideoPlayer";
import { addTrainingMediaApiHandler } from "../../../services/trainingManagement";
const { TRAINING_TITLE, TRAINING_LINK, VALID_TRAINING_LINK } = commonMessages;
const { youtubeLinkRegex, password, email, youtubeUrlRegex } = regex;

const AddTraining = ({ show, close, listing, trainingId }) => {
  // handle all state
  const [addTrainingValue, setAddTrainingValue] = useState({
    title: "",
    link: "",
  });
  const [error, setError] = useState({});
  const [showVideo, setShowVideo] = useState(false);

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader("Save");

  // handle all function

  // console.log("AddSubCategory",input)
  //----------vaidation------------
  const handleValidation = () => {
    const error = {};
    let status = true;

    if (!addTrainingValue.title) {
      error["title"] = TRAINING_TITLE;
      status = false;
    }

    if (!addTrainingValue.link) {
      error["videoLink"] = TRAINING_LINK;
      status = false;
    }
    // if (!addTrainingValue.link.match(youtubeLinkRegex)) {
    //   console.log("ok");
    //   error["matchVideoLink"] = VALID_TRAINING_LINK;
    //   status = false;
    // } 
    if (!addTrainingValue.link.match(youtubeUrlRegex)) {
      error["matchVideoLink"] = VALID_TRAINING_LINK;
      status = false;
    }

    setError(error);
    return status;
  };

  // Add training value

  const addTrainingValueHandler = (event) => {
    const { name, value } = event.target;
    let updatedValue = value;
    if (name === "title") {
      updatedValue = allowAlphaNumericWithSpace(value);
    }
    setAddTrainingValue({ ...addTrainingValue, [name]: updatedValue });
  };

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setAddTrainingValue({
      title: "",
      link: "",
    });
    setError({});
    close();
  };
  //--------------Add-sub-category------------------------
  const addTrainingHandler = async () => {
    const status = handleValidation();
    if (status) {
      try {
        buttonLoader(true);
        const sendData = {
          growthTrainingCategoryId: trainingId,
          title: addTrainingValue.title,
          videoLink: addTrainingValue.link,
        };
        const { data } = await addTrainingMediaApiHandler(sendData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addTrainingHandler");
          listing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addTrainingHandler");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={"Add Training Video"}
      closeIcon
      custom
      customizedFooter={
        <button
          className="btn_size"
          ref={buttonref}
          onClick={addTrainingHandler}
        >
          Save
        </button>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={addTrainingValue.title || ""}
            required
            onChange={(e) => addTrainingValueHandler(e)}
            placeholder="Training Title"
            className={`form-control`}
            label={"Training Title"}
            error={!addTrainingValue.title && error.title}
            maxhandleValidationLength={40}
          />
        </div>

        <div className="form-group mb-2">
          <Input
            type="text"
            name="link"
            value={addTrainingValue.link || ""}
            required
            onChange={(e) => addTrainingValueHandler(e)}
            placeholder="Training Video Link"
            className={`form-control`}
            label={"Training Video Link"}
            error={
              !addTrainingValue.link
                ? error.videoLink
                // : !addTrainingValue.link.match(youtubeLinkRegex) ||
                  :(!addTrainingValue.link.match(youtubeUrlRegex) &&
                    error.matchVideoLink)
            }
          />
        </div>

        {(addTrainingValue.link &&
          // addTrainingValue.link.match(youtubeLinkRegex)) ||
          addTrainingValue.link.match(youtubeUrlRegex) && (
            <YoutubeVideoPlayer
              src={addTrainingValue.link || ""}
              height={"200"}
              width={"100%"}
            />
          ))}
      </div>
    </ModalBox>
  );
};

export default AddTraining;
