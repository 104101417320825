import React, { useState } from "react";
import {
  allowAlphaNumericWithSpace,
  allowNumber,
  regex,
} from "../../utils/regex";
import commonMessages from "../../utils/commonMessages";
import { addFormApi, updateFormApi } from "../../services/form16A";
import { toastMessage } from "../../utils/toastMessage";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { Input } from "../../components/Form";
import ModalBox from "../../components/modal/ModalBox";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { updateDiscountCouponApi } from "../../services/shopify";

const EditDiscountCoupon = ({
  setEditModal,
  editModal,
  listing,
  selectedCoupon,
  setSelectedCoupon,
}) => {
  const [buttonref, buttonLoader] = useButtonLoader("Save");
  const [error, setError] = useState({});

    console.log(selectedCoupon,"selectedCoupon")

  const modalClose = () => {
    setError({});
    setEditModal(false);
  };

  const validation = () => {
    let status = true;
    const errors = {};
    if (!selectedCoupon?.coupon) {
      errors["coupon"] = "Coupon is mandatory";
      status = false;
    }
    if (!selectedCoupon?.commission) {
      errors["commission"] = "Commission is Mandatory";
      status = false;
    }
    setError(errors);
    return status;
  };

  const updateCouponDiscountHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true);

        const { data } = await updateDiscountCouponApi({
          shopifyCouponId: selectedCoupon?._id,
          commission: selectedCoupon?.commission,
          coupon: selectedCoupon?.coupon,
        });
        if (data.code === 200) {
          toastMessage(data.message, "success", "updateDiscountCoupon");
          setSelectedCoupon({});
          setError({});
          listing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "updateDiscountCoupon");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  //   console.log(form16Pdf,"form16A");
  return (
    <ModalBox
      status={editModal}
      onClose={() => {
        setError("");
        setSelectedCoupon({});
        setEditModal(false);
      }}
      title={"Update Discount Coupon"}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <button
            className="btn_size"
            ref={buttonref}
            onClick={updateCouponDiscountHandler}
          >
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="coupon"
            value={selectedCoupon?.coupon || ""}
            required
            onChange={(e) => {
              const sanitizedText = e.target.value;
              setSelectedCoupon({ ...selectedCoupon, coupon: sanitizedText });
            }}
            placeholder="Coupon"
            className={`form-control`}
            label={"Coupon"}
            error={!selectedCoupon?.coupon && error.coupon}
            maxLength={40}
          />
        </div>
        <div className="form-group my-2">
          <Input
            type="text"
            name="commission"
            value={selectedCoupon?.commission || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setSelectedCoupon({
                ...selectedCoupon,
                commission: Number(sanitizedText),
              });
            }}
            placeholder="Commission"
            className={`form-control`}
            label={"Commission"}
            error={!selectedCoupon?.commission && error.commission}
            maxLength={3}
          />
        </div>
      </div>
    </ModalBox>
  );
};

export default EditDiscountCoupon;
