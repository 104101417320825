import React from "react";
import ModalBox from "../../components/modal/ModalBox";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { toastMessage } from "../../utils/toastMessage";
import { changeMembershipStatus } from "../../services/discountManagement";

const DiscountStatus = ({
  statusModal,
  setStatusModal,
  status,
  setStatus,
  membershipDiscountId,
  setMembershipDiscountId,
  discountList,
  setDiscountList,
  buttonKey,
  couponType,
}) => {
  const [setButtonLoader, buttonLoader] = useButtonLoader("Yes");

  const close = () => {
    setStatusModal(false);
    setStatus(null);
    setMembershipDiscountId(null);
  };



  const changeStatusHandler = async () => {
    try {
      buttonLoader(true);
      let sendData = {
        status: status,
        membershipDiscountId: membershipDiscountId,
        type: buttonKey,
      };
      if (buttonKey === "coupon") {
        Object.assign(sendData, { couponType: couponType });
      }
      const { data } = await changeMembershipStatus(sendData);
      if (data.code === 200) {
        setDiscountList((prevListing) => {
          return prevListing.map((discount) => {
            if (discount._id === membershipDiscountId) {
              return { ...discount, status: status };
            }
            return discount;
          });
        });

        toastMessage(data.message, "success", "discountStatus");
        close();
      } else {
        toastMessage(data.message, "error", "discountStatus");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      buttonLoader(false);
    }
  };

  return (
    <ModalBox
      status={statusModal}
      onClose={() => {
        setStatusModal(false);
        setMembershipDiscountId(null);
        setStatus(null);
      }}
      title="Change Status"
      description="Do you want to change status?"
      action={changeStatusHandler}
      ref={buttonLoader}
    />
  );
};

export default DiscountStatus;
