import React, { useEffect, useState } from "react";
import {
  block_delete_ContentApi,
  getContentApi,
} from "../../../../services/cms";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import findSerialNumber from "../../../../utils/findSerialNumber";
import { CapitalizeFirstWord } from "../../../../utils/CapitalizeFirstWord";
import { dateFormat } from "../../../../utils/dateFormat";
import NotFound from "../../../../components/not-found/NotFound";
import Pagination from "react-js-pagination";
import { useSearchParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddNotice from "./AddNotice";
import EditNotice from "./EditNotice";
import { charLimit } from "../../../../utils/charLimit";
import AddIcon from "@mui/icons-material/Add";
import ModalBox from "../../../../components/modal/ModalBox";
import { toastMessage } from "../../../../utils/toastMessage";
import useButtonLoader from "../../../../hooks/UseButtonLoader";

const NoticeBoard = ({
  userType,
  type,
  title,
  contentData,
  setContentData,
  total,
  setTotal,
}) => {
  const [searchParam, setSearchParam] = useSearchParams();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [noticeId, setNoticeId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState({});
  let activePage = +searchParam.get("page") || 1;
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [buttonRef, buttonLoader] = useButtonLoader("Yes");
  const getCmsDetails = async () => {
    try {
      showLoader();
      const { data } = await getContentApi({ contentType: type, userType });
      console.log(data, "Data");
      if (data?.code === 200) {
        setContentData(data?.result.list);
        setTotal(data?.result?.total);
      } else {
        setContentData([]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // Delete Notice Handler
  const deleteNoticeHandler = async () => {
    try {
      buttonLoader(true);
      const res = await block_delete_ContentApi({
        noticeId: noticeId,
        contentType: type,
      });
      if (res?.data?.code === 200) {
        toastMessage(res?.data?.message, "success", "deleteNotice");
        setDeleteModal(false);
        getCmsDetails();
      } else {
        toastMessage(res?.data?.message, "error", "deleteNotice");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      buttonLoader(false);
    }
  };

  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  useEffect(() => {
    getCmsDetails();
  }, []);
  return (
    <>
      <div className="TitleBox mt-4">
        <h4 className="Title">Notice Board</h4>
        <div className="block_user_button">
          <button onClick={() => setAddModal(true)}>
            <AddIcon />
            Add Notice
          </button>
        </div>
      </div>
      <div className="content-table">
        <table>
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Title</th>
              <th className="text-center"> Action </th>
            </tr>
          </thead>
          <tbody>
            {contentData.length > 0 ? (
              contentData.map((user, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{findSerialNumber(index, activePage, 10)}</td>
                      <td>{charLimit(user?.title, 40) || "---"}</td>
                      <td>
                        <div className="action_button">
                          <IconButton
                            aria-label="update"
                            className="px-2 py-0"
                            onClick={() => {
                              setEditModal(true);
                              setSelectedNotice(user);
                            }}
                          >
                            <EditIcon className="edit_icon" />
                          </IconButton>
                          <IconButton
                            aria-label="update"
                            className="px-2 py-0"
                            onClick={() => {
                              setDeleteModal(true);
                              setNoticeId(user._id);
                            }}
                          >
                            <DeleteIcon className="edit_icon" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            ) : (
              <NotFound
                cols={9}
                msg="User not found!"
                // icon={NOT_FOUND}
                // loader={loader}
              />
            )}
          </tbody>
        </table>
      </div>
      {total > 10 ? (
        <div className="pagination-wrapper">
          <div className="Pagination">
            <Pagination
              activePage={activePage}
              previousLabel={"previous"}
              nextLabel={"next"}
              itemsCountPerPage={10}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              hideDisabled={true}
            />
          </div>
        </div>
      ) : null}

      <AddNotice
        setAddModal={setAddModal}
        addModal={addModal}
        getCmsDetails={getCmsDetails}
        userType={userType}
        type={type}
      />
      <EditNotice
        setEditModal={setEditModal}
        editModal={editModal}
        selectedNotice={selectedNotice}
        setSelectedNotice={setSelectedNotice}
        contentData={contentData}
        setContentData={setContentData}
        type={type}
      />
      <ModalBox
        status={deleteModal}
        onClose={() => setDeleteModal(false)}
        title={"Delete Notice"}
        description={"Are you sure want to delete this notice?"}
        action={deleteNoticeHandler}
        ref={buttonRef}
      />
    </>
  );
};

export default NoticeBoard;
