import React, { useState } from "react";
import { allowAlphaNumericWithSpace, regex } from "../../utils/regex";
import commonMessages from "../../utils/commonMessages";
import { addFormApi, updateFormApi } from "../../services/form16A";
import { toastMessage } from "../../utils/toastMessage";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { Input } from "../../components/Form";
import ModalBox from "../../components/modal/ModalBox";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const EditForm = ({ setEditModal, editModal, listing , selectedForm,setSelectedForm }) => {
  const [buttonref, buttonLoader] = useButtonLoader("Save");
  const [title, setTitle] = useState("");
  const [form16Pdf, setForm16Pdf] = useState("");
  const [error, setError] = useState({});
  const { IMAGE_FORMAT } = commonMessages;
  const { documentFileRegex } = regex;
  const { PDF_FORMAT } = commonMessages;


//   console.log(selectedForm,"selectedForm")


  const modalClose = () => {
    setError({});
    setEditModal(false);
  };

  const validation = () => {
    let status = true;
    const errors = {};
    if (!selectedForm.title) {
      errors["title"] = "Title is mandatory";
      status = false;
    }
    if (!selectedForm.form16Pdf) {
      errors["form16Pdf"] = "Form 16A is Mandatory";
      status = false;
    }
    setError(errors);
    return status;
  };

  const pdfHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (documentFileRegex.test(e.target.files[0].name)) {
        setForm16Pdf(e.target.files[0]);
      } else {
        e.target.value = "";
        setForm16Pdf("");
        toastMessage(PDF_FORMAT, "error", "pdfFormat");
      }
    } else {
      e.target.value = "";
      setForm16Pdf("");
    }
  };

//   console.log(form16Pdf.name,"form16Pdf");

  const updateFormHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("title", selectedForm.title);
        formData.append("form16Id", selectedForm._id);
        if (form16Pdf && form16Pdf?.name) {
          formData.append("form16Pdf", form16Pdf);
        }
        const  {data}  = await updateFormApi(formData);
        if (data?.code === 200) {
          toastMessage(data.message, "success", "updateForm");
          setForm16Pdf("");
          setSelectedForm("");
          setError({});
          listing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "updateForm");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

//   console.log(form16Pdf,"form16A");
  return (
    <ModalBox
      status={editModal}
      onClose={() => {
        setForm16Pdf("");
        setSelectedForm({});
        setEditModal(false);
      }}
      title={"Update Form"}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <button className="btn_size" ref={buttonref} onClick={updateFormHandler}>
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={selectedForm?.title || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowAlphaNumericWithSpace(e.target.value);
              setSelectedForm({...selectedForm,title:sanitizedText})
            }}
            placeholder="Title"
            className={`form-control`}
            label={"Title"}
            error={!selectedForm?.title && error.title}
            maxLength={40}
          />
        </div>
        <div className="form-group my-2">
          <Input
            type="file"
            accept="application/pdf"
            name="form16Pdf"
            required
            onChange={(e) => pdfHandler(e)}
            className={`form-control `}
            label={"Form 16A"}
            error={!form16Pdf && error.form16Pdf}
          />
        </div>

        <div>
          {selectedForm?.form16Pdf || form16Pdf ? (
            <div className="text-center">
              <PictureAsPdfIcon sx={{ fontSize: 100 }} />
            </div>
          ):""}
        </div>
      </div>
    </ModalBox>
  );
};

export default EditForm;
