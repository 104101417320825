import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  getHoroscopeDetail,
  updateHoroscopeDetail,
} from "../../services/horoscope";
import { Link, useParams } from "react-router-dom";
import { toastMessage } from "../../utils/toastMessage";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import JoditEditor from "jodit-react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IconButton } from "@mui/material";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { months } from "./months";

const ViewHoroscope = () => {
  const [selectedData, setSelectedData] = useState({});
  const [error, setError] = useState({});
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [buttonRef, buttonLoader] = useButtonLoader("Save");
  const { id } = useParams();

  // Get the current month
  const currentMonth = new Date()
    .toLocaleString("default", { month: "short" })
    .toUpperCase();
  const initialMonth =
    months.find((month) => month.toUpperCase() === currentMonth) || "Jan";
  const monthRef = useRef(initialMonth);

  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      toolbar: true,
      placeholer:"",
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
      controls: {
        font: {
          command: "fontname",
          list: {
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
            "Consolas,monaco,monospace": "Consolas",
          },
        },
      },
    }),
    []
  );

  const validation = () => {
    let status = true;
    let errors = {};

    if (!selectedData.month) {
      errors["month"] = "Please select month";
      status = false;
    }

    if (!selectedData?.detail || selectedData?.detail === "<p><br></p>") {
      errors["detail"] = "Detail is mandatory";
      status = false;
    }
    setError(errors);
    return status;
  };

  const horoscopeDetail = async (month = monthRef.current) => {
    try {
      showLoader();
      const { data } = await getHoroscopeDetail({
        horoscopeId: id,
        month,
      });
      if (data.code === 200) {
        setSelectedData(data.result || {});
      } else {
        setSelectedData({});
        toastMessage(data.message, "error", "horoscopeDetail");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  const updateHoroscope = async () => {
    if (validation()) {
      try {
        buttonLoader(true);
        const reqData = {
          horoscopeId: id,
          month: selectedData.month,
          detail: selectedData.detail,
        };
        const { data } = await updateHoroscopeDetail(reqData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "updatedHoroscope");
          horoscopeDetail(selectedData.month);
        } else {
          toastMessage(data.message, "error", "updatedHoroscope");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  useEffect(() => {
    horoscopeDetail();
  }, [id]);

  const handleMonthChange = (e) => {
    const newMonth = e.target.value;
    monthRef.current = newMonth;
    setSelectedData((prevState) => ({
      ...prevState,
      month: newMonth,
    }));
    horoscopeDetail(newMonth);
  };

  return (
    <div className="WrapperArea">
      <div className="WrapperBox">
        <div className="TitleBox">
          <div className="user_detail_heading">
            <Link className="back_icon" to={-2}>
              <IconButton>
                <ArrowBackIosNewIcon className="back_button" />
              </IconButton>
            </Link>
            <h4 className="Title">{selectedData.title || ""}</h4>
          </div>
          <div className="block_user_button">
            <select
              value={selectedData.month}
              onChange={handleMonthChange}
              className={`form-control ${error.month ? "is-invalid" : ""}`}
            >
              {months.map((data) => (
                <option key={data} value={data}>
                  {data}
                </option>
              ))}
            </select>
            {error.month && (
              <div className="invalid-feedback">{error.month}</div>
            )}
          </div>
        </div>
        {loader}
        <JoditEditor
          ref={editor}
          value={selectedData.detail || ""}
          tabIndex={1}
          onBlur={(newContent) =>
            setSelectedData({ ...selectedData, detail: newContent })
            
          }
          config={config}
        />
        {error.detail && (
          <p className={`invalid-feedback d-block`}>{error.detail}</p>
        )}
        <div className="text-center mt-4">
          <button
            className="btn_size"
            onClick={updateHoroscope}
            ref={buttonRef}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewHoroscope;
