const commonMessages = {
  EMAIL_NOT_FOUND: 'Please enter your email.',
  PASSWORD_NOT_FOUND: 'Please enter your password.',
  VALID_EMAIL: 'Please enter valid email.',
  VALID_PASSWORD:
    'Password must has altest 8 characters that include at least 1 lowercase character , 1 uppercase character ,1 number ,and 1 special character in (!@#$%^&*)',
  NEW_PASSWORD_NOT_FOUND: 'Please enter your new password',
  EXCEL_FORMAT: "Please select a excel file (XLSX,XLS).",
  CONFIRM_PASSWORD_NOT_FOUND: 'Please enter your confirm password',
  MATCH_PASSWORD: 'Your new password must be match with confirm password',
  MESSAGE_SIZE:
    ' Uploaded image will be resized to fit within: Width of 500px and height of 500px image must be in either the .jpg .jpeg or .png format',
  IMAGE_FORMAT: 'Please select a valid image file (JPEG, JPG, PNG, or GIF.',
  VIDEO_FORMAT: 'Please select a valid image file (mp4, avi, mov,mkv,wmv,flv, or webm).',
  DOC_FORMAT: "Please select a valid doc file (PDF, DOC, TXT, or DOCX)",
  PDF_FORMAT:"Please select a valid PDF file",
  ADD_BANNER_TITLE: 'Please add banner Title',
  CHOOSE_CATEGORY: 'Please choose category',
  BANNER_IMAGE: 'Please provider banner image',
  MESSAGE_IMAGE_SIZE:
    'The image dimensions exceed the maximum allowable size of pixels in either width or height.',
  MUSIC_CATEGORY_TITLE: 'Please add music category Title',
  MUSIC_CATEGORY_IMAGE: 'Please choose music category image',
  VASTU_COMPASS_CATEGORY_TITLE: 'Please select vastu compass category Title',
  VASTU_COMPASS_CATEGORY: 'Please select vastu compass category',
  VASTU_COMPASS_CATEGORY_IMAGE: 'Please choose vastu compass category image',
  MEDITATION_CATEGORY_TITLE: 'Please add meditation category Title',
  MEDITATION_CATEGORY_IMAGE: 'Please choose meditation category image',
  CATEGORY_TITLE: 'Please add expertise title',
  CATEGORY_IMAGE: 'Please choose expertise image',
  MUSIC_TITLE: 'Please add music title',
  MUSIC_IMAGE: 'Please upload music image',
  MUSIC: 'Please upload music',
  VALID_AUDIO: 'Please select a valid audio file (MP3, WAV, or OGG).',
  EXCEED_SIZE: 'File size exceeds the limit. Please select a smaller file.',
  AUDIO_EXCEED_SIZE:
    'Audio duration exceeds the limit. Please select a shorter file.',
  ERROR_AUDIO_LOADING: 'Error loading audio file. Please try again.',
  CONSULTANT_NAME: 'Please add consultant name.',
  CONSULTANT_EMAIL: 'Please add consultant email.',
  VALID_CONSULTANT_EMAIL: 'Pleae enter the valid consultant email',
  CONSULTANT_NUMBER: 'Please add consultant mobile number',
  AVERAGE_LIVE_BRIER: 'Please add avertage live brier',
  CONSULTANT_GENDER: 'Please select consultant gender',
  CONSULTANT_LANGUAGE: 'Please select consultant language',
  CONSULTANT_ENTERPRISE: 'Please select consultant expertise',
  CONSULTANT_TYPE: 'Please select consultant type',
  CONSULTANT_SKILL: 'Please select consultant skill',
  TRAINING_TITLE: 'Please add training title',
  TRAINING_LINK: 'Please add training video link',
  VALID_TRAINING_LINK: 'Please add training video valid link.',
  TRAINING_CATEGORY_TITLE: 'Please add training category title',
  IMAGE_GIF_FORMAT: 'Please upload a GIF image file only.', 
  GIFT_TITLE:"Please add gift title",
  GIFT_IMAGE:"Please upload gift icon",
  GIFT_COIN:"Please enter gift coin",
}

export default commonMessages
