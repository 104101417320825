import React, { useEffect, useMemo, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import useButtonLoader from "../../../hooks/UseButtonLoader";
import { getCmsDetailApi, updateCmsApi } from "../../../services/cms/index";
import { toastMessage } from "../../../utils/toastMessage";
const Editor = ({ title, type, userType }) => {
  const [error, setError] = useState({ data: "" });
  const [cmsData, setCmsData] = useState({});
  const [buttonref, buttonLoader] = useButtonLoader("Update");
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const headingTitle = {
    1: "About Us",
    2: "Terms and Condition",
    3: "Privacy Policy",
  }[Number(type)];

  const editor = useRef(null);

  // console.log("typeBidder", type)

  const config = useMemo(
    () => ({
      readonly: false,
      placeholer:"",
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
      controls: {
        font: {
          command: "fontname",
          list: {
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
            "Consolas,monaco,monospace": "Consolas",
          },
        },
      },
    }),
    []
  );
  //---------validation-----------------------
  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!cmsData.data || /^<p>(&nbsp;\s*)*<br><\/p>/.test(cmsData.data)) {
      errorMessage["data"] = `Please enter ${headingTitle}`;
      status = false;
    }

    setError(errorMessage);
    return status;
  };

  // console.log("error", error);
  // console.log(cmsData, "Cms Data");

  //---------on-Change-----------------------
  const onChangeHandler = (value) => {
    // console.log('Value', value)
    setCmsData({
      ...cmsData,
      data: value,
    });
  };
  //*********************GET CMS DATA********************************** */
  const getCmsDetails = async (type, userType) => {
    try {
      showLoader();
      const { data } = await getCmsDetailApi({ type, userType });
      if (data?.code === 200) {
        setCmsData({ ...cmsData, data: data?.result?.description || "" });
      } else {
        setCmsData({ data: "" });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  //*********************update cms handler********************************** */
  const updateCmsHandler = async (e) => {
    e.preventDefault();
    if (validation()) {
      const finalObj = {
        title: headingTitle,
        type: type,
        userType: userType,
        description: cmsData.data,
      };
      try {
        buttonLoader(true);
        const { data } = await updateCmsApi(finalObj);
        if (data.code === 200) {
          toastMessage(data.message, "success", "contentManagement");
        } else {
          toastMessage(data.message, "error", "contentManagement");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  useEffect(() => {
    getCmsDetails(type, userType);
    setError({ data: "" });
  }, [type, userType]);

  // const uploadFileRef = useRef();

  return (
    <>
      {/* <div className="d-flex justify-content-between"> */}
      {/* <div> */}
      <h4 className="text-start text-dark mb-4 mt-4">{title}</h4>
      {/* </div> */}
      {/* <div className="block_user_button mt-2">
          <button onClick={() => uploadFileRef.current.click()}>
            Upload PDF
          </button>
        </div> */}
      {/* <input type="file" ref={uploadFileRef} hidden /> */}
      {/* </div> */}
      {loader}
      <JoditEditor
        ref={editor}
        value={cmsData.data}
        tabIndex={1}
        onBlur={onChangeHandler}
        config={config}
      />
      {(!cmsData.data ||
        cmsData.data === "<p><br></p>" ||
        /<p>(&nbsp;\s*)*<br><\/p>/.test(cmsData.data)) &&
        error.data && (
          <p
            className={`invalid-feedback ${
              (!cmsData.data ||
                cmsData.data === "<p><br></p>" ||
                /<p>(&nbsp;\s*)*<br><\/p>/.test(cmsData.data)) &&
              "d-block"
            }`}
          >
            {error.data}
          </p>
        )}
      <div className="text-center mt-4">
        <button className="btn_size" onClick={updateCmsHandler} ref={buttonref}>
          Update
        </button>
      </div>
    </>
  );
};

export default Editor;
