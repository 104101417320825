import React from 'react'
import ModalBox from '../../components/modal/ModalBox'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { toastMessage } from '../../utils/toastMessage'
import { changeConcernStatus } from '../../services/concern/Index'

const ConcernStatus = ({ show, close, status, id, setListing }) => {
  const [buttonref, buttonLoader] = useButtonLoader('Update')

  //--------------change-status-sub-category------------------------
  const changeConcernStatusHandler = async () => {
    try {
      buttonLoader(true)
      let sendData = { status: !status, areaOfConcernId: id }
      const { data } = await changeConcernStatus(sendData)
      if (data.code === 200) {
        setListing((prevListing) => {
          return prevListing.map((concern) => {
            if (concern._id === id) {
              return { ...concern, status: !status }
            }
            return concern
          })
        })
        toastMessage(data.message, 'success', 'concernStatus')
        close()
      } else {
        toastMessage(data.message, 'error', 'concernStatus')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      buttonLoader(false)
    }
  }
//   console.log('button loader', buttonLoader)
  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Change Concern Status"
      description="Do you want to change the status of this concern?"
      action={changeConcernStatusHandler}
      ref={buttonref}
    />
  )
}

export default ConcernStatus
