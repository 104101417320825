import React, { useState } from 'react'
import { Input } from '../../components/Form'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { forgotApi } from '../../services/auth/Auth'
import { toast } from 'sonner'
import useButtonLoader from '../../hooks/UseButtonLoader'
import AfterForgetPassword from './AfterForgetPassword'
import { regex } from '../../utils/regex'
import commonMessages from '../../utils/commonMessages'
import { toastMessage } from '../../utils/toastMessage'
const { EMAIL_NOT_FOUND, VALID_EMAIL } = commonMessages
const { email } = regex

const BeforeForgotPassword = () => {
  const [error, setError] = useState({})
  const navigate = useNavigate()
  const [buttonref, buttonLoader] = useButtonLoader('Send')
  const [forgetEmail, setForgetEmail] = useState('')
  const [isBeforeForgetPassword, setIsBeforeForgetPassword] = useState(true)

  const handleValidation = () => {
    const error = {}
    let status = true

    if (!forgetEmail) {
      error['email'] = EMAIL_NOT_FOUND
      status = false
    }
    if (!email.test(forgetEmail)) {
      error['matchEmail'] = VALID_EMAIL
      status = false
    }

    setError(error)
    return status
  }

  const forgetPassword = async (e, forgetEmail, buttonLoader) => {
    e.preventDefault()
    const isValid = handleValidation()
    if (isValid) {
      try {
        buttonLoader(true)
        const { data } = await forgotApi(forgetEmail)
        if (data.code === 200) {
          setIsBeforeForgetPassword(false)
          toastMessage(data.message, 'success', 'forgetPassword')
        } else {
          toast.error(data.message)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        buttonLoader(false)
      }
    }
  }

  return (
    <>
      {isBeforeForgetPassword ? (
        <div className="LoginArea">
          <h1 className="login_head">Vedic Meet</h1>
          <div className="LoginBox">
            <h5 className="mt-2">Forget Password</h5>
            <p className="text-center fs-6">
              Please enter you registered email id.
              <br /> We will send you link to create new password.
            </p>
            <form>
              <div className="form-group">
                <Input
                  label={'Email ID'}
                  type="text"
                  name="email"
                  value={forgetEmail}
                  onChange={(e) => setForgetEmail(e.target.value)}
                  placeholder="Enter Email"
                  className={`form-control `}
                  error={
                    !forgetEmail
                      ? error.email
                      : !forgetEmail.match(email) && error.matchEmail
                  }
                  maxLength={40}
                  leftIcon={
                    <span className="Icon">
                      <i className="fa fa-envelope" />
                    </span>
                  }
                />
              </div>

              <div className="form_btn">
                <button
                  className="login_btn"
                  ref={buttonref}
                  onClick={(e) => forgetPassword(e, forgetEmail, buttonLoader)}
                >
                  Send
                </button>
              </div>
              <div className="form_btn">
                <Link to={'/'}>Back to Login</Link>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <AfterForgetPassword
          email={forgetEmail}
          forgetPassword={forgetPassword}
        />
      )}
    </>
  )
}

export default BeforeForgotPassword
