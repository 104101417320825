import React, { useState } from 'react'
import ModalBox from '../../components/modal/ModalBox'
import { Input } from '../../components/Form'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { regex, optimizeFunction, allowAlphaNumeric } from '../../utils/regex'
import { Avatar } from '@mui/material'
import { toastMessage } from '../../utils/toastMessage'
import { AddMusicCategoryApi } from '../../services/musicManagement'
import commonMessages from '../../utils/commonMessages'
import { checkImageDimensions } from '../../utils/checkImageDimension'
const {
  MUSIC_CATEGORY_TITLE,
  MUSIC_CATEGORY_IMAGE,
  IMAGE_FORMAT,
  MESSAGE_SIZE,
} = commonMessages
const { imageFileRegex } = regex

const AddMusicCategory = ({ show, close, listing }) => {
  // handle all state
  const [musicCategoryTitle, setMusicCategoryTitle] = useState('')
  const [image, setImage] = useState('')
  const [error, setError] = useState({})

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader('Save')

  // handle all function

  // console.log("AddSubCategory",input)
  //----------vaidation------------
  const validation = () => {
    let errorMessage = {}
    let status = true

    if (!musicCategoryTitle) {
      errorMessage['category'] = MUSIC_CATEGORY_TITLE
      status = false
    }
    if (!image.name) {
      errorMessage['image'] = MUSIC_CATEGORY_IMAGE
      status = false
    }
    setError(errorMessage)
    return status
  }

  // Add Category image handler function

  const imageHandler = async (e) => {
    e.preventDefault()
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        // setImage(e.target.files[0])
        const validSize = await checkImageDimensions(e.target.files[0])
        if (validSize) {
          setImage(e.target.files[0])
        } else {
          e.target.value = ''
          setImage('')
        }
      } else {
        e.target.value = ''
        setImage('')
        toastMessage(IMAGE_FORMAT, 'error', 'imageFormat')
      }
    } else {
      e.target.value = ''
      setImage('')
    }
  }

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setImage('')
    setMusicCategoryTitle('')
    // setInput({ name: '', categoryId: '' })
    setError({})
    close()
  }
  //--------------Add-Music-category------------------------
  const addMusicCategoryHandler = async () => {
    const status = validation()
    if (status) {
      try {
        buttonLoader(true)
        const formData = new FormData()
        formData.append('title', musicCategoryTitle)
        formData.append('musicImage', image)

        const { data } = await AddMusicCategoryApi(formData)
        if (data.code === 200) {
          toastMessage(data.message, 'success', 'addMusic')
          listing()
          modalClose()
        } else {
          toastMessage(data.message, 'error', 'addMusic')
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        buttonLoader(false)
      }
    }
  }

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={'Add Music Category'}
      closeIcon
      size={'sm'}
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <p>{MESSAGE_SIZE}</p>
          <button
            className="btn_size"
            ref={buttonref}
            onClick={addMusicCategoryHandler}
          >
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={musicCategoryTitle || ''}
            required
            onChange={(e) => {
              const sanitizedText = allowAlphaNumeric(e.target.value)
              setMusicCategoryTitle(sanitizedText)
            }}
            placeholder="Category Title"
            className={`form-control`}
            label={'Add Category'}
            error={!musicCategoryTitle && error.category}
            maxLength={40}
          />
        </div>
        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="categoryImage"
            // value={image.name || ''}
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Category Image"
            className={`form-control `}
            label={'Category Image'}
            error={!image && error.image}
            // maxLength={30}
          />
        </div>

        <div>
          {image && (
            <>
              <Avatar
                variant="square"
                sx={{
                  width: '100%',
                  borderRadius: 1,
                  height: 140,
                  border: '1px solid #FF8989',
                }}
                src={image && URL.createObjectURL(image)}
              />
            </>
          )}
        </div>
      </div>
    </ModalBox>
  )
}

export default AddMusicCategory
