import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import NotFound from "../../components/not-found/NotFound";
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";
import { Link, useSearchParams } from "react-router-dom";
import IconButton from "../../components/Button/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BlockIcon from "@mui/icons-material/Block";
import Switch from "../../components/Switch/Switch";
import { SecondaryButton } from "../../components/Button/Button";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { DatePicker, Select } from "../../components/Form";
import { toast } from "sonner";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
// import { getOrderApi } from '../../services/orderManagement'
import { SiMicrosoftexcel } from "react-icons/si";
import { IoSearch } from "react-icons/io5";
import profileImage from "../../Assests/image/profile_not_found.png";
import { user } from "../../routes/route";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import findSerialNumber from "../../utils/findSerialNumber";

import { toastMessage } from "../../utils/toastMessage";
import { fatchCategoryApi } from "../../services/categoryManagement";
import { charLimit } from "../../utils/charLimit";
import common from "../../routes/common";
// import UserStatus from './UserStatus'
import { dateFormat, dateFormatUtc, timeFormat } from "../../utils/dateFormat";
import { gender } from "../../utils/genderList";
import scrollToUp from "../../utils/scrollToUp";
import { CapitalizeFirstWord } from "../../utils/CapitalizeFirstWord";
import Loader from "../../comman/Loader";
import useFileDownloader from "../../hooks/useFileDownloader";
import csv from "../../Assests/image/csv.svg";
import {
  fetchRequestListApi,
  getRequestTypeDetail,
  requestAcceptRejectApi,
} from "../../services/requestManagement";
import { Avatar } from "@mui/material";
import RequestDetail from "./RequestDetail";

const { USER_DETAIL } = user;
const { NOT_FOUND } = common;

// console.log('Gender', gender)

const RequestManagement = () => {
  let URL = process.env.REACT_APP_API_URL;
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;

  const [search, setSearch] = useState(
    getLocalStorage("requestList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);
  const [total, setTotal] = useState(0);
  const [requestList, setRequestList] = useState([]);
  const [selectedRequestDetail, setSelectedRequestDetail] = useState({});
  const [requestId, setRequestId] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [newData, setNewData] = useState({});
  const [currentData, setCurrentData] = useState({});

  const requestType = searchParam.get("requestType") || "";

  useEffect(() => {
    setLocalStorage("requestList", "search", search);
  }, [search]);

  // handle all hooks
  const { loading, downloadFile } = useFileDownloader();

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const handleExcelDownload = () => {
    const url = `${URL}/admin/user/download`;
    const params = {};
    downloadFile(url, `user-excel-file-${dateFormat(new Date())}`, params);
  };

  const requestTypeHandler = (value) => {
    if (requestType === value) {
      searchParam.delete("requestType");
    } else {
      searchParam.set("requestType", value);
    }
    searchParam.delete("page");
    setSearchParam(searchParam);
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };
  //------------------request-listing-Api----------------------------------
  const getRequestList = async (debounceValue) => {
    try {
      showLoader();
      const sendData = {
        page: `${activePage}`,
        limit: "10",
      };
      if (debounceValue) {
        sendData.page = "1";
        sendData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      if (requestType) {
        sendData.requestType = requestType;
      }
      const { data } = await fetchRequestListApi(sendData);
      if (data.code === 200) {
        setRequestList(data?.result?.reqList || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setRequestList([]);
        setTotal(0);
        toastMessage(data.message, "error", "requestList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  //   Request Detail API
  const requestDetail = async () => {
    try {
      showLoader();
      const { data } = await getRequestTypeDetail({
        requestId: requestId.requestId,
      });
      if (data.code === 200) {
        setSelectedRequestDetail(data?.result?.reqList || []);
      } else {
        setSelectedRequestDetail({});
        toastMessage(data.message, "error", "requestDetail");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // useEffect(() => {
  //   if (debounceValue) {
  //     searchParam.set('query', debounceValue)
  //   } else {
  //     searchParam.delete('query')
  //   }
  //   if (activePage === 1) {
  //     searchParam.delete('page')
  //   }
  //   setSearchParam(searchParam)
  //   getOrder(activePage, debounceValue)
  // }, [searchParam, debounceValue])

  useEffect(() => {
    if (Number(activePage) === 1) {
      searchParam.delete("page");
    }
    if (!requestType) {
      searchParam.delete("requestType");
    }
    setSearchParam(searchParam);
    getRequestList(debounceValue);
  }, [searchParam, debounceValue]);

  useEffect(() => {
    if (viewModal) {
      requestDetail();
    }
  }, [viewModal]);
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Request Management</h4>
          </div>
          <div className="FilterBox FilterArea">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className={`form-control ${search && "active"}`}
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">
                <IoSearch />
              </span>
            </div>

            <div className="group_filter_value">
              {/* Gender-Filter */}
              <div className="form-group select_sm_width">
                <Select
                  className={`form-control ${requestType && "active"}`}
                  value={requestType || ""}
                  onChange={(e) => requestTypeHandler(e.target.value)}
                >
                  <option value="">All Request Type</option>
                  <option value="BANK">Bank Details</option>
                  <option value="PHONE">Phone Details</option>
                  <option value="PAN">PAN Details</option>
                  <option value="PRICE">Price Details</option>
                  <option value="FLAG">Flag</option>
                </Select>
              </div>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>Date & Time</th>
                  <th>Request Type</th>
                  <th>Status</th>

                  <th className="text-center"> Action </th>
                </tr>
              </thead>
              <tbody>
                {requestList.length > 0 ? (
                  requestList.map((request, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          <td className="user_profile_image">
                            <img
                              src={request.profileImage || profileImage}
                              alt={`${request.consName} profile image`}
                            />
                          </td>
                          <td>{request.consName || "---"}</td>
                          <td>
                            {dateFormat(request.createdAt) || "---"},{" "}
                            {timeFormat(request?.createdAt)}
                          </td>
                          <td className="text-capitalize">
                            {request?.requestType || "---"}
                          </td>
                          <td>
                            {request?.approveStatus === 1
                              ? "Pending"
                              : request?.approveStatus === 2
                              ? "Approved"
                              : request?.approveStatus === 3
                              ? "Rejected"
                              : ""}
                          </td>
                          <td>
                            <div className="action_button">
                              {/* <Link to=""> */}
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() => {
                                  setViewModal(true);
                                  setRequestId({
                                    requestId: request?._id,
                                    approveStatus: request.approveStatus,
                                  });
                                  setNewData({
                                    ...request?.newData,
                                    requestType: request?.requestType,
                                  });
                                  setCurrentData({
                                    ...request.currentData,
                                    consName: request?.consName,
                                    userName: request?.userName,
                                    requestType: request?.requestType,
                                    flagReason:request?.flagReason
                                  });
                                }}
                              >
                                <VisibilityIcon className="eye_icon" />
                              </IconButton>
                              {/* </Link> */}
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={6}
                    msg="Request not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total > 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* {statusModal && (
        <UserStatus
          show={statusModal}
          close={closeModel}
          setListing={setRequestList}
          setUserDetail={''}
          status={statusValue}
          id={requestId}
        />
      )} */}
      {viewModal && (
        <RequestDetail
          viewModal={viewModal}
          setViewModal={setViewModal}
          requestId={requestId}
          getRequestList={getRequestList}
          requestDetail={requestDetail}
          newData={newData}
          currentData={currentData}
          requestType={requestType}
        />
      )}
    </>
  );
};

export default RequestManagement;
