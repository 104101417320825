import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rightsInfo: {},
  role: "",
};

// Define the config slice
const configRights = createSlice({
  name: "rights",
  initialState,
  reducers: {
    setRightsInfo: (state, action) => {
      state.rightsInfo = action.payload.rightsInfo;
    },
    setRole: (state, action) => {
      state.role = action.payload.role;
    },
  },
});

export const { setRightsInfo,setRole } = configRights.actions;

export default configRights.reducer;
