import React, { useState } from "react";
import ModalBox from "../../../components/modal/ModalBox";
import { TextArea } from "../../../components/Form";
import { optimizeFunction } from "../../../utils/regex";
import { toastMessage } from "../../../utils/toastMessage";
import useButtonLoader from "../../../hooks/UseButtonLoader";
import { addSupportApi } from "../../../services/supportManagement";

const AddSupportSection = ({
  addSupport,
  setAddSupport,
  addModal,
  setAddModal,
  getsupportList,headingTitle,
  userType,supportType
}) => {
  const [buttonref, buttonLoader] = useButtonLoader("Save");
  const [error, setError] = useState({});


  const modalClose = () => {
    setError({});
    setAddModal(false);
    setAddSupport({});
  };

  const validation = () => {
    let status = true;
    const errors = {};
    if (!addSupport.question) {
      errors["question"] = "Question is mandatory";
      status = false;
    }
    if (!addSupport.description) {
      errors["description"] = "Description is mandatory";
      status = false;
    }
    setError(errors);
    return status;
  };

  const addSupportHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("question", addSupport?.question);
        formData.append("description", addSupport.description);
        formData.append("supportType", supportType);
        formData.append("userType", userType);
        const { data } = await addSupportApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addFaq");
          getsupportList();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addFaq");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };
  return (
    <ModalBox
      status={addModal}
      onClose={modalClose}
      title={headingTitle}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        <button className="btn_size" ref={buttonref} onClick={addSupportHandler}>
          Save
        </button>
      }
    >
      <div>
        <div className="form-group mb-2">
          <TextArea
            type="text"
            name="question"
            value={addSupport.question || ""}
            required
            onChange={(e) => {
              const sanitizedText = optimizeFunction(e.target.value);
              setAddSupport({ ...addSupport, ["question"]: sanitizedText });
            }}
            placeholder="Enter Question..."
            className={`form-control`}
            label={"Question"}
            error={!addSupport.question && error.question}
            // maxLength={40}
          />
        </div>
        <div className="form-group mb-2">
          <TextArea
            type="text"
            name="answer"
            value={addSupport.description || ""}
            required
            onChange={(e) => {
              const sanitizedText = optimizeFunction(e.target.value);
              setAddSupport({ ...addSupport, ["description"]: sanitizedText });
            }}
            placeholder="Enter description..."
            className={`form-control`}
            label={"Description"}
            error={!addSupport?.description && error.description}
            // maxLength={40}
          />
        </div>
      </div>
    </ModalBox>
  );
};

export default AddSupportSection;
