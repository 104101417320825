export const regex = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
  imageFileRegex: /\.(jpg|jpeg|png|bmp)$/i,
  excelFileRegex : /\.(xlsx|xls)$/i,
  documentFileRegex : /\.(pdf)$/i,
  audioFileRegex: /\.(mp3|wav|flac|m4a|aac|ogg)$/i,
  videoFileRegex: /\.(mp4|avi|mov|mkv|wmv|flv|webm)$/i,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%*~^&+=!]).{8,}$/,
  youtubeLinkRegex:
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\w+\/|(?:v|e(?:mbed)?)\/|\w+\/)?|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
  extractYoutubeLink:
    /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
  youtubeUrlRegex:
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/,
};

export function optimizeFunction(value) {
  return value.replace(/(^\s+)|([^A-Za-z\s])|(\s+)/g, " ").trimStart();
}

export function allowAlphaNumericWithSpace(value) {
  return value.replace(/[^a-zA-Z0-9\s]/g, "");
}

export function allowAlphaNumeric(value) {
  return value.replace(/[^a-zA-Z0-9]/g, "");
}

export function allowNumber(value) {
  return value.replace(/[^\d]/g, "").trimStart();
}
