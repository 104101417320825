import React from 'react'
import ModalBox from '../../components/modal/ModalBox'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { toastMessage } from '../../utils/toastMessage'
import { changeMeditationStatusCategory } from '../../services/meditationManagement'

const MeditationCategoryStatus = ({ show, close, status, id, setListing }) => {
  const [buttonref, buttonLoader] = useButtonLoader('Add')

  //--------------change-status-sub-category------------------------
  const changeMeditationCategoryStatusHandler = async () => {
    try {
      buttonLoader(true)
      let sendData = { status: !status, meditationCategoryId: id }
      const { data } = await changeMeditationStatusCategory(sendData)
      if (data.code === 200) {
        setListing((prevListing) => {
          return prevListing.map((category) => {
            if (category._id === id) {
              return { ...category, status: !status }
            }
            return category
          })
        })
        toastMessage(data.message, 'success', 'meditationCategoryStatus')
        close()
      } else {
        toastMessage(data.message, 'error', 'meditationCategoryStatus')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      buttonLoader(false)
    }
  }

  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Change Meditation Category Status"
      description="Do you want to change the status of this meditation category?"
      action={changeMeditationCategoryStatusHandler}
      ref={buttonref}
    />
  )
}

export default MeditationCategoryStatus
