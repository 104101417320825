import React, { useState, useEffect } from "react";
import { CustomTab, CustomTabs } from "../../components/tabs/Tabs";
import Editor from "./section/Editor";
import NoticeBoard from "./section/noticeBoard/NoticeBoard";
import Language from "./section/language/Language";
import Faq from "./section/faq/Faq";
import Skills from "./section/skills/Skills";
import { useSearchParams } from "react-router-dom";

const CmsManagement = () => {
  const [userType, setUserType] = useState("cons");
  const [type, setType] = useState("1");
  const [searchParam, setSearchParam] = useSearchParams();
  const [contentData, setContentData] = useState([]);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    const userTypeParam = searchParam.get("type");
    const typeParam =
      searchParam.get("cmstype") || searchParam.get("contentType");
    if (userTypeParam) setUserType(userTypeParam);
    if (typeParam) setType(typeParam);
  }, [searchParam]);

  const headingTitle = {
    1: "About Us",
    2: "Terms and Conditions",
    3: "Privacy Policy",
    4: "Notice Board",
    5: "Language",
    6: "Skills",
    // 7: "FAQ",
  }[type];

  const userTypeHandler = (type) => {
    setUserType(type);
    searchParam.set("type", type);
    searchParam.delete("contentType");
    searchParam.delete("cmstype");
    setType("1");
    setSearchParam(searchParam);
  };

  const typeHandler = (type) => {
    setType(type);
    const typeToContentType = {
      notice: "notice",
      language: "language",
      skills: "skills",
      // faq: "faq",
    };

    if (
      type === "notice" ||
      type === "language" ||
      type === "skills" 
      // ||
      // type === "faq"
    ) {
      searchParam.set("contentType", typeToContentType[type] || null);
      searchParam.delete("cmstype");
    } else {
      searchParam.set("cmstype", type);
      searchParam.delete("contentType");
    }
    setSearchParam(searchParam);
  };

  return (
    <div className="WrapperArea">
      <div className="WrapperBox">
        <div className="TitleBox">
          <h4 className="Title">CMS Management</h4>
        </div>
        <div className="consultant_button_wrapper">
          <div className="banner_button_wrapper">
            <button
              className={`_button ${userType === "cons" && "banner_active"}`}
              onClick={() => userTypeHandler("cons")}
            >
              Consultant
            </button>
            <button
              className={`_button ${userType === "user" && "banner_active"}`}
              onClick={() => userTypeHandler("user")}
            >
              User
            </button>
          </div>
        </div>

        <div
          className={`col-sm-${
            userType === "cons" ? "12" : "10"
          } col-form-label mx-auto mt-3`}
        >
          <CustomTabs
            value={type}
            onChange={(e, section) => {
              typeHandler(section);
            }}
            aria-label="Content"
            variant="fullWidth"
          >
            <CustomTab value="1" label="About Us" />
            <CustomTab value="3" label="Privacy Policy" />
            <CustomTab value="2" label="Terms & Conditions" />
            {/* <CustomTab value="faq" label="FAQ" /> */}
            {userType === "cons" && (
              <CustomTab value="notice" label="Notice Board" />
            )}
            {userType === "cons" && (
              <CustomTab value="language" label="Language" />
            )}
            {userType === "cons" && <CustomTab value="skills" label="Skills" />}
          </CustomTabs>
        </div>

        <div className="row">
          {(type === "1" || type === "2" || type === "3") && (
            <Editor userType={userType} type={type} title={headingTitle} />
          )}
          {}
          {userType === "cons" && type === "notice" && (
            <NoticeBoard
              userType={userType}
              type={type}
              title={headingTitle}
              contentData={contentData}
              setContentData={setContentData}
              total={total}
              setTotal={setTotal}
            />
          )}

          {userType === "cons" && type === "language" && (
            <Language
              userType={userType}
              type={"language"}
              title={headingTitle}
              contentData={contentData}
              setContentData={setContentData}
              total={total}
              setTotal={setTotal}
            />
          )}
          {userType === "cons" && type === "skills" && (
            <Skills
              userType={userType}
              type={"skills"}
              title={headingTitle}
              contentData={contentData}
              setContentData={setContentData}
              total={total}
              setTotal={setTotal}
            />
          )}
          {/* {type === "faq" && (
            <Faq
              userType={userType}
              type={"faq"}
              title={headingTitle}
              contentData={contentData}
              setContentData={setContentData}
              total={total}
              setTotal={setTotal}
            />
          )} */}
        </div>
      </div>

    </div>
  );
};

export default CmsManagement;
