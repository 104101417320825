import React from "react";
import ModalBox from "../../components/modal/ModalBox";

import useButtonLoader from "../../hooks/UseButtonLoader";
import { toastMessage } from "../../utils/toastMessage";
import { changeStatusCategory } from "../../services/categoryManagement";

const CategoryStatus = ({ show, close, listing, status, id, setListing }) => {
  const [buttonref, buttonLoader] = useButtonLoader("Yes");

  //--------------change-status-sub-category------------------------
  const changeStatus = async () => {
    try {
      buttonLoader(true);
      let data = { status: !status, categoryId: id, type: "category" };
      const res = await changeStatusCategory(data);
      if (res.data.code === 200) {
        setListing((prevListing) => {
          return prevListing.map((category) => {
            if (category._id === id) {
              return { ...category, status: !status };
            }
            return category;
          });
        });
        close();
        toastMessage(res.data.message, "success", "categoryStatus");
      } else {
        toastMessage(res.data.message, "error", "categoryStatus");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      buttonLoader(false);
    }
  };
  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Change Expertise Status"
      description="Do you want to change the status of this expertise?"
      action={changeStatus}
      ref={buttonref}
    />
  );
};

export default CategoryStatus;
