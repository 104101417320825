import React, { useRef, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import Cropper from 'react-cropper'
// import 'cropperjs/dist/cropper.css'

import IconButton from '../Button/IconButton'
import { charLimit } from '../../utils/charLimit'
import { base64ToFile } from '../../utils/base64toFile'
import { regex } from '../../utils/regex'
import { toastMessage } from '../../utils/toastMessage'
import commonMessages from '../../utils/commonMessages'

// import { base64ToFile, charLimit } from 'utils'

const ImagePicker = ({ imageSelect, error, ...rest }) => {
  const { imageFileRegex } = regex
  console.log('rest', rest)
  const imageRef = useRef()
  const [aspect] = useState(1)
  const [imageModal, setImageModal] = useState(false)

  const [image, setImage] = useState('')
  const [imageName, setImageName] = useState('')
  const [, setCropData] = useState('#')
  const [cropper, setCropper] = useState()

  const onChange = (e) => {
    e.preventDefault()
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        let files
        if (e.dataTransfer) {
          files = e.dataTransfer.files
        } else if (e.target) {
          files = e.target.files
        }
        setImageName(files[0]?.name)
        const reader = new FileReader()
        // reader.onload = () => {
        //   setImage(reader.result)
        // }
        reader.readAsDataURL(files[0])
        setImageModal(true)
      } else {
        toastMessage(
          'Please select a valid image file (JPEG, JPG, PNG, or GIF).',
          'error',
          'imageFormat'
        )
      }
    } else {
      setImageName('')
      rest.setImageSelect()
    }
  }

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      console.log()
      setCropData(cropper.getCroppedCanvas().toDataURL())
      imageSelect(
        base64ToFile(cropper.getCroppedCanvas().toDataURL(), imageName)
      )
      setImageModal(false)
    }
  }

  const cancelHandler = () => {
    setImageModal(false)
    setImage('')
    setImageName('')
    imageSelect('')
  }

  return (
    <>
      <label className="my-1">Image</label>
      <span className="text-danger"> *</span>
      <div className="file-wrap">
        <div
          className={`file-input ${rest.disabled && 'disabled'} ${
            !imageName && error && 'is-invalid'
          }`}
          onClick={() => imageRef.current?.click()}
        >
          <div className="choose">
            <span>Choose file</span>
          </div>
          <div className="file-name">
            <span>{charLimit(imageName, 22) || 'No file chosen'}</span>
          </div>
        </div>
        {!imageName && error && <div className="invalid-feed">{error}</div>}
      </div>
      <input
        type="file"
        name="image"
        onChange={onChange}
        placeholder="Select Image"
        className={`form-control `}
        accept="image/*"
        hidden
        ref={imageRef}
      />
      <Dialog
        open={imageModal}
        onClose={() => setImageModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="crop_image_container">
          <div className="crop_image">
            <Cropper
              initialAspectRatio={aspect}
              dragMode="move"
              preview=".img-preview"
              src={image}
              onInitialized={(instance) => {
                setCropper(instance)
              }}
              cropBoxResizable={false}
              movable={false}
            />
          </div>
          <div className="icons">
            <div className="d-flex">
              <IconButton onClick={cancelHandler}>
                <CloseIcon sx={{ color: '#000' }} />
              </IconButton>
              <IconButton onClick={getCropData}>
                <CheckIcon sx={{ color: '#000' }} />
              </IconButton>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default ImagePicker
