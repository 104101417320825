import React, { useState } from 'react'
import ModalBox from '../../components/modal/ModalBox'
import { Input } from '../../components/Form'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { allowAlphaNumeric, allowAlphaNumericWithSpace, optimizeFunction, regex } from '../../utils/regex'
import { Avatar } from '@mui/material'
import { toastMessage } from '../../utils/toastMessage'
import { updateMeditationCategoryApi } from '../../services/meditationManagement'
import commonMessages from '../../utils/commonMessages'
import { checkImageDimensions } from '../../utils/checkImageDimension'
const { imageFileRegex } = regex
const {
  MEDITATION_CATEGORY_TITLE,
  MEDITATION_CATEGORY_IMAGE,
  IMAGE_FORMAT,
  MESSAGE_SIZE,
} = commonMessages
const EditMeditationCategory = ({
  show,
  close,
  meditationCategoryDetail,
  setMeditationCategoryDetail,
  meditationListing,
}) => {
  // handle all state

  const [image, setImage] = useState('')
  const [error, setError] = useState({})
  const [updateMeditationCategory, setUpdateMeditationCategory] = useState({
    id: meditationCategoryDetail._id,
    title: meditationCategoryDetail.title,
    image: meditationCategoryDetail.meditationImage,
  })

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader('Update')

  // handle all function

  // console.log("AddSubCategory",input)
  //----------vaidation------------
  const validation = () => {
    let errorMessage = {}
    let status = true

    if (!updateMeditationCategory.title) {
      errorMessage['meditationTitle'] = 'Please add meditation category Title'
      status = false
    }

    setError(errorMessage)
    return status
  }

  // image handler function
  const imageHandler = async (e) => {
    e.preventDefault()
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        // setImage(e.target.files[0])
        const validSize = await checkImageDimensions(e.target.files[0])
        if (validSize) {
          setImage(e.target.files[0])
        } else {
          e.target.value = ''
          setImage('')
        }
      } else {
        setImage('')
        e.target.value = ''
        toastMessage(IMAGE_FORMAT, 'error', 'imageFormat')
      }
    } else {
      e.target.value = ''
      setImage('')
    }
  }
  //------------------onChange-input-value---------------------------
  const handleChange = (e) => {
    const { name, value } = e.target
    let updatedValue = value
    if (name === 'title') {
      updatedValue = allowAlphaNumericWithSpace(value)
    }
    setUpdateMeditationCategory({
      ...updateMeditationCategory,
      [name]: updatedValue,
    })
  }

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setImage('')
    setMeditationCategoryDetail({})
    setUpdateMeditationCategory({})
    setError({})
    close()
  }
  //--------------Update-music-category------------------------
  const updateMeditationCategoryHandler = async () => {
    const status = validation()
    if (status) {
      try {
        buttonLoader(true)
        const formData = new FormData()
        formData.append('meditationCategoryId', updateMeditationCategory.id)
        formData.append('title', updateMeditationCategory.title)
        if (image && image.name) {
          formData.append('meditationImage', image)
        }
        const { data } = await updateMeditationCategoryApi(formData)
        if (data.code === 200) {
          toastMessage(data.message, 'success', 'updateMeditationCategory')
          meditationListing()
          modalClose()
        } else {
          toastMessage(data.message, 'error', 'updateMeditationCategory')
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        buttonLoader(false)
      }
    }
  }

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={'Edit Meditation Category'}
      closeIcon
      size={'sm'}
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <p>{MESSAGE_SIZE}</p>
          <button
            className="btn_size"
            ref={buttonref}
            onClick={updateMeditationCategoryHandler}
          >
            Update
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={updateMeditationCategory?.title || ''}
            required
            onChange={(e) => handleChange(e)}
            placeholder="Category Title"
            className={`form-control `}
            label={'Add Category'}
            error={!updateMeditationCategory.title && error.meditationTitle}
            // maxLength={30}
          />
        </div>

        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="categoryImage"
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Meditation Category Image"
            className={`form-control `}
            label={'Category Image'}
            // error={!image && error.image}
            // maxLength={30}
          />
        </div>

        <div>
          {
            <>
              <Avatar
                variant="square"
                sx={{
                  width: '100%',
                  borderRadius: 1,
                  height: 140,
                  border: '1px solid #FF8989',
                }}
                src={
                  image && image.name
                    ? URL.createObjectURL(image)
                    : updateMeditationCategory.image
                }
              />
            </>
          }
        </div>
      </div>
    </ModalBox>
  )
}

export default EditMeditationCategory
