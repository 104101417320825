import React, { useState } from 'react'
// import NotFound from '../../../components/not-found/NotFound'
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded'
// import { toastMessage } from '../../../utils/toastMessage'
import { useSearchParams } from 'react-router-dom'
// import { getConsultantOrderHistoryApi } from '../../../services/consultantManagement'
// import useFullPageLoader from '../../../hooks/useFullPageLoader'
import Pagination from 'react-js-pagination'
import { dateFormatWithTime } from '../../utils/dateFormat'
import NotFound from '../../components/not-found/NotFound'
// import { dateFormatWithTime } from '../../../utils/dateFormat'

const UserConsultantListing = ({handlePageChange,total,activePage,orderList}) => {

  return (
    <div className="content-table">
      <table>
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Problem</th>
            <th>Consultant</th>
            <th>Date and Time</th>
            <th>Consultant Type</th>
            <th>Status</th>
            <th>Amount</th>
          </tr>
        </thead>
        {/* profile: profileImage, name: 'Rahul Chauhan', userId: '123456789',
              PhoneNumber: '1234567890', Email: 'rahul@gmail.com', gender:
              'male', RegistrationDate: '12 Jan 1234', */}
        <tbody>
          {orderList.length > 0 ? (
            orderList.map((order, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>{++index}</td>
                    <td>{order.areaOfConcern || '---'}</td>
                    <td>{`${order?.consDetails?.name}`}</td>
                    <td>{dateFormatWithTime(order?.createdAt) || '---'}</td>
                    <td>{order?.typeOfConsult || '---'}</td>
                    <td>{order?.isConsultantCompleted || "---"}</td>
                    <td>{order?.totalAmountPayToConsultant || '---'}</td>
                  </tr>
                </React.Fragment>
              )
            })
          ) : (
            <NotFound
              cols={6}
              msg="No Order Found!"
              icon={<ViewListRoundedIcon sx={{ fontSize: 48 }} />}
              // loader={loader}
            />
          )}
        </tbody>
      </table>
      {total > 1 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}
    </div>
  )
}
export default UserConsultantListing
