import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// ---------------------fatch-category-listing--------------------------
export const fatchCategoryApi = async () => {
  const endPoint = "/master";
  return await axios.get(endPoint, setJwtToken());
};

// Fetchign category listing
export const fetchCategoryListApi = async (data) => {
  const endPoint =
    `/admin/category` + "?" + new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// ---------------------add-category---------------------------
export const AddCategoryApi = async (data) => {
  const endPoint = `/admin/category/add`;
  return await axios.post(endPoint, data, setMultiPartHeader());
};
// ---------------------change-status-category---------------------------
export const changeStatusCategory = async (data) => {
  const endPoint = `/admin/category/block_unblock`;
  return await axios.put(endPoint, data, setJwtToken());
};

// ---------------------Category Media List -----------------------------//
export const fetchCategoryMediaApi = async (data) => {
  const endPoint =
    `/admin/category/media_list` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// ---------------------add-media-category---------------------------
export const AddMediaCategoryApi = async (data) => {
  const endPoint = `/admin/category/add_media`;
  return await axios.post(endPoint, data, setMultiPartHeader());
};

// ----------------------Update-media-category----------------------
export const editMediaCategory = async (data) => {
  const endPoint = `/admin/category/update_media`;
  return await axios.put(endPoint, data, setMultiPartHeader());
};

// Change media category status
export const changeMediaCategoryStatus = async (data) => {
  const endPoint = `/admin/category/block_unblock`;
  return await axios.put(endPoint, data, setJwtToken());
};
