import React, { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import useDebounce from '../../../hooks/useDebounce'
import useFullPageLoader from '../../../hooks/useFullPageLoader'
import { IconButton } from '@mui/material'
import Switch from '../../../components/Switch/Switch'
import NotFound from '../../../components/not-found/NotFound'
import Pagination from 'react-js-pagination'
import common from '../../../routes/common'
import { toastMessage } from '../../../utils/toastMessage'
import findSerialNumber from '../../../utils/findSerialNumber'
import { charLimit } from '../../../utils/charLimit'
import EditIcon from '@mui/icons-material/Edit'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import scrollToUp from '../../../utils/scrollToUp'
import { getLocalStorage, setLocalStorage } from '../../../config/config'
import AddTraining from './AddTraining'
import { fetchTrainingMediaApi } from '../../../services/trainingManagement'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteTraining from './DeleteTraining'
import EditTraining from './EditTraining'
import UpdateTrainingStatus from './UpdateTrainingStatus'
import AddIcon from '@mui/icons-material/Add';
const { NOT_FOUND } = common

const TrainingList = () => {
  const { trainingCategory, id } = useParams()
  // handle all state
  const [searchParam, setSearchParam] = useSearchParams()
  let activePage = +searchParam.get('page') || 1

  const [search, setSearch] = useState(
    getLocalStorage('trainingList', 'search') || ''
  )
  const debounceValue = useDebounce(search || '', 300)

  const [addTrainingModal, setAddTrainingModal] = useState(false)
  const [editTrainingModel, setEditTrainingModel] = useState(false)
  const [trainingList, setTrainigList] = useState([])

  const [total, setTotal] = useState(0)
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [statusModal, setStatusModal] = useState(false)
  const [deleteModel, setDeleteModel] = useState(false)
  const [statusValue, setStatusValue] = useState('')
  const [statusId, setStatusId] = useState('')
  const [selectedTrainingMediaDetail, setSelectedTrainingMediaDetail] =
    useState(null)

  useEffect(() => {
    setLocalStorage('trainingList', 'search', search)
  }, [search])

  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true)
    setStatusId(id)
    setStatusValue(status)
  }

  // update music category handler
  const updateTrainingMediaHandler = (data) => {
    setSelectedTrainingMediaDetail(data || null)
    setEditTrainingModel(true)
  }

  const DeleteTrainingMediaHandler = (trainingId) => {
    setStatusId(trainingId)
    setDeleteModel(true)
  }

  //*********Handel Block********* */
  // const blockHandler = (id, status) => {
  //     setBlockModal(true);
  //     setBlockId(id);
  //     // setStatusValue(status);
  // };
  //*********Handle update********* */
  // const updateHandler = (id, enName, arName, imageUrl, categoryId) => {
  //   setUpdateModal(true)
  //   setUpdateSubCategoryData({
  //     id,
  //     enName,
  //     arName,
  //     imageUrl,
  //     categoryId,
  //   })
  // }
  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set('page', event)
    setSearchParam(searchParam)
  }

  // close model

  const closeModel = () => {
    setSelectedTrainingMediaDetail(null)
    setStatusValue('')
    setStatusId('')
    setAddTrainingModal(false)
    setStatusModal(false)
    setEditTrainingModel(false)
    setDeleteModel(false)
  }

  // //------------------listing-music-Api----------------------------------
  const fetchTrainingHandler = async () => {
    try {
      setTrainigList([])
      showLoader()
      const sendData = {
        growthTrainingCategoryId: id,
        page: activePage,
        limit: 5,
      }
      if (debounceValue) {
        sendData.page = 1
        sendData.search = search
        searchParam.delete('page')
        setSearchParam(searchParam)
      }
      const { data } = await fetchTrainingMediaApi(sendData)
      if (data.code === 200) {
        setTrainigList(data?.result?.list || [])
        setTotal(data?.result?.total || 0)
        scrollToUp()
      } else {
        setTrainigList([])
        setTotal(0)
        toastMessage(data.message, 'error', 'trainingMediaList')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      hideLoader()
    }
  }

  // useEffect(() => {
  //   if (debounceValue) {
  //     searchParam.set('query', debounceValue)
  //   } else {
  //     searchParam.delete('query')
  //   }
  //   if (activePage === 1) {
  //     searchParam.delete('page')
  //   }
  //   setSearchParam(searchParam)
  //   fetchCategoryHandler(activePage, debounceValue)
  // }, [searchParam, debounceValue])
  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete('page')
    }
    setSearchParam(searchParam)
    fetchTrainingHandler()
  }, [searchParam, debounceValue])
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <div className="user_detail_heading">
              {/* <h4 className="Title">User Management</h4> */}
              <Link className="back_icon" to={-3}>
                <IconButton>
                  <ArrowBackIosNewIcon className="back_button" />
                </IconButton>
              </Link>
              <h4 className="Title">{trainingCategory || ''}</h4>
            </div>
            <div className="block_user_button">
              <button onClick={() => setAddTrainingModal(true)}>
                <AddIcon/>Add Training
              </button>
            </div>
          </div>
          <div className="FilterBox FilterArea ">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search || ''}
                style={{ '--color': '#FF8989' }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">{common.SEARCH_ICON}</span>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Video Title</th>
                  <th>Video Link</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>

              <tbody>
                {trainingList.length > 0 ? (
                  trainingList.map((training, index) => {
                    return (
                      <React.Fragment key={training._id}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 5)}</td>
                          <td>{charLimit(training.title, 30) || '---'}</td>
                          <td>{training.videoLink || '---'}</td>
                          <td>
                            <div className="action_button">
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() =>
                                  updateTrainingMediaHandler(training)
                                }
                              >
                                <EditIcon className="edit_icon" />
                              </IconButton>
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() =>
                                  DeleteTrainingMediaHandler(training._id)
                                }
                              >
                                <DeleteIcon className="edit_icon" />
                              </IconButton>
                              <Switch
                                checked={training.status}
                                onChange={() =>
                                  statusUpdateHandler(
                                    training._id,
                                    training.status
                                  )
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })
                ) : (
                  <NotFound
                    cols={7}
                    msg="Training not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total > 5 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  itemsCountPerPage={5}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}

          {/* <FloatingButton
            onClick={() => setAddModalShow(true)}
            icon={<AddIcon />}
          /> */}
        </div>
        {addTrainingModal && (
          <AddTraining
            show={addTrainingModal}
            close={closeModel}
            listing={fetchTrainingHandler}
            trainingId={id}
          />
        )}
        {statusModal && (
          <UpdateTrainingStatus
            show={statusModal}
            close={closeModel}
            setListing={setTrainigList}
            status={statusValue}
            id={statusId}
          />
        )}
        {editTrainingModel && (
          <EditTraining
            show={editTrainingModel}
            close={closeModel}
            trainingCategoryDetail={selectedTrainingMediaDetail}
            setTrainingCategoryDetail={setSelectedTrainingMediaDetail}
            trainingListing={fetchTrainingHandler}
          />
        )}
        {deleteModel && (
          <DeleteTraining
            show={deleteModel}
            close={closeModel}
            setListing={setTrainigList}
            status={statusValue}
            id={statusId}
          />
        )}
      </div>
    </>
  )
}

export default TrainingList
