import React, { useEffect, useState } from "react";
import { FaPaperclip } from "react-icons/fa";
import AddPhotoAlternateRoundedIcon from "@mui/icons-material/AddPhotoAlternateRounded";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Avatar, IconButton } from "@mui/material";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  fetchQueryApi,
  fetchQueryStatusApi,
  sendChatApi,
} from "../../services/supportManagement";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { toastMessage } from "../../utils/toastMessage";
import ModalBox from "../../components/modal/ModalBox";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { Input } from "../../components/Form";
import { regex } from "../../utils/regex";
import commonMessages from "../../utils/commonMessages";
import { fetchCommunityChatList } from "../../services/communityManagement";
import Pagination from "react-js-pagination";
import { BsThreeDotsVertical } from "react-icons/bs";

const GoToChat = () => {
  const { id } = useParams();
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;

  const [error, setError] = useState("");
  const [total, setTotal] = useState(0);
  const [message, setMessage] = useState("");
  const [chat, setChat] = useState([]);
  const [buttonRef, buttonLoader] = useButtonLoader("Yes");
  const [updatedQueryStatusModal, setUpdateQueryStatusModal] = useState(false);
  const [mediaModal, setMediaModal] = useState(false);
  const [media, setMedia] = useState("");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { imageFileRegex } = regex;
  const { IMAGE_FORMAT } = commonMessages;

  const fetchChatList = async () => {
    try {
      showLoader();
      const sendData = {
        page: activePage,
        limit: 10,
        communityId: id,
      };
      const { data } = await fetchCommunityChatList(sendData);
      if (data?.code === 200) {
        // console.log(data?.result?.chat,"----Chat ---");

        setChat(data?.result?.chat || []);
        setTotal(data?.result?.total);
      } else {
        setChat([]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  // Image Handler
  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        setMedia(e.target.files[0]);
      } else {
        e.target.value = "";
        setMedia("");
      }
    } else {
      e.target.value = "";
      setMedia("");
      toastMessage(IMAGE_FORMAT, "error", "imageFormat");
    }
  };

  //   Send Message Query
  const chatSendHandler = async () => {
    // if (validation()) {
    //   try {
    //     buttonLoader(true);
    //     const formData = new FormData();
    //     formData.append("customerSupportQueryId", id);
    //     if (media && media?.name) {
    //       formData.append("messageType", 1);
    //       formData.append("file", media);
    //     } else {
    //       formData.append("messageType", 0);
    //       formData.append("message", message);
    //     }
    //     const { data } = await sendChatApi(formData);
    //     if (data.code === 200) {
    //       fetchQuery();
    //       setMediaModal(false);
    //       setMessage("");
    //       setMedia("");
    //     } else {
    //       toastMessage(data.message, "error", "chatSend");
    //     }
    //   } catch (error) {
    //     console.log("error", error);
    //   } finally {
    //     buttonLoader(false);
    //   }
    // }
  };

  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete("page");
    }
    setSearchParam(searchParam);
    fetchChatList();
  }, [searchParam]);

  return (
    <div className="WrapperArea">
      <div className="WrapperBox">
        <div className="TitleBox">
          <div className="user_detail_heading">
            <Link className="back_icon" to={`/community-management/view/${id}`}>
              <IconButton>
                <ArrowBackIosNewIcon className="back_button" />
              </IconButton>
            </Link>
            {/* <h4 className="Title"> {`Mr. ${name}`}</h4> */}
          </div>
          <div className="block_user_button">
            {/* <button onClick={() => setUpdateQueryStatusModal(true)}>
              {status == true ? "Completed" : "Mark as completed"}
            </button> */}
          </div>
        </div>

        {/* <div className="chat-container">
          <div className="chat-box">
            {chat &&
              chat.length > 0 &&
              chat.map((data, index) => {
                if (data?.senderId?.name) {
                  return (
                    <div key={index} className="chat-message incoming-message">
                      <img
                        src={data?.senderId?.image || ""}
                        alt="Avatar"
                        className="avatar"
                      />
                      {(() => {
                        switch (data?.messageType) {
                          case 1:
                            return (
                              <div className="message-content">
                                {data?.message}
                              </div>
                            );
                          case 2:
                            return (
                              <div className="media-content">
                                {data?.file.map((file, idx) => (
                                  <video
                                    key={idx}
                                    controls
                                    src={file.mediaUrl}
                                    className="video-content"
                                  />
                                ))}
                              </div>
                            );
                          case 3:
                            return (
                              <div className="media-content">
                                {data?.file.map((file, idx) => (
                                  <audio
                                    key={idx}
                                    controls
                                    src={file.mediaUrl}
                                    className="audio-content"
                                  />
                                ))}
                              </div>
                            );
                          case 4:
                            return (
                              <a
                                href={data?.message}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="pdf-content"
                              >
                                View PDF
                              </a>
                            );
                          case 5:
                            return (
                              <div className="media-content">
                                {data?.file.map((file, idx) => {
                                  if (file.mediaType === "image") {
                                    return (
                                      <img
                                        key={idx}
                                        src={file.mediaUrl}
                                        className="image-content"
                                        height={"250px"}
                                        width={"250px"}
                                      />
                                    );
                                  } else {
                                    return (
                                      <video
                                        key={idx}
                                        controls
                                        src={file.mediaUrl}
                                        className="video-content"
                                      />
                                    );
                                  }
                                })}
                              </div>
                            );
                          case 6:
                            return (
                              <div className="call-content">Audio Call</div>
                            );
                          case 7:
                            return (
                              <div className="call-content">Video Call</div>
                            );
                          default:
                            return (
                              <img
                                src={data?.message}
                                alt="content"
                                className="image-content"
                              />
                            );
                        }
                      })()}
                    </div>
                  );
                }
              })}
          </div>
        </div> */}

        <div className="chat-container">
          <div className="chat-box">
            {chat &&
              chat.length > 0 &&
              chat.map((data, index) => {
                if (data?.senderId?.name) {
                  return (
                    <div key={index} className="chat-message incoming-message">
                      <img
                        src={data?.senderId?.image || ""}
                        alt="Avatar"
                        className="avatar"
                      />
                      {(() => {
                        switch (data?.messageType) {
                          case 1:
                            return (
                              <>
                                <div className="message-content">
                                  {data?.message}
                                </div>
                                <div>
                                  <div className="dropdowns">
                                    <button className="dropbtns">
                                    <BsThreeDotsVertical />
                                    </button>
                                    <div className="dropdown-contents">
                                      <Link to={"#"}>Pin</Link>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          case 2:
                            return (
                              <div className="media-content">
                                {data?.file.map((file, idx) => (
                                  <video
                                    key={idx}
                                    controls
                                    src={file.mediaUrl}
                                    className="video-content"
                                  />
                                ))}
                              </div>
                            );
                          case 3:
                            return (
                              <div className="media-content">
                                {data?.file.map((file, idx) => (
                                  <audio
                                    key={idx}
                                    controls
                                    src={file.mediaUrl}
                                    className="audio-content"
                                  />
                                ))}
                              </div>
                            );
                          case 4:
                            return (
                              <a
                                href={data?.message}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="pdf-content"
                              >
                                View PDF
                              </a>
                            );
                          case 5:
                            return (
                              <div className="media-content">
                                {data?.file.map((file, idx) => {
                                  if (file.mediaType === "image") {
                                    return (
                                      <img
                                        key={idx}
                                        src={file.mediaUrl}
                                        className="image-content"
                                        height={"250px"}
                                        width={"250px"}
                                      />
                                    );
                                  } else {
                                    return (
                                      <video
                                        key={idx}
                                        controls
                                        src={file.mediaUrl}
                                        className="video-content"
                                      />
                                    );
                                  }
                                })}
                              </div>
                            );
                          case 6:
                            return (
                              <div className="call-content">Audio Call</div>
                            );
                          case 7:
                            return (
                              <div className="call-content">Video Call</div>
                            );
                          default:
                            return (
                              <img
                                src={data?.message}
                                alt="content"
                                className="image-content"
                              />
                            );
                        }
                      })()}
                    </div>
                  );
                }
              })}
            {/* <div className="input-box">
              <div className="textarea-container">
                <textarea
                  placeholder="Type your message here..."
                  name="message"
                  // disabled={status=="true"}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <AddPhotoAlternateRoundedIcon
                  className="attach-icon"
                  onClick={() => {
                    setMediaModal(true);
                  }}
                />
              </div>
              <button
                className="send-button"
                // disabled={status == "true"}
                // onClick={chatSendHandler}
              >
                Send
              </button>
            </div> */}
          </div>
        </div>

        {total >= 10 ? (
          <div className="pagination-wrapper">
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                previousLabel={"previous"}
                nextLabel={"next"}
                itemsCountPerPage={10}
                totalItemsCount={total}
                pageRangeDisplayed={10}
                onChange={handlePageChange}
                hideDisabled={true}
              />
            </div>
          </div>
        ) : null}

        <ModalBox
          status={mediaModal}
          onClose={() => {
            setMediaModal(false);
            setMedia("");
          }}
          title={"Upload Media"}
          size={"sm"}
          closeIcon
          custom
          customizedFooter={
            <div className="model_custom_footer">
              <button
                className="btn_size"
                // ref={buttonref}
                onClick={chatSendHandler}
              >
                Upload
              </button>
            </div>
          }
        >
          <div>
            <div className="form-group my-2">
              <Input
                type="file"
                accept="image/*"
                name="file"
                // value={image.name || ''}
                required
                onChange={(e) => imageHandler(e)}
                className={`form-control `}
                label={"Image"}
                error={!media && error.media}
                // maxLength={30}
              />
            </div>
            <div>
              {media && (
                <>
                  <Avatar
                    variant="square"
                    sx={{
                      width: "100%",
                      borderRadius: 1,
                      height: 140,
                      border: "1px solid #FF8989",
                    }}
                    src={media && URL.createObjectURL(media)}
                  />
                </>
              )}
            </div>
          </div>
        </ModalBox>
      </div>
    </div>
  );
};

export default GoToChat;
