// export  const months=[
//     { name: "January", value: "jan" },
//     { name: "February", value: "feb" },
//     { name: "March", value: "mar" },
//     { name: "April", value: "apr" },
//     { name: "May", value: "may" },
//     { name: "June", value: "jun" },
//     { name: "July", value: "jul" },
//     { name: "August", value: "aug" },
//     { name: "September", value: "sep" },
//     { name: "October", value: "oct" },
//     { name: "November", value: "nov" },
//     { name: "December", value: "dec" },
// ]

export  const months=[
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
