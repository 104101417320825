import React, { useEffect, useState } from 'react'
import { Link, useSearchParams, useParams } from 'react-router-dom'
import useDebounce from '../../../hooks/useDebounce'
import useFullPageLoader from '../../../hooks/useFullPageLoader'
import { Avatar, IconButton } from '@mui/material'
import Switch from '../../../components/Switch/Switch'
import NotFound from '../../../components/not-found/NotFound'
import Pagination from 'react-js-pagination'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { music } from '../../../routes/route'
import common from '../../../routes/common'
import { toastMessage } from '../../../utils/toastMessage'
import findSerialNumber from '../../../utils/findSerialNumber'
import { charLimit } from '../../../utils/charLimit'
import { fetchMusicListHandler } from '../../../services/musicManagement'
import EditIcon from '@mui/icons-material/Edit'
import showFullSizeImage from '../../../utils/showFullSizeImage'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import AddMusic from './AddMusic'
import MusicStatus from './MusicStatus'
import EditMusic from './EditMusic'
import scrollToUp from '../../../utils/scrollToUp'
import PlayMusic from './PlayMusic'
import { MdLibraryMusic } from 'react-icons/md'
import { getLocalStorage, setLocalStorage } from '../../../config/config'
import ImagePreviewModel from '../../../components/imagePreview/ImagePreviewModel'
import AddIcon from '@mui/icons-material/Add';

const { NOT_FOUND } = common
const { MUSIC_DETAIL, MUSIC_LIST } = music

const MusicList = () => {
  const { musicCategory, id } = useParams()
  // handle all state
  const [searchParam, setSearchParam] = useSearchParams()
  let activePage = +searchParam.get('page') || 1
  // const [search, setSearch] = useState(searchParam.get('query') || '')

  const [search, setSearch] = useState(
    getLocalStorage('musicList', 'search') || ''
  )
  const debounceValue = useDebounce(search || '', 300)

  let limit = +searchParam.get('limit') || 10
  const [addMusicModal, setAddMusicModal] = useState(false)
  const [editMusicModel, setEditMusicModel] = useState(false)
  const [musicList, setMusicList] = useState([])
  const [total, setTotal] = useState(0)
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [statusModal, setStatusModal] = useState(false)
  const [statusValue, setStatusValue] = useState('')
  const [statusId, setStatusId] = useState('')
  const [selectedMusicDetail, setSelectedMusicDetail] = useState(null)
  const [playMusicModel, setPlayMusicModel] = useState(false)
  const [showImagePreviewModel, setShowImagePreviewModel] = useState(false)
  const [imagePreview, setImagePreview] = useState('')
  const [imageTitle, setImageTitle] = useState('')

  // Function to set localStorage values whenever the state variables change
  useEffect(() => {
    setLocalStorage('musicList', 'search', search)
  }, [search])

  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true)
    setStatusId(id)
    setStatusValue(status)
  }

  // update music category handler
  const updateMusicHandler = (data) => {
    setSelectedMusicDetail(data || null)
    setEditMusicModel(true)
  }

  // Play music handler
  const playMusicHandler = (data) => {
    setSelectedMusicDetail(data)
    setPlayMusicModel(true)
  }

  const showImagePreviewHandler = (music) => {
    setShowImagePreviewModel(true)
    setImagePreview(music.musicImage)
    setImageTitle(music.title)
  }

  //*********Handel Block********* */
  // const blockHandler = (id, status) => {
  //     setBlockModal(true);
  //     setBlockId(id);
  //     // setStatusValue(status);
  // };
  //*********Handle update********* */
  // const updateHandler = (id, enName, arName, imageUrl, categoryId) => {
  //   setUpdateModal(true)
  //   setUpdateSubCategoryData({
  //     id,
  //     enName,
  //     arName,
  //     imageUrl,
  //     categoryId,
  //   })
  // }
  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set('page', event)
    setSearchParam(searchParam)
  }

  // close model

  const closeModel = () => {
    setSelectedMusicDetail(null)
    setStatusValue('')
    setStatusId('')
    setImagePreview('')
    setImageTitle('')
    setAddMusicModal(false)
    setStatusModal(false)
    setEditMusicModel(false)
    setPlayMusicModel(false)
    setShowImagePreviewModel(false)
  }

  // //------------------listing-music-Api----------------------------------
  const fetchMusicList = async () => {
    try {
      setMusicList([])
      showLoader()
      const sendData = {
        musicId: id,
        page: activePage,
        limit: 10,
      }
      if (debounceValue) {
        sendData.page = 1
        sendData.search = debounceValue
        searchParam.set('page', 1)
        setSearchParam(searchParam)
      }
      const { data } = await fetchMusicListHandler(sendData)
      if (data.code === 200) {
        setMusicList(data?.result?.list || [])
        setTotal(data?.result?.total || 0)
        scrollToUp()
      } else {
        setMusicList([])
        setTotal(0)
        toastMessage(data.message, 'error', 'musicListHandler')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      hideLoader()
    }
  }

  // useEffect(() => {
  //   if (debounceValue) {
  //     searchParam.set('query', debounceValue)
  //   } else {
  //     searchParam.delete('query')
  //   }
  //   if (activePage === 1) {
  //     searchParam.delete('page')
  //   }
  //   setSearchParam(searchParam)
  //   fetchCategoryHandler(activePage, debounceValue)
  // }, [searchParam, debounceValue])
  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete('page')
    }
    setSearchParam(searchParam)
    fetchMusicList()
  }, [searchParam])
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <div className="user_detail_heading">
              {/* <h4 className="Title">User Management</h4> */}
              <Link className="back_icon" to={-3}>
                <IconButton>
                  <ArrowBackIosNewIcon className="back_button" />
                </IconButton>
              </Link>
              <h4 className="Title">{musicCategory || ''}</h4>
            </div>
            <div className="block_user_button">
              <button onClick={() => setAddMusicModal(true)}><AddIcon/>Add Music</button>
            </div>
          </div>

          <div className="FilterBox FilterArea ">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search || ''}
                style={{ '--color': '#FF8989' }}
                onChange={(e) => setSearch(e.target.value)}
                // disabled={musicList.length === 0}
              />
              <span className="user_search_icon">{common.SEARCH_ICON}</span>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  {/* <th>Music Id</th> */}
                  <th>Music Name</th>
                  <th>Music</th>
                  <th>Music Image</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>

              <tbody>
                {musicList.length > 0 ? (
                  musicList.map((music, index) => {
                    return (
                      <React.Fragment key={music._id}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 5)}</td>
                          {/* <td>{music._id || '---'}</td> */}
                          <td>{charLimit(music.title, 30) || '---'}</td>
                          <td
                            className="music_icon"
                            onClick={() => playMusicHandler(music)}
                          >
                            {/* {music.music || '---'} */}
                            <MdLibraryMusic />
                          </td>

                          <td>
                            <Avatar
                              id={music._id}
                              alt={music.title}
                              src={music.musicImage}
                              sx={{
                                width: 50,
                                height: 50,
                                cursor: 'pointer',
                                borderRadius: 2,
                              }}
                              onClick={() => showImagePreviewHandler(music)}
                            />
                          </td>

                          <td>
                            <div className="action_button">
                              {/* <Link to={`${MUSIC_DETAIL}/${music._id}`}>
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                >
                                  <VisibilityIcon className="eye_icon" />
                                </IconButton>
                              </Link> */}
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() => updateMusicHandler(music)}
                              >
                                <EditIcon className="edit_icon" />
                              </IconButton>
                              <Switch
                                checked={music.status}
                                onChange={() =>
                                  statusUpdateHandler(music._id, music.status)
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })
                ) : (
                  <NotFound
                    cols={7}
                    msg="Music not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total >= 5 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  itemsCountPerPage={5}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}

          {/* <FloatingButton
            onClick={() => setAddModalShow(true)}
            icon={<AddIcon />}
          /> */}
        </div>
        {addMusicModal && (
          <AddMusic
            show={addMusicModal}
            close={closeModel}
            listing={fetchMusicList}
            musicId={id}
          />
        )}
        {statusModal && (
          <MusicStatus
            show={statusModal}
            close={closeModel}
            setListing={setMusicList}
            status={statusValue}
            id={statusId}
          />
        )}
        {editMusicModel && (
          <EditMusic
            show={editMusicModel}
            close={closeModel}
            musicDetail={selectedMusicDetail}
            setMusicDetail={setSelectedMusicDetail}
            musicListing={fetchMusicList}
          />
        )}
        {playMusicModel && (
          <PlayMusic
            show={playMusicModel}
            close={closeModel}
            musicDetail={selectedMusicDetail}
            setMusicDetail={setSelectedMusicDetail}
          />
        )}
        {showImagePreviewModel && (
          <ImagePreviewModel
            show={showImagePreviewModel}
            close={closeModel}
            imageUrl={imagePreview}
            title={imageTitle}
          />
        )}
      </div>
    </>
  )
}

export default MusicList
