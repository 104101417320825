import Dashboard from "../pages/dashboard/Dashboard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { FaUserAlt, FaUsers } from "react-icons/fa";
// import ProductManagement from '../pages/productManagement/ProductManagement'
import UserManagement from "../pages/userManagement/UserManagement";
import {
  auth,
  user,
  consultant,
  dashboard,
  category,
  banner,
  music,
  meditation,
  training,
  image,
  cms,
  vastuCompass,
  gift,
  community,
  explore,
  refer,
  request,
  discount,
  horoscope,
  live,
  notification,
  support,
  feedback,
  form,
  concern,
  transaction,
  offers,
  consultantPayout,
  shopify,
  commission,
  subadmin,
} from "./route";
import UserDetail from "../pages/userManagement/UserDetail";
import Category from "../pages/category/Category";
import QrCodeIcon from "@mui/icons-material/QrCode";
import common from "../routes/common";
import MantraList from "../pages/category/mantra/MantraList";
import BannerList from "../pages/bannerManagement/BannerList";
import MusicCategoryList from "../pages/musicManagement/MusicCategoryList";
import MusicList from "../pages/musicManagement/music/MusicList";
import MeditationCategoryList from "../pages/meditationManagement/MeditationCategoryList";
import MeditationList from "../pages/meditationManagement/meditation/MeditationList";
import ConsultantList from "../pages/consultantManagement.jsx/ConsultantList";
import ConsultantDetail from "../pages/consultantManagement.jsx/ConsultantDetail";
import TrainingList from "../pages/trainingManagement/Training/TrainingList";
import TrainingCategoryList from "../pages/trainingManagement/TrainingCategoryList";
import ImageList from "../pages/imageManagement/ImageList";
import ImageDetail from "../pages/imageManagement/ImageDetail";
import CmsManagement from "../pages/cmsManagement/CmsManagement";
import VastuCompassCategory from "../pages/vastuCompassCategory/VastuCompassCategory";
import ViewVastuCompass from "../pages/vastuCompassCategory/ViewVastuCompass";
import AddConsultant from "../pages/consultantManagement.jsx/AddConsultant";
import GiftManagement from "../pages/giftManagement/GiftManagement";
import CommunityManagement from "../pages/communityManagement/CommunityManagement";
import CommunityDetail from "../pages/communityManagement/CommunityDetail";
import ExploreManagement from "../pages/exploreManagement/ExploreManagement";
import ViewExplore from "../pages/exploreManagement/ViewExplore";
import EditConsultant from "../pages/consultantManagement.jsx/EditConsultant";
import ReferManagement from "../pages/referManagement/ReferManagement";
import RequestManagement from "../pages/requestManagement/RequestManagement";
import MediaList from "../pages/category/media/MediaList";
import DiscountManagement from "../pages/discount/DiscountManagement";
import HoroscopeManagement from "../pages/horoscopeManagement/HoroscopeManagement";
import ViewHoroscope from "../pages/horoscopeManagement/ViewHoroscope";
import LiveManagement from "../pages/liveManagement/LiveManagement";
import Notification from "../pages/notificationManagement/Notification";
import AddNotification from "../pages/notificationManagement/AddNotification";
import SupportManagement from "../pages/supportManagement/SupportManagement";
import AddSupport from "../pages/supportManagement/AddSupport";
import ViewSupport from "../pages/supportManagement/ViewSupport";
import FeedbackManagement from "../pages/feedbackManagement/FeedbackManagement";
import Form from "../pages/form/Form";
import GoToChat from "../pages/communityManagement/GoToChat";
import ConcernManagement from "../pages/concern&Remedy/ConcernManagement";
import RemedyList from "../pages/concern&Remedy/remedy/RemedyList";
import TransactionManagement from "../pages/transaction/TransactionManagement";
import OffersManagement from "../pages/offersManagement/OffersManagement";
import CheckPerformance from "../pages/consultantManagement.jsx/CheckPerformance";
import ConsultantPayout from "../pages/consultantPayout/ConsultantPayout";
import Shopify from "../pages/shopify/Shopify";
import ConsultantCommission from "../pages/consultantCommission/ConsultantCommission";
import SubAdmin from "../pages/subAdmin/SubAdmin";
import AddSubadmin from "../pages/subAdmin/AddSubadmin";
import ViewSubadmin from "../pages/subAdmin/ViewSubadmin";
import EditSubadmin from "../pages/subAdmin/EditSubadmin";
import DeleteConsultant from "../pages/consultantManagement.jsx/DeleteConsultant";
import DeleteUser from "../pages/userManagement/DeleteUser";
const { FORGET_PASSWORD_ROUTE, RESET_PASSWORD_ROUTE } = auth;
const { DASHBOARD } = dashboard;
const { USER_MANAGEMENT, USER_DETAIL , USER_DELETE } = user;
const {
  CONSULTANT_MANAGEMENT,
  CONSULTANT_DETAIL,
  CONSULTANT_ADD,
  CONSULTANT_EDIT,
  CONSULTANT_CHECK_PERFORMANCE,
  CONSULTANT_DELETE
} = consultant;
const { CATEGORY, ADD_MEDIA } = category;
const { BANNER } = banner;
const { MUSIC_LIST, MUSIC_DETAIL } = music;
const { MEDITATION_LIST, MEDITATION_DETAIL } = meditation;
const { TRAINING_LIST, TRAINING_DETAIL } = training;
const { IMAGE_LIST, IMAGE_DETAIL } = image;
const { CONTENT_MANAGEMENT } = cms;
const { VASTU_COMPASS_CATEGORY, VASTU_COMPASS_CATEGORY_DETAIL } = vastuCompass;
const { GIFT_MANAGEMENT } = gift;
const { COMMUNITY_MANAGEMENT, COMMUNITY_DETAIL, COMMUNITY_CHAT } = community;
const { EXPLORE_MANAGEMENT, EXPLORE_DETAIL } = explore;
const { REFER_MANAGEMENT } = refer;
const { REQUEST_MANAGEMENT } = request;
const { DISCOUNT_MANAGEMENT } = discount;
const { HOROSCOPE_MANAGEMENT, VIEW } = horoscope;
const { LIVE_MANAGEMENT } = live;
const { NOTIFICATION_MANAGEMENT, ADD_NOTIFICATION } = notification;
const { SUPPORT_MANAGEMENT, ADD_SUPPORT, VIEW_SUPPORT } = support;
const { FEEDBACK_MANAGEMENT } = feedback;
const { FORM } = form;
const { SHOPIFY } = shopify;
const { CONCERN, CONCERN_DETAIL } = concern;
const { TRANSACTION } = transaction;
const { OFFERS } = offers;
const { PAYOUT } = consultantPayout;
const { COMMISSION } = commission;
const { SUBADMIN, ADDSUBADMIN, EDITSUBADMIN, VIEWSUBADMIN } = subadmin;

const showModule = (key, role) => {
  // console.log({ key, role });

  if (key) {
    return true;
  } else if (role === "admin") {
    return true;
  } else {
    return false;
  }
};

export const sidebar = (rightsInfo = {}, role) => {
  const allSidebar = [
    {
      id: 123,
      enabled: showModule(
        rightsInfo["consultant-payout-management"],
        role,
        // "Consultant Payout"
      ),
      // enabled: rightsInfo["consultant-payout"] || role === "admin",
      // enabled: rightsInfo?.consultant-payout || role === "admin",

      name: "Consultant Payout",
      path: PAYOUT,
      icon: common.PAYOUT_ICON,
      isChild: null,
    },
    {
      id: 101,
      enabled: true,
      name: "Dashboard",
      path: DASHBOARD,
      icon: common.DASHBOARD_ICON,
      isChild: null,
    },
    {
      id: 102,
      // enabled: true,
      enabled: showModule(
        rightsInfo["user-management"],
        role,
   
      ),
      name: "User Management",
      path: USER_MANAGEMENT,
      icon: common.USER_ICON,
      isChild: null,
    },
    {
      id: 103,
      enabled: true,
      enabled: showModule(rightsInfo["consultant-management"], role),
      name: "Consultant Management",
      path: CONSULTANT_MANAGEMENT,
      icon: common.CONSULTANT_ICON,
      isChild: null,
    },
    {
      id: 1003,
      // enabled: true,
      enabled: showModule(rightsInfo["  shopify-commission-management"], role),
      name: "Shopify Commission",

      path: COMMISSION,
      icon: common.COMMISSION,
      isChild: null,
    },
    {
      id: 104,
      // enabled: true,
      name: "Expertise Management",
      enabled: showModule(rightsInfo["expertise-management"], role),
      path: CATEGORY,
      icon: common.CATEGORY_ICON,
      isChild: null,
    },
    {
      id: 105,
      // enabled: true,
      name: "Banner Management",
      enabled: showModule(rightsInfo["banner-management"], role),
      path: BANNER,
      icon: common.CATEGORY_ICON,
      isChild: null,
    },
    {
      id: 106,
      // enabled: true,
      enabled: showModule(rightsInfo["music-management"], role),
      name: "Music Management",
      path: MUSIC_LIST,
      icon: common.MUSIC_ICON,
      isChild: null,
    },
    {
      id: 107,
      // enabled: true,
      enabled: showModule(rightsInfo["meditation-management"], role),
      name: "Meditation Management",
      path: MEDITATION_LIST,
      icon: common.MEDITATION_ICON,
      isChild: null,
    },
    {
      id: 108,
      // enabled: true,
      enabled: showModule(rightsInfo["training-management"], role),
      name: "Training Management",
      path: TRAINING_LIST,
      icon: common.TRAINING_ICON,
      isChild: null,
    },
    {
      id: 109,
      // enabled: true,
      enabled: showModule(rightsInfo["gallery-management"], role),
      name: "Image Management",
      path: IMAGE_LIST,
      icon: common.IMAGE_ICON,
      isChild: null,
    },
    {
      id: 10009,
      // enabled: true,
      enabled: showModule(rightsInfo["sub-admin-management"],role),
      name: "Subadmin Management",
      path: SUBADMIN,
      icon: common.SUBADMIN_ICON,
      isChild: null,
    },
    {
      id: 1009,
      // enabled: true,
      enabled: showModule(rightsInfo["content-management"], role),
      name: "Content Management",
      path: CONTENT_MANAGEMENT,
      icon: common.IMAGE_ICON,
      isChild: null,
    },
    {
      id: 110,
      // enabled: true,
      enabled: showModule(rightsInfo["vastu-management"],role),
      name: "Vastu Compass Category",
      path: VASTU_COMPASS_CATEGORY,
      icon: common.COMPASS_ICON,
      isChild: null,
    },
    {
      id: 111,
      enabled: showModule(rightsInfo["gift-management"], role),
      // enabled: true,
      name: "Gift Management",
      path: GIFT_MANAGEMENT,
      icon: common.GIFT_ICON,
      isChild: null,
    },
    {
      id: 112,
      enabled: showModule(rightsInfo["community-management"], role),
      // enabled: true,
      name: "Community Management",
      path: COMMUNITY_MANAGEMENT,
      icon: common.COMMUNITY_ICON,
      isChild: null,
    },
    {
      id: 113,
      enabled: showModule(rightsInfo["explore-management"], role),
      // enabled: true,
      name: "Explore Management",
      path: EXPLORE_MANAGEMENT,
      icon: common.EXPLORE_ICON,
      isChild: null,
    },
    {
      id: 114,
      enabled: showModule(rightsInfo["refer-management"], role),
      // enabled: true,
      name: "Refer Management",
      path: REFER_MANAGEMENT,
      icon: common.REFER_ICON,
      isChild: null,
    },
    {
      id: 115,
      // enabled: true,
      enabled: showModule(rightsInfo["request-management"], role),
      name: "Request Management",
      path: REQUEST_MANAGEMENT,
      icon: common.REQUEST_ICON,
      isChild: null,
    },
    {
      id: 116,
      enabled: showModule(rightsInfo["discount-management"], role),
      // enabled: true,
      name: "Discount Management",
      path: DISCOUNT_MANAGEMENT,
      icon: common.DISCOUNT_ICON,
      isChild: null,
    },
    // {
    //   id: 117,
    //   enabled: true,
    //   name: "Horoscope Management",
    //   path: HOROSCOPE_MANAGEMENT,
    //   icon: common.HOROSCOPE_ICON,
    //   isChild: null,
    // },
    {
      id: 118,
      enabled: showModule(rightsInfo["live-management"], role),
      // enabled: true,
      name: "Live Management",
      path: LIVE_MANAGEMENT,
      icon: common.LIVE_ICON,
      isChild: null,
    },
    {
      id: 120,
      enabled: showModule(rightsInfo["support-management"], role),
      // enabled: true,
      name: "Support Management",
      path: SUPPORT_MANAGEMENT,
      icon: common.SUPPORT_ICON,
      isChild: null,
    },
    {
      id: 121,
      enabled: showModule(rightsInfo["feedback-management"], role),
      // enabled: true,
      name: "Feedback Management",
      path: FEEDBACK_MANAGEMENT,
      icon: common.FEEDBACK_ICON,
      isChild: null,
    },

    {
      id: 119,
      enabled: showModule(rightsInfo["notification-management"], role),
      // enabled: true,
      name: "Notification Management",
      path: NOTIFICATION_MANAGEMENT,
      icon: common.NOTIFICATION_ICON,
      isChild: null,
    },
    // {
    //   id: 119,
    //   enabled: true,
    //   name: "Form 16A",
    //   path: FORM,
    //   icon: common.FORM_ICON,
    //   isChild: null,
    // },
    {
      id: 120,
      // enabled: true,
      enabled: showModule(rightsInfo["concern-management"], role),
      name: "Concern & Remedy",
      path: CONCERN,
      icon: common.CONCERN_ICON,
      isChild: null,
    },
    {
      id: 121,
      // enabled: true,
      enabled: showModule(rightsInfo["transaction-management"], role),
      name: "Transaction Management",
      path: TRANSACTION,
      icon: common.TRAINING_ICON,
      isChild: null,
    },
    {
      id: 122,
      // enabled: true,
      enabled: showModule(rightsInfo["offers-management"], role),
      name: "Offers Management",
      path: OFFERS,
      icon: common.OFFERS_ICON,
      isChild: null,
    },
    // {
    //   id: 104,
    //   enabled: true,
    //   name: 'Category Management',
    //   path: 'category',
    //   icon: <QrCodeIcon sx={{ fontSize: 18 }} />,
    //   isChild: [
    //     {
    //       id: 4001,
    //       enabled: true,
    //       name: 'Category',
    //       path: '/category-management',
    //       icon: <CategoryIcon sx={{ fontSize: 18 }} />,
    //     },
    //     {
    //       id: 4002,
    //       enabled: true,
    //       name: 'Sub Category',
    //       path: '/sub-category',
    //       icon: <BabyChangingStationIcon sx={{ fontSize: 18 }} />,
    //     },
    //   ],
    // },

    // {
    //   id: 106,
    //   enabled: true,
    //   name: 'Bidder Management',
    //   path: '/bidder-management',
    //   icon: <Person3RoundedIcon sx={{ fontSize: 18 }} />,
    //   isChild: null,
    // },

    // {
    //   id: 107,
    //   enabled: true,
    //   name: 'Seller Management',
    //   path: '/seller-management',
    //   icon: <LocalShippingIcon sx={{ fontSize: 18 }} />,
    //   isChild: null,
    // },
    // {
    //   id: 108,
    //   enabled: true,
    //   name: 'Bid History',
    //   path: '/bid-history',
    //   icon: <GavelIcon sx={{ fontSize: 18 }} />,
    //   isChild: null,
    // },
    // {
    //     id: 109,
    //     enabled: true,
    //     name: "Help & Support",
    //     path: "/help-and-support",
    //     icon: <SupportAgentIcon sx={{ fontSize: 18 }} />,
    //     isChild: null
    // },
    // {
    //     id: 110,
    //     enabled: true,
    //     name: "Notification Management",
    //     path: "/notification-management",
    //     icon: <NotificationsActiveIcon sx={{ fontSize: 18 }} />,
    //     isChild: null
    // },
    // {
    //     id: 111,
    //     enabled: true,
    //     name: "Payment History",
    //     path: "/payment-history",
    //     icon: <AccountBalanceIcon sx={{ fontSize: 18 }} />,
    //     isChild: null
    // },

    // {
    //     id: 112,
    //     enabled: true,
    //     name: "CMS Management",
    //     path: "/cms-management",
    //     icon: <MessageIcon sx={{ fontSize: 18 }} />,
    //     isChild: null
    // },
  ].filter((data) => data.enabled);
  return allSidebar;
};

export const routes = (rightsInfo, role) => {
  const allRoutes = [
    {
      id: 123,
      // enabled: true,
      enabled: showModule(rightsInfo?.["consultant-payout-management"] , role) ,
      element: <ConsultantPayout />,
      path: PAYOUT,
    },
    {
      id: 101,
      enabled: true,
      name: "Dashboard",
      element: <Dashboard />,
      path: DASHBOARD,
    },
    //---------------User-Management--------------------
    {
      id: 102,
      // enabled: true,
      enabled: showModule(rightsInfo?.["user-management"] ,role),
      element: <UserManagement />,
      path: USER_MANAGEMENT,
    },
    {
      id: 102,
      // enabled: true,
      enabled: showModule(rightsInfo?.["user-management"] ,role),
      element: <DeleteUser />,
      path: USER_DELETE,
    },
    {
      id: 103,
      // enabled: true,
      enabled: showModule(rightsInfo?.["user-management"] , role ),
      element: <UserDetail />,
      path: `${USER_DETAIL}/:id`,
    },

    //-----------------------------------
    // {
    //   id: 102,
    //   enabled: true,
    //   element: <SubAdmin />,
    //   path: '/sub-admin',
    // },
    //---------------Consultant-Management--------------------
    {
      id: 103,
      // enabled: true,
      enabled: showModule(rightsInfo?.["consultant-management"] ,role),
      element: <ConsultantList />,
      path: CONSULTANT_MANAGEMENT,
    },
    {
      id: 104,
      // enabled: true,
      enabled: showModule(rightsInfo?.["consultant-management"] ,role),
      element: <AddConsultant />,
      path: CONSULTANT_ADD,
    },
    {
      id: 10004,
      // enabled: true,
      enabled: showModule(rightsInfo?.["consultant-management"] ,role),
      element: <DeleteConsultant />,
      path: CONSULTANT_DELETE,
    },
    {
      id: 111,
      // enabled: true,
      enabled: showModule(rightsInfo?.["consultant-management"],role),
      element: <ConsultantDetail />,
      path: `${CONSULTANT_DETAIL}/:id`,
    },
    {
      id: 1111,
      // enabled: true,
      enabled: showModule(rightsInfo?.["consultant-management"] , role),
      element: <EditConsultant />,
      path: `${CONSULTANT_EDIT}/:id`,
    },
    {
      id: 1111,
      // enabled: true,
      element: <CheckPerformance />,
      enabled: showModule(rightsInfo?.["consultant-management"] ,role),
      path: `${CONSULTANT_CHECK_PERFORMANCE}/:id/:name`,
    },
    {
      id: 11101,
      // enabled: true,
      enabled: showModule(rightsInfo?.["consultant-management"] ,role),
      element: <ConsultantCommission />,
      path: `${COMMISSION}`,
    },
    //--------------sub-category---------------------
    {
      id: 104,
      // enabled: true,
      enabled: showModule(rightsInfo?.["expertise-management"] ,role),
      element: <Category />,
      path: CATEGORY,
    },
    {
      id: 105,
      // enabled: true,
      // element: <MantraList />,
      element: <MediaList />,
      path: `${ADD_MEDIA}/:id`,
    },

    //--------------Banner-management---------------------
    {
      id: 106,
      // enabled: true,
      enabled: showModule(rightsInfo?.["banner-management"] ,role),
      element: <BannerList />,
      path: BANNER,
    },

    // Music management
    {
      id: 107,
      // enabled: true,
      enabled: showModule(rightsInfo?.["music-management"] ,role),
      element: <MusicCategoryList />,
      path: MUSIC_LIST,
    },
    {
      id: 108,
      // enabled: true,
      enabled: showModule(rightsInfo?.["music-management"], role),
      element: <MusicList />,
      path: `${MUSIC_DETAIL}/:musicCategory/:id`,
    },

    // Meditation management
    {
      id: 109,
      // enabled: true,
      enabled: showModule(rightsInfo?.["meditation-management"] ,role ),
      element: <MeditationCategoryList />,
      path: MEDITATION_LIST,
    },
    {
      id: 110,
      // enabled: true,
      enabled: showModule(rightsInfo?.["meditation-management"] , role ),

      element: <MeditationList />,
      path: `${MEDITATION_DETAIL}/:meditationCategory/:id`,
    },

    // Training
    {
      id: 112,
      enabled: showModule(rightsInfo?.["training-management"], role),
      // enabled: true,
      element: <TrainingCategoryList />,
      path: TRAINING_LIST,
    },
    // ${TRAINING_DETAIL}/${training.title}/${training._id}
    {
      id: 112,
      enabled: showModule(rightsInfo?.["training-management"] ,role),
      // enabled: true,
      element: <TrainingList />,
      path: `${TRAINING_DETAIL}/:trainingCategory/:id`,
    },

    // image management
    {
      id: 113,
      // enabled: true,
      enabled: showModule(rightsInfo?.["gallery-management"],role),
      element: <ImageList />,
      path: IMAGE_LIST,
    },
    {
      id: 114,
      // enabled: true,
      enabled: showModule(rightsInfo?.["gallery-management"] , role),
      element: <ImageDetail />,
      path: `${IMAGE_DETAIL}/:imageType/:id`,
    },
    // content management
    {
      id: 115,
      // enabled: true,
      enabled: showModule(rightsInfo?.["content-management"] ,role),
      element: <CmsManagement />,
      path: CONTENT_MANAGEMENT,
    },
    //  vastu compass management
    {
      id: 116,
      // enabled: true,
      enabled: showModule(rightsInfo?.["vastu-management"] , role),
      element: <VastuCompassCategory />,
      path: VASTU_COMPASS_CATEGORY,
    },
    {
      id: 117,
      // enabled: true,
      enabled: showModule(rightsInfo?.["vastu-management"] ,role),
      element: <ViewVastuCompass />,
      path: `${VASTU_COMPASS_CATEGORY_DETAIL}/:id`,
    },
    {
      id: 118,
      // enabled: true,
      enabled: showModule(rightsInfo?.["gift-management"] ,role),
      element: <GiftManagement />,
      path: GIFT_MANAGEMENT,
    },
    {
      id: 118,
      // enabled: true,
      enabled: showModule(rightsInfo?.["community-management"],role),
      element: <CommunityManagement />,
      path: COMMUNITY_MANAGEMENT,
    },
    {
      id: 119,
      // enabled: true,
      enabled: showModule(rightsInfo?.["community-management"] , role),
      element: <CommunityDetail />,
      path: `${COMMUNITY_DETAIL}/:id`,
    },
    {
      id: 201,
      // enabled: true,
      enabled: showModule(rightsInfo?.["community-management"] , role),
      element: <GoToChat />,
      path: `${COMMUNITY_CHAT}/:id`,
    },

    {
      id: 118,
      // enabled: true,
      enabled: showModule(rightsInfo?.["explore-management"] , role),
      element: <ExploreManagement />,
      path: EXPLORE_MANAGEMENT,
    },
    {
      id: 118,
      // enabled: true,
      enabled: showModule(rightsInfo?.["explore-management"] , role ),
      element: <ViewExplore />,
      path: `${EXPLORE_DETAIL}/:id`,
    },
    {
      id: 1001,
      // enabled: true,
      enabled: showModule(rightsInfo?.["refer-management"] , role),
      element: <ReferManagement />,
      path: REFER_MANAGEMENT,
    },
    {
      id: 1002,
      // enabled: true,
      enabled: showModule(rightsInfo?.["request-management"] ,role ),
      element: <RequestManagement />,
      path: REQUEST_MANAGEMENT,
    },
    {
      id: 1003,
      // enabled: true,
      enabled: showModule(rightsInfo?.["discount-management"] ,role ),
      element: <DiscountManagement />,
      path: DISCOUNT_MANAGEMENT,
    },
    // {
    //   id: 1004,
    // enabled: true,
    //   element: <HoroscopeManagement />,
    //   path: HOROSCOPE_MANAGEMENT,
    // },
    {
      id: 1004,
      enabled: true,
      element: <ViewHoroscope />,
      path: `${VIEW}/:id`,
    },
    {
      id: 1005,
      // enabled: true,
      enabled: showModule(rightsInfo?.["live-management"] ,role ),
      element: <LiveManagement />,
      path: LIVE_MANAGEMENT,
    },
    {
      id: 1008,
      // enabled: true,
      enabled: showModule(rightsInfo?.["support-management"] , role),
      element: <SupportManagement />,
      path: SUPPORT_MANAGEMENT,
    },
    {
      id: 1009,
      // enabled: true,
      enabled: showModule(rightsInfo?.["support-management"] , role),
      element: <AddSupport />,
      path: ADD_SUPPORT,
    },
    {
      id: 1009,
      // enabled: true,
      enabled: showModule(rightsInfo?.["support-management"] ,role),
      element: <ViewSupport />,
      path: `${VIEW_SUPPORT}/:status/:id/:name`,
    },
    {
      id: 1010,
      // enabled: true,
      enabled: showModule(rightsInfo?.["feedback-management"] , role),
      element: <FeedbackManagement />,
      path: FEEDBACK_MANAGEMENT,
    },
    {
      id: 1006,
      // enabled: true,
      enabled: showModule(rightsInfo?.["notification-management"] ,role),
      element: <Notification />,
      path: NOTIFICATION_MANAGEMENT,
    },
    {
      id: 1007,
      // enabled: true,
      enabled: showModule(rightsInfo?.["notification-management"] , role ),
      element: <AddNotification />,
      path: ADD_NOTIFICATION,
    },
    {
      id: 1008,
      // enabled: true,
      enabled: showModule(rightsInfo?.["consultant-management"], role),
      element: <Form />,
      path: FORM,
    },
    {
      id: 100001,
      // enabled: true,
      enabled: showModule(rightsInfo?.["consultant-management"] , role),
      element: <Shopify />,
      path: SHOPIFY,
    },
    {
      id: 1009,
      // enabled: true,
      enabled: showModule(rightsInfo?.["concern-management"] , role ),
      element: <ConcernManagement />,
      path: CONCERN,
    },
    {
      id: 1010,
      // enabled: true,
      enabled: showModule(rightsInfo?.["concern-management"] ,role ),
      element: <RemedyList />,
      path: `${CONCERN_DETAIL}/:concern/:id`,
    },
    {
      id: 1011,
      // enabled: true,
      enabled: showModule(rightsInfo?.["transaction-management"] ,role ),
      element: <TransactionManagement />,
      path: TRANSACTION,
    },
    {
      id: 1012,
      // enabled: true,
      enabled: showModule(rightsInfo?.["offers-management"] , role ),
      element: <OffersManagement />,
      path: OFFERS,
    },
    {
      id: 1013,
      // enabled: true,
      enabled: showModule(rightsInfo?.["sub-admin-management"] ,role),
      element: <SubAdmin />,
      path: SUBADMIN,
    },
    {
      id: 1014,
      // enabled: true,
      enabled: showModule(rightsInfo?.["sub-admin-management"] , role),
      element: <AddSubadmin />,
      path: ADDSUBADMIN,
    },
    {
      id: 1015,
      // enabled: true,
      enabled: showModule(rightsInfo?.["sub-admin-management"] , role),
      // element: <ViewSubadmin />,
      path: VIEWSUBADMIN,
    },
    {
      id: 1016,
      // enabled: true,
      enabled: showModule(rightsInfo?.["sub-admin-management"] , role),
      element: <EditSubadmin />,
      path: EDITSUBADMIN,
    },
  ].filter((data) => data.enabled);
  return allRoutes;
};
