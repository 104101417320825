// addBannerApi
import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from '../../config/axiosInstance'

export const getConsultantPayoutApi = async (data) => {
  const endPoint =
    `/admin/transaction/payout/list` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// before payout
export const getbeforeConsultantPayoutApi = async (data) => {
  const endPoint =
    `/admin/payout` + '?' + new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// ------------------------Import  Consultant Payout------------------
export const importExcelApi = async (data) => {
  const endPoint = `/admin/transaction/payout/upload/file`
  return await axios.post(endPoint, data, setMultiPartHeader())
}
