import React, { useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import { Input } from "../../components/Form";
import useButtonLoader from "../../hooks/UseButtonLoader";
import {allowAlphaNumeric } from "../../utils/regex";
import { toastMessage } from "../../utils/toastMessage";
import { AddConcernApi } from "../../services/concern/Index";

const AddConcern = ({ show, close, listing }) => {
  // handle all state
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader("Save");


  //----------vaidation------------
  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!title) {
      errorMessage["title"] = "Title is mandatory";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  //------------------modal-close--------------------------
  const modalClose = () => {
    setTitle("");
    setError("");
    close();
  };
  //--------------Add-Music-category------------------------
  const addConcernHandler = async () => {
    const status = validation();
    if (status) {
      try {
        buttonLoader(true);
        const formData = { title: title };

        const { data } = await AddConcernApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addConcern");
          listing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addConcern");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={"Add Concern"}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <button
            className="btn_size"
            ref={buttonref}
            onClick={addConcernHandler}
          >
            Save
          </button>
        </div>
      }category
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={title || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowAlphaNumeric(e.target.value);
              setTitle(sanitizedText);
            }}
            placeholder="Category Title"
            className={`form-control`}
            label={"Title"}
            error={!title && error.title}
            maxLength={40}
          />
        </div>
      </div>
    </ModalBox>
  );
};

export default AddConcern;
