import React, { useEffect, useState, Fragment } from "react";
import Pagination from "react-js-pagination";
import NotFound from "../../../../components/not-found/NotFound";
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";
import { Link, useSearchParams } from "react-router-dom";
import IconButton from "../../../../components/Button/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { SiMicrosoftexcel } from "react-icons/si";
import { IoSearch } from "react-icons/io5";
import profileImage from "../../../../Assests/image/profile_not_found.png";
import findSerialNumber from "../../../../utils/findSerialNumber";

import { toastMessage } from "../../../../utils/toastMessage";
import { charLimit } from "../../../../utils/charLimit";
import {
  dateFormat,
  dateFormatUtc,
  timeFormat,
} from "../../../../utils/dateFormat";
import EditIcon from "@mui/icons-material/Edit";

const Technical = ({
  supportList,
  activePage,
  setSelectedData,
  setEditModal,
  setTitleModal,
  setDescriptionModal,
  setTitle,
  setDescription,
}) => {
  // console.log(supportList,"Support List");

  return (
    <Fragment>
      <div className="content-table">
        <table>
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Question</th>
              <th>Description</th>
              {/* <th>Support Type</th> */}
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {supportList && supportList.length > 0 ? (
              supportList.map((supportData, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{findSerialNumber(index, activePage, 10)}</td>
                      <td
                        onClick={() => {
                          if (
                            supportData.question &&
                            supportData.question.length > 50
                          ) {
                            setTitleModal(true);
                            setTitle(supportData.question);
                          }
                        }}
                      >
                        {supportData?.question &&
                          (supportData.question.length > 50
                            ? charLimit(supportData.question, 50)
                            : supportData.question || "---")}
                      </td>

                      <td
                        onClick={() => {
                          if (
                            supportData.description &&
                            supportData.description.length > 50
                          ) {
                            setDescriptionModal(true);
                            setDescription(supportData.description);
                          }
                        }}
                      >
                        {supportData?.description &&
                          (supportData.description.length > 50
                            ? charLimit(supportData.description, 50)
                            : supportData.description || "---")}
                      </td>
                      {/* <td>{supportData?.supportType || "---"}</td> */}
                      <td>
                        <div className="action_button">
                          {/* <Link to="#"> */}
                          <IconButton
                            aria-label="update"
                            className="px-2 py-0"
                            onClick={() => {
                              setEditModal(true);
                              setSelectedData(supportData);
                            }}
                          >
                            <EditIcon className="edit_icon" />
                          </IconButton>
                          {/* </Link> */}
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            ) : (
              <NotFound cols={6} msg="Request not found!" />
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default Technical;
