import {
    instance as axios,
    setJwtToken,
    setMultiPartHeader,
  } from "../../config/axiosInstance";
  
  // ---------------------fetch-conern-listing--------------------------
  export const fetchOffersApi = async (data) => {
    const endPoint =
      `/admin/discount/offers` + "?" + new URLSearchParams({ ...data }).toString();
    return await axios.get(endPoint, setJwtToken());
  };

// ---------------------add-offer---------------------------
export const AddOfferApi = async (data) => {
    const endPoint = `/admin/discount/offers/add`;
    return await axios.post(endPoint, data, setJwtToken());
  };
  
 // ---------------------change-offer-status---------------------------
  export const changeOfferStatus = async (data) => {
    const endPoint =
      `/admin/discount/offers/block_unblock` +
      "?" +
      new URLSearchParams({ ...data }).toString();
    return await axios.get(endPoint, setJwtToken());
  };
  
 // ---------------------update-Offer---------------------------
  export const updateOfferApi = async (data) => {
    const endPoint = `/admin/discount/offers/update`;
    return await axios.put(endPoint, data, setJwtToken());
  };