import React, { useState } from "react";
import ModalBox from "../../../components/modal/ModalBox";
import { Input, TextArea } from "../../../components/Form";
import useButtonLoader from "../../../hooks/UseButtonLoader";
import {
  regex,
  optimizeFunction,
  allowAlphaNumericWithSpace,
} from "../../../utils/regex";
import { toastMessage } from "../../../utils/toastMessage";
import { addRemedyApi } from "../../../services/concern/Index";

const AddRemedy = ({ show, close, listing, remedyId }) => {
  const [remedy, setRemedy] = useState({
    areaOfConcernId: remedyId,
    title: "",
    description: "",
  });
  const [error, setError] = useState({});

  const [buttonref, buttonLoader] = useButtonLoader("Save");

  //----------vaidation------------
  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!remedy.title) {
      errorMessage["title"] = "Title is mandatory";
      status = false;
    }
    if (!remedy.description) {
      errorMessage["description"] = "Description is mandatory";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setRemedy({});
    setError({});
    close();
  };
  //--------------Add-sub-category------------------------
  const addRemedyHandler = async () => {
    const status = validation();
    if (status) {
      try {
        buttonLoader(true);
        const { data } = await addRemedyApi(remedy);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addRemedyHandler");
          listing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addRemedyHandler");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={"Add Remedy"}
      size={"sm"}
      closeIcon
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <button
            className="btn_size"
            ref={buttonref}
            onClick={addRemedyHandler}
          >
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={remedy.title || ""}
            required
            onChange={(e) => {
              // const sanitizedText = allowAlphaNumericWithSpace(e.target.value);
              setRemedy({ ...remedy, title: e.target.value });
            }}
            placeholder="Remedy Title"
            className={`form-control`}
            label={"Title"}
            error={!remedy.title && error.title}
          />
        </div>
        <div className="form-group my-2">
          <TextArea
            type="text"
            name="description"
            value={remedy.description || ""}
            required
            onChange={(e) => {
              setRemedy({ ...remedy, description: e.target.value });
            }}
            placeholder="Remedy Description"
            className={`form-control`}
            label={"Description"}
            error={!remedy.description && error.description}
          />
        </div>
      </div>
    </ModalBox>
  );
};

export default AddRemedy;
