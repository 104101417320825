const rightsObject = {
  "user-management": {
    type: Boolean,
  },
  "consultant-management": {
    type: Boolean,
  },
  "expertise-management": {
    type: Boolean,
  },
  "banner-management": {
    type: Boolean,
  },
  "music-management": {
    type: Boolean,
  },
  "meditation-management": {
    type: Boolean,
  },
  "training-management": {
    type: Boolean,
  },
  "gallery-management": {
    type: Boolean,
  },
  "content-management": {
    type: Boolean,
  },
  "vastu-management": {
    type: Boolean,
  },
  "gift-management": {
    type: Boolean,
  },
  "community-management": {
    type: Boolean,
  },
  "explore-management": {
    type: Boolean,
  },
  "refer-management": {
    type: Boolean,
  },
  "request-management": {
    type: Boolean,
  },
  "discount-management": {
    type: Boolean,
  },
  "horoscope-management": {
    type: Boolean,
  },
  "live-management": {
    type: Boolean,
  },
  "support-management": {
    type: Boolean,
  },
  "feedback-management": {
    type: Boolean,
  },
  "notification-management": {
    type: Boolean,
  },
  "concern-management": {
    type: Boolean,
  },
  "transaction-management": {
    type: Boolean,
  },
  "offers-management": {
    type: Boolean,
  },
  "sub-admin-management": {
    type: Boolean,
  },
  "shopify-commission-management": {
    type: Boolean,
  },
  "consultant-payout-management": {
    type: Boolean,
  },
};

export const rights = Object.keys(rightsObject).map((key) => ({
  name: key
    .replace(/-/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase())
    .replace(/Management/g, "Management"),
  value: key,
  status: false,
}));
