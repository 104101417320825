import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import NotFound from "../../components/not-found/NotFound";
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";
import { Link, useSearchParams } from "react-router-dom";
import IconButton from "../../components/Button/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BlockIcon from "@mui/icons-material/Block";
import Switch from "../../components/Switch/Switch";
import { SecondaryButton } from "../../components/Button/Button";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { DatePicker, Select } from "../../components/Form";
import { toast } from "sonner";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
// import { getOrderApi } from '../../services/orderManagement'
import { SiMicrosoftexcel } from "react-icons/si";
import { IoSearch } from "react-icons/io5";
import profileImage from "../../Assests/image/profile_not_found.png";
import { user } from "../../routes/route";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import findSerialNumber from "../../utils/findSerialNumber";

import { toastMessage } from "../../utils/toastMessage";
import { fatchCategoryApi } from "../../services/categoryManagement";
import { charLimit } from "../../utils/charLimit";
import common from "../../routes/common";
// import UserStatus from './UserStatus'
import { dateFormat, dateFormatUtc, timeFormat } from "../../utils/dateFormat";
import { gender } from "../../utils/genderList";
import scrollToUp from "../../utils/scrollToUp";
import { CapitalizeFirstWord } from "../../utils/CapitalizeFirstWord";
import Loader from "../../comman/Loader";
import useFileDownloader from "../../hooks/useFileDownloader";
import csv from "../../Assests/image/csv.svg";
import {
  fetchhoroscopeListApi,
  getRequestTypeDetail,
  requestAcceptRejectApi,
} from "../../services/requestManagement";
import { Avatar } from "@mui/material";
import { fetchHoroscopeApi } from "../../services/horoscope";
// import RequestDetail from "./RequestDetail";

const { USER_DETAIL } = user;
const { NOT_FOUND } = common;

// console.log('Gender', gender)

const HoroscopeManagement = () => {
  let URL = process.env.REACT_APP_API_URL;
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;

  const [search, setSearch] = useState(
    getLocalStorage("horoscopeList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);
  const [total, setTotal] = useState(0);
  const [horoscopeList, setHoroscopeList] = useState([]);
  const [selectedRequestDetail, setSelectedRequestDetail] = useState({});
  const [problemList, setProblemList] = useState([]);
  const [statusModal, setStatusModal] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [requestId, setRequestId] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [newData, setNewData] = useState({});
  const [currentData, setCurrentData] = useState({});

  const requestType = searchParam.get("requestType") || "";
  // const [statusSearch, setStatusSearch] = useState('')
  const registrationDate = searchParam.get("registrationDate") || "";
  const problemValue = searchParam.get("problem") || "";
  // const [endDateSearch, setEndDateSearch] = useState('')

  useEffect(() => {
    setLocalStorage("horoscopeList", "search", search);
  }, [search]);

  // handle all hooks
  const { loading, downloadFile } = useFileDownloader();

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true);
    setRequestId(id);
    setStatusValue(status);
  };

  // gender handler

  const requestTypeHandler = (value) => {
    if (requestType === value) {
      searchParam.delete("requestType");
    } else {
      searchParam.set("requestType", value);
    }
    searchParam.delete("page");
    setSearchParam(searchParam);
  };

  // Problem handler

  const problemValueHandler = (value) => {
    if (problemValue === value) {
      searchParam.delete("problem");
    } else {
      searchParam.set("problem", value);
    }
    searchParam.delete("page");
    setSearchParam(searchParam);
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };
  //------------------request-listing-Api----------------------------------
  const getHoroscopeList = async (debounceValue) => {
    try {
      showLoader();
      const sendData = {
        page: `${activePage}`,
        limit: "10",
      };
      if (debounceValue) {
        sendData.page = "1";
        sendData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      const { data } = await fetchHoroscopeApi(sendData);
      if (data.code === 200) {
        setHoroscopeList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setHoroscopeList([]);
        setTotal(0);
        toastMessage(data.message, "error", "horoscopeList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  //   Request Detail API
  const requestDetail = async () => {
    try {
      showLoader();
      const { data } = await getRequestTypeDetail({
        requestId: requestId.requestId,
      });
      if (data.code === 200) {
        setSelectedRequestDetail(data?.result?.reqList || []);
      } else {
        setSelectedRequestDetail({});
        toastMessage(data.message, "error", "requestDetail");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  const closeModel = () => {
    setStatusModal(false);
    setStatusValue("");
    setRequestId("");
  };

  // problem list handler function

  const fetchProblemHandler = async () => {
    try {
      showLoader();
      const { data } = await fatchCategoryApi();
      if (data.code === 200) {
        setProblemList(data?.result?.categoryList || []);
      } else {
        setProblemList([]);
        toastMessage(data.message, "error", "categoryList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  const handleRegistrationDate = (date) => {
    const utcDateString = dateFormatUtc(date);
    searchParam.delete("page");
    searchParam.set("registrationDate", utcDateString);
    setSearchParam(searchParam);
  };

  useEffect(() => {
    if (Number(activePage) === 1) {
      searchParam.delete("page");
    }
    setSearchParam(searchParam);
    getHoroscopeList(debounceValue);
  }, [searchParam, debounceValue]);

  useEffect(() => {
    if (viewModal) {
      requestDetail();
    }
  }, [viewModal]);
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Horoscope Management</h4>
          </div>
          <div className="FilterBox FilterArea">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className={`form-control ${search && "active"}`}
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">
                <IoSearch />
              </span>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Icon</th>
                  <th>Horoscope</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>
              <tbody>
                {horoscopeList.length > 0 ? (
                  horoscopeList.map((request, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          <td className="user_profile_image">
                            <img
                              src={request.horoscopeIcon || profileImage}
                              alt={`${request.title} profile image`}
                            />
                          </td>
                          <td>{request.title || "---"}</td>
                          <td>
                            <div className="action_button">
                              <Link to={`/horoscope-management/view/${request._id}`}>
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                >
                                  <VisibilityIcon className="eye_icon" />
                                </IconButton>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={6}
                    msg="Request not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total > 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default HoroscopeManagement;
