const VideoPreview = ({ videoUrl }) => {
  return (
    <div className="video-container">
      <video
        controls
        loop
        controlsList="nodownload noremoteplayback noplaybackrate foobar"
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
    </div>
  )
}
export default VideoPreview
