import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// ---------------------fatch-conern-listing--------------------------
export const fetchConcernApi = async (data) => {
  const endPoint =
    `/admin/concern` + "?" + new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// // ---------------------add-concern---------------------------
export const AddConcernApi = async (data) => {
  const endPoint = `/admin/concern/add`;
  return await axios.post(endPoint, data, setJwtToken());
};

// // ---------------------change-status-category---------------------------
export const changeConcernStatus = async (data) => {
  const endPoint =
    `/admin/concern/block_unblock` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// // ---------------------update-concern---------------------------
export const updateConcernApi = async (data) => {
  const endPoint = `/admin/concern/update`;
  return await axios.put(endPoint, data, setJwtToken());
};

//   Remedy Handler

// add remedy api handler
export const addRemedyApi = async (data) => {
  const endPoint = `/admin/concern/remedy/add`;
  return await axios.post(endPoint, data, setJwtToken());
};

// Remedy list handler

export const fetchRemedyListHandler = async (data) => {
  const endPoint =
    `/admin/concern/remedy` + "?" + new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Change Remedy status
export const changeRemedyStatus = async (data) => {
  const endPoint =
    `/admin/concern/remedy/block_unblock` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint,setJwtToken());
};

// update remedy 
export const updateRemedyApi = async (data) => {
  const endPoint = `/admin/concern/remedy/update`;
  return await axios.put(endPoint, data, setJwtToken());
};
