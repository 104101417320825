import {
    instance as axios,
    setJwtToken,
    setMultiPartHeader,
  } from '../../config/axiosInstance'
  
  // ---------------------fatch-category-listing--------------------------
  export const fetchDiscountApi = async (data) => {
    const endPoint =
      `/admin/discount` + '?' + new URLSearchParams({ ...data }).toString()
  
    return await axios.get(endPoint, setJwtToken())
  }

  // ---------------------change-membership-status---------------------------
export const changeMembershipStatus = async (data) => {
    const endPoint = `/admin/discount/block_unblock`
    return await axios.put(endPoint, data, setJwtToken())
  }

    // // ---------------------add Discounts---------------------------
export const addDiscountApi = async (data) => {
    const endPoint = `/admin/discount/add`
    return await axios.post(endPoint, data, setMultiPartHeader())
  }

// ---------------Edit Discounts---------------------
export const editDiscountApi = async (data) => {
    const endPoint = `/admin/discount/update`
    return await axios.put(endPoint, data, setMultiPartHeader())
  }

