import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from '../../config/axiosInstance'

// ---------------------fatch-music-listing--------------------------
export const fetchMusicApi = async (data) => {
  const endPoint =
    `/admin/music/list` + '?' + new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// // ---------------------add-category---------------------------
export const AddMusicCategoryApi = async (data) => {
  const endPoint = `/admin/music/add`
  return await axios.post(endPoint, data, setMultiPartHeader())
}
// // ---------------------change-status-category---------------------------
export const changeMusicStatusCategory = async (data) => {
  const endPoint = `/admin/music/block_unblock`
  return await axios.put(endPoint, data, setJwtToken())
}
// update music cateogry

export const updateMusicCategoryApi = async (data) => {
  const endPoint = `/admin/music/edit`
  return await axios.put(endPoint, data, setMultiPartHeader())
}

// Music handler
// add music api handler
export const addMusicApiHandler = async (data) => {
  const endPoint = `/admin/music/add_media`
  return await axios.post(endPoint, data, setMultiPartHeader())
}

// music list handler

export const fetchMusicListHandler = async (data) => {
  const endPoint =
    `/admin/music/media_list` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// Change music status
export const changeMusicStatus = async (data) => {
  const endPoint = `/admin/music/media_block_unblock`
  return await axios.put(endPoint, data, setJwtToken())
}

// update music media
export const updateMusicMediaApi = async (data) => {
  const endPoint = `/admin/music/edit_media`
  return await axios.put(endPoint, data, setMultiPartHeader())
}
