import React, { useState, useEffect } from "react";
import { CustomTab, CustomTabs } from "../../components/tabs/Tabs";
import { useParams, useSearchParams } from "react-router-dom";
import Editor from "./section/Editor";
import {
  vastuZoneApi,
  viewVastuCategoryApi,
} from "../../services/vastuCompassCategory";
import { toastMessage } from "../../utils/toastMessage";
import useButtonLoader from "../../hooks/UseButtonLoader";
import HindiEditor from "./section/HindiEditor";
// import SimpleSlider from "../../comman/Slider";
import useFullPageLoader from "../../hooks/useFullPageLoader";

const ViewVastuCompass = () => {
  const [buttonLoader, setButtonLoader] = useButtonLoader("Save");
  const [searchParam, setSearchParam] = useSearchParams();
  const [zoneData, setZoneData] = useState({});
  const [error, setError] = useState({});
  const [pdfFile, setPdfFile] = useState("");
  const { id } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const zoneTypeParam = searchParam.get("zone") || "red";

  const headingTitle = {
    red: "Red Zone",
    green: "Green Zone",
    yellow: "Yellow Zone",
  }[zoneTypeParam];

  // Get Zone Details
  const getZoneDetails = async () => {
    try {
      setZoneData("");
      showLoader();
      const data = {
        vastuCategoryId: id,
        zone: zoneTypeParam,
      };
      const res = await viewVastuCategoryApi(data);
      if (res?.data?.code === 200) {
        setZoneData(res?.data?.result || "");
        setPdfFile(res?.data?.result?.vastuCategory?.vastuCompassCategoryImage);
      } else {
        toastMessage(res?.data?.message, "error", "zoneDetails");
        setZoneData("");
      }
    } catch (e) {
      console.log("Error:", e.message);
    } finally {
      hideLoader();
    }
  };

  // Validation for zone data
  const validation = () => {
    let errorMessage = {};
    let status = true;
    if (zoneTypeParam === "red") {
      if (
        !zoneData.descriptionEngish ||
        zoneData.descriptionEngish === "<p><br></p>"
      ) {
        errorMessage["descriptionEngish"] = `Please enter english description`;
        status = false;
      }
      if (
        !zoneData.descriptionHindi ||
        zoneData.descriptionHindi === "<p><br></p>"
      ) {
        errorMessage["descriptionHindi"] = `Please enter hindi description`;
        status = false;
      }
      // if (!pdfFile) {
      //   errorMessage["pdfFile"] = `Please upload pdf file`;
      //   status = false;
      // }
    }
    if (zoneTypeParam === "green") {
      if (
        !zoneData.descriptionEngish ||
        zoneData.descriptionEngish === "<p><br></p>"
      ) {
        errorMessage["descriptionEngish"] = `Please enter english description`;
        status = false;
      }
      if (
        !zoneData.descriptionHindi ||
        zoneData.descriptionHindi === "<p><br></p>"
      ) {
        errorMessage["descriptionHindi"] = `Please enter hindi description`;
        status = false;
      }
      // if (!pdfFile) {
      //   errorMessage["pdfFile"] = `Please upload pdf file`;
      //   status = false;
      // }
    }
    if (zoneTypeParam === "yellow") {
      if (
        !zoneData.descriptionEngish ||
        zoneData.descriptionEngish === "<p><br></p>"
      ) {
        errorMessage["descriptionEngish"] = `Please enter english description`;
        status = false;
      }
      if (
        !zoneData.descriptionHindi ||
        zoneData.descriptionHindi === "<p><br></p>"
      ) {
        errorMessage["descriptionHindi"] = `Please enter hindi description`;
        status = false;
      }
      // if (!pdfFile) {
      //   errorMessage["pdfFile"] = `Please upload pdf file`;
      //   status = false;
      // }
    }

    setError(errorMessage);
    return status;
  };

  // Update Zone
  const updateVastuZone = async (e) => {
    e.preventDefault();
    if (validation()) {
      const formData = new FormData();
      formData.append("vastuCategoryId", id);
      formData.append("zone", zoneTypeParam);
      formData.append("descriptionEngish", zoneData.descriptionEngish);
      formData.append("descriptionHindi", zoneData.descriptionHindi);
      if (pdfFile && pdfFile.name) {
        formData.append("vastuCompassZoneFile", pdfFile);
      }
      try {
        setButtonLoader(true);
        const { data } = await vastuZoneApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "vastuZoneUpdate");
          getZoneDetails();
        } else {
          toastMessage(data.message, "error", "vastuZoneUpdate");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setButtonLoader(false);
      }
    }
  };

  const typeHandler = (type) => {
    setZoneData("");
    searchParam.set("zone", type);
    setSearchParam(searchParam);
  };

  useEffect(() => {
    setZoneData("");
    getZoneDetails();
    setError({});
  }, [searchParam]);

  return (
    <div className="WrapperArea">
      <div className="WrapperBox">
        <div className="TitleBox">
          <h4 className="Title">{zoneData?.vastuCategory?.title || ""}</h4>
        </div>
        <div
          className="col-sm-
            12
           col-form-label mx-auto mt-3"
        >
          <CustomTabs
            value={zoneTypeParam}
            onChange={(e, section) => {
              typeHandler(section);
            }}
            aria-label="Content"
            variant="fullWidth"
          >
            <CustomTab value="red" label="Red Zone" />
            <CustomTab value="green" label="Green Zone" />
            <CustomTab value="yellow" label="Yellow Zone" />
          </CustomTabs>
        </div>

        <div className="row">
          {loader}

          <Editor
            key={zoneTypeParam}
            zoneType={zoneTypeParam}
            title={headingTitle}
            language="English"
            zoneData={zoneData}
            setZoneData={setZoneData}
            error={error}
            setError={setError}
            pdfFile={pdfFile}
            setPdfFile={setPdfFile}
          />
        </div>
        <div className="row">
          <HindiEditor
            key={zoneTypeParam}
            zoneType={zoneTypeParam}
            title={headingTitle}
            language="Hindi"
            zoneData={zoneData}
            setZoneData={setZoneData}
            error={error}
            setError={setError}
          />
        </div>
        <div className="text-center mt-4">
          <button
            className="btn_size"
            onClick={updateVastuZone}
            ref={buttonLoader}
          >
            Save
          </button>
        </div>
        {/* <SimpleSlider /> */}
      </div>
    </div>
  );
};

export default ViewVastuCompass;
