import React from 'react'
import ModalBox from '../../components/modal/ModalBox'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { toastMessage } from '../../utils/toastMessage'
import { changeConcernStatus } from '../../services/concern/Index'
import { changeOfferStatus } from '../../services/offersManagement'

const OfferStatus = ({ show, close, status, id, setListing }) => {
  const [buttonref, buttonLoader] = useButtonLoader('Update')

  //--------------change-status-sub-category------------------------
  const changeOfferStatusHandler = async () => {
    try {
      buttonLoader(true)
      let sendData = { status: !status, offerId: id }
      const { data } = await changeOfferStatus(sendData)
      if (data.code === 200) {
        setListing((prevListing) => {
          return prevListing.map((offer) => {
            if (offer._id === id) {
              return { ...offer, status: !status }
            }
            return offer
          })
        })
        toastMessage(data.message, 'success', 'offerStatus')
        close()
      } else {
        toastMessage(data.message, 'error', 'offerStatus')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      buttonLoader(false)
    }
  }
//   console.log('button loader', buttonLoader)
  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Change Offer Status"
      description="Do you want to change the status of this offer?"
      action={changeOfferStatusHandler}
      ref={buttonref}
    />
  )
}

export default OfferStatus
