import React, { useState } from 'react'
import ModalBox from '../../components/modal/ModalBox'
import { Input } from '../../components/Form'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { regex, optimizeFunction,allowAlphaNumeric, allowAlphaNumericWithSpace } from '../../utils/regex'
import { Avatar } from '@mui/material'
import { toastMessage } from '../../utils/toastMessage'
import commonMessages from '../../utils/commonMessages'
import { AddTrainingCategoryApi } from '../../services/trainingManagement'
const { TRAINING_CATEGORY_TITLE } = commonMessages

const AddTrainingCategory = ({ show, close, listing }) => {
  // handle all state
  const [trainingCategoryTitle, setTrainingCategoryTitle] = useState('')
  const [error, setError] = useState({})

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader('Save')

  // handle all function

  // console.log("AddSubCategory",input)
  //----------vaidation------------
  const validation = () => {
    let errorMessage = {}
    let status = true

    if (!trainingCategoryTitle) {
      errorMessage['category'] = TRAINING_CATEGORY_TITLE
      status = false
    }

    setError(errorMessage)
    return status
  }

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setTrainingCategoryTitle('')
    setError({})
    close()
  }
  //--------------Add-Music-category------------------------
  const addTrainingCategoryHandler = async () => {
    const status = validation()
    if (status) {
      try {
        buttonLoader(true)
        const sendData = {
          title: trainingCategoryTitle,
        }
        const { data } = await AddTrainingCategoryApi(sendData)
        if (data.code === 200) {
          toastMessage(data.message, 'success', 'addTrainingCategory')
          listing()
          modalClose()
        } else {
          toastMessage(data.message, 'error', 'addTrainingCategory')
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        buttonLoader(false)
      }
    }
  }

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={'Add Training Category'}
      closeIcon
      size={'sm'}
      custom
      customizedFooter={
        <button
          className="btn_size"
          ref={buttonref}
          onClick={addTrainingCategoryHandler}
        >
          Save
        </button>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={trainingCategoryTitle || ''}
            required
            onChange={(e) => {
              const sanitizedText = allowAlphaNumericWithSpace(e.target.value)
              setTrainingCategoryTitle(sanitizedText)
            }}
            placeholder="Category Title"
            className={`form-control`}
            label={'Add Category'}
            error={!trainingCategoryTitle && error.category}
            maxLength={40}
          />
        </div>
      </div>
    </ModalBox>
  )
}

export default AddTrainingCategory
